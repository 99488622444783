<template>
  <div class="thumbnail_container">
    <ThemeObserver :theme="theme" />
    <div class="thumbnail-inner">
      <div class="title">
        <ul>
          <li class="title-01">CAR KNOB UX</li>
          <li class="title-02">SEOYON<span class="inactive_820w">&nbsp;</span>E<span class="inactive_820w">-</span>H<span class="inactive_820w">WA</span></li>
          <li class="title-03">MUG SWITCH</li>
          <li class="title-04">
            <span class="point-color">UX&nbsp;</span>Design<span class="point-color">.</span>
            <span class="name">Kim Huiok</span>
          </li>
          <li class="title-05">
            <span class="point-color">Motion&nbsp;</span>Design<span class="point-color">.</span>
            <span class="name">Min Hyejin</span>
          </li>
        </ul>
      </div>
      <div class="visual"></div>
    </div><!--//thumbnail inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail0",
  data() {
    return {
      theme: Theme.white,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 1280 ~ 1920 */
/* Default */

.thumbnail_container {
  width: 100vw;
  height: calc(var(--responsive) * 1080);
  position: relative;
  background: linear-gradient(126.67deg, #050E1F 0%, #0C142A 75%);
  font-family: var(--pretendard);
}

.thumbnail-inner {
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

/* title */

.title {
  width: calc(var(--responsive) * 624);
}

.title-01 {
  font-size: calc(var(--responsive) * 15);
  font-weight: 600;
  line-height: 1.200em;
  color: #DDDFE1;
  margin-bottom: 2.000em;
}

.title-02,
.title-03 {
  font-size: calc(var(--responsive) * 72);
  font-weight: 400;
  line-height: 1.194em;
  letter-spacing: -0.001em;
  color: #DDDFE1;
}

.title-03 {
  margin-bottom: 2.194em;
}

.title-04,
.title-05 {
  font-size: calc(var(--responsive) * 14);
  line-height: 1.214em;
  letter-spacing: -0.001em;
  color: #DDDFE1;
}

.title-04 {
  margin-bottom: 1.429em;
}

.title-04 .point-color,
.title-05 .point-color {
  color: rgba(168, 91, 255, 1);
}

.title-04 .name,
.title-05 .name {
  color: rgba(221, 223, 225, 0.49);
  margin-left: 0.714em;
}

/* visual */

.visual {
  width: calc(var(--responsive) * 774);
  height: calc(var(--responsive) * 854);
  background-image: url("../../../assets/img/2021_seoyoneh/img_1.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: calc(var(--responsive) * -200);
}

/*__Default*/

/* Animation */

.title-01 {
  opacity: 0;
  animation: ani01 1000ms ease-in-out 200ms 1 forwards;
}

.title-02 {
  opacity: 0;
  animation: ani01 1000ms ease-in-out 400ms 1 forwards;
}

.title-03 {
  opacity: 0;
  animation: ani01 1000ms ease-in-out 600ms 1 forwards;
}

.title-04 {
  opacity: 0;
  animation: ani01 1000ms ease-in-out 800ms 1 forwards;
}

.title-05 {
  opacity: 0;
  animation: ani01 1000ms ease-in-out 1000ms 1 forwards;
}

.visual {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: ani02 3000ms ease-in-out infinite forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
/*__Animation */

/* 반응형 */

@media screen and (max-width: 1200px) {
} /*__end*/

@media screen and (max-width: 820px) {

  .thumbnail_container {
    /* height: calc(var(--responsive) * 640); */
    height: 100vh;
  }

  .thumbnail-inner {
    width: calc(var(--responsive) * 280);
    align-items: flex-start;
  }

  /* title */

  .title {
    width: auto;
    margin-top: calc(var(--responsive) * 78);
  }

  .title-01 {
    font-size: calc(var(--responsive) * 12);
    margin-bottom: 1.417em;
  }

  .title-02,
  .title-03 {
    font-size: calc(var(--responsive) * 34);
    line-height: 1.206em;
  }

  .title-03 {
    margin-bottom: 1.324em;
  }

  .title-04,
  .title-05 {
    font-size: calc(var(--responsive) * 12);
    line-height: 1.167em;
  }

  .title-04 {
    margin-bottom: 1.250em;
  }

  /* visual */

  .visual {
    position: absolute;
    bottom: calc(var(--responsive) * 0);
    left: calc(var(--responsive) * 45);
    width: calc(var(--responsive) * 298.94);
    height: calc(var(--responsive) * 371);
    margin-right: initial;
  }



} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
