<template>
<div class="content_containers">
            <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                <div class="title_text">Homepage Design</div>
                <div class="body_text">(주)아진 기업 홈페이지 디자인입니다.<br class="active_425w"> 기업소개가 주 목적인 만큼 어려운 용어의 경우 인포그래픽을<br class="active_425w"> 활용하여 디자인했으며,<br class="inactive_768w">워드프레스를 활용하여 제작했습니다.</div>
            </div>
            <div class="image_01" :class="show1 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_01.jpg" alt="image" class="inactive_768w">
                <img src="@/assets/img/2020_ajin/m_ajin_01.jpg" alt="mobile image" class="active_768w">
            </div>
            <div class="image_02" :class="show2 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_02.jpg" alt="image" class="inactive_768w">
                <img src="@/assets/img/2020_ajin/m_ajin_02.jpg" alt="mobile image" class="active_768w">
            </div>
            <div class="image_03" :class="show3 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_03.jpg" alt="image" class="inactive_768w">
                <img src="@/assets/img/2020_ajin/m_ajin_03.jpg" alt="mobile image" class="active_768w">
            </div>
            <div class="image_04" :class="show4 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_04.jpg" alt="image" class="inactive_768w">
                <img src="@/assets/img/2020_ajin/m_ajin_04.jpg" alt="mobile image" class="active_768w">
            </div>
            <div class="image_05" :class="show5 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver5" class="observer_exception"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_05.jpg" alt="image" class="inactive_768w">
                <img src="@/assets/img/2020_ajin/m_ajin_05.jpg" alt="mobile image" class="active_768w">
            </div>
            <div class="image_06" :class="show6 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver6" class="observer_exception"></ShowObserver>
                <img src="@/assets/img/2020_ajin/ajin_06.jpg" alt="image" class="inactive_768w">
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "AjinDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
    isShowObserver6(value: boolean) {
      this.show6 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */
.observer_exception {
    height: 130%;
}

.content_containers {
    width: 100%;
    padding-top: 91px;
    content-visibility: auto;
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 220px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 19px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_containers div[class^="image_"] {
    width: 100;
    margin: 0;
    padding: 0;
}

.image_05,
.image_06 {
    background-color: #ebebeb;
}

/*__Default*/

/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_01.active img,
.image_02.active img,
.image_03.active img,
.image_04.active img,
.image_05.active img,
.image_06.active img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_01 img,
.image_02 img,
.image_03 img,
.image_04 img,
.image_05 img,
.image_06 img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:768px) {} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text {
        max-width: 86.66666vw;
    }

    .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 98px;
    }

    .content_containers .main_text .title_text {
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 54px;
        font-size: 12px;
        line-height: 1.5;
    }    

} /*__end*/


/*__반응형*/

</style>