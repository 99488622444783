
import TopNavi from "@/components/TopNavi.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "App",
  components: {
    TopNavi
  },
});
