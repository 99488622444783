<template>
   <div class="uiuxsitemap_containers">
      
     <div class="uiuxsitemap_sub_text numbering">
       <div class="sub_text_left">UI/UX_SITEMAP</div>
       <div class="sub_text_right">05</div>
     </div>
     <div class="uiuxsitemap_content content_wrap">
       <!-- wrap -->
       <div class="tit_text" :class="show0 ? 'active' : ''" style="position: relative;">
         <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
         <h3>Sitemap</h3>
       </div>
        
       <ul class="sitemap_body" :class="show1 ? 'active' : ''" style="position: relative;">
       <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
         <li class="map1">
           <ul>
             <li>Brand</li>
             <li>Story</li>
             <li>Process</li>
           </ul>
         </li>
         <li class="map2">
           <ul>
             <li>Make my <br class="active_1024w">cosmetics</li>
             <li>Customizing <br class="active_1024w">guide</li>
             <li>Skin type <br class="active_1024w">test</li>
             <li>Make my <br class="active_1024w">cosmetics</li>
             <li>B2B Service</li>
           </ul>
         </li>
         <li class="map3">
           <ul>
             <li>Shop</li>
             <li>Product</li>
             <li>Material</li>
           </ul>
         </li>
         <li class="map4">
           <ul>
             <li>Community</li>
             <li>Q&amp;A</li>
             <li>Review</li>
             <li>Notice</li>
             <li>Event</li>
           </ul>
         </li>
       </ul>
     </div><!-- //wrap -->
   </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail5",
  data() {
    return {
      show0: false,
      show1: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    color: #393531;
}
/*__ALl*/

.uiuxsitemap_containers {
    content-visibility: auto;
}

.uiuxsitemap_containers .uiuxsitemap_content {
    margin-top: 59px;
    padding-bottom: 141px;
}

.uiuxsitemap_containers .uiuxsitemap_content div.tit_text {
    text-align: center;
    margin-bottom: 76px;
}

.uiuxsitemap_containers .uiuxsitemap_content .sitemap_body {
    display: flex;
    justify-content: center;
}

.uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 60px;
    margin: 30px 20px;
    text-align: center;
    line-height: normal;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Noto Sans KR', sans-serif;
    color: #47423b;
    border: 0.5px solid #6b6154;
}

.uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 31px;
    opacity: 0.2;
    background-color: #6b6154;
    left: 50%;
    bottom: -31px;
}

.uiuxsitemap_containers .uiuxsitemap_content .map1 li:first-child::after {
    content: "";
    position: absolute;
    width: 42px;
    height: 1px;
    opacity: 0.2;
    background-color: #6b6154;
    top: 50%;
    right: -42px;
}

.uiuxsitemap_containers .uiuxsitemap_content .map2 li:first-child::after {
    content: "";
    position: absolute;
    width: 42px;
    height: 1px;
    opacity: 0.2;
    background-color: #6b6154;
    top: 50%;
    right: -42px;
}

.uiuxsitemap_containers .uiuxsitemap_content .map3 li:first-child::after {
    content: "";
    position: absolute;
    width: 42px;
    height: 1px;
    opacity: 0.2;
    background-color: #6b6154;
    top: 50%;
    right: -42px;
}

.uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li:last-child::before {
    display: none;
}

.uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li:nth-child(1) {
    color: #fff;
    font-weight: 500;
    border: none;
    background-color: #95846d;
}

/*__Default*/


/* Animation */

.uiuxsitemap_containers .tit_text.active h3{
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.uiuxsitemap_containers .tit_text h3{
    opacity: 0;
}

.sitemap_body.active .map1 ul li,
.sitemap_body.active .map2 ul li,
.sitemap_body.active .map3 ul li,
.sitemap_body.active .map4 ul li {
    opacity: 1;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.sitemap_body.active .map1 ul li:nth-child(1) {
    transition-delay: 0;
}

.sitemap_body.active .map1 ul li:nth-child(2) {
    transition-delay: .1s;
}

.sitemap_body.active .map1 ul li:nth-child(3) {
    transition-delay: .2s;
}


.sitemap_body.active .map2 ul li:nth-child(1) {
    transition-delay: .1s;
}

.sitemap_body.active .map2 ul li:nth-child(2) {
    transition-delay: .2s;
}

.sitemap_body.active .map2 ul li:nth-child(3) {
    transition-delay: .3s;
}

.sitemap_body.active .map2 ul li:nth-child(4) {
    transition-delay: .4s;
}

.sitemap_body.active .map2 ul li:nth-child(5) {
    transition-delay: .5s;
}

.sitemap_body.active .map3 ul li:nth-child(1) {
    transition-delay: .2s;
}

.sitemap_body.active .map3 ul li:nth-child(2) {
    transition-delay: .3s;
}

.sitemap_body.active .map3 ul li:nth-child(3) {
    transition-delay: .4s;
}

.sitemap_body.active .map4 ul li:nth-child(1) {
    transition-delay: .3s;
}

.sitemap_body.active .map4 ul li:nth-child(2) {
    transition-delay: .4s;
}

.sitemap_body.active .map4 ul li:nth-child(3) {
    transition-delay: .5s;
}

.sitemap_body.active .map4 ul li:nth-child(4) {
    transition-delay: .6s;
}

.sitemap_body.active .map4 ul li:nth-child(5) {
    transition-delay: .7s;
}

.sitemap_body .map1 ul li,
.sitemap_body .map2 ul li,
.sitemap_body .map3 ul li,
.sitemap_body .map4 ul li {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li {
        width: calc(100% / 4);
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li {
        width: calc(100% - 40px);
        font-size: 14px;
    }
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

   .uiuxsitemap_containers .uiuxsitemap_content div.tit_text {
        margin-bottom: 13.78205%;
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body {
        margin-top: -30px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body {
        margin: -14px;
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li {
        font-size: 12px;
        width: calc(100% - 28px);
        margin: 14px;
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li::before {
        height: 14px;
        bottom: -15px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body {
        margin: -8px;
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li {
        height: 44px;
        line-height: 1.3;
        font-size: 10px;
        width: calc(100% - 16px);
        margin: 8px;
    }

    .uiuxsitemap_containers .uiuxsitemap_content .sitemap_body li ul li::before {
        height: 9px;
        bottom: -10px;
    }

} /*__end*/


/*__반응형*/

</style>
