<template>
  <div class="thumbnail_containers">
    <ThemeObserver :theme=theme />
    <div class="thumbnail_content content_wrap">
      <div class="thumbnail_title_wrap">
        <div class="thumbnail_title">바로세움 연구소<br>BRAND &amp; DIGITAL<br class="active_425w"> CONSULTING</div>
        <div class="thumbnail_info01"><span>BRAND DESIGN</span><span>Kim Yuna<br>Kim Huiok</span></div>
        <div class="thumbnail_info02"><span>WEB DESIGN</span><span>Kim Yuna</span></div>
      </div>
    </div>
    <input class="thumimg_click" type="checkbox" onclick="">
    <div class="thumbnail_img"></div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      theme: Theme.white
    }
  },
  components: {
    ThemeObserver
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.thumbnail_containers {
    position: relative;
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    background-color: #264384;
    content-visibility: auto;
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 216px;
}

.thumbnail_title_wrap {
    display: flex;
    color: #fff;
}

.thumbnail_title_wrap .thumbnail_title {
    width: 413px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    font-family: 'NanumSquareRound', serif;
}

.thumbnail_title_wrap [class^="thumbnail_info0"] {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.25;
    color: rgba(255, 255, 255, 0.8);
}

.thumbnail_title_wrap [class^="thumbnail_info0"] span:first-child {
    font-weight: bold;
    margin-bottom: 6px;
}

.thumbnail_title_wrap .thumbnail_info01 {
    margin-left: 99px;
}

.thumbnail_title_wrap .thumbnail_info02 {
    margin-left: 85px;
}

/*thumbnail img*/

.thumbnail_img {
    position: absolute;
    bottom: 17.60416%;
    width: 100vw;
    height: 240px;
    white-space: nowrap;
    background-image: url(../../../assets/img/2020_baro/thumbnail_img.png);
    background-position: 0 center;
    background-size: 1920px;
    background-repeat: repeat-x;
}

input.thumimg_click {
    position: absolute;
    bottom: 17.60416%;
    width: 100vw;
    height: 240px;
    z-index: 1;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

/*__Default*/


/* Animation */

.thumbnail_title,
.thumbnail_info01,
.thumbnail_info02 {
    animation-name: thumani_tit;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

@-webkit-keyframes thumani_tit {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.thumbnail_img {
    animation-name: thumani;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

input.thumimg_click:checked ~ .thumbnail_img {
    animation-play-state: paused;
    opacity: 0.5;
    transition: 0.3s ease-in-out;
}

@keyframes thumani {
    0% {
        background-position: 0 center;
    }
    100% {
        background-position: -1920px center;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

   .thumbnail_title_wrap .thumbnail_info01 {
       margin-left: 8.25%;
   }

   .thumbnail_title_wrap .thumbnail_info02 {
       margin-left: 7.08333%;
   }    

} /*__end*/

@media screen and (max-width:768px) {
      .thumbnail_title_wrap {
          flex-wrap: wrap;
      }

      .thumbnail_title_wrap .thumbnail_title {
          margin-bottom: 34px;;
          width: 100%;
      }

      .thumbnail_title_wrap .thumbnail_info01 {
          margin-left: 0;
          width: 120px;
      }

      .thumbnail_title_wrap .thumbnail_info02 {
          margin-left: 20px;
          width: 120px;
      }

} /*__end*/

@media screen and (max-width:425px) {
      /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .thumbnail_containers .thumbnail_content {
        padding-top: 106px;
    }

    .thumbnail_img {
        bottom: 142px;
        height: 120px;
        background-size: cover;
    }

} /*__end*/


/*__반응형*/

</style>