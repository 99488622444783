<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="text-content" :class="show0 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
        <div class="title">
          <h3>Design<br>Concept.</h3>
        </div>
        <div class="description">
          <p>
            고정된 위치에서 보여지는 메뉴의 확장성이<br class="inactive_820w">
            우주의<br class="active_820w"> 끝없는 공간과 은하에 모여있는<br class="inactive_820w">
            행성들을 닮았다고<br class="active_820w"> 생각했습니다.<br class="inactive_820w">
            태양을 중심으로한 우리 태양계와<br>
            각 행성을 공전하는 위성의 형태를 모티브로<br>
            서연이화 mug switch의 환경을 우주로<br class="inactive_820w">
            비유했으며,<br class="active_820w"> <span class="inactive_820w">각 </span>메뉴를 행성과 위성으로<br class="inactive_820w">
            비유<span class="active_820w inline">해</span><span class="inactive_820w">하여</span> 디자인<span class="inactive_820w"> 작업을 진행</span>했습니다.
          </p>
        </div>
      </div>
      <div class="visual-content" :class="show1 ? 'active' : ''">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="around item">
          <span>Around</span>
        </div>
        <div class="circle item">
          <span>Circle</span>
        </div>
        <div class="universe item">
          <span>Universe</span>
        </div>
        <div class="shining item">
          <span>Shining</span>
        </div>
        <div class="planet item">
          <span>Planet</span>
        </div>
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail1",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 1080);
  background-image: url("../../../assets/img/2021_seoyoneh/detail01-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--pretendard);
}

.container-inner {
  display: flex;
  justify-content: space-between;
  width: calc(var(--responsive) * 1200);
  height: calc(var(--responsive) * 760);
  margin-top: calc(var(--responsive) * 40);
}

/* text content */

.text-content {
  margin-top: calc(var(--responsive) * 140);
}

.title {
  font-size: calc(var(--responsive) * 54);
  line-height: 1.204em;
  font-weight: 400;
  color: #fff;
  margin-bottom: 2.407em;
}

.description {
  font-size: calc(var(--responsive) * 15);
  line-height: 1.933em;
  font-weight: 400;
  letter-spacing: -0.001em;
  color: #DDDFE1;
}


/* visual content */

.visual-content {
  position: relative;
  width: calc(var(--responsive) * 721);
  height: calc(var(--responsive) * 760);
}

.visual-content .item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.around,
.circle,
.shining,
.planet {
  width: calc(var(--responsive) * 180);
  height: calc(var(--responsive) * 180);
  background-image: url("../../../assets/img/2021_seoyoneh/detail01-img-s.webp");
}

.around span,
.circle span,
.shining span,
.planet span {
  color: #fff;
  font-size: calc(var(--responsive) * 24);
  letter-spacing: -0.001em;
  font-weight: 400;
}

.universe span {
  color: #fff;
  font-size: calc(var(--responsive) * 44);
  letter-spacing: -0.001em;
  font-weight: 400;
}

.around {
  top: calc(var(--responsive) * 47);
  left: 0;
}

.circle {
  top: 0;
  left: calc(var(--responsive) * 348);
}

.universe {
  width: calc(var(--responsive) * 362);
  height: calc(var(--responsive) * 362);
  top: calc(var(--responsive) * 206);
  left: calc(var(--responsive) * 116);
  background-image: url("../../../assets/img/2021_seoyoneh/detail01-img-l.webp");
}

.shining {
  top: calc(var(--responsive) * 267);
  right: 0;
}

.planet {
  bottom: 0;
  left: calc(var(--responsive) * 384);
}


/*__Default*/

/* Animation */
.text-content .title,
.text-content .description,
.visual-content .item {
  opacity: 0;
}

.text-content.active .title {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.text-content.active .description {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.visual-content.active .universe {
  animation: ani01 1000ms ease-in-out 400ms 1 forwards;
}

.visual-content.active .around {
  animation: ani01 1000ms ease-in-out 600ms 1 forwards;
}

.visual-content.active .circle {
  animation: ani01 1000ms ease-in-out 800ms 1 forwards;
}

.visual-content.active .shining {
  animation: ani01 1000ms ease-in-out 1000ms 1 forwards;
}

.visual-content.active .planet {
  animation: ani01 1000ms ease-in-out 1200ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container {
    height: calc(var(--responsive) * 812);
    background-size: auto 100%;
    background-position: 50% 0%;
  }

  .container-inner {
    width: calc(var(--responsive) * 280);
    height: auto;
    margin-top: calc(var(--responsive) * 5);
    flex-direction: column;
    justify-content: initial;
  }

  /* text content */

  .text-content {
    margin-top: initial;
    text-align: center;
    margin-bottom: calc(var(--responsive) * 92);
  }

  .title {
    font-size: calc(var(--responsive) * 30);
    margin-bottom: 1.333em;
  }

  .description {
    width: 100%;
    font-size: calc(var(--responsive) * 13);
    line-height: 1.692em;
  }
  
  /* visual content */
  
  .visual-content {
    position: relative;
    left: calc((100vw - var(--responsive) * 280) / 2 * -1);
    width: calc(var(--responsive) * 360);
    height: calc(var(--responsive) * 313);
  }

  .around,
  .circle,
  .shining,
  .planet {
    width: calc(var(--responsive) * 80);
    height: calc(var(--responsive) * 80);
  }

  .around span,
  .circle span,
  .shining span,
  .planet span {
    font-size: calc(var(--responsive) * 16);
  }

  .universe span {
    font-size: calc(var(--responsive) * 24);
  }

  .around {
    top: calc(var(--responsive) * 13);
    left: calc(var(--responsive) * 249);
  }

  .circle {
    top: 0;
    left: calc(var(--responsive) * 105);
  }

  .universe {
    width: calc(var(--responsive) * 168);
    height: calc(var(--responsive) * 168);
    top: calc(var(--responsive) * 78);
    left: calc(var(--responsive) * 125);
  }

  .shining {
    top: calc(var(--responsive) * 108);
    left: calc(var(--responsive) * 29);
    right: initial;
  }

  .planet {
    left: calc(var(--responsive) * 81);
  }


} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
