<template>
  <div ref="el" class="observer">
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import useShowIntersectionObserver from "@/composables/useShowIntersectionObserver.ts";

export default defineComponent({
  name: "ShowObserver",
  setup() {
    const el = ref(null)
    const {observe, unobserve, isShown} = useShowIntersectionObserver()

    onMounted(() => {
      observe(el.value)
    })

    onBeforeUnmount(() => {
      unobserve(el.value)
    })

    return {el, isShown}
  },
  watch: {
    isShown(value) {
      this.$emit('isShow', value)
    }
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.observer {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 130%;
  min-height: 150vh;
  z-index: -100;
}
</style>
