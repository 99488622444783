
    import {
        defineComponent
    } from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "CommercialDetail0",
        data() {
            return {

            }
        },
        components: {

        },
        methods: {

        }
    });
