<template>
  <div class="thumbnail_containers">
    <ThemeObserver :theme=theme />
    <div class="thumbnail_content">
      <div class="thumbnail_text">
        <div class="thumbnail_tit">
          <h1>LIVE SHOPPING PLATFORM</h1>
        </div>
        <div class="thumbnail_logo">
          <img src="@/assets/img/2019_vogo/thumbnail_logo1.png" alt="image">
          <img src="@/assets/img/2019_vogo/thumbnail_logo2.png" alt="image">
          <img src="@/assets/img/2019_vogo/thumbnail_logo3.png" alt="image">
          <img src="@/assets/img/2019_vogo/thumbnail_logo4.png" alt="image">
        </div>
        <div class="thumbnail_img"><img src="@/assets/img/2019_vogo/thumbnail_bg.png" alt="image"></div>
        <div class="thumbnail_info">
          <p>UX &amp; PROTOTYPE DESIGN</p>
          <span>© WACKY COMPANY</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "VogoDetail0",
  data() {
    return {
      theme: Theme.white
    }
  },
  components: {
    ThemeObserver
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */
html, body {
    overflow: initial;
}

.thumbnail_containers {
    width: 100vw;
    height: 100vh;
    /*    padding-bottom: 100vh;*/
    position: relative;
    background-color: #5f3ff3;
    content-visibility: auto;
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 227px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
    height: 56px;
    font-size: 38px;
    line-height: normal;
    font-weight: bold;
    color: #fff;
    margin-bottom: 23px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_logo {
    display: flex;
    width: 250px;
    margin-bottom: 270px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_logo img {
    width: calc(100% / 4);
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
    line-height: normal;
    color: #fff;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info p {
    height: 27px;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info span {
    height: 20px;
    font-size: 14px;
    line-height: normal;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fdd932;
    margin-bottom: 24px;
}

.thumbnail_containers .thumbnail_content .thumbnail_img {
    position: absolute;
    max-width: 516px;
    left: 50%;
    top: 14.4%;
    z-index: 10;

}

/*__Default*/


/* Animation */

.thumbnail_tit,
.thumbnail_info {
    animation-name: ani_text;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}


.thumbnail_logo img:nth-child(1) {
    animation-name: vogo_01;
    animation-delay: .5s;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_logo img:nth-child(2) {
    animation-name: vogo_02;
    animation-delay: 1s;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_logo img:nth-child(3) {
    animation-name: vogo_03;
    animation-delay: 1.5s;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_logo img:nth-child(4) {
    animation-name: vogo_04;
    animation-delay: 2s;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_text .thumbnail_info::before {
    animation-name: thumb_line;
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_img {
    animation-name: thumb_img;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}



/*애니메이션 키프레임*/

@-webkit-keyframes ani_text {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes vogo_01 {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes vogo_02 {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes vogo_03 {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes vogo_04 {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes thumb_line {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@-webkit-keyframes thumb_img {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        height: 52px;
        font-size: 34px;
        margin-bottom: 23px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_logo {
        width: 230px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info p {
        height: 23px;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 3px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info span {
        height: 18px;
        font-size: 12px;
        line-height: normal;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_img {
        position: absolute;
        max-width: 412.8px;
        left: 50%;
        top: 19%;
        z-index: 10;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .thumbnail_containers .thumbnail_content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-top: 68px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_logo {
        margin: 0 auto;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_img {
        position: relative;
        width: 63.3333vw;
        left: -6%;
        top: 0;
        margin: 0 auto;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        margin-top: -110px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
        margin-bottom: 100px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 86.66666vw;
    }
    
    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        font-size: 18px;
        height: auto;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_logo {
        width: 46.11111%;
        margin-bottom: 43px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_img {
        left: -7.5%;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        margin-top: -72px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
        height: 1px;
        margin-bottom: 82px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info p {
        height: 18px;
        font-size: 12px;
        margin-bottom: 2px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info span {
        height: 15px;
        font-size: 10px;
    }


} /*__end*/


/*__반응형*/

</style>
