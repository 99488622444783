import {createStore} from 'vuex';
import {Theme} from "@/enums/Theme";

interface ThemeValue {
    name: String,
    theme: Theme
}

export default createStore({
    state: {
        themes: []
    },
    getters: {
        currentTheme: function (state) {
            if (state.themes.length > 0) {
                return state.themes[0].value;
            }
            return Theme.dark;
        }
    },
    mutations: {
        pushNaviTheme(state, val: ThemeValue) {
            // console.log("pushNaviTheme : " + val.name + " || " + val.theme);
            // state.themes.forEach( (theme, index) => {
            //     console.log("pushNaviTheme themes " + index + " : " + theme.key + " || " + theme.value);
            // });
            let pushed = false;
            state.themes.forEach( (theme, index) => {
                if(theme.key === val.name) {
                    theme.value = val.theme;
                    pushed = true;
                    return;
                }
            });

            if (!pushed) {
                state.themes.push({key: val.name, value: val.theme});
            }
        },
        popNaviTheme(state, name: String) {
            // console.log("popNaviTheme : " + name);
            state.themes.forEach( (theme, index) => {
                if(theme.key === name) state.themes.splice(index,1);
            });
        },
        resetNaviTheme(state) {
            // console.log("resetNaviTheme");
            state.themes = [];
        }
    },
    modules: {}
});
