
import {defineComponent} from "vue";


export default defineComponent({
  name: "Visual",
  methods: {

  },
  
});
