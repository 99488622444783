import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import redirect from "./utils/Redirect";

//꼬모팝 QR코드 리다이렉트
redirect();
createApp(App)
  .use(router)
  .use(store)
  .mount("#app");
