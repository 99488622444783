<template>
    <div class="containers_03">
        <div class="container_wrap">
        <h3 class="content_tit">STORYBOARD</h3>
            <div class="content_body">
                <div class="item_img_01" :class="show0 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_09.jpg" alt="image">
                </div>
                <div class="item_img_02" :class="show1 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_10.jpg" alt="image">
                </div>
                <div class="item_img_03" :class="show2 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_11.jpg" alt="image">
                </div>
                <div class="item_img_04" :class="show3 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_12.jpg" alt="image">
                </div>
                <div class="item_img_05" :class="show4 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_13.jpg" alt="image">
                </div>
                <div class="item_img_06" :class="show5 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_14.jpg" alt="image">
                </div>
                <div class="item_img_07" :class="show6 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver6"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_15.jpg" alt="image">
                </div>
                <div class="item_img_08" :class="show7 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver7"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_16.jpg" alt="image">
                </div>
                <div class="item_img_09" :class="show8 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver8"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_17.jpg" alt="image">
                </div>
                <div class="item_img_10" :class="show9 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver9"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_18.jpg" alt="image">
                </div>
                <div class="item_img_11" :class="show10 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver10"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_19.jpg" alt="image">
                </div>
                <div class="item_img_12" :class="show11 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver11"></ShowObserver>    
                    <img src="@/assets/img/2021_momostick/momo_20.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "MomostickDetail3",
        data() {
          return {
              show0: false,
              show1: false,
              show2: false,
              show3: false,
              show4: false,
              show5: false,
              show6: false,
              show7: false,
              show8: false,
              show9: false,
              show10: false,
              show11: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
            isShowObserver2(value: boolean) {
            this.show2 = value;
          },
            isShowObserver3(value: boolean) {
            this.show3 = value;
          },
            isShowObserver4(value: boolean) {
            this.show4 = value;
          },
            isShowObserver5(value: boolean) {
            this.show5 = value;
          },
            isShowObserver6(value: boolean) {
            this.show6 = value;
          },
            isShowObserver7(value: boolean) {
            this.show7 = value;
          },
            isShowObserver8(value: boolean) {
            this.show8 = value;
          },
            isShowObserver9(value: boolean) {
            this.show9 = value;
          },
            isShowObserver10(value: boolean) {
            this.show10 = value;
          },
            isShowObserver11(value: boolean) {
            this.show11 = value;
          },
        }
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 24px;
    color: #383f6e;
    font-weight: 700;
    font-family: 'NanumSquare', serif;
    margin-bottom: 54px;
}

.container_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.containers_03 {
    position: relative;
    width: 100%;
    background: #f6f6f6;
    padding: 152px 0 337px;
}

.containers_03 .content_body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: -10px;   
}

.containers_03 [class^="item_img_"] {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

/*__Default*/


/* Animation */

[class^="item_img_"] {
    opacity: 0;
}

[class^="item_img_"].active  {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .container_wrap {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .containers_03 {
        padding: 122px 0 270px;
    }

    .content_tit {
        font-size: 19px;
    }   

} /*__end*/

@media screen and (max-width:768px) {
    .containers_03 {
        padding: 98px 0 216px;
    }

    .content_tit {
        font-size: 15px;
        margin-bottom: 25px;
    }

    .containers_03 .content_body {
        flex-direction: column;
        margin: -7px;   
    }

    .containers_03 [class^="item_img_"] {
        width: 100%;
        padding: 7px;
        box-sizing: border-box;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .containers_03 {
        padding: 42px 0 100px;
    }

    .container_wrap {
        max-width: 86.66666vw;
    }

    .containers_03 .content_body {
        flex-direction: column;
        margin: -3px;   
    }

    .containers_03 [class^="item_img_"] {
        width: 100%;
        padding: 3px;
        box-sizing: border-box;
    }

} /*__end*/


/*__반응형*/

</style>
