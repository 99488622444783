<template>
<div class="uiuxsub_containers">  
  <div class="uiuxsub_sub_text numbering">
    <div class="sub_text_left">UI/UX_SUB</div>
    <div class="sub_text_right">07</div>
  </div>
  <div class="uiuxsub_content">
    <!-- wrap -->
    <div class="uiuxsub_titwrap">
      <div class="tit_text" :class="show0 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <h3>Specialized <br class="active_1024w">Cosmetics Recipe</h3>
      </div>
      <div class="body_text" :class="show1 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <p>일러스트를 활용한 아이콘 타입등으로 피부진단 테스트, 나만의 화장품 만들기 시 사용자의 즉각적 판단이 가능합니다.<br class="inactive_768w">
        사이트를 통해 브랜드의 가치를 인지하고, 피부진단 테스트를 통해 나를 알아가고, 나 자신을 위한 화장품을 만들어 사용해보는 경험을 해보실 수 있습니다.</p>
      </div>
    </div>
    <div class="uiuxs_content_01" :class="show2 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxsub_01.png" alt="image">
    </div>
    <div class="uiuxs_content_02" :class="show3 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxsub_02.png" alt="image" class="inactive_1024w">
      <img src="@/assets/img/2021_ccb/m_uiuxsub_02.png" alt="image" class="active_1024w">
    </div>
    <div class="uiuxs_content_03" :class="show4 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxsub_03.png" alt="image" class="inactive_1024w">
      <img src="@/assets/img/2021_ccb/m_uiuxsub_03.png" alt="image" class="active_1024w">
    </div>
    <div class="uiuxs_content_04" :class="show5 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxsub_04.png" alt="image" class="inactive_1024w">
      <img src="@/assets/img/2021_ccb/m_uiuxsub_04.png" alt="image" class="active_1024w">
    </div>
  </div><!-- //wrap -->
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail7",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    color: #393531;
}
/*__ALl*/

.uiuxsub_containers {
    position: relative;
    background-color: #f1f1f1;
    z-index: -20;
    content-visibility: auto;
}

.uiuxsub_containers .uiuxsub_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 59px;
    text-align: center;
}

.uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap {
    max-width: 1200px;
    margin-bottom: 80px;
}

.uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap .tit_text {
    height: 75px;
    line-height: normal;
    margin-bottom: 52px;
}

.uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap .body_text {
    height: 48px;
    line-height: normal;
    letter-spacing: -0.1px;
}

.uiuxsub_containers .uiuxsub_content .uiuxs_content_01 {
    margin-bottom: 180px;
}

.uiuxsub_containers .uiuxsub_content .uiuxs_content_02 {
    margin-bottom: 200px;
    width: 100vw;
}

.uiuxsub_containers .uiuxsub_content .uiuxs_content_03 {
    margin-bottom: 223px;
}

.uiuxsub_containers .uiuxsub_content .uiuxs_content_04 {
    margin-bottom: 180px;
}

.uiuxsub_containers::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 772px;
    background-color: #fff;
}

/*__Default*/


/* Animation */

.uiuxsub_containers .tit_text.active,
.uiuxsub_containers .body_text.active,
.uiuxsub_containers .uiuxs_content_01.active,
.uiuxsub_containers .uiuxs_content_02.active,
.uiuxsub_containers .uiuxs_content_03.active,
.uiuxsub_containers .uiuxs_content_04.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.uiuxsub_containers .tit_text,
.uiuxsub_containers .body_text,
.uiuxsub_containers .uiuxs_content_01,
.uiuxsub_containers .uiuxs_content_02,
.uiuxsub_containers .uiuxs_content_03,
.uiuxsub_containers .uiuxs_content_04 {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering,
    .uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_01,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_03,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_04 {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .uiuxsub_containers .uiuxsub_content {
        margin-top: 13.7820%;
    }

    .uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap {
        margin-bottom: 12.8205%;
    }

    .uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap .tit_text {
        height: auto;
        margin-bottom: 7.6923%;
    }

    .uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap .body_text {
        height: auto;
    }

    .uiuxsub_containers .uiuxsub_content .uiuxs_content_01 {
        margin-bottom: 15.3846%;
    }

    .uiuxsub_containers .uiuxsub_content .uiuxs_content_02,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_03,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_04 {
        margin-bottom: 25%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering,
    .uiuxsub_containers .uiuxsub_content .uiuxsub_titwrap,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_01,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_03,
    .uiuxsub_containers .uiuxsub_content .uiuxs_content_04 {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

} /*__end*/


/*__반응형*/

</style>
