
import {defineComponent, onBeforeUnmount, onMounted, ref, watch} from "vue";
import useThemeIntersectionObserver from "@/composables/useThemeIntersectionObserver.ts";
import {useStore} from "vuex";
import {Theme} from "@/enums/Theme";
import { generateUID } from "@/utils/UUID"

export default defineComponent({
  name: "ThemeObserver",
  props: {
    theme: {
      default: Theme.dark
    }
  },
  setup(props) {
    const el = ref(null);

    let header = document.querySelector('header');
    const {observe, unobserve, isShown} = useThemeIntersectionObserver(header);

    onMounted(() => {
      observe(el.value)
    })

    onBeforeUnmount(() => {
      unobserve(el.value)
    })

    const uuid = generateUID();
    const store = useStore();
    const pushTheme = () => store.commit("pushNaviTheme", {
      name: uuid,
      theme: props.theme
    });
    const popTheme = () => store.commit("popNaviTheme", uuid);

    watch(() => props.theme, (current) => {
      pushTheme();
    })

    return {el, isShown, pushTheme, popTheme}
  },
  watch: {
    isShown(value) {
      this.$emit('isShow', value);

      if (value) {
        this.pushTheme();
      } else {
        this.popTheme();
      }
    }
  }
});
