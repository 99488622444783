<template>
    <div class="thumbnail_containers">    
        <div class="thumbnail_content">
            <div class="thumbnail_text">
                <div class="thumbnail_tit">
                    <h3>Wherever You Want</h3>
                    <img src="@/assets/img/2021_momostick/momo_02.png" alt="logo">
                </div>
                <div class="thumbnail_info">
                    <span class="info_01">Storytelling</span>
                    <span class="info_02">2D Illustration</span>
                    <span class="info_03">Motion</span>
                </div>
            </div>
        </div>   
    </div> 
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        name: "MomostickDetail0",
        data() {
          return {}
        },
        components: {
        },
        methods: {}
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


/* Default */
.thumbnail_containers {
    top: 0;
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    position: relative;
    background-image: url(../../../assets/img/2021_momostick/momo_01.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content-visibility: auto;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
}

.thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    /* margin-top: 188px; */
    padding-top: 188px;
}

.thumbnail_text .thumbnail_tit h3 {
    font-size: 28px;
    font-weight: 700;
    color: #383f6e;
    margin-bottom: 19px;
}

.thumbnail_text img {
    width: 281px;
}

.thumbnail_info {
    position: absolute;
    bottom: 18%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.thumbnail_info span {
    display: inline-block;
    height: 28px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 14px;
    padding: 3px 11px 5px 11px;
    font-weight: 700;
    color: #fff;
    background: #333b71;
    border-radius: 8px;
    opacity: .73;

}

/*__Default*/


/* Animation */

.thumbnail_tit {
    animation: thumbtit_ani 1.2s ease-in-out both;
}

@-webkit-keyframes thumbtit_ani {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.thumbnail_info {
    animation: thumbinfo_ani 1.2s ease-in-out 400ms both;
}

@-webkit-keyframes thumbinfo_ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_content {
        max-width: 90%;
    }   

} /*__end*/

@media screen and (max-width:1024px) {
    .thumbnail_text .thumbnail_tit h3 {
        font-size: 22px;
    }

    .thumbnail_text img {
        width: 225px;
    }

    .thumbnail_info span {
        font-size: 14px;
    }
    

} /*__end*/

@media screen and (max-width:768px) {
    .thumbnail_text .thumbnail_tit h3 {
        font-size: 18px;
    }

    .thumbnail_text img {
        width: 180px;
    }

    .thumbnail_info span {
        font-size: 12px;
        padding: 6px 10px;
        height: 13px;
        line-height: 13px;
        margin-bottom: 6px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .thumbnail_containers {
        background-image: url(../../../assets/img/2021_momostick/momo_01_m.jpg);
    }

    .thumbnail_content {
        max-width: 86.66666vw;
    }

    .thumbnail_info {
        bottom: 7.2%;
    }

} /*__end*/


/*__반응형*/

</style>
