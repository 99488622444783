<template>
  <div class="work_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
    <ThemeObserver :theme=theme v-on:isShow="isShowObserver0"/>
    <div class="item_containers">
      <div class="work_title">
        <div class="top_tit">
          <h2><span class="txt_ani_1">Design Creatively</span></h2>
          <span class="tit_line"></span>
        </div>
        <div class="bottom_tit">
          <h2><span class="txt_ani_2">Based on Systematic</span> <br class="active_425w"><span
              class="txt_ani_3">Process</span></h2>
        </div>
      </div>
      <div class="item_wrap">
        <transition-group name="list">
          <WorkItem :project="project" :key="project" v-for="project in displayProjects"/>
        </transition-group>
      </div>
      <div v-if="isMoreProject" class="more_project" v-on:click="moreProject">
        <button>More Project</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from "vue";
import {Theme} from "@/enums/Theme";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {useRoute, useRouter} from "vue-router";
import {WORKPROJECTS} from "@/constants/work-projects";
import WorkItem from "@/components/main/WorkItem.vue";

export default defineComponent({
  name: "Works",
  components: {
    ThemeObserver,
    WorkItem
  },
  setup() {
    const list = WORKPROJECTS;

    let moreCount = ref(0);
    let viewCount = ref(9);
    const route = useRoute();
    const router = useRouter();
    watch(() => route.query, query => {
      processViewCount();
    })

    const processViewCount = () => {
      moreCount.value = (route.query.more) ? Number(route.query.more) : 0;
      viewCount.value = 9 + (moreCount.value * 6);
      if (viewCount.value > list.length) {
        viewCount.value = list.length;
      }
    }

    onMounted(() => {
      processViewCount();
    });

    const moreProject = () => {
      if (viewCount.value < list.length) {
        moreCount.value += 1;
        router.replace({
          hash: route.hash,
          query: {
            more: moreCount.value.toString()
          }
        });
      }
    }

    return {
      list,
      viewCount,
      moreProject,
    }
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
  computed: {
    displayProjects() {
      return this.list.slice(0, this.viewCount);
    },
    isMoreProject() {
      return this.viewCount < this.list.length;
    },
  },
  data() {
    return {
      show0: false,
      theme: Theme.white
    }
  },
});
</script>

<style scoped lang="css">

/* Default */

div.observer.separate_second {
  height: 100%;
  min-height: inherit;
  top: 5%;
}

.work_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: calc(var(--responsive) * 183);
  padding-bottom: calc(var(--responsive) * 193);
  background: #000;
}

.item_containers {
  width: calc(var(--responsive) * 1200);
  margin: 0 auto;
}

.item_containers .work_title {
  margin-bottom: calc(var(--responsive) * 70);
  margin-left: 23%;
  overflow: hidden;
}

.item_containers .work_title .top_tit {
  position: relative;
  display: flex;
  align-items: center;
}

.item_containers .work_title div h2 {
  font-size: calc(var(--responsive) * 40);
  font-weight: 700;
  line-height: 1.28em;
  color: #fff;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
}

.item_containers .work_title .top_tit .tit_line {
  display: block;
  width: 100%;
  border-bottom: 0.5px solid #fff;
  margin-left: calc(var(--responsive) * 50);
}

.item_containers .item_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto -1.83333%;
  margin-bottom: -2.75%;
}

.more_project {
  margin-top: calc(var(--responsive) * 65);
}

.more_project button {
  position: relative;
  color: #fff;
  background: transparent;
  border: none;
  font-size: calc(var(--responsive) * 20);
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  text-decoration-line: underline;
  transition: .3s ease-in-out;
  transition-property: color;
  cursor: pointer;
}

.more_project button:hover {
  color: #fff400;
}

/*__Default*/

/* Animation */


.work_wrap.active .work_title h2 .txt_ani_1 {
  display: inline-block;
  animation: worktit_ani 1500ms cubic-bezier(0.22, 0.61, 0.36, 1) both;
}

.work_wrap.active .work_title .bottom_tit h2 {
  display: inline-block;
  animation: worktit_ani 1500ms cubic-bezier(0.22, 0.61, 0.36, 1) both;
  animation-delay: 300ms;
}


@keyframes worktit_ani {
  0% {
    transform: translateX(-102%);
  }
  100% {
    transform: translateX(0);
  }
}

.work_wrap.active .top_tit .tit_line {
  animation: work_line 1400ms ease-in-out both;
  animation-delay: 400ms;
}

@keyframes work_line {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

.work_wrap.active .item_wrap .work_item {
  opacity: 1;
  animation-name: item_ani;
  animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.work_wrap .item_wrap .work_item {
  opacity: 0;
}

.work_wrap.active .item_wrap > div:nth-child(1) {
  animation-duration: .6s;
}

.work_wrap.active .item_wrap > div:nth-child(2) {
  animation-duration: .7s;
}

.work_wrap.active .item_wrap > div:nth-child(3) {
  animation-duration: .8s;
}

.work_wrap.active .item_wrap > div:nth-child(4) {
  animation-duration: .7s;
}

.work_wrap.active .item_wrap > div:nth-child(5) {
  animation-duration: .8s;
}

.work_wrap.active .item_wrap > div:nth-child(6) {
  animation-duration: .9s;
}

.work_wrap.active .item_wrap > div:nth-child(7) {
  animation-duration: .8s;
}

.work_wrap.active .item_wrap > div:nth-child(8) {
  animation-duration: .9s;
}

.work_wrap.active .item_wrap > div:nth-child(9) {
  animation-duration: 1s;
}

@keyframes item_ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation*/

/* 반응형 */

@media screen and (max-width: 1024px) {

  .item_containers .item_wrap .work_item {
    width: calc((100% / 2) - 2.75%);
    padding-bottom: 29.33333%;
  }

}
/*__end*/

@media screen and (max-width: 820px) {
  .item_containers {
    width: calc(var(--responsive) * 312);
  }

  .work_wrap {
    padding-top: calc(var(--responsive) * 96);
    padding-bottom: calc(var(--responsive) * 96);
  }

  .item_containers .work_title {
    margin-left: inherit;
    margin-bottom: calc(var(--responsive) * 32);
  }

  .item_containers .work_title div h2 {
    font-size: min(calc(var(--responsive) * 24), 29px);
  }

  .item_containers .work_title .top_tit .tit_line {
    margin-left: calc(var(--responsive) * 17);
  }

  .more_project {
   margin-top: calc(var(--responsive) * 36);
  }

  .more_project button {
    font-size: min(calc(var(--responsive) * 14), 14px);
  }

}
/*__end*/

@media screen and (max-width: 550px) {
  .item_containers .item_wrap .work_item {
    width: calc((100%) - 2.75%);
    padding-bottom: 61.85897%;
  }

}
/*__end*/

@media screen and (max-width: 425px) {

  .work_wrap.active .work_title .bottom_tit h2 {
    animation: initial;
  }

  .work_wrap.active .work_title .bottom_tit h2 .txt_ani_2 {
    display: inline-block;
    animation: worktit_ani 1500ms cubic-bezier(0.22, 0.61, 0.36, 1) 300ms both;
  }

  .work_wrap.active .work_title .bottom_tit h2 .txt_ani_3 {
    display: inline-block;
    animation: worktit_ani 1500ms cubic-bezier(0.22, 0.61, 0.36, 1) 600ms both;
  }

}
/*__end*/

/*__반응형*/

</style>
