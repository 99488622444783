<template>
  <div class="work_item" :class="computeWorkItemClass()">
    <span v-if="isConfidentialProject()" class="hidden_text"
      >{{ projectName }}
      <span>{{ projectDescription || "Will Be Soon" }}</span>
    </span>
    <span v-if="isOnGoingProject()" class="hidden_text"
      >{{ projectName }}
      <span>On-Going</span>
    </span>
    <a href="javascript:void(0);" v-on:click="goDetail()">
      <img :src="projectThumbnail" alt="project_img" />
    </a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { ProjectType } from "@/enums/ProjectType";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Works",
  props: {
    project: Object,
  },
  setup(props) {
    const projectName = computed(() => props.project.name);
    const projectType = computed(() => props.project.type);
    const projectDescription = computed(() => props.project.description);
    const projectThumbnail = computed(() => props.project.thumbnail);

    const isConfidentialProject = () => {
      return props.project.type == ProjectType.CONFIDENTIAL;
    };

    const isOnGoingProject = () => {
      return props.project.type == ProjectType.ONGOING;
    };

    const computeWorkItemClass = () => {
      if (props.project.type == ProjectType.CONFIDENTIAL) {
        return "confidential";
      }

      if (props.project.type == ProjectType.ONGOING) {
        return "ongoing";
      }

      return "";
    };

    const router = useRouter();
    const goDetail = () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      if (props.project.type == ProjectType.NORMAL) {
        router.push("/works/" + props.project.name);
      }
    };

    return {
      projectName,
      projectType,
      projectDescription,
      projectThumbnail,
      isConfidentialProject,
      isOnGoingProject,
      goDetail,
      computeWorkItemClass,
    };
  },
});
</script>

<style scoped lang="css">
.item_containers .item_wrap .work_item {
  position: relative;
  width: calc((100% / 3) - 2.75%);
  height: auto;
  padding-bottom: 19.5%;
  margin-bottom: 2.75%;
  margin-left: 2.75%;
  overflow: hidden;
  box-sizing: border-box;
  transition-property: opacity;
}

/* transition-group */

.list-enter-active {
  opacity: 0;
  animation-duration: 700ms;
}

.item_containers .item_wrap .work_item a {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

/* 대외비 item class에 confidential 추가 */

.item_containers .item_wrap .confidential:hover::after {
  opacity: 1;
}

.item_containers .item_wrap .confidential::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 105%;
  cursor: pointer;
}

.item_wrap .confidential .hidden_text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  color: #fff;
  background: transparent;
  font-size: 18px;
  font-family: "Roboto", "Noto Sans KR", sans-serif;
  font-weight: 400;
  line-height: normal;
  z-index: 100;
  transition: 0.4s ease-in-out;
  opacity: 0;
  cursor: default;
  transition: opacity 500ms ease-in-out, background-color 300ms ease-in-out;
}

.item_wrap .confidential .hidden_text > span {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

.item_wrap .confidential:hover .hidden_text {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.item_containers .item_wrap .work_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item_containers .item_wrap .confidential > a > img {
  transition: filter, transform, 300ms ease-in-out;
}

.item_containers .item_wrap .confidential:hover > a > img {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  transform: scale(1.1);
}

/* ONGOING */

.item_containers .item_wrap .ongoing:hover::after {
  opacity: 1;
}

.item_containers .item_wrap .ongoing::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 105%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.item_wrap .ongoing .hidden_text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-family: "Roboto", "Noto Sans KR", sans-serif;
  font-weight: 400;
  z-index: 100;
  transition: 0.4s ease-in-out;
  opacity: 0;
  cursor: default;
}

.item_wrap .ongoing .hidden_text > span {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

.item_wrap .ongoing .hidden_text:hover {
  opacity: 1;
}

/*__대외비*/
</style>
