<template>
  <component
      v-for="compName in componentNames"
      v-bind:key="compName"
      v-bind:is="compName"
  />
</template>

<script lang="ts">
import {defineAsyncComponent, defineComponent, onMounted, VNode} from "vue";
import {useStore} from "vuex";


interface componentType {
  [key: string]: VNode;
}

let components: componentType = {};

export default defineComponent({
  name: "WorksDetail",
  props: ["projectName"],
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("resetNaviTheme");
    });

        function changeBodyColor() {
        document.querySelector("body").style.background = "inherit" ;
    }
    
    changeBodyColor();

  },
  computed: {

    componentNames(): any {
      const requireContext = require.context("@/components/details", true, /\.vue$/i);
      let names: Array<string> = requireContext
          .keys()
          .filter((path: String) => path.includes(this.projectName))
          .map((file) => file.replace(/^.*\//, ""));
      // console.log("names : " + names);
      names.forEach((component) => {
        components[component] = defineAsyncComponent(
            () => import("@/components/details/" + this.projectName + "/" + component + "")
        );
      });

      return names;
    }
  },
  components,
  created() {
    document.documentElement.style.overflow = 'auto';
  },
});


</script>

<style>
.wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

</style>
