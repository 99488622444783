<template>
  <div class="content_containers">
    <ShowObserver v-on:isShow="isShowObserver"></ShowObserver>
    <div class="content_05" :class="show ? 'active' : ''">
      <div class="title_text">USER PAGE</div>
      <div class="body_text">사용자의 정보 및 쿠폰을 조회하고, 1:1 문의를 작성합니다.</div>
      <div class="content_body">
        <div><img src="@/assets/img/2019_vogo/vogo_12.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_13.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_14.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_15.jpg" alt="app_screen_img">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail6",
  data() {
    return {
      show: false
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver(value: boolean) {
      this.show = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.content_containers .content_05 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 523px;
}

.content_containers .content_05 .title_text {
    margin-bottom: 12px;
}

.content_containers .content_05 .body_text {
    color: #383838;
    margin-bottom: 101px;
}

.content_containers .content_05 .content_body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 1082px;
    margin-left: -30px;
}

.content_containers .content_05 .content_body div {
    padding-left: 30px;
}

.content_containers .content_05 .content_body div img {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13);
}

/*__Default*/


/* Animation */

.content_containers .content_05.active .title_text,
.content_containers .content_05.active .body_text {
    opacity: 1;
    transition-duration: .8s;
}

.content_containers .content_05 .title_text,
.content_containers .content_05 .body_text {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.content_containers .content_05.active .content_body div {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.content_containers .content_05 .content_body div {
    opacity: 0;
    transform: translateY(200px);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {} /*__end*/

@media screen and (max-width:1024px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

    .content_containers .content_05 .content_body {
        max-width: 90%;
    }

    .content_containers .content_05 {
        margin-bottom: 418.4px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .content_05 .content_body {
        flex-wrap: wrap;
    }

    .content_containers .content_05 .content_body div {
        padding-bottom: 30px;
    }

    .content_containers .content_05 .content_body div {
        width: calc(100% / 2 - 30px);
    }

} /*__end*/

@media screen and (max-width:425px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/

    .content_containers .content_05 .content_body {
        width: 84.44444vw;
        margin-left: -16px;
    }

    .content_containers .content_05 .content_body div {
        width: calc(100% / 2 - 16px);
        padding-left: 16px;
        padding-bottom: 16px;
    }

    .content_containers .content_05 .body_text {
        margin-bottom: 48px;
    }

    .content_containers .content_05 {
        margin-bottom: 182px;
    }

} /*__end*/


/*__반응형*/

</style>
