import {createRouter, createWebHistory, RouterScrollBehavior} from 'vue-router'
import WorkDetail from "@/views/WorksDetail.vue";
import Main from "@/views/Main.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/works/:projectName",
    name: "WorkDetail",
    component: WorkDetail,
    props: true,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) {
      return { top: 0 };
    } else if (savedPosition) {
      return savedPosition;
    }
  }
})

export default router
