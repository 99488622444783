<template>
    <div class="containers_07">
      <div class="content_wrap">
        <div class="text_wrap" :class="show0 ? 'active' : ''" style="position: relative;"> 
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
           <div class="tit_box">
             <h3 class="common_tit">SONOTITE GUI Design</h3>
           </div>
           <div class="text_box">
             <h3 class="common_tit">Light Theme</h3>
             <div class="body_txt">
               SONOTITE는 유려한 디자인의 Light Theme와 고급스러움을 강조한 Dark Theme
               2가지로 제공됩니다. Light는 깔끔한 레이아웃과 볼드한 텍스트의 조화로 필요한
               정보만 명확하게 인지할 수 있도록 디자인했으며, Dark는 제품의 Black Line과
               스크린을 GUI 디자인으로 연결하여 제품의 장점을 극대화할 수 있도록 디자인했습니다.
             </div>
           </div>
        </div>
        <div class="light_item_01" :class="show1 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>  
          <div class="info_txt">
            <span>Info<br>Area</span>
          </div>
          <img src="@/assets/img/2020_viol/viol_11.png" alt="image">
          <div class="control_txt">
            <span>Control<br>Area</span>
            </div>
        </div>
        <div class="light_item_02" :class="show2 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>  
          <div class="light_item_02_l">
            <img src="@/assets/img/2020_viol/viol_12.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_13.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_14.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_15.png" alt="image">
          </div>
          <div class="light_item_02_r">
            <img src="@/assets/img/2020_viol/viol_16.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_17.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_18.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_19.png" alt="image">
          </div>
        </div>
        <div class="light_item_03" :class="show3 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>  
          <div class="modeling_tit">3D Modeling</div>
          <div class="modeling_01"><img src="@/assets/img/2020_viol/viol_20.png" alt="image"></div>
          <div class="modeling_02"><img src="@/assets/img/2020_viol/viol_21.png" alt="image"></div>
          <div class="modeling_03"><img src="@/assets/img/2020_viol/viol_22.png" alt="image"></div>
          <div class="modeling_text">
            Cartridge의 시술 범위에 따라서 Face,<br class="inactive_1280w">
            Face+Body, Body 3개의 범위로 구분<br class="inactive_1280w">
            했으며, 각 시술 부위가 잘 보이는 최적의<br class="inactive_1280w"> 
            포즈를 적용하여 Modeling했습니다.
          </div>
        </div>
        <div class="light_item_04" :class="show4 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>  
          <div class="illustration_tit">Skin Illustration</div>
          <div class="content_box">
          <div class="illustration_text">
            초음파의 깊이별 시술 위치를 표시하는<br class="inactive_1280w">
            일러스트를 함께 디자인했습니다.<br class="inactive_1280w">
            시술의 이해도를 높이기 위해 피부의<br class="inactive_1280w">
            표피층, 진피층, 지방층 등을 실제와<br class="inactive_1280w">
            유사하지만 심미성있게 표현했습니다.
          </div>
          <div class="illustration_01"><img src="@/assets/img/2020_viol/viol_23.png" alt="image"></div>
          <div class="illustration_02"><img src="@/assets/img/2020_viol/viol_24.png" alt="image"></div>
          <div class="illustration_03"><img src="@/assets/img/2020_viol/viol_25.png" alt="image"></div>
        </div>
        </div>

      </div>      
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "ViOLDetail07.vue",
        data() {
          return {
              show0: false,
              show1: false,
              show2: false,
              show3: false,
              show4: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
            isShowObserver2(value: boolean) {
            this.show2 = value;
          },
            isShowObserver3(value: boolean) {
            this.show3 = value;
          },
            isShowObserver4(value: boolean) {
            this.show4 = value;
          },                                        
        }
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.containers_07 {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-image: linear-gradient(to bottom, #edeff3, #f3f6f9 20%, #f9fafc 23%, #f9fafc);

}

.containers_07 .content_wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

}

.containers_07 .text_wrap {
  width: 100%;
  display: flex;
  padding: 60px 0 237px 0;
  color: #2a4064;
}

.common_tit {
  font-size: 14px;
  margin-bottom: 177px;
}

.tit_box {
    width: 50%;
}

.text_box {
  width: 50%;
}

.body_txt {
  font-size: 18px;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  line-height: 1.61;
}

/* image01 */

.light_item_01 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 161px;
}

.light_item_01 img {
  width: 57.33333%;
  box-shadow: 0 30px 30px rgba(204, 204, 204, 0.5);
  border-radius: 26px;
}

.light_item_01 div.info_txt {
  position: relative;
  text-align: end;
  margin: 19.916666% 10.91666% 0 0; 
}

.light_item_01 .info_txt span {
  width: 100%;
  position: relative;
  background-image: linear-gradient(133deg, #c0c3ca -4%, #a8acb3 98%);
  font-size: 40px;
  line-height: normal;
  letter-spacing: -0.88px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.light_item_01 .info_txt span::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -124.0506%;
  width: 100%;
  height: 2px;
  background: #c8cad0;
}

.light_item_01 div.control_txt {
  position: relative;
  margin: auto 0 19.916666% 10.91666%;
}

.light_item_01 .control_txt span {
  width: 100%;
  position: relative;
  background-image: linear-gradient(121deg, #c0c3ca -5%, #a8acb3 102%);
  font-size: 40px;
  line-height: normal;
  letter-spacing: -0.88px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.light_item_01 .control_txt span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -124.0506%;
  width: 100%;
  height: 2px;
  background: #c8cad0;
}

/* image02 */

.light_item_02 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 218px;
}

.light_item_02_l {
  width: 40.5%;
  display: flex;
  flex-direction: column;
  margin-right: 34px;
}

.light_item_02_r {
  width: 40.5%;
  margin-top: 37.25%;
  display: flex;
  flex-direction: column;
}

[class^="light_item_02"] img{
  margin-bottom: 34px;
  border-radius: 18px;
  box-shadow: 0 10px 14px rgba(204, 204, 204, 0.4);
}

/* modeling */

.light_item_03 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  margin-bottom: 206px;
}

.modeling_01 {
  width: 25.91666%;
}

.modeling_02 {
  width: 23.58333%;
  margin-right: 25px;
}

.modeling_03 {
  width: 23.58333%;
  margin-right: 16px;
}

.modeling_tit {
  position: absolute;
  font-size: 84px;
  font-weight: 500;
  letter-spacing: -1.84px;
  color: #2a4064;
  top: -50px;
  z-index: -1;
}

.modeling_text {
  width: 24%;
  font-size: 18px;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #2a4064;
  font-weight: 500;
}

/* item 04 */

.light_item_04 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 217px;
}

.light_item_04 .content_box {
  width: 100%;
  display: flex;
  align-items: center;
}

.illustration_tit {
  width: 100%;
  font-size: 84px;
  font-weight: 500;
  letter-spacing: -1.84px;
  color: #2a4064;
  margin-bottom: 69px;
}

.illustration_text {
  width: 23.5%;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  font-size: 18px;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #2a4064;
  margin-right: 14px;
}

.illustration_01,
.illustration_02,
.illustration_03 {
  width: 26.25%;
}

/*__Default*/


/* Animation */

.text_wrap.active .body_txt {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 250ms;
  transition-property: opacity;
}

.containers_07 .body_txt {
  opacity: 0;
}

.light_item_01.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_01 {
  opacity: 0;
}

.light_item_02.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_02 {
  opacity: 0;
}

.light_item_03.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_03 {
  opacity: 0;
}

.light_item_04.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_04 {
  opacity: 0;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {

.containers_07 .content_wrap {
  max-width: 90%
}

.body_txt {
  font-size: 16px;
}

} /*__end*/

@media screen and (max-width:1024px) {

.modeling_tit {
  font-size: 67px;
}

.modeling_text {
  font-size: 16px;
}

.illustration_tit {
  font-size: 67px;
}

.illustration_text {
  font-size: 16px;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_07 .text_wrap {
  flex-direction: column;
  padding: 48px 0 189px 0;
}

.common_tit {
  margin-bottom: 25px;
}

.text_box .common_tit {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 43px;
}

.body_txt {
  font-size: 13px;
}

.tit_box {
  width: 100%;
}

.text_box {
  width: 100%;
}

/* image01 */

.light_item_01 {
  margin-bottom: 97px;
}

.light_item_01 img {
  width: 59.44444vw;
  position: relative;
  z-index: 100;
  box-shadow: 0 18px 18px rgb(204, 204, 204, 0.5);
  border-radius: 16px;
}

.light_item_01 div.info_txt {
  margin: 19.916666% 5.45% 0 0;
}

.light_item_01 div.control_txt {
  margin: auto 0 19.916666% 5.45%;
}


.light_item_01 .info_txt span,
.light_item_01 .control_txt span {
  font-size: 24px;
}

/* image02 */

.light_item_02 {
  margin-bottom: 131px;
}

.light_item_02_l {
  margin-right: 20px;
}

[class^="light_item_02"] img {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 10px rgb(204, 204, 204, 0.4);
}

/* modeling */

.light_item_03 {
  flex-wrap: wrap;
  margin-bottom: 124px;
}

.modeling_tit {
  top: -40px;
  font-size: 54px;
}

.modeling_01 {
  width: 30vw;
  margin-right: 1.38888vw;
}

.modeling_02 {
  width: 26.66666vw;
  margin-right: 1.94444vw;
}

.modeling_03 {
  width: 26.66666vw;
  margin-right: 0;
}

.modeling_text {
  width: 100%;
  margin-top: 47px;
}

/* item 04 */

.light_item_04 {
  padding-bottom: 130px;
}

.illustration_tit {
  font-size: 54px;
  margin-bottom: 42px;
}

.light_item_04 .content_box {
  flex-wrap: wrap;
}

.illustration_01, 
.illustration_02,
.illustration_03 {
  width: 33.3333%;
}

.illustration_text {
  order: 4;
  width: 100%;
  margin-top: 47px;
}




} /*__end*/

@media screen and (max-width:425px) {

.containers_07 .content_wrap {
  max-width: 86.66666vw;
}

.containers_07 .text_wrap {
  padding: 32px 0 78px 0;
}

.text_box .common_tit {
  font-size: 36px;
}

.common_tit {
  font-size: 10px;
  margin-bottom: 20px;
}

.tit_box {
  margin-bottom: 42px;
}

/* image01 */

.light_item_01 {
  margin-bottom: 48px;
}

.light_item_01 img {
  box-shadow: 0 8px 10px rgb(204, 204, 204, 0.5);
  border-radius: 10px;
}

.light_item_01 .info_txt span,
.light_item_01 .control_txt span {
  font-size: 12px;
}

.light_item_01 .info_txt span::before,
.light_item_01 .control_txt span::before {
  height: 1px;
}

/* image02 */

.light_item_02 {
  margin-bottom: 82px;
}

.light_item_02_l {
  width: 50%;
  margin-right: 12px;
}

.light_item_02_r {
  width: 50%;
}

[class^="light_item_02"] img {
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgb(204, 204, 204, 0.4);
}

/* modeling */

.light_item_03 {
  margin-bottom: 82px;
}

.modeling_tit {
  top: -20px;
  font-size: 36px;
  letter-spacing: -0.79px;
}

.modeling_text {
  width: 100%;
  margin-top: 28px;
  font-size: 13px;
  letter-spacing: -0.07px;
}

/* item 04 */

.light_item_04 {
  padding-bottom: 112px;
}

.illustration_tit {
  font-size: 36px;
  margin-bottom: 40px;
  letter-spacing: -0.79px;
}

.illustration_text {
  margin-top: 23px;
  font-size: 13px;
  letter-spacing: -0.07px;
}

} /*__end*/



/*__반응형*/

</style>
