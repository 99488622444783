
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail7",
  data() {
    return {
        theme: Theme.white,
        show0: false,
        show1: false,
        show2: false,
        show3: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
      isShowObserver1(value: boolean) {
      this.show1 = value;
    },
      isShowObserver2(value: boolean) {
      this.show2 = value;
    },
      isShowObserver3(value: boolean) {
      this.show3 = value;
    },
  }
});
