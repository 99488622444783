<template>
<div class="content_containers">
    <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title_text">Inforgrahpic 2D Motion</div>
        <div class="body_text">삼성 갤럭시 프렌즈 관련 사업자를 위한 서비스 소개 영상<br>
            갤럭시 프렌즈는 모든 삼성 기기와 접목 가능하며, 해당 기능을 통해 B2B, B2C의 부가가치를 창출 할 수 있습니다.<br>
            Flat 2D 일러스트를 사용하여 여러 경우의 이용 사례를 컴팩트하게 표현해 갤럭시 프렌즈 서비스를 처음 접하는 사람에게도 쉬운 이해를 할 수 있도록 구성했습니다.</div>
    </div>
    <div class="content_video" :class="show1 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <iframe src="https://player.vimeo.com/video/433569026?muted=1&autoplay=1&autopause=0&loop=1&
           " frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="illustration_wrap">
        <div class="illustration_title">ILLUSTRATION</div>
        <div class="image_wrap">
            <div class="image_left">
                <div class="img_l_01" :class="show2 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_01.jpg" alt="image">
                </div>
                <div class="img_l_02" :class="show3 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_02.jpg" alt="image">
                </div>
                <div class="img_l_03" :class="show4 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_03.jpg" alt="image">
                </div>
                <div class="img_l_04" :class="show5 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_04.jpg" alt="image">
                </div>
            </div>
            <div class="image_right">
                <div class="img_r_01" :class="show6 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver6"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_05.jpg" alt="image">
                </div>
                <div class="img_r_02" :class="show7 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver7"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_06.jpg" alt="image">
                </div>
                <div class="img_r_03" :class="show8 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver8"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_07.jpg" alt="image">
                </div>
                <div class="img_r_04" :class="show9 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver9"></ShowObserver>
                    <img src="@/assets/img/2017_commercial/commercial_08.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CommercialDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
    isShowObserver6(value: boolean) {
      this.show6 = value;
    },
    isShowObserver7(value: boolean) {
      this.show7 = value;
    },
    isShowObserver8(value: boolean) {
      this.show8 = value;
    },
    isShowObserver9(value: boolean) {
      this.show9 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    width: 100%;
    background-color: #f5f9fc;
    content-visibility: auto 
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 92px;
    margin-bottom: 150px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 72px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_video {
    max-width: 1200px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 176px;
}

.content_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.illustration_wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 542px;
}

.illustration_wrap .illustration_title {
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: #515559;
    margin-bottom: 18px;
}

.illustration_wrap .image_wrap {
    display: flex;
    margin-left: -11px;
}

.illustration_wrap .image_wrap > div {
    padding-left: 11px;
}

.illustration_wrap .image_wrap > div > div {
    margin-bottom: 11px;
}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.content_video.active iframe {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.content_video iframe {
    opacity: 0;
}

.img_l_01.active img,
.img_l_02.active img,
.img_l_03.active img,
.img_l_04.active img,
.img_r_01.active img,
.img_r_02.active img,
.img_r_03.active img,
.img_r_04.active img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.img_l_01 > img,
.img_l_02 > img,
.img_l_03 > img,
.img_l_04 > img,
.img_r_01 > img,
.img_r_02 > img,
.img_r_03 > img,
.img_r_04 > img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text,
    .content_containers .main_text,
    .content_video,
    .illustration_wrap {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

    .illustration_wrap .illustration_title {
        height: 32px;
        font-size: 20px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .illustration_wrap {
        padding-bottom: 342px;
    }

    .illustration_wrap .image_wrap {
        flex-wrap: nowrap;
        flex-direction: column;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text,
    .content_containers .main_text,
    .content_video,
    .illustration_wrap {
        max-width: 86.66666vw;
    }

    .content_containers .main_text {
        padding-top: 36px;
        margin-bottom: 70px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 108px;
        font-size: 12px;
        line-height: 1.5;
    }

    .content_video {
        margin-bottom: 89px;
    }

    .illustration_wrap .illustration_title {
        height: 22px;
        font-size: 15px;
        margin-bottom: 16px;
    }

    .illustration_wrap {
        padding-bottom: 155px;
    }
    
} /*__end*/


/*__반응형*/

</style>
