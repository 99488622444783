
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "MomostickDetail3",
        data() {
          return {
              show0: false,
              show1: false,
              show2: false,
              show3: false,
              show4: false,
              show5: false,
              show6: false,
              show7: false,
              show8: false,
              show9: false,
              show10: false,
              show11: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
            isShowObserver2(value: boolean) {
            this.show2 = value;
          },
            isShowObserver3(value: boolean) {
            this.show3 = value;
          },
            isShowObserver4(value: boolean) {
            this.show4 = value;
          },
            isShowObserver5(value: boolean) {
            this.show5 = value;
          },
            isShowObserver6(value: boolean) {
            this.show6 = value;
          },
            isShowObserver7(value: boolean) {
            this.show7 = value;
          },
            isShowObserver8(value: boolean) {
            this.show8 = value;
          },
            isShowObserver9(value: boolean) {
            this.show9 = value;
          },
            isShowObserver10(value: boolean) {
            this.show10 = value;
          },
            isShowObserver11(value: boolean) {
            this.show11 = value;
          },
        }
      });
      