<template>
        <div class="graphics_containers">
            <div class="graphics_content content_wrap">
                <div class="graphics_title_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
                 <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                    <div class="content_title">Card Graphic System</div>
                    <div class="content_body">바로세움 연구소 유형카드 그래픽 시스템</div>
                </div>
                <div class="graphics_item_wrap" :class="show1 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                    <div class="basic">
                        <h3 class="inactive_425w">Basic Shape</h3>
                        <img src="@/assets/img/2020_baro/cardimg_01.png" alt="image">
                    </div>
                    <div class="plus"></div>
                    <div class="type">
                        <h3 class="inactive_425w">Type Color</h3>
                        <img src="@/assets/img/2020_baro/cardimg_02.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
      show1: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.graphics_containers {
    padding: 187px 0 251px 0;
}

.graphics_title_wrap {
    margin-bottom: 167px;
}

.graphics_containers .content_title {
    margin-bottom: 32px;
}

.graphics_item_wrap {
    display: flex;
    justify-content: center;
}

.graphics_item_wrap div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graphics_item_wrap .basic {
    width: 24.25%;
}

.graphics_item_wrap .type {
    width: 22.75%;
}

.graphics_item_wrap .plus {
    position: relative;
    width: 30px;
    height: 30px;
    margin: auto 7.25% 55px 7.25%;
    overflow: hidden;
}
.graphics_item_wrap .plus::before {
    position: absolute;
    top: 50%;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #979797;
}

.graphics_item_wrap .plus::after {
    position: absolute;
    display: block;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #979797;
}

.graphics_item_wrap div h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    margin-bottom: 32px;
}

/*__Default*/


/* Animation */

.graphics_title_wrap.active .content_title,
.graphics_title_wrap.active .content_body {
    opacity: 1;
    transition-duration: .8s;
}

.graphics_title_wrap .content_title,
.graphics_title_wrap .content_body {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.graphics_item_wrap.active .basic img,
.graphics_item_wrap.active .type img {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.graphics_item_wrap .basic img,
.graphics_item_wrap .type img {
    opacity: 0;
}

.graphics_item_wrap.active .basic h3,
.graphics_item_wrap.active .type h3 {
    opacity: 1;
    transition-delay: .6s;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

.graphics_item_wrap .basic h3,
.graphics_item_wrap .type h3 {
    opacity: 0;
}

.graphics_item_wrap.active .plus::after {
    animation-name: plusafter_ani;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
} 

.graphics_item_wrap.active .plus::before {
    animation-name: plusbefore_ani;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
} 

@keyframes plusafter_ani {
    0% {
        top: -100%;
    }
    100% {
        top: 0;
    }
}

@keyframes plusbefore_ani {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:768px) {
    .graphics_item_wrap {
        flex-direction: column;
        align-items: center;
    }

    .graphics_item_wrap .plus {
        margin: 32px auto;
    }

    .graphics_item_wrap .basic {
        width: 93.2692%;
    }

    .graphics_item_wrap .type {
        width: 87.5%;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .graphics_containers {
        padding: 86px 0 92px 0; 
    }

    .graphics_title_wrap {
        margin-bottom: 100px;
    }

} /*__end*/


/*__반응형*/

</style>