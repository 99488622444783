import {ref} from 'vue'
import {Direction} from "@/enums/Direction";

export default function useDirectionIntersectionObserver() {
    const isSupported = 'IntersectionObserver' in window
    const isShown = ref(false)
    const direction = ref(Direction.none)
    let prevRatio = 0;
    let prevY = 0;

    if (!isSupported) {
        console.log('Your browser does not support this feature')
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            const currentY = entry.boundingClientRect.y
            const currentRatio = entry.intersectionRatio
            const isIntersecting = entry.isIntersecting

            // Scrolling down/up
            if (currentY < prevY) {
                if (currentRatio > prevRatio && isIntersecting) {
                    // console.log("Scrolling down enter");
                    direction.value = Direction.down;
                } else {
                    // console.log("Scrolling down leave");
                    direction.value = Direction.none;
                }
            } else if (currentY > prevY && isIntersecting) {
                if (currentRatio < prevRatio) {
                    // console.log("Scrolling up leave");
                    direction.value = Direction.none;
                } else {
                    // console.log("Scrolling up enter");
                    direction.value = Direction.up;
                }
            }

            prevY = currentY
            prevRatio = currentRatio
        })
    }, {
        threshold: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0]
    })

    const observe = (element: Element | null) => {
        if (element != null) {
            observer.observe(element)
        }
    }

    const unobserve = (element: Element | null) => {
        if (element != null) {
            observer.unobserve(element)
        }
    }

    return {observe, unobserve, isShown, direction}
}
