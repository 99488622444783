<template>
  <div class="container">
    <div class="container-inner">
      <ul
        id="halloween_emoji"
        class="halloween_emoji"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <li
          :class="`halloween_${index + 1}`"
          :key="index"
          v-for="(item, index) in items"
        >
          <img
            :src="require(`@/assets/img/2021_emoji/${item.url}.webp`)"
            alt="emoji image"
          />
        </li>
      </ul>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail3",
  el: "#halloween_emoji",
  data() {
    const items = [];
    const pushItems = () => {
      let urlLength = 32;
      for (let i = 1; i < urlLength; i++) {
        items.push({ url: `halloween_${i}` });
      }
    };
    pushItems();
    return {
      show0: false,
      pushItems,
      items,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  background: #fff;
}

.container-inner {
  width: calc(var(--responsive) * 1320);
  margin-top: calc(var(--responsive) * 134);
  margin-bottom: calc(var(--responsive) * 134);
}

/* halloween */

.halloween_emoji {
  display: flex;
  flex-wrap: wrap;
  font-size: calc(var(--responsive) * 65);
  width: calc(100% + 1em);
  margin-left: -1em;
  margin-bottom: -1.123em;
}

.halloween_emoji li {
  width: 1.662em;
  padding-left: 1em;
  padding-bottom: 1.123em;
}

.halloween_emoji li img {
  width: 100%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.halloween_emoji {
  opacity: 0;
}

.halloween_emoji.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container-inner {
    width: 77.7777%;
    margin-top: 16.6666vw;
    margin-bottom: 16.6666vw;
  }

  .halloween_emoji {
    font-size: 7.77777vw;
    margin-bottom: -1.071em;
  }

  .halloween_emoji li {
    width: 1.74em;
    padding-bottom: 1.071em;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
