
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


import { Swiper, SwiperSlide } from 'swiper/vue';
import {defineComponent} from "vue";
import 'swiper/swiper.scss';
import SwiperCore, {
  Autoplay
} from 'swiper/core';

SwiperCore.use([Autoplay]);

export default defineComponent({
  name: "KiaCesDetail8",
    data() {
    return {
        theme: Theme.white
    }
  },
  components: {
      Swiper,
      SwiperSlide,
      ThemeObserver
    },
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log('slide change');
      },
   },
});   
