<template>
  <div class="concept_containers">
         <ThemeObserver :theme=theme />
          <h3 class="content_tit">Concept Board</h3>
          <div class="content_body" :class="show0 ? 'active' : ''" style="position: relative;">
              <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
              <div class="body_l">
                  <div class="body_l_t"><img src="@/assets/img/2019_kia_ces/kiaces_07.jpg" alt="content_img"></div>
                  <div class="body_l_b"><img src="@/assets/img/2019_kia_ces/kiaces_08.jpg" alt="content_img"></div>
              </div>
              <div class="body_c">
                  <div class="body_c_t"><img src="@/assets/img/2019_kia_ces/kiaces_09.jpg" alt="content_img"></div>
                  <div class="body_c_b"><img src="@/assets/img/2019_kia_ces/kiaces_10.jpg" alt="content_img"></div>
              </div>
              <div class="body_r"><img src="@/assets/img/2019_kia_ces/kiaces_11.jpg" alt="content_img"></div>
          </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail3",
  data() {
    return {
        theme: Theme.white,
        show0: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.concept_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 366px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.concept_containers .content_tit {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 151px;
}

.concept_containers .content_body {
    display: flex;
}

/*__Default*/


/* Animation */

.content_body .body_l_t,
.content_body .body_l_b,
.content_body .body_c_t,
.content_body .body_c_b,
.content_body .body_r {
    opacity: 0;
}

.content_body.active .body_l_t {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.content_body.active .body_l_b {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.content_body.active .body_c_t {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.content_body.active .body_c_b {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 300ms;
    transition-property: opacity;
}

.content_body.active .body_r {
    opacity: 1;
    transition: 900ms cubic-bezier(0.47, 0, 0.75, 0.72) 300ms;
    transition-property: opacity;
}
/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .concept_containers .content_tit {
        max-width: 90%;
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .concept_containers {
        padding-bottom: 292px;
    }
    .concept_containers .content_tit {
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .concept_containers {
        padding-bottom: 233px;
    }

    .concept_containers .content_tit {
       margin-bottom: 120px;
    }

    .concept_containers .content_body {
        flex-direction: column;
    }
    
    .body_l_t {
        width: 100%;
        height: 0;
        padding-bottom: 61.11111%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_07.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .body_l_b {
        width: 100%;
        height: 0;
        padding-bottom: 61.11111%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_09.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .body_c_t {
        width: 100%;
        height: 0;
        padding-bottom: 61.11111%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_08.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .body_c_b {
        width: 100%;
        height: 0;
        padding-bottom: 61.11111%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_10.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .body_r {
        width: 100%;
        height: 0;
        padding-bottom: 111.11111%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_11.jpg);
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }
    
    .content_body  img {
        visibility: hidden;
    }

    .content_body.active .body_l_t {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
        transition-property: opacity;
    }
    
    .content_body.active .body_l_b {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
        transition-property: opacity;
    }
    
    .content_body.active .body_c_t {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
        transition-property: opacity;
    }
    
    .content_body.active .body_c_b {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 600ms;
        transition-property: opacity;
    }
    
    .content_body.active .body_r {
        opacity: 1;
        transition: 900ms cubic-bezier(0.47, 0, 0.75, 0.72) 800ms;
        transition-property: opacity;
    }
    

} /*__end*/

@media screen and (max-width:425px) {
    .concept_containers .content_tit {
       max-width: 86.66666vw;
       font-size: 12px;
       margin-bottom: 90px;
       letter-spacing: -0.27px;
    }

    .concept_containers {
       padding-bottom: 130px;
}

} /*__end*/


/*__반응형*/

</style>
