<template>
<div class="content_containers">
    <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title_text">꼬모팝 AR Mobile Game App</div>
        <div class="body_text">디지털 미디어 애니메이션 회사인 Design Egg와 협업을 통하여 개발한 인터랙티브AR 게임 컨텐츠입니다.<br>꼬모팝 AR 사운드북과 함께 App
            store, Google play에서<br class="m_br"> 다운로드 가능합니다.</div>
    </div>
    <div class="content_wrap">
        <div class="image_01" :class="show1 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
            <img src="@/assets/img/2018_como_arbook/como_01.jpg" alt="image" style="height: 0;">
        </div>
        <div class="caption_01" :class="show2 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
            <p>카메라로 책을 비추면 귀여운 캐릭터들이 신나는 동요 8곡에 맞춰 귀여운 율동을 하는 장면을 볼 수 있으며, 8개의 AR 게임을 즐길 수 있습니다.</p>
        </div>
        <div class="infographic" :class="show3 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
            <div class="info_left">
                <div class="info_story">STORY</div>
                <div class="info_ar">AR</div>
                <div class="info_character">CHARACTER</div>
            </div>
            <div class="info_line"></div>
            <div class="info_arbook">AR STORY<br>BOOK</div>
        </div>
        <div class="image_02" :class="show4 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
            <img src="@/assets/img/2018_como_arbook/como_02.jpg" alt="image">
        </div>
        <div class="image_03" :class="show5 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
            <img src="@/assets/img/2018_como_arbook/como_03.jpg" alt="image">
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "ComoArBookDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 91px;
    content-visibility: auto;

}

.content_containers .main_text {
    margin: 0 auto;
    margin-bottom: 188px;

}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_containers .main_text .body_text .m_br {
    display: none;
}

.content_wrap {
    margin: 0 auto;
}

.content_wrap .image_01 {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    background-image: url(../../../assets/img/2018_como_arbook/como_01.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 36px;
}



.content_wrap .caption_01 p {
    display: block;
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
    margin: 0 auto;
    margin-bottom: 158px;
}

.content_wrap .infographic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px 0;
    margin: 0 auto;
    margin-bottom: 180px;
}

.content_wrap .infographic .info_left {
    display: flex;
}

.content_wrap .infographic .info_story,
.info_ar,
.info_character,
.info_arbook {
    width: 270px;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 135px;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.28;
    text-align: center;
}

.content_wrap .infographic .info_story {
    background-color: #e0f8f3;
    color: #238d78;
}

.content_wrap .infographic .info_ar {
    background-color: #c6ebe4;
    color: #238d78;
}

.content_wrap .infographic .info_character {
    background-color: #95d6c9;
    color: #238d78;
}

.content_wrap .infographic .info_ar,
.info_character {
    margin-left: -53px;
}

.content_wrap .infographic .info_arbook {
    background-color: #583ca5;
    color: #fff;
}

.content_wrap .infographic .info_line {
    width: 172px;
    border-bottom: 3px dotted #707070;

}

.content_wrap .image_02 {
    margin: 0 auto;
    margin-bottom: 24px;
}

.content_wrap .image_03 {
    margin: 0 auto;
    margin-bottom: 458px;
}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_01.active,
.caption_01.active p,
.infographic.active,
.image_02.active img,
.image_03.active img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_01,
.caption_01 > p,
.infographic,
.image_02 > img,
.image_03 > img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text,
    .content_img_wrap,
    .caption_01 > p,
    .infographic,
    .image_02,
    .image_03 {
        max-width: 90%;
    }

    .content_containers {
        max-width: inherit;
    }

    .infographic {
        width: 100%;
    }

    .content_wrap .infographic .info_story,
    .info_ar,
    .info_character,
    .info_arbook {
        width: 19.9652vw;
        height: 19.9652vw;
        font-size: 20px;
    }

    .content_wrap .infographic .info_line {
        width: 13.1944vw;

    }

    .content_wrap .infographic .info_ar,
    .info_character {
        margin-left: -40px;
    }

    .content_wrap .image_02 {
        margin-bottom: 24px;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text, 
    .content_wrap .caption_01 p {
        font-size: 14px;
    }

    .content_containers .main_text {
        margin-bottom: 140px;

    }

    .content_wrap .caption_01 p {
        margin-bottom: 120px;
    }

    .content_wrap .infographic {
        margin-bottom: 140px;
    }

    .content_wrap .image_03 {
        margin-bottom: 300px;
    }

} /*__end*/

@media screen and (max-width:768px) {
      .content_containers .main_text .title_text {
        height: 118px;
    }

    .content_wrap .infographic {
        flex-direction: column;
        padding: 0px;
    }

    .content_wrap .infographic .info_story,
    .info_ar,
    .info_character,
    .info_arbook {
        width: 34vw;
        height: 34vw;
        font-size: 14px;
    }

    .content_wrap .infographic .info_line {
        transform: rotate(90deg);
        width: 20vw;
        border-bottom: 2px dotted #707070;

    }

    .content_wrap .infographic .info_left {
        margin-bottom: 90px;
    }

    .content_wrap .infographic .info_arbook {
        margin-top: 90px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text .content_img_wrap {
        max-width: 86.66666vw;
    }
     .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 97px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.1px;
    }

    .content_containers .main_text .body_text .m_br {
        display: block;
    }

    .content_wrap .image_01 {
        padding-bottom: 135.27777%;
        background-position: 40%;
        margin-bottom: 20px;
    }

    .content_wrap .caption_01 p {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.3px;
        margin-bottom: 92px;
    }

    .content_wrap .infographic .info_story,
    .info_ar,
    .info_character,
    .info_arbook {
        font-size: 12px;
    }

    .content_wrap .infographic .info_left {
        margin-bottom: 70px;
    }

    .content_wrap .infographic .info_arbook {
        margin-top: 70px;
    }

    .content_wrap .infographic {
        margin-bottom: 129px;
    }

    .content_wrap .infographic .info_ar,
    .info_character {
        margin-left: -28px;
    }

    .content_wrap .image_03 {
        margin-bottom: 150px;
    }

} /*__end*/


/*__반응형*/

</style>
