<template>
  <div class="structure_containers">
      <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <h3 class="content_tit">Structure</h3>
          <div class="content_body" :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver> 
              <div class="content_top">
                  <h3>Center Fascia</h3>
                  <img src="@/assets/img/2019_kia_ces/kiaces_12.png" alt="image">
              </div>
              <div class="content_btm">
                 <div class="content_b_l">
                     <img src="@/assets/img/2019_kia_ces/kiaces_13.png" alt="image">
                     <h3>Door Trim<br>Display</h3>
                 </div>
                 <div class="content_b_c">
                     <img src="@/assets/img/2019_kia_ces/kiaces_14.png" alt="image">
                     <h3>Rear Seat <br class="active_425w">Display</h3>
                 </div>
                 <div class="content_b_r">
                     <img src="@/assets/img/2019_kia_ces/kiaces_15.png" alt="image">
                     <h3>Door Trim<br>Display</h3>
                 </div>
              </div>   
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail4",
  data() {
    return {
        theme: Theme.white,
        show0: false,
    }
  },
  components: {
       ThemeObserver,
       ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.structure_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 353px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.structure_containers .content_tit {
    margin-bottom: 150px;
}

.structure_containers .content_body {
    display: flex;
    flex-direction: column;
}

.structure_containers .content_body .content_top {
    margin-bottom: 76px;
}

.structure_containers .content_body .content_top h3 {
    margin-bottom: 24px;
}

.structure_containers .content_body .content_btm {
    display: flex;
    justify-content: space-between;
}

.structure_containers .content_body .content_btm .content_b_c {
    text-align: center;
}

.structure_containers .content_body .content_btm img {
    height: 227px;
    margin-bottom: 22px;
}

.structure_containers .content_body .content_btm .content_b_l img,
.structure_containers .content_body .content_btm .content_b_r img {
    width: 62px;
}

.structure_containers .content_body .content_btm .content_b_c img {
    width: 158px;
}

.structure_containers .content_body h3 {
    font-size: 22px;
    ine-height: 1.14;
    letter-spacing: -0.49px;
    font-weight: 600;
}

.structure_containers .content_body .content_btm .content_b_c h3 {
    text-align: center;
}

.structure_containers .content_body .content_btm .content_b_r {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.structure_containers .content_body .content_btm .content_b_r h3 {
    text-align: right;
}



/*__Default*/


/* Animation */

.content_body .content_top,
.content_body .content_b_l,
.content_body .content_b_c,
.content_body .content_b_r {
    opacity: 0;
}

.content_body.active .content_top {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.content_body.active .content_b_l {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.content_body.active .content_b_c {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
    transition-property: opacity;
}

.content_body.active .content_b_r {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 600ms;
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_wrap {
        max-width: 90%;
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .structure_containers {
        padding-bottom: 282px;
    }

    .structure_containers .content_tit {
        font-size: 14px;
    }

    .structure_containers .content_body h3 {
        font-size: 18px;
    }
} /*__end*/

@media screen and (max-width:768px) {
    .structure_containers {
        padding-bottom: 225px;
    }

    .structure_containers .content_tit {
        margin-bottom: 120px;
    }


    .structure_containers .content_body .content_btm .content_b_l img,
    .structure_containers .content_body .content_btm .content_b_r img {
        width: 50px;
        height: 181px;
    }

    .structure_containers .content_body .content_btm .content_b_c img {
         width: 126px;
         height: 181px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .structure_containers {
        padding-bottom: 130px;
    }

    .content_wrap {
        max-width: 86.66666vw;
    }

    .structure_containers .content_tit {
        font-size: 12px;
        margin-bottom: 90px;
        letter-spacing: -0.27px;
    }

    .structure_containers .content_body .content_top {
        margin-bottom: 64px;
    }

    .structure_containers .content_body h3 {
        font-size: 15px;
        letter-spacing: -0.33px;
    }

    .structure_containers {
        padding-bottom: 130px;
    }

    .structure_containers .content_body .content_top img {
        height: 85px;
    }

    .structure_containers .content_body .content_btm .content_b_l img,
    .structure_containers .content_body .content_btm .content_b_r img {
        width: 38px;
        height: 119px;
    }

    .structure_containers .content_body .content_btm .content_b_c img {
         width: 83px;
         height: 119px;
    }

} /*__end*/


/*__반응형*/

</style>
