<template>
    <div class="containers_02" :class="show0 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
    <!-- <ThemeObserver :theme=theme />  -->
      <div class="content_wrap">
        <div class="tit_box">
          <h3 class="common_tit">Planning Project</h3>
          <div class="userflow">User Flow</div>
        </div>
        <div class="text_box">
          <h3 class="common_tit">User Flow</h3>
          <div class="body_txt">
            직관적인 인터페이스를 설계하기 위해 Flow Chart를 활용했습니다.<br class="inactive_1024w">
            사용자가 기기를 진입하는 시점부터 마지막까지를 예측해보고, 각 단계별 사용자가<br class="inactive_1280w">
            취할 액션이나 사용할 수 있는 옵션을 고민하여 최적의 UX를 설계했습니다.
          </div>
        </div>
      </div>
      <div class="flow_chart">
        <img src="@/assets/img/2020_viol/viol_03.jpg" alt="image" class="inactive_768w">
        <img src="@/assets/img/2020_viol/viol_03_m.jpg" alt="image" class="active_768w">
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail02.vue",
    data() {
      return {
          show0: false,
          // theme: Theme.dark
      }
    },
    components: {
        ShowObserver,
        // ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.containers_02 {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #2a4064;
}

.containers_02 .content_wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 70px 0 155px 0;
}

.common_tit {
  font-size: 14px;
  margin-bottom: 177px;
}

.tit_box {
    width: 50%;
}

.text_box {
  width: 50%;
}

.userflow {
  font-size: 84px;
  font-weight: 500;
}

.body_txt {
  font-size: 18px;
  font-family: 'SECRobotoLight', serif;
  line-height: 1.61;
}

.flow_chart {
  background: #f4f5f7;
}

/*__Default*/


/* Animation */

.containers_02.active .userflow {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 100ms;
  transition-property: opacity;
}

.containers_02.active .body_txt {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.containers_02.active .flow_chart img {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 800ms;
  transition-property: opacity;
}

.containers_02 .userflow,
.containers_02 .body_txt,
.containers_02 .flow_chart img {
  opacity: 0;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {

.containers_02 .content_wrap {
  max-width: 90%
}

.userflow {
  font-size: 67px;
}

.body_txt {
  font-size: 16px;
}


} /*__end*/

@media screen and (max-width:1024px) {

.userflow {
  font-size: 54px;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_02 .content_wrap {
  flex-direction: column;
  padding: 56px 0 124px 0;
}

.common_tit {
  margin-bottom: 25px;
}

.userflow {
  font-size: 43px;
}

.body_txt {
  font-size: 13px;
}

.tit_box {
  width: 100%;
  margin-bottom: 53px;
}

.text_box {
  width: 100%;
}

} /*__end*/

@media screen and (max-width:425px) {

.containers_02 .content_wrap {
  max-width: 86.66666vw;
  padding: 32px 0 78px 0;
}

.common_tit {
  font-size: 10px;
  margin-bottom: 20px;
}

.userflow {
  font-size: 36px;
}

.tit_box {
  margin-bottom: 42px;
}

} /*__end*/


/*__반응형*/

</style>
