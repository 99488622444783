<template>
  <div class="content_containers">
    <div class="content_03" :class="show0 ? 'active' : ''"> 
      <ShowObserver v-on:isShow="isShowObserver0" style="height: 40%;"></ShowObserver>
      <div class="app_s_img_01">
        <img src="@/assets/img/2019_vogo/vogo_01.png" alt="app_screen_img">
      </div>
      <div class="deco"><span></span></div>
      
      <div style="position: relative;" :class="show1 ? 'active' : ''">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="title_text">LIVE MODE</div>
        <div class="body_text">방송 중 간편 결제 시스템을 이용해 <br class="active_425w">사용자가 즉시 상품을 결제할 수 있습니다.</div>
      </div>
      <div class="content_body" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <div><img src="@/assets/img/2019_vogo/vogo_02.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_03.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_04.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_05.jpg" alt="app_screen_img">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail4",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.content_containers .content_03 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 267px;
}

.content_containers .content_03 .app_s_img_01 {
    max-width: 360px;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13);
}


.content_containers .content_03 .deco {
    display: block;
    position: relative;
    padding-top: 211px;
    padding-bottom: 419px;
}

.content_containers .content_03 .deco span {
    content: "";
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fdd933;
    transform: rotate(-315deg);
    z-index: -1;
}

.content_containers .content_03 .deco span::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #8150ff;
}

.content_containers .content_03 .deco span::after {
    content: "";
    position: absolute;
    top: 124px;
    left: 124px;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #6727ff;
}

.content_containers .content_03 .title_text {
    margin-bottom: 12px;
}

.content_containers .content_03 .body_text {
    color: #383838;
    margin-bottom: 101px;
}

.content_containers .content_03 .content_body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 1082px;
    margin-left: -30px;
}

.content_containers .content_03 .content_body div:not(.observer) {
    padding-left: 30px;

}

.content_containers .content_03 .content_body div img {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13);
}

/*__Default*/


/* Animation */

.content_containers .content_03.active .app_s_img_01 {
    opacity: 1;

}

.content_containers .content_03 .app_s_img_01 {
    opacity: 0;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.content_containers .content_03.active .deco span {
    animation-name: con3_deco1;
    animation-duration: .8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.content_containers .content_03.active .deco span::before {
    animation-name: con3_deco2;
    animation-duration: .8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.content_containers .content_03.active .deco span::after {
    animation-name: con3_deco3;
    animation-duration: .8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@-webkit-keyframes con3_deco1 {
    0% {
        background-color: #6727ff;
    }

    50% {
        background-color: #8150ff;
    }

    100% {
        background-color: #fdd933;
    }
}

@-webkit-keyframes con3_deco2 {
    0% {
        background-color: #fdd933;
    }

    50% {
        background-color: #6727ff;
    }

    100% {
        background-color: #8150ff;
    }
}

@-webkit-keyframes con3_deco3 {
    0% {
        background-color: #8150ff;
    }

    50% {
        background-color: #fdd933;
    }

    100% {
        background-color: #6727ff;
    }
}

.content_containers .content_03 .active .title_text,
.content_containers .content_03 .active .body_text {
    opacity: 1;
    transition-duration: .8s;
}

.content_containers .content_03 .title_text,
.content_containers .content_03 .body_text  {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}


.content_containers .content_03 .content_body.active div:not(.observer) {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.content_containers .content_03 .content_body div:not(.observer) {
    opacity: 0;
    transform: translateY(200px);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {} /*__end*/

@media screen and (max-width:1024px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

      .content_containers .content_03 {
        padding-bottom: 213.6px;
    }

    .content_containers .content_03 .app_s_img_01 {
        max-width: 288px;
    }

    .content_containers .content_03 .deco {
        padding-top: 168.8px;
        padding-bottom: 335.2px;
    }

    .content_containers .content_03 .content_body {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .content_03 .content_body {
        flex-wrap: wrap;
    }

    .content_containers .content_03 .content_body div {
        padding-bottom: 30px;
    }

    .content_containers .content_03 .content_body div {
        width: calc(100% / 2 - 30px);
    }
} /*__end*/

@media screen and (max-width:425px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/

    .content_containers .content_03 {
        padding-bottom: 82px;
    }

    .content_containers .content_03 .app_s_img_01 {
        max-width: 242px;
    }

    .content_containers .content_03 .deco {
        padding-top: 100px;
        padding-bottom: 210px;
    }

    .content_containers .content_03 .deco span {
        width: 10.9px;
        height: 10.9px;
    }

    .content_containers .content_03 .deco span::before {
        width: 10.9px;
        height: 10.9px;
        top: 6px;
        left: 6px;
    }

    .content_containers .content_03 .deco span::after {
        width: 10.9px;
        height: 10.9px;
        top: 88px;
        left: 88px;
    }

    .content_containers .content_03 .body_text {
        margin-bottom: 48px;
    }

    .content_containers .content_03 .app_s_img_01 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
    }

    .content_containers .content_03 .content_body {
        width: 84.44444vw;
        margin-left: -16px;
    }

    .content_containers .content_03 .content_body div:not(.observer) {
        width: calc(100% / 2 - 16px);
        padding-left: 16px;
        padding-bottom: 16px;
    }

} /*__end*/


/*__반응형*/

</style>
