<template>
  <div class="wireframe_containers">
      <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <h3 class="content_tit">Wireframe</h3>
          <div class="content_body" :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>    
              <div class="content_top">
                  <h3>Manual Driving Mode</h3>
                  <div class="kia_ces_16">
                    <img src="@/assets/img/2019_kia_ces/kiaces_16.png" alt="image">
                  </div>
              </div>
              <div class="content_btm">
                  <h3>Autonomous Driving Mode</h3>
                  <div class="auto_imgwrap">
                      <div class="kia_ces_17">
                        <img src="@/assets/img/2019_kia_ces/kiaces_17.png" alt="image">
                      </div>
                      <div class="txtbox">
                          <span>Switching</span>
                          <img src="@/assets/img/2019_kia_ces/wfarrow_01.svg" alt="image" class="wftxtbox_01">
                          <img src="@/assets/img/2019_kia_ces/wfarrow_02.svg" alt="image">
                      </div>
                  </div>
              </div>   
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail5",
  data() {
    return {
        theme: Theme.white,
        show0: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.wireframe_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 334px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.wireframe_containers .content_tit {
    margin-bottom: 150px;
}

.wireframe_containers .content_top {
    margin-bottom: 108px;
}

.wireframe_containers .content_top h3,
.wireframe_containers .content_btm h3 {
    font-size: 22px;
    etter-spacing: -0.49px;
    font-weight: 600;
    margin-bottom: 33px;
}

.wireframe_containers .content_btm {
    position: relative;
}

.wireframe_containers .content_btm .txtbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wireframe_containers .content_btm .txtbox span {
    font-size: 22px;
    etter-spacing: -0.49px;
    color: #50b7f8;
    font-weight: 600;
    margin-bottom: 5px;
}

.wireframe_containers .content_btm .txtbox .wftxtbox_01 {
    margin-bottom: 7px;
}



/*__Default*/


/* Animation */

.content_body .content_top,
.content_body .content_btm {
    opacity: 0;
}

.content_body.active .content_top {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.content_body.active .content_btm {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
   .content_wrap {
           max-width: 90%;
   }
} /*__end*/

@media screen and (max-width:1024px) {
    .wireframe_containers {
        padding-bottom: 267px;
    }

    .wireframe_containers .content_tit {
        font-size: 14px;
    }

    .wireframe_containers .content_top h3, 
    .wireframe_containers .content_btm h3 {
        font-size: 18px;
    }

    .wireframe_containers .content_btm .txtbox span {
        font-size: 18px;
    }
} /*__end*/

@media screen and (max-width:768px) {
    .wireframe_containers {
        padding-bottom: 213px;
    }

    .wireframe_containers .content_tit {
        margin-bottom: 120px;
    }

    .wireframe_containers .content_btm .txtbox span {
        font-size: 14px;
    }

    .kia_ces_16 {
        width: 100%;
        height: 0;
        padding-bottom: 21.15384%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_16_m.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .kia_ces_17 {
        width: 100%;
        height: 0;
        padding-bottom: 21.15384%;
        background-image: url(../../../assets/img/2019_kia_ces/kiaces_17_m.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .kia_ces_16  img,
    .kia_ces_17  img {
        visibility: hidden;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .wireframe_containers {
        padding-bottom: 130px;
    }

   .content_wrap {
        max-width: 86.66666vw;
   }

   .wireframe_containers .content_top {
       margin-bottom: 74px;
   }

    .wireframe_containers .content_tit {
        font-size: 12px;
        margin-bottom: 90px;
        letter-spacing: -0.27px;
    }

    .wireframe_containers .content_btm .txtbox span {
        font-size: 12px;
        letter-spacing: -0.27px;
    }

    .wireframe_containers .content_top h3, 
    .wireframe_containers .content_btm h3 {
        font-size: 15px;
        letter-spacing: -0.33px;
    }

    .wireframe_containers .content_btm .txtbox {
        width: 40%;
    }
} /*__end*/


/*__반응형*/

</style>
