
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail3",
  data() {
    return {
      show: false
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver(value: boolean) {
      this.show = value;
    }
  }
});
