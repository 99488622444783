<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="title" :class="show0 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
        <h3>
          <span class="point-color">Design</span>
          <br>System<span class="point-color">.</span>
        </h3>
      </div>
      <div class="sub-title" :class="show1 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <h3>ICON DESIGN</h3>
        <p>둥근 모서리와 마감을 적용해 원형의 knob 형태와 <br class="active_820w">이질감없이 어우러지도록 했습니다</p>
      </div>
      <div class="graphics" :class="show2 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <div class="graphic-01">
          <img src="@/assets/img/2021_seoyoneh/detail03-img-01.svg" alt="graphic image">
        </div>
        <div class="graphic-02">
          <img src="@/assets/img/2021_seoyoneh/detail03-img-02.svg" alt="graphic image">
          <span>Corner Rounded</span>
        </div>
        <div class="graphic-03">
          <img src="@/assets/img/2021_seoyoneh/detail03-img-03.svg" alt="graphic image">
          <span>Stroke Rounded</span>
        </div>
      </div>
      <div class="icons" :class="show3 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <ul id="detail3-icons">
          <li
            :class="`icon_${index + 1}`"
            :key="index"
            v-for="(item, index) in items"
          >
            <img :src="require(`@/assets/img/2021_seoyoneh/${item.url}.webp`)" alt="icon">
          </li>
        </ul>
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail3",
  el: "#detail3-icons",
  data() {
    const items = [];
    const pushItems = () => {
      let urlLength = 22;
      for (let i = 1; i < urlLength; i++) {
        items.push({ url: `detail03-ico-${Number(i).toString().padStart(2, '0')}` });
      }
    };
    pushItems();
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      pushItems,
      items,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

/*__Default*/

.container {
  position: relative;
  display: flex;
  width: 100vw;
  color: #fff;
  background:  #131C33;
  font-family: var(--pretendard);
}

.container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(var(--responsive) * 1200);
  margin: 0 auto;
}

/* title */

.title {
  width: 100%;
  margin-top: calc(var(--responsive) * 110);
  margin-bottom: calc(var(--responsive) * 171);
}

.title h3 {
  font-size: calc(var(--responsive) * 54);
  line-height: 1.333em;
  font-weight: 400;
}

.point-color {
  color: #A85BFF;
}

/* sub title */

.sub-title {
  width: 100%;
  margin-bottom: calc(var(--responsive) * 86);
  text-align: center;
}

.sub-title h3 {
  font-size: calc(var(--responsive) * 23);
  line-height: 1.217em;
  font-weight: 400;
  margin-bottom: 0.826em;
}

.sub-title p {
  font-size: calc(var(--responsive) * 14);
  line-height: 1.214em;
  font-weight: 400;
  color: #65749D;
}

/* graphics */

.graphics {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: calc(var(--responsive) * 100);
}

.graphic-01 {
  width: calc(var(--responsive) * 319);
  height: calc(var(--responsive) * 319);
}

[class^='graphic-0']:not(.graphic-01) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graphic-02 {
  margin-left: calc(var(--responsive) * 140);
}

.graphic-03 {
  margin-left: calc(var(--responsive) * 105.5);
  margin-right: calc(var(--responsive) * 59);
}

.graphic-02 img,
.graphic-03 img {
  width: calc(var(--responsive) * 113);
  height: calc(var(--responsive) * 113);
  margin-bottom: calc(var(--responsive) * 20);
}

.graphic-02 span,
.graphic-03 span {
  font-size: calc(var(--responsive) * 18);
  font-weight: 500;
}

/* icons */

.icons {
  width: calc(var(--responsive) * 1152);
  margin-bottom: calc(var(--responsive) * 240);
}

.icons ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--responsive) * -94);
  margin-bottom: calc(var(--responsive) * -52);
}

.icons ul li {
  display: flex;
  width: calc(100% / 7);
  padding-right: calc(var(--responsive) * 94);
  padding-bottom: calc(var(--responsive) * 52);
  box-sizing: border-box;
}

/* Animation */

.title,
.sub-title,
.graphics .graphic-01,
.graphics .graphic-02,
.graphics .graphic-03,
.icons {
  opacity: 0;
}

.title.active,
.sub-title.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.graphics.active .graphic-01 {
  animation: ani01 1000ms ease-in-out 400ms 1 forwards;
}

.graphics.active .graphic-02 {
  animation: ani01 1000ms ease-in-out 500ms 1 forwards;
}

.graphics.active .graphic-03 {
  animation: ani01 1000ms ease-in-out 600ms 1 forwards;
}

.icons.active {
  animation: ani01 1400ms ease-in-out 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container {
  }

  .container-inner {
    width: calc(var(--responsive) * 280);
  }

  /* title */

  .title {
    margin-top: calc(var(--responsive) * 78);
    margin-bottom: calc(var(--responsive) * 100);
  }

  .title h3 {
    font-size: calc(var(--responsive) * 30);
  }

  /* sub title */

  .sub-title {
    margin-bottom: calc(var(--responsive) * 65);
  }

  .sub-title h3 {
    font-size: calc(var(--responsive) * 17);
    line-height: 1.176em;
    margin-bottom: 1em;
  }

  .sub-title p {
    font-size: calc(var(--responsive) * 13);
    line-height: 1.692em;
  }

  /* graphics */

  .graphics {
    flex-wrap: wrap;
    /* flex-direction: column;
    align-items: center; */
  }

  .graphic-01 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: calc(var(--responsive) * 36);
  }

  .graphic-01 img {
    width: calc(var(--responsive) * 212);
    height: calc(var(--responsive) * 212);
  }

  .graphic-02,
  .graphic-03 {
    width: 50%;
    margin-left: initial;
    margin-right: initial;
    box-sizing: border-box;
  }

  .graphic-02 {
    padding-left: calc(var(--responsive) * 20);
    padding-right: calc(var(--responsive) * 14);
  }

  .graphic-03 {
    padding-left: calc(var(--responsive) * 14);
    padding-right: calc(var(--responsive) * 20);
  }

  .graphic-02 img,
  .graphic-03 img {
    width: calc(var(--responsive) * 76);
    height: calc(var(--responsive) * 76);
    margin-bottom: calc(var(--responsive) * 12);
  }

  .graphic-02 span,
  .graphic-03 span {
    font-size: calc(var(--responsive) * 13);
    font-weight: 400;
  }

  /* icons */

  .icons {
    width: calc(var(--responsive) * 276);
    margin-bottom: calc(var(--responsive) * 100);
  }

  .icons ul {
    margin-right: calc(var(--responsive) * -28);
    margin-bottom: calc(var(--responsive) * -32);
  }

  .icons ul li {
    width: calc(100% / 4);
    padding-right: calc(var(--responsive) * 28);
    padding-bottom: calc(var(--responsive) * 32);
  }

  .icons ul li.icon_16 {
    display: none;
  }


} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
