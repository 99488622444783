<template>
    <div class="containers_01">
        <div class="container_wrap">
            <div class="maintxt_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>    
                <h3>More Convenient, More Freely</h3>
                <p>모모스틱에서 자체 개발한 UtoGo앱과 연동되는 제품 소개 모션그래픽을 진행했습니다. 다양한 기능들을<br class="inactive_768w">
                   적절한 상황과 심플한 일러스트로 표현하여 사용자로 하여금 제품에 대해 보다 쉽게 이해할 수 있도록 제작했습니다.</p>
            </div>
            <div class="content_video" :class="show1 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver1" class="ob01"></ShowObserver>    
              <iframe src="https://player.vimeo.com/video/561253004?muted=1&autoplay=1&autopause=0&loop=1&
               " frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "MomostickDetail1",
        data() {
          return {
              show0: false,
              show1: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
        }
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */
.container_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.containers_01 {
    position: relative;
    width: 100%;
    padding-top: 91px;
}

.containers_01 h3 {
    font-size: 40px;
    font-family: 'NanumSquare', serif;
    color: #383f6e;
    font-weight: 700;
    margin-bottom: 18px;
}

.containers_01 p {
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    color: #383f6e;
    font-weight: 400;
    margin-bottom: 150px;
    line-height: normal;
}

.containers_01 .content_video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.containers_01 .content_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/*__Default*/


/* Animation */

.maintxt_wrap h3,
.maintxt_wrap p,
.content_video {
    opacity: 0;
}

.maintxt_wrap.active h3,
.maintxt_wrap.active p,
.content_video.active {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .container_wrap {
        max-width: 90%;
    }  

} /*__end*/

@media screen and (max-width:1024px) {
    .containers_01 {
        padding-top: 73px;
    }

    .containers_01 h3 {
        font-size: 32px;
    }

    .containers_01 p {
        margin-bottom: 120px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .containers_01 {
        padding-top: 58px;
    }

    .containers_01 h3 {
        font-size: 27px;
    }

    .containers_01 p {
        margin-bottom: 96px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .container_wrap {
        max-width: 86.66666vw;
    }

    .containers_01 {
        padding-top: 42px;
    }

    .containers_01 h3 {
        font-size: 20px;
    }

    .containers_01 p {
        font-size: 12px;
        margin-bottom: 74px;
    }

} /*__end*/


/*__반응형*/

</style>
