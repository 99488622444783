
import { computed, defineComponent } from "vue";
import { ProjectType } from "@/enums/ProjectType";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Works",
  props: {
    project: Object,
  },
  setup(props) {
    const projectName = computed(() => props.project.name);
    const projectType = computed(() => props.project.type);
    const projectDescription = computed(() => props.project.description);
    const projectThumbnail = computed(() => props.project.thumbnail);

    const isConfidentialProject = () => {
      return props.project.type == ProjectType.CONFIDENTIAL;
    };

    const isOnGoingProject = () => {
      return props.project.type == ProjectType.ONGOING;
    };

    const computeWorkItemClass = () => {
      if (props.project.type == ProjectType.CONFIDENTIAL) {
        return "confidential";
      }

      if (props.project.type == ProjectType.ONGOING) {
        return "ongoing";
      }

      return "";
    };

    const router = useRouter();
    const goDetail = () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      if (props.project.type == ProjectType.NORMAL) {
        router.push("/works/" + props.project.name);
      }
    };

    return {
      projectName,
      projectType,
      projectDescription,
      projectThumbnail,
      isConfidentialProject,
      isOnGoingProject,
      goDetail,
      computeWorkItemClass,
    };
  },
});
