<template>
<div class="swiper_container">
<ThemeObserver :theme=theme />
      <swiper
       :slidesPerView="1" 
       :spaceBetween="6.66666 + '%'"
       :loop="true" 
       :loopFillGroupWithBlank="true"
       :centeredSlides="true"
       :autoplay="{
         'delay': 4000,
         'disableOnInteraction': false
       }"
       class="mySwiper"
      >
    <swiper-slide><img src="@/assets/img/2019_kia_ces/kiaces_gif_05.gif" alt="image"></swiper-slide>
    <swiper-slide><img src="@/assets/img/2019_kia_ces/kiaces_26.png" alt="image"></swiper-slide>
    <swiper-slide><img src="@/assets/img/2019_kia_ces/kiaces_27.png" alt="image"></swiper-slide>
    <swiper-slide><img src="@/assets/img/2019_kia_ces/kiaces_28.png" alt="image"></swiper-slide>
  </swiper>
</div>
</template>
<script lang="ts">
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


import { Swiper, SwiperSlide } from 'swiper/vue';
import {defineComponent} from "vue";
import 'swiper/swiper.scss';
import SwiperCore, {
  Autoplay
} from 'swiper/core';

SwiperCore.use([Autoplay]);

export default defineComponent({
  name: "KiaCesDetail8",
    data() {
    return {
        theme: Theme.white
    }
  },
  components: {
      Swiper,
      SwiperSlide,
      ThemeObserver
    },
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log('slide change');
      },
   },
});   
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrap {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
    
}

img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.swiper_container {
    position: relative;
    width: 100vw;
    padding-bottom: 313px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    background: #000;
}
.swiper-container {
    overflow: initial;
}

.mySwiper {
    max-width: 1200px;
}

.swiper-slide-prev,
.swiper-slide-next {
    opacity: .3;
}

/*__Default*/

/* Animation */
/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
  .mySwiper {
    max-width: 90%;
  }
} /*__end*/

@media screen and (max-width:1024px) {
  .swiper_container {
    padding-bottom: 250px;
  }

} /*__end*/

@media screen and (max-width:768px) {
  .swiper_container {
    padding-bottom: 200px;
  }
} /*__end*/

@media screen and (max-width:425px) {
  .swiper_container {
    padding-bottom: 108px;
  }

  .mySwiper {
    max-width: 86.66666vw;
  }
} /*__end*/


/*__반응형*/

</style>

