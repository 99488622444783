<template>
    <div class="containers_08" style="position: relative;">
    <ThemeObserver :theme=theme />  
      <div class="content_wrap">
        <div class="text_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
           <div class="tit_box">
             <h3 class="common_tit">SONOTITE GUI Design</h3>
           </div>
           <div class="text_box">
             <h3 class="common_tit">Dark Theme</h3>
           </div>
        </div>
        <div class="light_item_01" :class="show1 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>  
          <img src="@/assets/img/2020_viol/viol_26.png" alt="image">
        </div>
        <div class="light_item_02" :class="show2 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>  
          <div class="light_item_02_l">
            <img src="@/assets/img/2020_viol/viol_27.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_28.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_29.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_30.png" alt="image">
          </div>
          <div class="light_item_02_r">
            <img src="@/assets/img/2020_viol/viol_31.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_32.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_33.png" alt="image">
            <img src="@/assets/img/2020_viol/viol_34.png" alt="image">
          </div>
        </div>
        <div class="light_item_03" :class="show3 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>  
          <div class="modeling_tit">3D Modeling</div>
          <div class="modeling_01"><img src="@/assets/img/2020_viol/viol_35.png" alt="image"></div>
          <div class="modeling_02"><img src="@/assets/img/2020_viol/viol_36.png" alt="image"></div>
          <div class="modeling_03"><img src="@/assets/img/2020_viol/viol_37.png" alt="image"></div>
          <div class="modeling_text">
            Cartridge의 시술 범위에 따라서 Face,<br class="inactive_1280w">
            Face+Body, Body 3개의 범위로 구분<br class="inactive_1280w">
            했으며, 각 시술 부위가 잘 보이는 최적의<br class="inactive_1280w"> 
            포즈를 적용하여 Modeling했습니다.
          </div>
        </div>
        <div class="light_item_04" :class="show4 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>  
          <div class="illustration_tit">Skin Illustration</div>
          <div class="content_box">
          <div class="illustration_text">
            초음파의 깊이별 시술 위치를 표시하는<br class="inactive_1280w">
            일러스트를 함께 디자인했습니다.<br class="inactive_1280w">
            시술의 이해도를 높이기 위해 피부의<br class="inactive_1280w">
            표피층, 진피층, 지방층 등을 실제와<br class="inactive_1280w">
            유사하지만 심미성있게 표현했습니다.
          </div>
          <div class="illustration_01"><img src="@/assets/img/2020_viol/viol_38.png" alt="image"></div>
          <div class="illustration_02"><img src="@/assets/img/2020_viol/viol_39.png" alt="image"></div>
          <div class="illustration_03"><img src="@/assets/img/2020_viol/viol_40.png" alt="image"></div>
        </div>
        </div>
        <div class="copyright" :class="show5 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>   
          <span class="copy_line"></span>
          <span class="copy_txt01">SONOTITE : UX Design Project</span>
          <span class="copy_txt02">&copy; WACKY Company</span>
        </div>
      </div>      
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
    name: "ViOLDetail08.vue",
    data() {
      return {
          show0: false,
          show1: false,
          show2: false,
          show3: false,
          show4: false,
          show5: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
        isShowObserver1(value: boolean) {
        this.show1 = value;
      },
        isShowObserver2(value: boolean) {
        this.show2 = value;
      },
        isShowObserver3(value: boolean) {
        this.show3 = value;
      },
        isShowObserver4(value: boolean) {
        this.show4 = value;
      },
        isShowObserver5(value: boolean) {
        this.show5 = value;
      },                                 
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


/* Default */

.containers_08 {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background: #101010;

}

.containers_08 .content_wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.containers_08 .text_wrap {
  width: 100%;
  display: flex;
  padding: 70px 0 237px 0;
  color: rgba(255, 255, 255, 0.6)
}

.common_tit {
  font-size: 14px;
}

.tit_box {
    width: 50%;
}

.text_box {
  width: 50%;
}

.body_txt {
  font-size: 18px;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  line-height: 1.61;
}

/* image01 */

.light_item_01 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 161px;
}

.light_item_01 img {
  width: 57.33333%;
  box-shadow: 0 43px 12px rgba(0, 0, 0, 0.3);
  border-radius: 26px;
}

/* image02 */

.light_item_02 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 218px;
}

.light_item_02_l {
  width: 40.5%;
  display: flex;
  flex-direction: column;
  margin-right: 34px;
}

.light_item_02_r {
  width: 40.5%;
  margin-top: 37.25%;
  display: flex;
  flex-direction: column;
}

[class^="light_item_02"] img{
  margin-bottom: 34px;
  border-radius: 18px;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.4);
}

/* modeling */

.light_item_03 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  margin-bottom: 206px;
}

.modeling_01 {
  width: 25.91666%;
}

.modeling_02 {
  width: 23.58333%;
  margin-right: 25px;
}

.modeling_03 {
  width: 23.58333%;
  margin-right: 16px;
}

.modeling_tit {
  position: absolute;
  font-size: 84px;
  font-weight: 500;
  letter-spacing: -1.84px;
  top: -50px;
  z-index: -1;
  background-image: linear-gradient(101deg, #f5fafc, #b0b5b9 115%);
   -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modeling_text {
  width: 24%;
  font-size: 18px;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #fafafa;
}

/* item 04 */

.light_item_04 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 330px;
}

.light_item_04 .content_box {
  width: 100%;
  display: flex;
  align-items: center;
}

.illustration_tit {
  width: 100%;
  font-size: 84px;
  font-weight: 500;
  letter-spacing: -1.84px;
  margin-bottom: 69px;
  background-image: linear-gradient(99deg, #f5fafc, #b0b5b9 115%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.illustration_text {
  width: 23.5%;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  font-size: 18px;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #fafafa;
  margin-right: 2.916666%;
}

.illustration_01,
.illustration_02,
.illustration_03 {
  width: 22.08333%;
}

.illustration_01 {
  margin-right: 3.166666%;
}

.illustration_02 {
  margin-right: 3.166666%;
}

/* copy */

.copyright {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: 'Roboto', serif;  
  padding-bottom: 408px;
  overflow: hidden;
}

.copyright .copy_line {
  display: block;
  width: 1px;
  height: 0;
  padding-bottom: 19%;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  margin-bottom: 41px;
  
}

.copy_txt01 {
  display: block;
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-bottom: 14px;
}

.copy_txt02 {
  display: block;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
  letter-spacing: -0.21px;
}




/*__Default*/


/* Animation */

.light_item_01.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_01 {
  opacity: 0;
}

.light_item_02.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_02 {
  opacity: 0;
}

.light_item_03.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_03 {
  opacity: 0;
}

.light_item_04.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.light_item_04 {
  opacity: 0;
}

.copyright.active .copy_line {
  animation: copyline 800ms cubic-bezier(0, 0, 0.2, 1) both;
}

@keyframes copyline {
  0% {
    transform: translateY(-100%);
  }

  100% {
   transform: translateY(0);
  }
}

.copyright.active .copy_txt01 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 550ms;
  transition-property: opacity;
}

.copyright.active .copy_txt02 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 650ms;
  transition-property: opacity;
}

.copyright .copy_txt01,
.copyright .copy_txt02 {
  opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {

.containers_08 .content_wrap {
  max-width: 90%
}

} /*__end*/

@media screen and (max-width:1024px) {

.modeling_tit {
  font-size: 67px;
}

.modeling_text {
  font-size: 16px;
}

.illustration_tit {
  font-size: 67px;
}

.illustration_text {
  font-size: 16px;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_08 .text_wrap {
  flex-direction: column;
  padding: 56px 0 189px 0;
}

.text_box .common_tit {
  font-size: 45px;
  font-weight: 500;
}

.tit_box {
  width: 100%;
  margin-bottom: 25px;
}

.text_box {
  width: 100%;
}

/* image01 */

.light_item_01 {
  margin-bottom: 97px;
}

.light_item_01 img {
  width: 59.44444vw;
  box-shadow: 0 30px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}

/* image02 */

.light_item_02 {
  margin-bottom: 131px;
}

.light_item_02_l {
  margin-right: 20px;
}

[class^="light_item_02"] img {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);
}

/* modeling */

.light_item_03 {
  flex-wrap: wrap;
  margin-bottom: 124px;
}

.modeling_tit {
  top: -40px;
  font-size: 54px;
}

.modeling_01 {
  width: 30vw;
  margin-right: 1.38888vw;
}

.modeling_02 {
  width: 26.66666vw;
  margin-right: 1.94444vw;
}

.modeling_03 {
  width: 26.66666vw;
  margin-right: 0;
}

.modeling_text {
  width: 100%;
  margin-top: 47px;
}

/* item 04 */

.light_item_04 {
  padding-bottom: 264px;
}

.illustration_tit {
  font-size: 54px;
  margin-bottom: 42px;
}

.light_item_04 .content_box {
  flex-wrap: wrap;
}

.illustration_01, 
.illustration_02,
.illustration_03 {
  width: 32.3076923%;
}

.illustration_01 {
  margin-right: 1.5383%;
}

.illustration_02 {
  margin-right: 1.5383%;
}

.illustration_text {
  order: 4;
  width: 100%;
  margin-top: 47px;
}

/* copy */

.copyright {
  padding-bottom: 326px;
}

.copyright .copy_line {
  margin-bottom: 36px;
}

.copy_txt01 {
  font-size: 18px;
}

.copy_txt02 {
  font-size: 14px;
}


} /*__end*/

@media screen and (max-width:425px) {

.containers_08 .content_wrap {
  max-width: 86.66666vw;
}

.containers_08 .text_wrap {
  padding: 32px 0 78px 0;
}

.text_box .common_tit {
  font-size: 36px;
}

.common_tit {
  font-size: 10px;
}

.tit_box {
  margin-bottom: 20px;
}

/* image01 */

.light_item_01 {
  margin-bottom: 48px;
}

.light_item_01 img {
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* image02 */

.light_item_02 {
  margin-bottom: 82px;
}

.light_item_02_l {
  width: 50%;
  margin-right: 12px;
}

.light_item_02_r {
  width: 50%;
}

[class^="light_item_02"] img {
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

/* modeling */

.light_item_03 {
  margin-bottom: 82px;
}

.modeling_tit {
  top: -10px;
  font-size: 36px;
  letter-spacing: -0.79px;
}

.modeling_text {
  width: 100%;
  margin-top: 28px;
  font-size: 13px;
  letter-spacing: -0.07px;
}

/* item 04 */

.light_item_04 {
  padding-bottom: 112px;
}

.illustration_tit {
  font-size: 36px;
  margin-bottom: 40px;
  letter-spacing: -0.79px;
}

.illustration_text {
  margin-top: 23px;
  font-size: 13px;
  letter-spacing: -0.07px;
}

/* copy */

.copyright {
  padding-bottom: 158px;
}

.copy_txt01 {
  font-size: 16px;
}

.copy_txt02 {
  font-size: 13px;
}


} /*__end*/


/*__반응형*/

</style>
