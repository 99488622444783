<template>
    <div class="visual_containers">
      <video 
        class="inactive_820w" 
        autoplay="autoplay" 
        playsinline 
        loop="loop" 
        muted="muted" 
        preload="none" 
        crossorigin="anonymous" 
      >
         <source src="@/assets/img/main/main1_looping.webm" type="video/webm">
         <source src="@/assets/img/main/main1_looping.mp4" type="video/mp4">
      </video>
      <video 
        class="active_820w" 
        autoplay="autoplay" 
        playsinline 
        loop="loop" 
        muted="muted" 
        preload="none" 
        crossorigin="anonymous" 
      >
         <source src="@/assets/img/main/main1_mobile.webm" type="video/webm">
         <source src="@/assets/img/main/main1_mobile.mp4" type="video/mp4"> 
      </video>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";


export default defineComponent({
  name: "Visual",
  methods: {

  },
  
});
</script>

<style scoped lang="css">



/* Default */

.visual_containers {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .mob_video {
  display: none;
} */

/*__Default*/

/* Animation */
/*__Animation*/

/* 반응형 */

@media screen and (max-width:1280px) {} /*__end*/

@media screen and (max-width:768px) {
/* .pc_video {
  display: none;
}

.mob_video {
  display: block;
} */

} /*__end*/

@media screen and (max-width:425px) {} /*__end*/

/*__반응형*/

</style>
