<template>
        <div class="content_containers">
            <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                <div class="title_text">Scenery Theme</div>
                <div class="body_text">여름날에 여유로운 풍경을 소재로 삼성의 갤럭시 테마를 제작하고 업로드했습니다.</div>
            </div>
            <div class="content_img_wrap">
                <div class="image_01" :class="show1 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                    <img src="@/assets/img/2018_samsung_scenery/scenery_01.png" alt="image" class="inactive_768w">
                    <img src="@/assets/img/2018_samsung_scenery/m_scenery_01.png" alt="mobile image" class="active_768w">
                </div>
                <div class="image_02" :class="show2 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <img src="@/assets/img/2018_samsung_scenery/scenery_02.png" alt="image" class="inactive_768w">
                    <img src="@/assets/img/2018_samsung_scenery/m_scenery_02.png" alt="mobile image" class="active_768w">
                </div>
                <div class="image_03" :class="show3 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                    <img src="@/assets/img/2018_samsung_scenery/scenery_03.png" alt="image" class="inactive_768w">
                    <img src="@/assets/img/2018_samsung_scenery/m_scenery_03.png" alt="mobile image" class="active_768w">
                </div>
                <div class="image_04" :class="show4 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                    <img src="@/assets/img/2018_samsung_scenery/scenery_04.png" alt="image" class="inactive_768w">
                    <img src="@/assets/img/2018_samsung_scenery/m_scenery_04.png" alt="mobile image" class="active_768w">
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "SamsungSceneryDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    width: 100%;
    padding-top: 91px;
    content-visibility: auto;
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 188px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 24px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_img_wrap {
    width: 100%;
    height: auto;
    background-image: url(../../../assets/img/2018_samsung_scenery/scenery_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_01.active img,
.image_02.active img,
.image_03.active img,
.image_04.active img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_01 img,
.image_02 img,
.image_03 img,
.image_04 img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
      .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_img_wrap {
        background-image: url(../../../assets/img/2018_samsung_scenery/m_scenery_bg.jpg);
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text {
        max-width: 86.66666vw;
    }

    .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 98px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        width: 280px;
        height: 36px;
        font-size: 12px;
        line-height: 1.5;
    }

} /*__end*/


/*__반응형*/

</style>