<template>
  <div class="overview_containers">
            <div class="overview_content content_wrap">
                <div class="overview_title_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                    <div class="content_title">Project Overview</div>
                    <div class="content_body">특정 브랜드 아이덴티티 없이 컨텐츠 위주로 운영되던 기존 바로세움연구소에서 ‘다양한 사람의 행복을 위하는 연구소’라는 새로운 브랜드
                        핵심가치를 부여</div>
                </div>
                <div class="overview_infographic" :class="show1 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver1" class="infoghc_observer"></ShowObserver>
                    <div class="infogpc1">STANDARD</div>
                    <div class="infogpc2"><span>New Brand</span>
                        <img class="inactive_768w" src="@/assets/img/2020_baro/overview_ico_01.svg" alt="ico"><img class="active_768w" src="@/assets/img/2020_baro/m_overview_ico_01.svg" alt="ico">
                        <span>Definition</span>
                    </div>
                    <div class="infogpc3">
                        <div class="circle1"><span>Positive</span><span>긍정적인 힘으로 가득찬</span></div>
                        <div class="circle2"><span>Trust</span><span>언제나 신뢰 할 수 있는</span></div>
                    </div>
                    <div class="infogpc4"><img class="inactive_768w" src="@/assets/img/2020_baro/overview_ico_01.svg" alt="ico"><img class="active_768w" src="@/assets/img/2020_baro/m_overview_ico_01.svg" alt="ico"></div>
                    <div class="infogpc5"><span>바로세움 연구소</span><span>다양한 사람의<br>행복을 위하는 연구소</span></div>
                </div>
                <div class="overview_item_wrap" :class="show2 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <div class="item_definition">
                        <span>Brand Definition</span>
                        <span>브랜드가 전하고자 하는 가치를 바탕으로 아이덴티티 정립</span>
                    </div>
                    <div class="item_identity">
                        <span>Design Identity</span>
                        <span>정립한 아이덴티티를 바탕으로 디자인 시스템 구축</span>
                    </div>
                    <div class="item_application">
                        <span>Brand Design Application</span>
                        <span>디자인 시스템을 활용해 고객에게 전달되는 결과물 제작</span>
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.overview_containers {
    width: 100%;
    position: relative;
    margin: 209px 0 205px 0;
}

.overview_title_wrap {
    margin-bottom: 147px;
}

.overview_title_wrap .content_title {
    color: #0d1e41;
    margin-bottom: 32px;
}

.overview_title_wrap .content_body {
    color: #0d1e41;
}

/*infographic*/

.overview_infographic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

/*infographic_infogpc1*/

.overview_infographic .infogpc1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 179px;
    border: 1.5px solid #a4a6b0;
    border-radius: 50%;
    font-family: 'NanumSquareRound', serif;
    font-weight: 700;
    color: #a4a6b0;
    letter-spacing: 0.9px;
}

/*infographic_infogpc2*/

.overview_infographic .infogpc2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px 0 27px;
    overflow: hidden;
}

.overview_infographic .infogpc2 span {
    font-family: 'NanumSquareRound', serif;
    font-weight: 700;
    color: #a4a6b0;
    font-size: 12px;

}

.overview_infographic .infogpc2 img {
    margin: 8px 0;
}

/*infographic_infogpc3*/

.overview_infographic .infogpc3 {
    display: flex;
    flex-direction: column;
}

.overview_infographic .infogpc3 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    mix-blend-mode: multiply;
    color: #fff;
}

.overview_infographic .infogpc3 div span:first-child {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    margin-bottom: 3px;
}

.overview_infographic .infogpc3 div span:last-child {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
}

.overview_infographic .infogpc3 .circle1 {
    background-color: rgba(229, 85, 217, 0.8);
    margin-bottom: -34px;
}

.overview_infographic .infogpc3 .circle2 {
    background-color: rgba(70, 203, 192, 0.8);
    
}

/*infographic_infogpc4*/

.overview_infographic .infogpc4 {
    margin: 0 22px 0 16px;
    overflow: hidden;
}

/*infographic_infogp5*/

.overview_infographic .infogpc5 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 179px;
    height: 179px;
    border-radius: 50%;
    background-color: #4061ac;
}

.overview_infographic .infogpc5 span {
    text-align: center;
    color: #fff;
}

.overview_infographic .infogpc5 span:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    margin-bottom: 8px;
}

.infogpc5 span:last-child {
    font-size: 12px;
    line-height: 1.33;
}

/*--infographic*/

/*overview_item*/

.overview_item_wrap {
    display: flex;
    justify-content: center;
}

.overview_item_wrap [class^="item_"] {
    display: flex;
    flex-direction: column;
    width: 240px;
    color: #2553bc;
}

.overview_item_wrap [class^="item_"] span:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    margin-bottom: 4px;
    white-space: nowrap;
}

.overview_item_wrap [class^="item_"] span:first-child::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(156, 164, 204, 0.33);
    z-index: -10;
    margin: -14px 0 0 -14px;
}

.overview_item_wrap [class^="item_"] span:last-child {
    font-size: 13px;
    line-height: normal;
}


.overview_item_wrap [class^="item_"]:not(.item_definition) {
    margin-left: 122px;
}

/*__Default*/


/* Animation */

.circle1,
.circle2 {
    transition: .8s ease-in-out;
}

.infogpc3:hover .circle2 {
   transform: translateY(calc(-100% + 34px));
} 

.infogpc3:hover .circle1 {
    transform: translateY(calc(100% - 34px));
 } 

.overview_item_wrap [class^="item_"] span:first-child::before {
    transition: .5s ease-in-out;
}

.overview_item_wrap > div:hover span:first-child:before {
    transform: scale(2);
    opacity: 0;   
} 

.overview_title_wrap.active .content_title,
.overview_title_wrap.active .content_body {
    opacity: 1;
    transition-duration: .8s;
}

.overview_title_wrap .content_title,
.overview_title_wrap .content_body {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.overview_infographic.active .infogpc1,
.overview_infographic.active .infogpc3,
.overview_infographic.active .infogpc5 {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.overview_infographic .infogpc1,
.overview_infographic .infogpc3,
.overview_infographic .infogpc5 {
    opacity: 0;
}

.overview_infographic.active .infogpc2 img,
.overview_infographic.active .infogpc4 img {
    opacity: 1;
    transform: translateX(0) translateY(0);
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.overview_infographic .infogpc2 img,
.overview_infographic .infogpc4 img {
    opacity: 0;
    transform: translateX(-100%);
}

.overview_item_wrap.active [class^="item_"] {
    opacity: 1;
    transition-duration: 1.2s;
    transition-timing-function: ease-in-out;
}

.overview_item_wrap [class^="item_"] {
    opacity: 0;

}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

    .overview_infographic .infogpc2,
    .overview_infographic .infogpc4 {
        width: 14.91666%;
    }

    .overview_infographic .infogpc2 > img,
    .overview_infographic .infogpc4 > img {
        width: 100%;
    }

    .overview_item_wrap [class^="item_"]:not(.item_definition) {
        margin-left: 10.16666%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

   .overview_infographic .infogpc1 {
       width: 143px;
       height: 143px;
   }

   .overview_infographic .infogpc5 {
       width: 143px;
       height: 143px;
   }

} /*__end*/

@media screen and (max-width:768px) {
      .overview_infographic {
        flex-direction: column; 
      }

      .overview_infographic .infogpc1,
      .overview_infographic .infogpc5 {
          width: 179px;
          height: 179px;
      }

      .overview_infographic .infogpc2 {
          width: inherit;
          flex-direction: row;
      }

      .overview_infographic .infogpc4 {
          display: flex;
          justify-content: center;
      }

      .overview_infographic .infogpc2 img,
      .overview_infographic .infogpc4 img {
          opacity: 0;
          transform: translateY(-200%);
          transition-duration: 1.2s;
          transition-timing-function: ease-in-out;
     }

      .overview_infographic .infogpc2 > img, 
      .overview_infographic .infogpc4 > img {
          width: 7px;
          margin: 26px 24px 32px 24px;
      }

      .overview_item_wrap {
        flex-direction: column;
        align-items: center;
      }

      .overview_item_wrap [class^="item_"]:not(.item_definition) {
          margin-left: 0;
      }

      .overview_item_wrap [class^="item_"]:not(.item_application) {
          margin-bottom: 67px;
      }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .overview_containers {
        margin: 85px 0 132px 0;
    }

    .overview_title_wrap {
        margin-bottom: 52px;
    }

    .overview_infographic {
        margin-bottom: 104px;
    }

} /*__end*/


/*__반응형*/

</style>