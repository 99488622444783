<template>
  <div class="container">
    <div class="container-inner">
      <div class="motion_emoji-wrap">
        <ul
          id="motion_emoji"
          class="motion_emoji"
          :class="show0 ? 'active' : ''"
          style="position: relative"
        >
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
          <li
            :class="`motion_${index + 1}`"
            :key="index"
            v-for="(item, index) in items"
          >
            <img
              :src="require(`@/assets/img/2021_emoji/${item.url}.gif`)"
              alt="emoji image"
            />
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="footer-txt">
          <h3>Thank You For</h3>
          <h3>Watching!</h3>
          <img
            src="@/assets/img/2021_emoji/footer-emoji.webp"
            alt="emoji image"
          />
        </div>
      </div>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail7",
  el: "#motion_emoji",
  data() {
    const items = [
      {
        url: "motion_1",
        name: "Smiling",
      },
      {
        url: "motion_2",
        name: "Grinning",
      },
      {
        url: "motion_3",
        name: "Slightly Smiling",
      },
      {
        url: "motion_4",
        name: "Kissing",
      },
      {
        url: "motion_5",
        name: "winking",
      },
      {
        url: "motion_6",
        name: "Raised Eyebrow",
      },
      {
        url: "motion_7",
        name: "Neutral",
      },
      {
        url: "motion_8",
        name: "Expressionless",
      },
      {
        url: "motion_9",
        name: "Clouds",
      },
      {
        url: "motion_10",
        name: "Grimacing",
      },
      {
        url: "motion_11",
        name: "Drooling",
      },
      {
        url: "motion_12",
        name: "Medical Mask",
      },
      {
        url: "motion_13",
        name: "Exploding Head",
      },
      {
        url: "motion_14",
        name: "Cowboy Hat",
      },
      {
        url: "motion_15",
        name: "Disguised",
      },
      {
        url: "motion_16",
        name: "Nauseated",
      },
      {
        url: "motion_17",
        name: "Disappointed",
      },
      {
        url: "motion_18",
        name: "Worried",
      },
      {
        url: "motion_19",
        name: "Flushed",
      },
      {
        url: "motion_20",
        name: "Nerd",
      },
      {
        url: "motion_21",
        name: "Weary",
      },
      {
        url: "motion_22",
        name: "Steam From Nose",
      },
      {
        url: "motion_23",
        name: "Screaming",
      },
      {
        url: "motion_24",
        name: "Angry horns",
      },
    ];

    return {
      show0: false,
      items,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  background: #fafafa;
  font-family: "Poppins", sans-serif;
}

.container-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* motion */
.motion_emoji-wrap {
  width: calc(var(--responsive) * 1236);
  margin-top: calc(var(--responsive) * 174);
  margin-bottom: calc(var(--responsive) * 215);
}

.motion_emoji {
  display: flex;
  flex-wrap: wrap;
  font-size: calc(var(--responsive) * 84);
  width: calc(100% + 1em);
  margin-left: -1em;
  margin-bottom: -1.55em;
}

.motion_emoji li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2.89em;
  padding-left: 1em;
  padding-bottom: 1.643em;
}

.motion_emoji li img {
  width: 100%;
  object-fit: cover;
}

.motion_emoji li span {
  font-size: calc(var(--responsive) * 20);
  white-space: nowrap;
  font-weight: 600;
  color: #525252;
}

/* footer */

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(var(--responsive) * 520);
  background: #1686e1;
}

.footer-txt {
  position: relative;
  display: flex;
  align-items: center;
  font-size: calc(var(--responsive) * 52);
}

.footer-txt::before {
  content: "";
  display: block;
  position: absolute;
  top: -28px;
  left: calc(50% - 18px);
  width: 37px;
  height: 2px;
  background: #fff;
}

.footer-txt::after {
  content: "© 2021 Wacky";
  position: absolute;
  top: 6.955em;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-size: calc(var(--responsive) * 22);
  font-weight: 500;
  color: #e9f5ff;
  white-space: nowrap;
}

.footer-txt h3 {
  color: #fff;
  font-weight: 600;
}

.footer-txt h3:nth-child(1) {
  margin-right: 0.26em;
}

.footer-txt img {
  width: 1.231em;
}

/*__Default*/

/* Animation */

.motion_emoji {
  opacity: 0;
}

.motion_emoji.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .motion_emoji-wrap {
    width: 78.3333%;
    margin-top: 17.2222vw;
    margin-bottom: 17.2222vw;
  }

  .motion_emoji {
    font-size: 8.33333vw;
    margin-bottom: -1.033em;
  }

  .motion_emoji li {
    width: 2.45em;
    padding-bottom: 1.033em;
  }

  .motion_emoji li span {
    font-size: 3.3333vw;
    line-height: 1.5em;
    white-space: pre-wrap;
    text-align: center;
  }

  .footer {
    height: 80.83333vw;
  }

  .footer-txt {
    width: 50vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -5.27777vw;
  }

  .footer-txt::before {
    top: -3.8888vw;
    left: calc(50% - 2.7777vw);
    width: 5.5555vw;
    height: 1px;
  }

  .footer-txt::after {
    top: 12.25em;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-size: 3.33333vw;
    white-space: nowrap;
  }

  .footer-txt h3 {
    font-size: 6.66666vw;
    line-height: 1.375em;
  }

  .footer-txt img {
    width: 7.77777vw;
    margin-left: 0.55555vw;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
