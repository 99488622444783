import {ref} from 'vue'

export default function useThemeIntersectionObserver(header: Element) {
    const isSupported = 'IntersectionObserver' in window
    const isShown = ref(false)

    if (!isSupported) {
        console.log('Your browser does not support this feature')
    }

    // TOP, RIGHT, BOTTOM, LEFT
    const margin = '-' + (header.getBoundingClientRect().height * 0.5) + 'px 0px -50px 0px';
    const observer = new IntersectionObserver((entries) => {
        const component = entries[0]
        isShown.value = component.isIntersecting
    }, {rootMargin: margin});

    const observe = (element: Element | null) => {
        if (element != null) {
            observer.observe(element)
        }
    }

    const unobserve = (element: Element | null) => {
        if (element != null) {
            observer.unobserve(element)
        }
    }

    return {observe, unobserve, isShown}
}
