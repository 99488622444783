<template>
  <div class="system_containers">
      <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <h3 class="content_tit">R.E.A.D. System</h3>
          <div class="systemitem_top" :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0" class="ob01"></ShowObserver>    
            <div class="system_l">
               <div class="content_box">
                   <div class="item_human"><span>Human</span></div>
                   <span class="plus"></span>
                   <div class="item_tech"><span>Tech</span></div>
                </div>
                <div class="item_img"><img src="@/assets/img/2019_kia_ces/kiaces_02.png" alt="image"></div>
            </div>
            <span class="arrow"><img src="@/assets/img/2019_kia_ces/wfarrow_03.svg" alt="image"></span>
            <div class="item_read">
               <span>R.E.A.D<br>System</span>
               <img src="@/assets/img/2019_kia_ces/kiaces_03.png" alt="image">
            </div>   
          </div>
          <div class="systemitem_btm" :class="show0 ? 'active' : ''" style="position: relative;">
              <div class="system_btm_l"><img src="@/assets/img/2019_kia_ces/kiaces_04.png" alt="image"></div>
              <div class="system_btm_c"><img src="@/assets/img/2019_kia_ces/kiaces_05.png" alt="image"></div>
              <div class="system_btm_r"><img src="@/assets/img/2019_kia_ces/kiaces_06.png" alt="image"></div>
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail2",
  data() {
    return {
        theme: Theme.white,
        show0: false,
    }
  },
  components: {
       ThemeObserver,
       ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.system_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 154px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.system_containers .content_tit {
    padding-top: 101px;
    margin-bottom: 156px;
}

.systemitem_top {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.systemitem_top .system_l {
    position: relative;
    width: 53.3333%;
}

.systemitem_top .system_l .item_human {
    position: absolute;
    top: 5.82574%;
    left: 3.13971743%;
    width: 43.3281%;
    padding-bottom: 43.3281%;
    display: flex;
    justify-content: center;
}

.systemitem_top .system_l .item_human span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.systemitem_top .system_l .item_tech {
    position: absolute;
    top: 5.82574%;
    right: 3.13971743%;
    width: 43.3281%;
    padding-bottom: 43.3281%;
    display: flex;
    justify-content: center;
}

.systemitem_top .system_l .item_tech span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.systemitem_top .system_l .plus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 21px;
    height: 21px;
}

.systemitem_top .system_l .plus::before {
    content: "";
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: 100%;
    height: 1px;
    background: #2D96E7;
}

.systemitem_top .system_l .plus::after {
    content: "";
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: 1px;
    height: 100%;
    background: #2D96E7;
}

.systemitem_top .system_l [class^="item_"] span {
    font-size: 28px;
    letter-spacing: -0.62px;
    font-weight: 700;
}

.systemitem_top .arrow {
    position: relative;
    width: 7.83333%;
    margin: 0 2.5%;
}

.systemitem_top .item_read {
    position: relative;
    display: flex;
    width: 34.16666%;
    justify-content: center;
    align-items: center;
}

.systemitem_top .item_read img {
    width: 100%;
    height: 100%;
}

.systemitem_top .item_read span {
    position: absolute;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -0.93px;
    font-weight: 700;
}

.systemitem_btm {
    display: flex;
}

/*__Default*/


/* Animation */

.systemitem_top .system_l,
.systemitem_top .arrow,
.systemitem_top .item_read {
    opacity: 0;
}

.systemitem_top.active .system_l {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.systemitem_top.active .arrow {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 100ms;
    transition-property: opacity;
}

.systemitem_top.active .item_read {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.systemitem_btm {
    opacity: 0;
}

.systemitem_btm.active {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .system_containers .content_wrap {
        max-width: 90%;
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .system_containers .content_tit {
        font-size: 14px;
    }

    .systemitem_top .system_l [class^="item_"] span {
        font-size: 20px;
    }

    .systemitem_top .item_read span {
       font-size: 29px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .system_containers .content_tit {
        margin-bottom: 164px;
    }

    .systemitem_top .system_l [class^="item_"] span {
        font-size: 14px;
    }

    .systemitem_top .item_read span {
       font-size: 20px;
    }


} /*__end*/

@media screen and (max-width:425px) {
    .system_containers {
        padding-bottom: 100px;
    }

    .system_containers .content_wrap {
        max-width: 86.66666vw;
    }

    .system_containers .content_tit {
        font-size: 12px;
        margin-bottom: 105px;
        letter-spacing: -0.27px;
    }

    .systemitem_top .system_l [class^="item_"] span {
        font-size: 10px;
        letter-spacing: -0.22px;
    }

    .systemitem_top .item_read span {
       font-size: 12px;
       line-height: 1.08;
       letter-spacing: -0.27px;
    }

    .systemitem_top .system_l .plus {
        width: 10px;
        height: 10px;
    }

} /*__end*/


/*__반응형*/

</style>
