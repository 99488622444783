<template>
   <div class="thumbnail_containers">
       <div class="thumbnail_content">
           <div class="thumbnail_text">
               <div class="thumbnail_tit">
                   <h1>Galaxy Friends<br>Commercial Motion Graphic</h1>
               </div>
               <div class="thumbnail_info">
                   <p>Storytelling, 2D Illustration, Motion</p>
               </div>
           </div>
       </div>
   </div>
</template>

<script lang="ts">
    import {
        defineComponent
    } from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "CommercialDetail0",
        data() {
            return {

            }
        },
        components: {

        },
        methods: {

        }
    });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.thumbnail_containers {
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    position: relative;
    background-image: url(../../../assets/img/2017_commercial/thumbnail_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content-visibility: auto 
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 175px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
    height: 142px;
    font-size: 48px;
    font-weight: bold;
    line-height: normal;
    color: #008adf;
    margin-bottom: 88px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
    height: 27px;
    font-size: 18px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.7);
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
    content: "";
    display: block;
    width: 23px;
    height: 1px;
    background-color: rgba(176, 176, 176, 0.7);
    margin-bottom: 6px;
}

/*__Default*/

/* Animation */

.thumbnail_text {
    animation-name: thumbtit_ani;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}


@-webkit-keyframes thumbtit_ani {

    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 90%;
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        height: 134px;
        font-size: 40px;
        margin-bottom: 80px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        font-size: 16px;
    }

} /*__end*/

@media screen and (max-width:768px) {} /*__end*/

@media screen and (max-width:425px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 86.66666vw;
    }

    .thumbnail_containers .thumbnail_content {
        padding-top: 87px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        height: 99px;
        font-size: 24px;
        margin-bottom: 47px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        height: 20px;
        font-size: 14px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
        width: 9px;
    }

} /*__end*/


/*__반응형*/

</style>
