
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "MomostickDetail1",
        data() {
          return {
              show0: false,
              show1: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
        }
      });
      