interface CustomLocation extends Location, URL {}

function redirect() {
  const params = new URL(document.location as CustomLocation & string)
    .searchParams;
  const id = params.get("page_id");

  const comoAR_ID = {
    IOS: "403",
    AOS: "400",
  };

  if (comoAR_ID.IOS === id) {
    document.location.href =
      "https://apps.apple.com/kr/app/%EA%BC%AC%EB%AA%A8%ED%8C%9D-ar-%EC%82%AC%EC%9A%B4%EB%93%9C%EB%B6%81/id1463020270";
    return;
  }

  if (comoAR_ID.AOS === id) {
    document.location.href =
      "https://play.google.com/store/apps/details?id=com.wacky.como.ARSoundBook&hl=ko-KR";
    return;
  }
}

export default redirect;
