<template>
    <div class="thumbnail_containers">
        <ThemeObserver class="separate_theme" :theme=theme />
        <div class="thumbnail_content">
            <div class="thumbnail_text">
                <div class="thumbnail_tit">
                    <h1>Knox Messenger<br>감성 이모티콘</h1>
                </div>
                <div class="thumbnail_info">
                    <p>Motion</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ThemeObserver from "@/components/ThemeObserver.vue";
    import {Theme} from "@/enums/Theme";

    export default defineComponent({
        name: "EmoticonDetail0",
        data() {
          return {
            theme: Theme.white
          }
        },
        components: {
          ThemeObserver
        },
        methods: {}
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.separate_theme {
    height: 90vh;
}

.thumbnail_containers {
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    position: relative;
    background-image: url(../../../assets/img/2016_emoticon/thumbnail_bg.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    content-visibility: auto;
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 175px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
    height: 136px;
    font-size: 48px;
    line-height: 1.42;
    letter-spacing: -0.23px;
    color: #fff;
    margin-bottom: 88px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
    height: 27px;
    font-size: 18px;
    line-height: normal;
    color: rgba(255, 255, 255, 0.7);
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
    content: "";
    display: block;
    width: 23px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 6px;
}

/*__Default*/


/* Animation */

.thumbnail_text {
    animation-name: thumbtit_ani;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}


@-webkit-keyframes thumbtit_ani {

    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .thumbnail_containers {
        background-position: 65%;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        height: 134px;
        font-size: 40px;
        margin-bottom: 80px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        font-size: 16px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .thumbnail_containers {
        background-image: url(../../../assets/img/2016_emoticon/m_thumbnail_bg.jpg);
    }

} /*__end*/

@media screen and (max-width:425px) {
        .thumbnail_containers > .thumbnail_content {
            max-width: 86.66666vw;
        }

        .thumbnail_containers .thumbnail_content {
            padding-top: 84px;
        }

        .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
            height: 72px;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 47px;
        }

        .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
            height: 20px;
            font-size: 14px;
        }

        .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
            width: 9px;
        }
        
} /*__end*/


/*__반응형*/

</style>
