import { ProjectType } from "@/enums/ProjectType";

export const WORKPROJECTS = [
  {
    name: "Family Hub Scenario Development",
    type: ProjectType.CONFIDENTIAL,
    description: "Samsung",
    thumbnail: require("@/assets/img/main/2023_fh.jpg"),
  },
  {
    name: "Osstem UX 디자인 연간 프로젝트",
    type: ProjectType.CONFIDENTIAL,
    description: "Osstem Implant",
    thumbnail: require("@/assets/img/main/2023_osstem.jpg"),
  },
  {
    name: "One UI 6 Emoji Design",
    type: ProjectType.CONFIDENTIAL,
    description: "Samsung",
    thumbnail: require("@/assets/img/main/2023_emoji_6.0.jpg"),
  },
  {
    name: "NUVi : Smartcare Robot Service",
    type: ProjectType.CONFIDENTIAL,
    description: "WACKY",
    thumbnail: require("@/assets/img/main/2023_Nuvi.jpg"),
  },
  {
    name: "T전화 대표사이트 운영",
    type: ProjectType.CONFIDENTIAL,
    description: "SK Telecom",
    thumbnail: require("@/assets/img/main/2023__Tphone.jpg"),
  },
  {
    name: "미더스 대표사이트 운영",
    type: ProjectType.CONFIDENTIAL,
    description: "SK Telecom",
    thumbnail: require("@/assets/img/main/2023__meetus.jpg"),
  },
  {
    name: "Samsung Calendar Sticker",
    type: ProjectType.CONFIDENTIAL,
    description: "Samsung",
    thumbnail: require("@/assets/img/main/2022_sticker.jpg"),
  },
  {
    name: "Samsung Smart things\nVisual Concept Motion",
    type: ProjectType.CONFIDENTIAL,
    description: "Samsung",
    thumbnail: require("@/assets/img/main/2022_smartthigs.jpg"),
  },
  {
    name: "SUPERNOVA Total Solution",
    type: ProjectType.CONFIDENTIAL,
    description: "SK Telecom",
    thumbnail: require("@/assets/img/main/2021_supernova.webp"),
  },
  {
    name: "Samsung Privacy Web",
    type: ProjectType.CONFIDENTIAL,
    description: "Samsung",
    thumbnail: require("@/assets/img/main/2021_samsung_privacy.jpg"),
  },
  {
    name: "Human-Centered Smart City",
    type: ProjectType.CONFIDENTIAL,
    description: "Hyundai Motors",
    thumbnail: require("@/assets/img/main/2021_hmg_smart_city.webp"),
  },
  {
    name: "2021_emoji",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2021-kbe-expression@3x.webp"),
  },
  {
    name: "2021_seoyoneh",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2021-seoyon@3x.webp"),
  },
  {
    name: "2021_KBbank",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2021-kb@3x.webp"),
  },
  {
    name: "2021_momostick",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-momostick.webp"),
  },
  {
    name: "2021_ccb",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2021-ccb@3x.webp"),
  },
  {
    name: "2019_kia_ces",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2019-kia-ces@3x.webp"),
  },
  {
    name: "2020_baro",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2020-baro@3x.webp"),
  },
  {
    name: "의료기기 UX-Motion",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2020-viol@3x.webp"),
  },
  {
    name: "2019_vogo",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2019-vogo@3x.webp"),
  },
  {
    name: "2019_hyundai_kia",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2019-hyundai-kia-ux@3x.webp"),
  },
  {
    name: "Non-Face Order Service",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2020-newzen@3x.webp"),
  },
  {
    name: "2017_emoji",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2017-keyboard-emoji@3x.webp"),
  },
  {
    name: "2018_mde_wellness",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2018-samsung-life-care@3x.webp"),
  },
  {
    name: "2019_galaxy_friends",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2019-samsung-led-case@3x.webp"),
  },
  {
    name: "2015_samsung_gear_vr",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2015-samsung-gear-vr@3x.webp"),
  },
  {
    name: "2020_ajin",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2020-ajin@3x.webp"),
  },
  {
    name: "2017_commercial",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2017-galaxy-friends-motion@3x.webp"),
  },
  {
    name: "2018_family_scheduler",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2018-family-scheduler@3x.webp"),
  },
  {
    name: "2018_como_arbook",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2018-como@3x.webp"),
  },
  {
    name: "2018_samsung_neon",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2018-samsung-theme-neon@3x.webp"),
  },
  {
    name: "2018_samsung_scenery",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2018-samsung-scenery-theme@3x.webp"),
  },
  {
    name: "Samsung Foldable Device",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-next-foldable@3x.webp"),
  },
  {
    name: "2016_emoticon",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2016-emotiocon@3x.webp"),
  },
  {
    name: "Design Philosophy of Samsung",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-da-vx@3x.webp"),
  },
  {
    name: "Samsung VR Home Experience",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-vr-home-foryou@3x.webp"),
  },
  {
    name: "2017_perseus",
    type: ProjectType.NORMAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-2017-perseus@3x.webp"),
  },
  {
    name: "Samsung Expended VR",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-expended-vr@3x.webp"),
  },
  {
    name: "Samsung VR Prototype",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-ar-glass-home@3x.webp"),
  },
  {
    name: "Samausng Accessory Concept Motion",
    type: ProjectType.CONFIDENTIAL,
    thumbnail: require("@/assets/img/main/pc-pf-list-marvel-endlogo@3x.webp"),
  },
];
