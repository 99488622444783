import {ref} from 'vue'

export default function useShowIntersectionObserver() {
    const isSupported = 'IntersectionObserver' in window
    const isShown = ref(false)

    if (!isSupported) {
        console.log('Your browser does not support this feature')
    }

    const observer = new IntersectionObserver((entries) => {
        const component = entries[0]

        isShown.value = component.isIntersecting
    })

    const observe = (element: Element | null) => {
        if (element != null) {
            observer.observe(element)
        }
    }

    const unobserve = (element: Element | null) => {
        if (element != null) {
            observer.unobserve(element)
        }
    }

    return {observe, unobserve, isShown}
}
