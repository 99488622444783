<template>
  <div class="content_containers">
    <ShowObserver v-on:isShow="isShowObserver"></ShowObserver>
    <div class="content_02"  :class="show ? 'active' : ''">
      <div class="title_text">DESIGN IDENTITY</div>
      <div class="content_body">
        <div><img src="@/assets/img/2019_vogo/Identity_01.png" alt="pop"></div>
        <div><img src="@/assets/img/2019_vogo/Identity_02.png" alt="vivid"></div>
        <div><img src="@/assets/img/2019_vogo/Identity_03.png" alt="young"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail3",
  data() {
    return {
      show: false
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver(value: boolean) {
      this.show = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.content_containers .content_02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 89px 0 239px 0;
    background-image: url(../../../assets/img/2019_vogo/Identity_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 248px;
}

.content_containers .content_02 .title_text {
    color: #fff;
    margin-bottom: 190px;
}

.content_containers .content_02 .content_body {
    display: flex;
    justify-content: center;
    margin-left: -104px;
}

.content_containers .content_02 .content_body div {
    padding-left: 104px;
}

.content_containers .content_02 .content_body div:nth-child(1) {
    max-width: 223px;
}

.content_containers .content_02 .content_body div:nth-child(2) {
    max-width: 268px;
}

.content_containers .content_02 .content_body div:nth-child(3) {
    max-width: 374px;
}

/*__Default*/


/* Animation */

.content_containers .content_02.active .title_text {
    opacity: 1;
}

.content_containers .content_02 .title_text {
    opacity: 0;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.content_containers .content_02.active .content_body div {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: .8s;
}

.content_containers .content_02 .content_body div {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers .content_02 .content_body {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

    .content_containers .content_02 {
        padding: 71.2px 0 191.2px 0;
        margin-bottom: 198.4px;
    }

    .content_containers .content_02 .title_text {
        margin-bottom: 152px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .content_02 .content_body {
        flex-direction: column;
        align-items: center;
    }

    .content_containers .content_02 .title_text {
        margin-bottom: 100px;
    }

} /*__end*/

@media screen and (max-width:425px) {
     /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/

    .content_containers .content_02 {
        padding: 52px 0 70px 0;
        margin-bottom: 98px;
    }

    .content_containers .content_02 .title_text {
        margin-bottom: 58px;
    }

    .content_containers .content_02 .content_body div:nth-child(1) {
        max-width: 91px;
        margin-bottom: 28px;
    }

    .content_containers .content_02 .content_body div:nth-child(2) {
        max-width: 111px;
        margin-bottom: 25px;
    }

    .content_containers .content_02 .content_body div:nth-child(3) {
        max-width: 153px;
    }

} /*__end*/


/*__반응형*/

</style>
