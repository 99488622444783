<template>
  <div class="containers_01">
    <div
      class="item_01"
      :class="show0 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
      <span>Professional</span>
    </div>
    <div
      class="item_02"
      :class="show1 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
      <span>
        신뢰와 전문성이 느껴지도록 선명한 컬러와 명확한 <br />형태로 컴포넌트를
        정의했으며, 여러 정보가 혼동되지 <br />않도록 위계를 고려한 배치를
        했습니다.
      </span>
    </div>
    <div
      class="item_03"
      :class="show2 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
      <span>
        최적의 UI를 제공하여 사용자가 필요한 정보를 명확하게 <br />인지할 수
        있게 했으며, 구조적인 UI로 불필요한 행동을 <br />감소시킬 수 있도록
        했습니다.
      </span>
    </div>
    <div
      class="item_04"
      :class="show3 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
      <span>Optimal</span>
    </div>
    <div
      class="item_05"
      :class="show4 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
      <span>Comfort</span>
    </div>
    <div
      class="item_06"
      :class="show5 ? 'active' : ''"
      style="position: relative"
    >
      <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
      <span
        >기존의 딱딱한 의료기기 화면들과는 다르게 <br />사용자가 사용하면서
        시각적인 안정감과 편안함을느낄 <br />수 있도록 디자인적으로
        고려했습니다.</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "ViOLDetail01.vue",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Default */

.containers_01 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.containers_01 [class^="item_0"] {
  width: 50%;
  height: 452px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.containers_01 .item_01 {
  background-image: linear-gradient(115deg, #ff6b6b -4%, #c2255c 135%);
}

.containers_01 .item_01 span,
.containers_01 .item_04 span,
.containers_01 .item_05 span {
  font-weight: bold;
  font-size: 72px;
  line-height: 0.46;
  letter-spacing: -0.69px;
}

.containers_01 .item_02 {
  background: #fafafa;
}

.containers_01 .item_02 span {
  font-family: "SECRobotoLight", serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 1.76;
  letter-spacing: -0.2px;
  background-image: linear-gradient(110deg, #ff6b6b 5%, #c2255c 102%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.containers_01 .item_03 {
  background: #fff;
}

.containers_01 .item_03 span {
  font-family: "SECRobotoLight", serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 1.76;
  letter-spacing: -0.2px;
  background-image: linear-gradient(106deg, #c0c7ca -10%, #b0b5b9 108%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.containers_01 .item_04 {
  background-image: linear-gradient(115deg, #d6dcde, #b0b5b9);
}

.containers_01 .item_05 {
  background-image: linear-gradient(116deg, #77dae6 -7%, #34b4d4 106%);
}

.containers_01 .item_06 {
  background: #fafafa;
}

.containers_01 .item_06 span {
  font-family: "SECRobotoLight", serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 1.76;
  letter-spacing: -0.2px;
  background-image: linear-gradient(106deg, #52d0e0 -1%, #12b8e2 104%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*__Default*/

/* Animation */

.item_01.active span,
.item_03.active span,
.item_05.active span {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 100ms;
  transition-property: opacity;
}

.item_02.active span,
.item_04.active span,
.item_06.active span {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.item_01 span,
.item_02 span,
.item_03 span,
.item_04 span,
.item_05 span,
.item_06 span {
  opacity: 0;
}

/*__Animation*/

/* 반응형 */

@media screen and (max-width: 1280px) {
  .containers_01 .item_01 span,
  .containers_01 .item_04 span,
  .containers_01 .item_05 span {
    font-size: 58px;
  }

  .containers_01 .item_02 span,
  .containers_01 .item_03 span,
  .containers_01 .item_06 span {
    font-size: 17px;
  }
} /*__end*/

@media screen and (max-width: 1024px) {
  .containers_01 .item_01 span,
  .containers_01 .item_04 span,
  .containers_01 .item_05 span {
    font-size: 46px;
  }
} /*__end*/

@media screen and (max-width: 768px) {
  .containers_01 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .containers_01 [class^="item_0"] {
    width: 100%;
    height: 362px;
  }

  /* ani */

  .item_02.active span,
  .item_04.active span,
  .item_06.active span {
    opacity: 1;
    transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
    transition-property: opacity;
  }
} /*__end*/

@media screen and (max-width: 550px) {
  .containers_01 .item_01 span,
  .containers_01 .item_04 span,
  .containers_01 .item_05 span {
    font-size: 32px;
  }

  .containers_01 .item_02 span,
  .containers_01 .item_03 span,
  .containers_01 .item_06 span {
    font-size: 14px;
  }

  .containers_01 [class^="item_0"] {
    height: 286px;
  }
} /*__end*/

@media screen and (max-width: 425px) {
  .containers_01 [class^="item_0"] {
    height: 186px;
  }
} /*__end*/

/*__반응형*/
</style>
