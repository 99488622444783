
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail3",
  el: "#detail3-icons",
  data() {
    const items = [];
    const pushItems = () => {
      let urlLength = 22;
      for (let i = 1; i < urlLength; i++) {
        items.push({ url: `detail03-ico-${Number(i).toString().padStart(2, '0')}` });
      }
    };
    pushItems();
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      pushItems,
      items,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
  },
});
