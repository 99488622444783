<template>
  <div ref="matched" class="fullpage">
    <ThemeObserver v-on:isShow="isShowObserver0" :theme=theme />
    <vue100vh :css="{height: '100rvh'}">
    <div class="about_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
      <div class="flex_containers">
        <div class="slogan">
          <!-- <span class="flex_space"></span> -->
          <h2 class="tit_wrap">
              <span class="txt_ani_w1"><span class="txt_ani_i1">WACKY means</span> <br class="active_820w"><span class="txt_ani_i2">a Unique way of Enjoying.</span></span>
              <br>
              <span class="txt_ani_w2"><span class="txt_ani_i3">We're Thinking about</span> <br class="active_820w"><span class="txt_ani_i4">Making our Lives more <span class="inactive_820w">New</span></span></span>
              <br>
              <span class="txt_ani_w3"><span class="txt_ani_i5"><span class="active_820w inline">New</span> and Enjoyable with</span> <br class="active_820w"><span class="txt_ani_i6">UX Design.</span></span>
         </h2>
        </div>
        <div class="info">
          <div class="title_txt">
            <h3>What We Do</h3>
          </div>
          <div class="body_txt">
            <ul>
              <li class="ux">
                <h3>UX Design</h3>
                <span>Deeply understand user needs and present the best results with a creative thinking that doesn't
                  lock
                  in the right UX principles and frameworks.</span>
              </li>
              <li class="motion">
                <h3>Motion Graphics</h3>
                <span>Organize the story to understand and communicate key messages and provide visual satisfaction
                  through the Motion.</span>
              </li>
              <li class="develop">
                <h3>Develop</h3>
                <span>Realize ideas based on innovative technologies to provide direct solutions.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="about_visual_containers">
      <video 
        class="about_pc_video inactive_820w" 
        autoplay="autoplay" 
        playsinline 
        loop="loop" 
        muted="muted" 
        preload="none" 
        crossorigin="anonymous" 
      >
         <source src="@/assets/img/main/main2_looping.webm" type="video/webm">
         <source src="@/assets/img/main/main2_looping.mp4" type="video/mp4">
      </video>
      <video 
        class="about_mob_video active_820w" 
        autoplay="autoplay" 
        playsinline 
        loop="loop" 
        muted="muted" 
        preload="none" 
        crossorigin="anonymous"
      >
         <source src="@/assets/img/main/main2_mobile.webm" type="video/webm">
         <source src="@/assets/img/main/main2_mobile.mp4" type="video/mp4"> 
      </video>
    </div>
    </vue100vh>  
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";
import vue100vh from '@/components/Vue100vh.vue';


export default defineComponent({
  name: "About",
  data() {
    return {
      show0: false,
      theme: Theme.white,
    }
  },
  components: {
    ThemeObserver,
    vue100vh,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
      this.theme = Theme.white;
      if (value) {
        setTimeout(() => {
          this.theme = Theme.dark;
        }, 1000);
      }
    },
  }
});
</script>

<style scoped lang="css">

/* Default */

div.observer.separate_fullpage {
  top: 5vh;  
  height: 90vh;
  min-height: inherit;
}

div.fullpage {
    height: 100%;
}

.about_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #000;
    z-index: 10;
    overflow: hidden;
}

.about_wrap .flex_containers {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(var(--responsive) * 1200);
    justify-content: center;
}

.about_wrap .slogan {
    width: calc(var(--responsive) * 894);
    margin-left: calc(var(--responsive) * 307);
    margin-bottom: calc(var(--responsive) * 210);
}

.about_wrap .slogan h2 {
    font-size: calc(var(--responsive) * 40);
    font-weight: 700;
    line-height: 1.28em;
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    overflow: hidden;
}

.about_wrap .info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.about_wrap .title_txt h3 {
    font-size: calc(var(--responsive) * 18);
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}


.about_wrap .title_txt {
    position: relative;
    width: calc(var(--responsive) * 277);
}

.about_wrap .title_txt::before {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #000;
    margin-bottom: 30px;

}

.about_wrap .body_txt {
    width: calc(var(--responsive) * 894);
}

.about_wrap .body_txt ul {
    display: flex;
    flex-direction: row;
}

.about_wrap .body_txt::before {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #000;
    margin-bottom: calc(var(--responsive) * 24);
}

.about_wrap .body_txt ul li {
    width: calc(var(--responsive) * 275);
}

.about_wrap .body_txt ul li:not(.ux) {
    margin-left: calc(var(--responsive) * 35);
}

.about_wrap .body_txt ul li h3 {
    font-size: calc(var(--responsive) * 24);
    font-weight: 700;
    line-height: 1.58em;
    margin-bottom: 0.417em;
    font-family: 'Roboto', sans-serif;
}

.about_wrap .body_txt ul li span {
    font-size: calc(var(--responsive) * 14);
    line-height: 1.29em;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.about_visual_containers {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.about_visual_containers video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*__Default*/

/* Animation */

.about_wrap.active {
    background: transparent;
    transition-delay: 100ms;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: background;
}

.about_wrap.active .title_txt::before,
.about_wrap.active .body_txt::before {
    border-top: 1px solid #000;
}

.about_wrap.active .info {
    animation: bodytxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1400ms both;
    opacity: 0;
}

@keyframes bodytxt_ani {
    0% {
        opacity: 0;
        transform: translate3d(0, 65%, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.about_wrap.active .tit_wrap .txt_ani_w1{
    display: inline-block;
    animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 900ms both;
}

.about_wrap.active .tit_wrap .txt_ani_w2{
    display: inline-block;
    animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1100ms both;
}

.about_wrap.active .tit_wrap .txt_ani_w3{
    display: inline-block;
    animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1300ms both;
}


@keyframes slogantxt_ani {
    0% {
        transform: translateX(-110%);
    }
    100% {
        transform: translateX(0);
    }
}

/* 중복방지 */    
    .about_wrap.active .tit_wrap .txt_ani_i1,
    .about_wrap.active .tit_wrap .txt_ani_i2,
    .about_wrap.active .tit_wrap .txt_ani_i3,
    .about_wrap.active .tit_wrap .txt_ani_i4,
    .about_wrap.active .tit_wrap .txt_ani_i5,
    .about_wrap.active .tit_wrap .txt_ani_i6 {
        display: inline-block;
        animation: initial;
    }

/*__Animation*/

/* 반응형 */

@media screen and (max-width:820px) {
    .about_wrap .flex_containers {
        width: calc(var(--responsive) * 312);
    }

    .about_wrap .slogan {
        margin-left: initial;
        width: 100%;
        margin-bottom: calc(var(--responsive) * 82);
    }

    .about_wrap .slogan h2 {
        display: inline-block;
        font-size: min(calc(var(--responsive) * 24), 24px);
        line-height: 1.23em;
    }

    .about_wrap .info {
        flex-direction: column;
    }

    .about_wrap .title_txt::before {
        display: none;
    }

    .about_wrap .body_txt {
        margin-left: inherit;
        width: 100%;
    }

    .about_wrap .title_txt {
        margin-bottom: calc(var(--responsive) * 20);
    }

    .about_wrap .title_txt h3 {
        font-size: main(calc(var(--responsive) * 14), 14px);
    }

    .about_wrap .body_txt ul {
        flex-direction: column;
    }

    .about_wrap .body_txt ul li:not(.ux) {
        margin-left: inherit;
    }

    .about_wrap .body_txt ul li:not(.develop) {
        margin-bottom: calc(var(--responsive) * 32);
    }

    .about_wrap .body_txt ul li h3 {
        font-size: min(calc(var(--responsive) * 18), 18px);
        line-height: normal;
        margin-bottom: 0.556em;
    }

    .about_wrap .body_txt ul li span {
        font-size: min(calc(var(--responsive) * 12), 12px);
        line-height: 1.43em;
    }

    /* Animation */

    .about_wrap.active .tit_wrap .txt_ani_i1 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 900ms both;
    }

    .about_wrap.active .tit_wrap .txt_ani_i2 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1100ms both;
    }
    
    .about_wrap.active .tit_wrap .txt_ani_i3 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1300ms both;
    }
    
    .about_wrap.active .tit_wrap .txt_ani_i4 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1500ms both;
    }
    
    .about_wrap.active .tit_wrap .txt_ani_i5 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1700ms both;
    }
    
    .about_wrap.active .tit_wrap .txt_ani_i6 {
        display: inline-block;
        animation: slogantxt_ani 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) 1900ms both;
    }
    
    /* 중복방지 */

    .about_wrap.active .tit_wrap .txt_ani_w1,
    .about_wrap.active .tit_wrap .txt_ani_w2,
    .about_wrap.active .tit_wrap .txt_ani_w3 {
        animation: initial;
    }

    /*__Animation */

}

@media screen and (max-width:425px) {

    .about_wrap .slogan {
        margin-bottom: calc(var(--responsive) * 42);
    }

    .about_wrap .slogan h2 {
        font-size: calc(var(--responsive) * 20);
        line-height: 1.2em;
    }

    .about_wrap .body_txt ul li:not(.develop) {
        margin-bottom: calc(var(--responsive) * 20);
    }

    .about_wrap .body_txt ul li h3 {
        font-size: calc(var(--responsive) * 16);
        line-height: normal;
        margin-bottom: 0.3em;
    }

    .about_wrap .body_txt ul li span {
        font-size: calc(var(--responsive) * 11);
        line-height: 1.2em;
    }
    
}


/*__반응형*/

</style>
