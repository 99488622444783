<template>
  <header v-bind:class="{active: isActive}">
    <nav>
      <ul>
        <li :class=theme><a v-on:click="INmyFilter" href="javascript:void(0);"><router-link to="/#home">Home</router-link></a></li>
        <li :class=theme><a v-on:click="INmyFilter" href="javascript:void(0);"><router-link to="/#about">About</router-link></a></li>
        <li :class=theme><a v-on:click="INmyFilter" href="javascript:void(0);"><router-link to="/#works">Work</router-link></a></li>
        <li :class=theme><a v-on:click="INmyFilter" href="javascript:void(0);"><router-link to="/#contact">Contact</router-link></a></li>
      </ul>
      <div class="m_menu" :class=theme v-on:click="myFilter"></div>
    </nav>
  </header>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import { useStore } from "vuex";
import {Theme} from "@/enums/Theme";

export default defineComponent({
  name: "TopNavi",
    data() {
    return {
      isActive: false,
    }
  },
  computed: {
  },
  setup() {

    const store = useStore();

    let theme = ref(Theme.dark);
    watch(() => store.getters.currentTheme, (current) => {
      theme.value = current;
    })

    return { theme }
  },
  components: {
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    INmyFilter: function() {
      this.isActive = false;
    }

  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/topnav.css";
</style>

"
