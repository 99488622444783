<template>
  <div class="productmodeling2_containers">
    <div class="productmodeling2_sub_text numbering">
      <div class="sub_text_left">PRODUCT MODELING</div>
      <div class="sub_text_right">08</div>
    </div>
    <div class="productmodeling2">
      <!-- wrap -->
      <div class="tit_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="productmodeling2_img1"><img src="@/assets/img/2021_ccb/productmodeling2_01.jpg" alt="image"
            class="inactive_1024w"></div>
        <div class="productmodeling2_m_l"><img src="@/assets/img/2021_ccb/productmodeling2_02.jpg" alt="image"></div>
        <div class="text_wrap">
          <div class="tit_text">Make My<br>Cosmetics</div>
          <div class="active_1024w"><img src="@/assets/img/2021_ccb/productmodeling2_01.jpg" alt="image"></div>
          <div class="body_text">
            사이트를 통해 브랜드의 가치를 인지하고, 피부진단<br class="inactive_1280w">
            테스트를 통해 나를 알아가고, 나 자신을 위한 화장품을<br class="inactive_1280w">
            만들어 사용해보는 경험을 해보실 수 있습니다.</div>
        </div>
      </div>
      <div class="m_body_text inactive_pc_all" :class="show1 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="body_text">
          사이트를 통해 브랜드의 가치를 인지하고, 피부진단 <br class="active_425w">테스트를 통해 나를 알아가고, 나 자신을 위한 화장품을 만들어 사용해보는 경험을 해보실 수 있습니다.
        </div>
      </div>

      <div class="productmodeling2_img2" :class="show2 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <img src="@/assets/img/2021_ccb/productmodeling2_03.jpg" alt="image">
      </div>
      <div class="productmodeling2_img3" :class="show3 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <img src="@/assets/img/2021_ccb/productmodeling2_04.png" alt="image">
      </div>
    </div>
    <div class="bg_wrap">
      <div class="modeling2_img4_wrap" :class="show4 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
        <img src="@/assets/img/2021_ccb/productmodeling2_05.png" alt="image">
      </div>
      <div class="info_text" :class="show5 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
        <ul>
          <li><span>BX Design.</span><span>Hyeyoung Kim</span></li>
          <li><span>3D.</span><span>Hyejin Min</span></li>
          <li><span>UX Design.</span><span>Hyeyoung Kim, Yuna Kim, Duchan Jo</span></li>
        </ul>
      </div>
      <div class="productmodeling2_bg"></div>
    </div><!-- //bg_wrap -->
  </div>
</template>

<script lang="ts">
  import {
    defineComponent
  } from "vue";
  import ShowObserver from "@/components/ShowObserver.vue";

  export default defineComponent({
    name: "CcbDetail8",
    data() {
      return {
        show0: false,
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
      }
    },
    components: {
      ShowObserver
    },
    methods: {
      isShowObserver0(value: boolean) {
        this.show0 = value;
      },
      isShowObserver1(value: boolean) {
        this.show1 = value;
      },
      isShowObserver2(value: boolean) {
        this.show2 = value;
      },
      isShowObserver3(value: boolean) {
        this.show3 = value;
      },
      isShowObserver4(value: boolean) {
        this.show4 = value;
      },
      isShowObserver5(value: boolean) {
        this.show5 = value;
      },
    }
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    color: #393531;
}
/*__ALl*/

.productmodeling2_containers {
    content-visibility: auto;
}

.productmodeling2 {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 96px;
}

.productmodeling2 .tit_wrap {
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 283px;
}

.productmodeling2 .tit_wrap .productmodeling2_img1 {
    width: 26.5%;
    margin-left: 0;
}

.productmodeling2 .tit_wrap .productmodeling2_m_l {
    width: 37.3333%;
    margin-left: 38px;
}

.productmodeling2 .tit_wrap .text_wrap {
    width: 29.58333%;
    margin-left: 38px;
}

.productmodeling2 .tit_wrap .text_wrap .tit_text {
    height: 140px;
    line-height: 1.35;
    margin-bottom: 24px;
}

.productmodeling2 .tit_wrap .text_wrap .body_text {
    height: 72px;
    line-height: normal;
    letter-spacing: -1px;
    margin-bottom: 31px;
}

.productmodeling2 .tit_wrap .productmodeling2_img1 {
    margin-bottom: -54px;
}

.productmodeling2 .productmodeling2_img2 {
    width: 100%;
}

.productmodeling2_img3 {
    max-width: 1200px;
    margin: 0 auto;
}

.productmodeling2_img2,
.productmodeling2_img3 {
    margin-bottom: 240px;
}

.productmodeling2_containers .bg_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 211px;
    width: 100%;
    padding-bottom: 100px;
}

.productmodeling2_containers .bg_wrap .info_text {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productmodeling2_containers .bg_wrap .modeling2_img4_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productmodeling2_containers .bg_wrap img {
    max-width: 1200px;
    margin-bottom: 179px;
}

.productmodeling2_containers .bg_wrap .info_text ul {
    width: 100%;
    position: relative;
}

.productmodeling2_containers .bg_wrap .info_text ul li {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 15px;
}

.productmodeling2_containers .bg_wrap .info_text ul li:first-child {
    margin-top: 32px;
}

.productmodeling2_containers .bg_wrap .info_text ul li:last-child {
    margin-bottom: inherit;
}

.productmodeling2_containers .bg_wrap .info_text ul li span {
    height: 14px;
    line-height: normal;
    font-size: 12px;
    color: #322c11;
    font-family: 'Noto Sans KR', sans-serif;
}

.productmodeling2_containers .bg_wrap .info_text ul li span:first-child {
    width: 78px;
    height: 14px;

}

.productmodeling2_containers .bg_wrap .info_text ul li span:last-child {
    margin-left: 24px;
    width: 300px;
    font-weight: 300;
}

.productmodeling2_containers .bg_wrap .info_text ul li:first-child::before {
    content: "";
    position: absolute;
    max-width: 1200px;
    width: 100%;
    height: 0.5px;
    top: 0;
    background-color: #95846d;
}

.productmodeling2_containers .bg_wrap .productmodeling2_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
    top: 0;
    left: 0;
    background-image: url(../../../assets/img/2021_ccb/productmodeling2_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

/*__Default*/


/* Animation */

.productmodeling2_containers .tit_wrap.active .productmodeling2_img1 {
    opacity: 1;
    transition-delay: 1.2s;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .tit_wrap.active .productmodeling2_m_l {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .tit_wrap.active .text_wrap {
    opacity: 1;
    transition-delay: .8s;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .productmodeling2_img2.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .productmodeling2_img3.active {
    opacity: 1;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .bg_wrap .modeling2_img4_wrap.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .bg_wrap .info_text.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling2_containers .tit_wrap .productmodeling2_img1,
.productmodeling2_containers .tit_wrap .productmodeling2_m_l,
.productmodeling2_containers .tit_wrap .text_wrap,
.productmodeling2_containers .productmodeling2_img2,
.productmodeling2_containers .productmodeling2_img3,
.productmodeling2_containers .bg_wrap .modeling2_img4_wrap,
.productmodeling2_containers .bg_wrap .info_text {
    opacity: 0;
}

.productmodeling2_containers .bg_wrap .info_text.active ul li:first-child::before {
    animation-name: bgwrap_line;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

@keyframes bgwrap_line {
    0% {
        width: 0%;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 1;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering,
    .productmodeling2 .productmodeling2_img3,
    .productmodeling2 .tit_wrap,
    .productmodeling2 .m_body_text,
    .productmodeling2_containers .bg_wrap img,
    .productmodeling2_containers .bg_wrap ul {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/

    .productmodeling2_containers .bg_wrap img {
        margin-bottom: 10.9895%;
    }

    .productmodeling2_containers .bg_wrap {
        padding-top: 10.9895%;
        padding-bottom: 5.208333;
    }
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .productmodeling2 .tit_wrap .productmodeling2_img1 {
        display: none;
    }

    .productmodeling2 .tit_wrap {
        width: 100%;
        margin-left: 0;
        margin-bottom: 6.41025%;
    }

    .productmodeling2 .tit_wrap .productmodeling2_m_l {
        width: 56.08974%;
        margin-left: 0;
    }

    .productmodeling2 .tit_wrap .text_wrap {
        width: 40.3846%;
        padding-left: 0;
        margin-left: 3.52564%;
    }

    .productmodeling2 .tit_wrap > div {
        padding-left: 0;
    }

    .productmodeling2 .tit_wrap .text_wrap .body_text {
        display: none;
    }

    .productmodeling2 .m_body_text {
        display: block;
        width: 100%;
        margin-bottom: 19.87179%;
    }

    .productmodeling2_img2,
    .productmodeling2_img3 {
        margin-bottom: 19.87179%;
    }

    .productmodeling2_containers .bg_wrap .info_text ul li:first-child {
        margin-top: 4.48717%;
    }

    .productmodeling2_containers .m_body_text.active {
        opacity: 1;
        transition-duration: .8s;
        transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    }

    .productmodeling2_containers .m_body_text {
        opacity: 0;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .productmodeling2 .tit_wrap .text_wrap .tit_text {
        height: auto;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering,
    .productmodeling2 .productmodeling2_img3,
    .productmodeling2 .tit_wrap,
    .productmodeling2 .m_body_text,
    .productmodeling2_containers .bg_wrap img,
    .productmodeling2_containers .bg_wrap ul {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

    .productmodeling2 .tit_wrap .text_wrap .tit_text {
        height: 70px;
    }

    .productmodeling2_containers .bg_wrap .info_text ul li span {
        font-size: 9px;
        height: auto;
    }

    .productmodeling2_containers .bg_wrap .info_text ul li {
        margin-bottom: 10px;
    }

} /*__end*/


/*__반응형*/

</style>
