<template>
  <div class="contact_wrap">
    <ThemeObserver v-on:isShow="isShowObserver0" :theme="theme" />
    <div
      class="contact_containers"
      :class="show0 ? 'active' : ''"
      style="position: relative"
    >
      <div class="information">
        <div class="office">
          <h3>Office</h3>
          <span
            >805, 47, Digital-ro 9-gil, Geumcheon-gu, Seoul, Republic of
            Korea</span
          >
          <a class="map_btn" href="http://kko.to/GC6tFiaH1z" target="_blank"
            >Map</a
          >
        </div>
        <div class="phone">
          <h3>Phone</h3>
          <span>+82 70 8801 2353</span>
        </div>
        <div class="email">
          <h3>Email</h3>
          <span>master@wacky.kr</span>
        </div>
      </div>
      <div class="copylight">
        <span>&copy; Wacky Company Inc. All Rights Reserved.</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      show0: false,
      theme: Theme.white,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
  setup() {
    function changeBodyColor() {
      document.querySelector("body").style.background = "#2c2c2c";
    }

    changeBodyColor();
  },
});
</script>

<style scoped lang="css">
/* Default */

div.observer.separate_second {
  top: 0;
  height: 100%;
  min-height: inherit;
}

.contact_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: calc(var(--responsive) * 185);
  padding-bottom: calc(var(--responsive) * 187);
  background: #2c2c2c;
}

.contact_containers {
  width: calc(var(--responsive) * 1200);
  margin: 0 auto;
}

.contact_containers .information {
  display: flex;
  margin-bottom: calc(var(--responsive) * 304);
}

.information .office {
  width: calc(var(--responsive) * 478);
}

.information .phone {
  width: calc(var(--responsive) * 191);
  margin-left: calc(var(--responsive) * 138);
}

.information .email {
  width: calc(var(--responsive) * 189);
  margin-left: calc(var(--responsive) * 117);
}

.information h3 {
  font-size: calc(var(--responsive) * 40);
  font-weight: 700;
  color: #fff;
  line-height: 0.78em;
  margin-bottom: 2.025em;
  font-family: "Roboto", sans-serif;
}

.information div a {
  display: block;
  font-size: calc(var(--responsive) * 24);
  font-weight: 300;
  color: #fff;
  line-height: 1.29em;
  transition: 0.3s ease-in-out;
  transition-property: color;
  font-family: "Roboto", sans-serif;
}

.information div span {
  display: block;
  font-size: calc(var(--responsive) * 24);
  font-weight: 300;
  color: #fff;
  line-height: 1.29em;
  transition: 0.3s ease-in-out;
  transition-property: color;
  font-family: "Roboto", sans-serif;
}

.information .office .map_btn {
  position: relative;
  width: calc(var(--responsive) * 48);
  margin-top: calc(var(--responsive) * 8);
  color: #fff400;
  text-decoration-line: underline;
}

.contact_containers .copylight {
  width: 100%;
  position: relative;
  padding-top: calc(var(--responsive) * 35);
}

.contact_containers .copylight span {
  font-size: calc(var(--responsive) * 20);
  line-height: 1.55em;
  font-weight: 300;
  letter-spacing: normal;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.contact_containers .copylight::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}

/*__Default*/

/* 반응형 */

@media screen and (max-width: 820px) {
  .contact_wrap {
    padding-top: calc(var(--responsive) * 81);
    padding-bottom: calc(var(--responsive) * 83);
  }

  .contact_containers {
    width: calc(var(--responsive) * 312);
  }

  .contact_containers .information {
    flex-direction: column;
    margin-bottom: calc(var(--responsive) * 80);
  }

  .information .office {
    max-width: inherit;
    width: 100%;
    margin-bottom: calc(var(--responsive) * 39);
  }

  .information .phone {
    max-width: inherit;
    width: 100%;
    margin-left: inherit;
    margin-bottom: calc(var(--responsive) * 39);
  }

  .information .email {
    max-width: inherit;
    width: 100%;
    margin-left: inherit;
  }

  .information h3 {
    font-size: min(calc(var(--responsive) * 29), 29);
    margin-bottom: calc(var(--responsive) * 13);
  }

  .information div a,
  .information div span {
    font-size: min(calc(var(--responsive) * 18), 18px);
  }

  .contact_containers .copylight {
    padding-top: calc(var(--responsive) * 20);
  }

  .contact_containers .copylight span {
    font-size: main(calc(var(--responsive) * 14), 14px);
  }
}

/*__반응형*/
</style>
