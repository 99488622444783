
import {defineComponent, onMounted, ref, watch} from "vue";
import {Theme} from "@/enums/Theme";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {useRoute, useRouter} from "vue-router";
import {WORKPROJECTS} from "@/constants/work-projects";
import WorkItem from "@/components/main/WorkItem.vue";

export default defineComponent({
  name: "Works",
  components: {
    ThemeObserver,
    WorkItem
  },
  setup() {
    const list = WORKPROJECTS;

    let moreCount = ref(0);
    let viewCount = ref(9);
    const route = useRoute();
    const router = useRouter();
    watch(() => route.query, query => {
      processViewCount();
    })

    const processViewCount = () => {
      moreCount.value = (route.query.more) ? Number(route.query.more) : 0;
      viewCount.value = 9 + (moreCount.value * 6);
      if (viewCount.value > list.length) {
        viewCount.value = list.length;
      }
    }

    onMounted(() => {
      processViewCount();
    });

    const moreProject = () => {
      if (viewCount.value < list.length) {
        moreCount.value += 1;
        router.replace({
          hash: route.hash,
          query: {
            more: moreCount.value.toString()
          }
        });
      }
    }

    return {
      list,
      viewCount,
      moreProject,
    }
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
  computed: {
    displayProjects() {
      return this.list.slice(0, this.viewCount);
    },
    isMoreProject() {
      return this.viewCount < this.list.length;
    },
  },
  data() {
    return {
      show0: false,
      theme: Theme.white
    }
  },
});
