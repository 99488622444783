<template>
 <div class="content_containers">
     <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
     <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
         <div class="title_text">삼성 패밀리허브 Family Scheduler</div>
         <div class="body_text">가족들의 모바일을 패밀리허브에 연동하여 서로의 일정을 공유하고 한눈에 확인 할 수 있는 Family Scheduler입니다.<br>
             각 모바일과 패밀리허브에서 스케줄을 확인하는 프로토타입을 진행했습니다.</div>
     </div>
     <div class="image_01" :class="show1 ? 'active' : ''" style="position: relative;">
     <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
         <img src="@/assets/img/2018_family_scheduler/scheduler_01.jpg" alt="image" class="inactive_768w">
         <img src="@/assets/img/2018_family_scheduler/m_scheduler_01.jpg" alt="mobile image" class="active_768w">
     </div>
     <div class="caption_01" :class="show2 ? 'active' : ''" style="position: relative;">
     <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
         <p>Family scheduler에 기능을 가장 자연스럽게 보여줄 수 있는 UI 시나리오를 구성하여 프로토타입을 제작했습니다.<br class="inactive_425w">
             모바일과 패밀리허브가 연동되며, 시간에 따라 가족들에게 어떤 일정이 있는지 한눈에 확인 할 수 있도록 보여주는 기능을 강조했습니다.</p>
     </div>
     <div class="image_02" :class="show3 ? 'active' : ''" style="position: relative;">
     <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
         <img src="@/assets/img/2018_family_scheduler/scheduler_02.jpg" alt="image" class="inactive_768w">
         <img src="@/assets/img/2018_family_scheduler/m_scheduler_02.jpg" alt="mobile image" class="active_768w">
     </div>
     <div class="image_03" :class="show4 ? 'active' : ''" style="position: relative;">
     <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
         <img src="@/assets/img/2018_family_scheduler/scheduler_03.jpg" alt="image" class="inactive_768w">
         <img src="@/assets/img/2018_family_scheduler/m_scheduler_03.png" alt="mobile image" class="active_768w">
     </div>
 </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "FamilySchedulerDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    width: 100%;
    padding-top: 91px;
    content-visibility: auto;
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 188px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_containers .image_01 {
    width: 100%;
    margin-bottom: 36px;
}


.content_containers .caption_01 p {
    display: block;
    max-width: 1200px;
    height: 48px;
    font-size: 16px;
    line-height: 1.56;
    color: #000;
    margin: 0 auto;
    margin-bottom: 188px;
}

.content_containers .image_02 {
    width: 100%;
    margin-bottom: 108px;
}

.content_containers .image_03::before {
    content: "";
    display: block;
    width: 1px;
    height: 20.52083vw;
    background-color: #000;
    margin: 0 auto;
    margin-bottom: 108px;
}

.content_containers .image_03 {
    width: 100%;
    overflow: hidden;
}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_01.active img,
.caption_01.active p,
.image_02.active img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_03.active img {
    opacity: 1;
    transition-delay: 1s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_03.active::before {
    animation-name: img04_line;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes img04_line {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.image_01 img,
.caption_01 p,
.image_02 img,
.image_03 img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text,
    .content_containers > .caption_01 p {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
      .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

    .content_containers .caption_01 p {
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:768px) {
      .content_containers .main_text .title_text {
        height: 118px;
    }

    .content_containers .image_03 {
        margin-bottom: 185px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text,
    .content_containers .caption_01 p {
        max-width: 86.66666vw;
    }

    .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 98px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
    }

    .content_wrap .image_01 {
        margin-bottom: 24px;
    }

    .content_containers .caption_01 p {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 98px;
    }

    .content_containers .image_02 {
        width: 100%;
        margin-bottom: 90px;
    }

    .content_containers .image_03::before {
        content: "";
        display: block;
        width: 1px;
        height: 50vw;
        background-color: #000;
        margin: 0 auto;
        margin-bottom: 90px;
    }
    
} /*__end*/


/*__반응형*/

</style>