<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="seasonal"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title">
          <span class="left-line"></span>
          <h3 class="inactive_820w">Christmas Seasonal</h3>
          <h3 class="active_820w">Christmas</h3>
          <span class="right-line"></span>
        </div>
        <span>
          산타, 루돌프, 트리 등 크리스마스를 연상하는
          <br class="active_820w" />
          메타포와 눈사람, 촛불, 담요 등 겨울을 연상하는
          <br class="active_820w" />
          메타포를 활용했으며,
          <br class="inactive_820w" />
          크리스마스의 포근하고
          <br class="active_820w" />
          밝은 무드, Holiday의 반짝거리는 분위기를 표현하기
          <br class="active_820w" />
          위해 높은 채도와 밝은 색을 중심으로 구성했습니다.
        </span>
      </div>
      <div
        class="speech_bubble"
        :class="show1 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="bubble-inner">
          <img
            class="bubble-emoji-01"
            src="@/assets/img/2021_emoji/speech_bubble_4.webp"
            alt="emoji image"
          />
          <span class="speech_bubble-txt">
            Merry <span class="inactive_820w">Christmas</span>
          </span>
          <img
            class="bubble-emoji-02"
            src="@/assets/img/2021_emoji/speech_bubble_5.webp"
            alt="emoji image"
          />
          <img
            class="bubble-emoji-03"
            src="@/assets/img/2021_emoji/speech_bubble_6.webp"
            alt="emoji image"
          />
        </div>
        <span class="mobile-bubble-txt active_820w">Christmas</span>
      </div>
      <div class="contnet02-wrap">
        <span class="leaf"></span>
        <div
          class="contnet02-inner"
          :class="show2 ? 'active' : ''"
          style="position: relative"
        >
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
          <div class="items-01">
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-1.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-2.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-3.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-4.webp"
              alt="emoji image"
            />
          </div>
          <div class="items-02">
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-5.webp"
              alt="emoji image"
            />
          </div>
          <div class="items-03">
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-6.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-7.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-8.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/christmas-emoji-9.webp"
              alt="emoji image"
            />
          </div>
        </div>
      </div>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail4",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  width: 100vw;
  position: relative;
  background: #fff;
  font-family: "Poppins", sans-serif;
  margin-top: calc(var(--responsive) * 180);
}

.container-inner {
  width: 68.75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* seasonal title */

.seasonal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--responsive) * 140);
}

.seasonal .title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: calc(var(--responsive) * 28);
  margin-bottom: 1.75em;
}

.seasonal .title h3 {
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0.75em;
}

.seasonal .title .left-line,
.seasonal .title .right-line {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #979797;
}

.seasonal span {
  font-family: "NanumSquare", serif;
  font-size: calc(var(--responsive) * 18);
  font-weight: 400;
  line-height: 1.61em;
  text-align: center;
}

/* speech_bubble */

.speech_bubble {
  position: relative;
  width: calc(var(--responsive) * 806);
  height: calc(var(--responsive) * 120);
  margin-left: auto;
  background-image: url("../../../assets/img/2021_emoji/speech_bubble_bg_2.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: calc(var(--responsive) * 79);
}

.bubble-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speech_bubble img {
  width: 9.42928%;
  object-fit: cover;
}

.speech_bubble-txt {
  color: #fff;
  font-size: calc(var(--responsive) * 52);
  font-weight: 500;
  margin: 0 0.346em 0 0.269em;
}

.bubble-emoji-03 {
  margin-left: 1.73697%;
}

/* content02 */

.contnet02-wrap {
  position: relative;
  display: flex;
  width: 100vw;
  height: 52.65625vw;
  background-image: url("../../../assets/img/2021_emoji/christmas_bg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  align-items: center;
  justify-content: center;
}

.contnet02-inner {
  width: 63.64583vw;
  height: 39.84375vw;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.leaf {
  position: absolute;
  left: 13.8541vw;
  top: -2.23958vw;
  width: calc(var(--responsive) * 116);
  height: calc(var(--responsive) * 174);
  background-image: url("../../../assets/img/2021_emoji/leaf.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.items-01 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 45.00818%;
  height: 63.3986%;
  align-content: space-between;
  justify-content: space-between;
}
.items-01 img {
  width: 34.909%;
  object-fit: cover;
}

.items-02 {
  width: 30.44189%;
  height: 50.84967%;
  margin: 5.5646% auto auto 8.01963%;
}

.items-02 img {
  width: 100%;
  object-fit: cover;
}

.items-03 {
  display: flex;
  width: 100%;
  height: 25.098%;
  margin-top: auto;
  justify-content: space-between;
}

.items-03 img {
  width: 15.71194%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.seasonal,
.speech_bubble,
.bubble-emoji-01,
.bubble-emoji-02,
.bubble-emoji-03,
.speech_bubble-txt,
.contnet02-inner .items-01,
.contnet02-inner .items-02,
.contnet02-inner .items-03 {
  opacity: 0;
}

.seasonal.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.speech_bubble.active {
  animation: bubble-ani-bg 500ms ease-in-out 1 forwards;
}

.speech_bubble.active .bubble-emoji-01 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 350ms;
}

.speech_bubble.active .speech_bubble-txt {
  animation: bubble-ani-02 500ms ease-in-out 1 forwards;
  animation-delay: 700ms;
}

.speech_bubble.active .bubble-emoji-02 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 1050ms;
}

.speech_bubble.active .bubble-emoji-03 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 1400ms;
}

.contnet02-inner.active .items-02 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.contnet02-inner.active .items-01 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 300ms;
}

.contnet02-inner.active .items-03 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 600ms;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bubble-ani-bg {
  0% {
    transform: translate3d(0%, 80%, 0) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translate3d(0%, 0%, 0) scale(1);
    opacity: 1;
  }
}

@keyframes bubble-ani-01 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}

@keyframes bubble-ani-02 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
  }
  100% {
    transform: translate3d(0%, 0, 0);
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container {
    margin-top: 16.3888vw;
  }

  .container-inner {
    width: 88.88888%;
  }

  .seasonal {
    margin-bottom: 20.5555vw;
  }

  .seasonal .title {
    font-size: 5.55555vw;
    margin-bottom: 1.5em;
  }

  .seasonal .title h3 {
    margin: 0 0.6em;
  }

  .seasonal span {
    font-size: 3.88888vw;
    line-height: 1.57em;
  }

  .speech_bubble {
    position: relative;
    width: 58.3333vw;
    height: 23.8888vw;
    background-image: url("../../../assets/img/2021_emoji/speech_bubble_bg_2_m.svg");
    margin-bottom: 8.05555vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bubble-inner {
    width: 100%;
    height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .speech_bubble img {
    width: 9.16666vw;
  }

  .speech_bubble-txt {
    font-size: 6.6666vw;
    line-height: 1.375em;
    margin: 0 0.167em;
  }

  .mobile-bubble-txt {
    width: 100%;
    color: #fff;
    font-size: 6.6666vw;
    line-height: 1.375em;
    font-weight: 500;
    padding-left: 0.708em;
    box-sizing: border-box;
  }

  .contnet02-wrap {
    position: relative;
    display: flex;
    width: 100vw;
    height: 219.7222vw;
    background-image: url("../../../assets/img/2021_emoji/christmas_bg-m.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    align-items: center;
    justify-content: center;
  }

  .contnet02-inner {
    width: 67.77777vw;
    height: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
  }

  .leaf {
    position: absolute;
    left: 8.61111vw;
    top: -5.5555vw;
    width: 14.7222vw;
    height: 21.1111vw;
  }

  .items-01 {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-bottom: 6.38888vw;
  }

  .items-01 img {
    width: 23.05555vw;
    object-fit: cover;
  }

  .items-01 img:nth-child(1),
  .items-01 img:nth-child(2) {
    margin-bottom: 10.5555vw;
  }

  .items-02 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: initial;
    margin-bottom: 6.38888vw;
  }

  .items-02 img {
    width: 48.0555vw;
  }

  .items-03 {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: initial;
  }

  .items-03 img {
    width: 23.05555vw;
    object-fit: cover;
  }

  .items-03 img:nth-child(1),
  .items-03 img:nth-child(2) {
    margin-bottom: 10.5555vw;
  }

  /* Animation */
  .mobile-bubble-txt {
    opacity: 0;
  }

  .speech_bubble.active .mobile-bubble-txt {
    animation: bubble-ani-02 500ms ease-in-out 1 forwards;
    animation-delay: 1750ms;
  }
  /*__Animation */
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
