<template>
    <div class="thumbnail_containers">    
        <div class="thumbnail_content">
            <div class="thumbnail_text">
                <div class="thumbnail_tit">
                   <div class="thum_tit_01"><h2 class="ani_01">UX Design</h2><span class="ani_02"></span><h2 class="ani_03">3D Motion</h2></div>
                   <div class="thum_tit_02"><h2 class="medtit">Medical</h2><h2 class="devtit">Device</h2></div>
                </div>
                <div class="thumbnail_info" :class="show0 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
                    <h3>SONOTITE : HIFU Technology</h3>
                    <span class="info_txt"><span class="txt_b">SONOTITE</span>는 <span class="txt_b">HIFU(High-Intensity Focused Ultrasound)</span> 기술을 사용한 피부 의료기기입니다.<br class="inactive_550w">
                    고강도 집속 초음파 에너지를 사용해 피부 표면을 보호하고 피부 속에 열점 <span class="txt_b">(Thermal Dot)</span>을<br class="inactive_550w">
                    만들어내어 쳐진 피부를 리프팅하고 커진 모공을 타이트닝하는 목적으로 사용됩니다.</span>
                </div>
            </div>
            <div class="thum_img">
               <img src="@/assets/img/2020_viol/viol_01.png" alt="image" class="model">
               <img src="@/assets/img/2020_viol/viol_02.png" alt="image" class="imgshadow">
            </div>
        </div>   
    </div> 
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "ViOLDetail00.vue",
        data() {
          return {
              show0: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
        }
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.thumbnail_containers {
    position: relative;
    top: 0;
    width: 100vw;
    height: auto;
    /* padding-bottom: 68.6458%; */
    margin-bottom: 124px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    letter-spacing: normal; 
}

.thumbnail_content {

}

.thumbnail_content .thum_img {
  position: absolute;
  top: 148px;
  left: 50%;
  transform: translatex(-50%);
  z-index: 10;
}

.thumbnail_content .thum_img .model{
  max-width: 495px;
}

.thumbnail_content .thum_img .imgshadow{
  position: absolute;
  left: 0;
  z-index: -1;
}

.thumbnail_text {
  margin-top: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.thumbnail_tit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail_tit .thum_tit_01 {
  font-size: 84px;
  font-weight: 500;
  color: #e5e5e5;
  display: flex;
  align-items: center;
  margin-bottom: 54px;
}

.thumbnail_tit .thum_tit_01 span{
  width: 384px;
  height: 2px;
  background: #e5e5e5;
  margin: 0 20px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 170px;
  font-weight: 500;
  background-image: linear-gradient(109deg, #ff6b6b 0%, #c2255c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 333px;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 100px;
}

.thumbnail_info {
  margin-top: 433px;
  max-width: 1200px;
}

.thumbnail_info h3 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.29px;
  margin-bottom: 25px;
}

.thumbnail_info .info_txt {
  font-size: 20px;
  line-height: 1.65;
  letter-spacing: -0.19px;
  font-family: 'SECRobotoLight', serif;
}

.thumbnail_info .info_txt .txt_b {
  font-weight: 700;
}

/*__Default*/


/* Animation */

.thumbnail_tit .thum_tit_01 .ani_01 {
  animation: thum_ani01 1000ms ease-in-out 100ms both;
}

.thumbnail_tit .thum_tit_01 .ani_02 {
  animation: thum_ani01 1000ms ease-in-out 200ms both;
}

.thumbnail_tit .thum_tit_01 .ani_03 {
  animation: thum_ani01 1000ms ease-in-out 300ms both;
}

@keyframes thum_ani01 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.thumbnail_tit .thum_tit_02 {
  animation: thum_ani02 1800ms cubic-bezier(0, 0, 0.2, 1) 300ms both;
}

@keyframes thum_ani02 {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.thumbnail_info.active {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53);
  transition-property: opacity;
}

.thumbnail_info {
  opacity: 0;
}

/*__Animation*/


/* 반응형 */
@media screen and (max-width:1640px) {
.thumbnail_content .thum_img .model{
  max-width: 396px;
}

.thumbnail_text {
  margin-top: 228px;
}

.thumbnail_tit .thum_tit_01 {
  font-size: 67px;
  margin-bottom: 43px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 136px;
}

.thumbnail_info {
  margin-top: 346px;
}

.thumbnail_info h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.thumbnail_info .info_txt {
  font-size: 16px;
}

.thumbnail_tit .thum_tit_01 span{
  width: 307px;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 266px;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 80px;
}
  
} /*__end*/

@media screen and (max-width:1280px) {

.thumbnail_content .thum_img .model{
  max-width: 317px;
}


.thumbnail_tit .thum_tit_01 {
  font-size: 54px;
  margin-bottom: 34px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 109px;
}

.thumbnail_info {
  margin-top: 277px;
}

/* .thumbnail_info h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.thumbnail_info .info_txt {
  font-size: 16px;
} */

.thumbnail_tit .thum_tit_01 span{
  width: 246px;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 213px;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 64px;
}

} /*__end*/

@media screen and (max-width:1024px) {

.thumbnail_content .thum_img .model{
  max-width: 254px;
}

.thumbnail_text {
  margin-top: 182px;
}

.thumbnail_tit .thum_tit_01 {
  font-size: 43px;
  margin-bottom: 27px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 87px;
}

/* .thumbnail_info {
  margin-top: 277px;
} */

/* .thumbnail_info h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.thumbnail_info .info_txt {
  font-size: 16px;
} */

.thumbnail_tit .thum_tit_01 span{
  width: 197px;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 170px;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 51px;
}

} /*__end*/

@media screen and (max-width:768px) {

.thumbnail_content .thum_img .model{
  max-width: 203px;
}

/* .thumbnail_text {
  margin-top: 182px;
} */

.thumbnail_tit .thum_tit_01 {
  font-size: 34px;
  margin-bottom: 22px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 70px;
}

.thumbnail_info {
  margin-top: 222px;
}

.thumbnail_info h3 {
  font-size: 19px;
  margin-bottom: 16px;
}

.thumbnail_info .info_txt {
  font-size: 14px;
}

.thumbnail_tit .thum_tit_01 span{
  width: 158px;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 136px;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 41px;
}

} /*__end*/

@media screen and (max-width:550px) {

.inactive_550w {
  display: none;
}

.thumbnail_content .thum_img .model{
  max-width: 190px;
}

/* .thumbnail_text {
  margin-top: 182px;
} */

.thumbnail_tit .thum_tit_01 {
  font-size: 28px;
  margin-bottom: 7px;
  letter-spacing: -1.7px;
}

.thumbnail_tit .thum_tit_02 {
  font-size: 52px;
  letter-spacing: -2.07px;
}

.thumbnail_info {
  margin-top: 222px;
  width: 86.66666vw;
}

.thumbnail_info h3 {
  margin-bottom: 13px;
}

.thumbnail_info .info_txt {
  font-size: 14px;
  letter-spacing: -0.13px;
}

.thumbnail_tit .thum_tit_01 span{
  width: 33.61111vw;
  margin: 0 4px;
}

.thumbnail_tit .thum_tit_02 .medtit {
  margin-right: 17.22222vw;
}

.thumbnail_tit .thum_tit_02 .devtit {
  padding-right: 3.33333vw;
}

} /*__end*/

@media screen and (max-width:425px) {

} /*__end*/

/*__반응형*/

</style>
