<template>
  <div class="thumbnail_containers">
    <ThemeObserver :theme=theme />
    <div class="thumbnail_content">
      <div class="thumbnail_text">
        <div class="thumbnail_tit">
          <img src="@/assets/img/2019_kia_ces/kiaces_logo.png" alt="kia_logo">
          <h1>CES 2019<br class="active_768w"> R.E.A.D System</h1>
        </div>
        <div class="thumbnail_info">
          <p>UX Design</p>
          <p>3D-3D Animation</p>
          <p>Interactive Motion</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail0",
  data() {
    return {
      theme: Theme.white
    }
  },
  components: {
    ThemeObserver
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ALL */
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */

.thumbnail_containers {
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    position: relative;
    background-image: url(../../../assets/img/2019_kia_ces/kiaces_01.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content-visibility: auto;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    height: 100vh;
    position: relative;
    margin: 0 auto;
}

.thumbnail_text {
    padding-top: 156px;
}

.thumbnail_tit img {
    width: 113px;
    margin-bottom: 19px;
}

.thumbnail_tit h1 {
    margin-left: -1px;
    font-size: 50px;
    font-weight: 600;
}

.thumbnail_info {
    position: absolute;
    width: 185px;
    bottom: 100px;
    right: 0;
    border-top: 0.5px solid #fff;
    padding-top: 17px;
}

.thumbnail_info p {
    font-size: 16px;
    letter-spacing: -0.36px;
    font-weight: 400;
}

.thumbnail_info p:nth-child(1),
.thumbnail_info p:nth-child(2) {
    margin-bottom: 7px;
}

/*__Default*/


/* Animation */

.thumbnail_tit {
    animation: thumbtit_ani 1.2s ease-in-out both;
}

@-webkit-keyframes thumbtit_ani {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.thumbnail_info {
    animation: thumbinfo_ani 1.2s ease-in-out 400ms both;
}

@-webkit-keyframes thumbinfo_ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_containers .thumbnail_content {
      max-width: 90%;
  }

} /*__end*/

/* *.8 */
@media screen and (max-width:1024px) {
  .thumbnail_containers {
    background-position: 30%;
  }

  .thumbnail_tit img {
    width: 90px;
  }

  .thumbnail_tit h1 {
    font-size: 40px;
  }

} /*__end*/

@media screen and (max-width:768px) {
  .thumbnail_tit h1 {
    line-height: 1.15;
  }

} /*__end*/

@media screen and (max-width:425px) {
  .thumbnail_containers {
    background-image: url(../../../assets/img/2019_kia_ces/kiaces_01_m.png);
  } 


  .thumbnail_containers .thumbnail_content {
      max-width: 86.66666vw;
  }

  .thumbnail_containers::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 40vh;
    background: linear-gradient(180deg,rgba(000,000,000,0),rgba(000,000,000,.8) 85%,#000);
  }

  .thumbnail_text {
    padding-top: 88px;
  }

  .thumbnail_tit h1 {
    font-size: 26px;
  }

  .thumbnail_info {
    width: 132px;
    padding-top: 15px;
  }

  .thumbnail_info p {
    font-size: 13px;
  }

} /*__end*/


/*__반응형*/

</style>
