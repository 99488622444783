
import { defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import useShowIntersectionObserver from "@/composables/useShowIntersectionObserver.ts";

export default defineComponent({
  name: "ShowObserver",
  setup() {
    const el = ref(null)
    const {observe, unobserve, isShown} = useShowIntersectionObserver()

    onMounted(() => {
      observe(el.value)
    })

    onBeforeUnmount(() => {
      unobserve(el.value)
    })

    return {el, isShown}
  },
  watch: {
    isShown(value) {
      this.$emit('isShow', value)
    }
  },
  methods: {}
});
