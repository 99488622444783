
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      show0: false,
      theme: Theme.white,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
  setup() {
    function changeBodyColor() {
      document.querySelector("body").style.background = "#2c2c2c";
    }

    changeBodyColor();
  },
});
