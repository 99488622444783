
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail7",
  el: "#motion_emoji",
  data() {
    const items = [
      {
        url: "motion_1",
        name: "Smiling",
      },
      {
        url: "motion_2",
        name: "Grinning",
      },
      {
        url: "motion_3",
        name: "Slightly Smiling",
      },
      {
        url: "motion_4",
        name: "Kissing",
      },
      {
        url: "motion_5",
        name: "winking",
      },
      {
        url: "motion_6",
        name: "Raised Eyebrow",
      },
      {
        url: "motion_7",
        name: "Neutral",
      },
      {
        url: "motion_8",
        name: "Expressionless",
      },
      {
        url: "motion_9",
        name: "Clouds",
      },
      {
        url: "motion_10",
        name: "Grimacing",
      },
      {
        url: "motion_11",
        name: "Drooling",
      },
      {
        url: "motion_12",
        name: "Medical Mask",
      },
      {
        url: "motion_13",
        name: "Exploding Head",
      },
      {
        url: "motion_14",
        name: "Cowboy Hat",
      },
      {
        url: "motion_15",
        name: "Disguised",
      },
      {
        url: "motion_16",
        name: "Nauseated",
      },
      {
        url: "motion_17",
        name: "Disappointed",
      },
      {
        url: "motion_18",
        name: "Worried",
      },
      {
        url: "motion_19",
        name: "Flushed",
      },
      {
        url: "motion_20",
        name: "Nerd",
      },
      {
        url: "motion_21",
        name: "Weary",
      },
      {
        url: "motion_22",
        name: "Steam From Nose",
      },
      {
        url: "motion_23",
        name: "Screaming",
      },
      {
        url: "motion_24",
        name: "Angry horns",
      },
    ];

    return {
      show0: false,
      items,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
});
