<template>
  <div class="thumbnail_container">
    <ThemeObserver :theme="theme" />
    <div class="thumbnail-inner">
      <div class="title">
        <h3>쉽고 안전한<br />금융거래의 시작 KB</h3>
        <p>KB은행 APP RENEWAL DESIGN</p>
      </div>
      <div class="visual">
        <div class="visual-01">
          <img
            src="@/assets/img/2021_KBbank/section00-visual01.webp"
            alt="image"
          />
        </div>
        <div class="visual-02">
          <img
            src="@/assets/img/2021_KBbank/section00-visual02.webp"
            alt="image"
          />
        </div>
        <img
          class="visual-03"
          src="@/assets/img/2021_KBbank/section00-visual03.webp"
          alt="image"
        />
      </div>
      <div class="background-logo"></div>
    </div>
    <!--//thumbnail inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "KookminBankDetail0",
  data() {
    return {
      theme: Theme.dark,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 1280 ~ 1920 */
/* Default */

.thumbnail_container {
  width: 100vw;
  height: calc(var(--responsive) * 1180);
  position: relative;
  background: #ffbc01;
  font-family: var(--pretendard);
  z-index: -1;
}

.thumbnail-inner {
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* title */

.title {
  margin-top: auto;
  margin-bottom: calc(var(--responsive) * 65);
}
.title h3 {
  font-size: calc(var(--responsive) * 46);
  font-weight: 700;
  line-height: 1.37em;
  letter-spacing: 0.005em;
  text-align: center;
  margin-bottom: 0.5em;
}

.title p {
  font-size: calc(var(--responsive) * 18);
  font-weight: 500;
  line-height: 1.167em;
  text-align: center;
}

/* visual */

.visual {
  position: relative;
  width: 100%;
  height: calc(var(--responsive) * 732);
  z-index: 10;
}

.visual img {
  width: 100%;
}

.visual .visual-01 {
  position: absolute;
  width: calc(var(--responsive) * 303);
  height: calc(var(--responsive) * 603);
  left: calc(var(--responsive) * 264);
  bottom: calc(var(--responsive) * -106);
}

.visual .visual-01::before {
  font-size: calc(var(--responsive) * 100);
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 0.02em);
  height: 100%;
  border-radius: 0.5em;
  background: transparent;
  top: 0;
  left: 0.02em;
  z-index: -1;
  box-shadow: -0.22em 0.22em 0.25em rgba(0, 0, 0, 0.25);
}

.visual .visual-02 {
  position: absolute;
  width: calc(var(--responsive) * 303);
  height: calc(var(--responsive) * 603);
  right: calc(var(--responsive) * 259);
  bottom: calc(var(--responsive) * 129);
}

.visual .visual-02::before {
  font-size: calc(var(--responsive) * 100);
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 0.02em);
  height: 100%;
  border-radius: 0.5em;
  background: transparent;
  top: 0;
  left: 0.02em;
  z-index: -1;
  box-shadow: -0.22em 0.22em 0.25em rgba(0, 0, 0, 0.25);
}

.visual .visual-03 {
  position: absolute;
  width: calc(var(--responsive) * 490);
  height: calc(var(--responsive) * 288);
  right: calc(var(--responsive) * -87);
  bottom: 0;
}

.background-logo {
  width: calc(var(--responsive) * 861);
  height: calc(var(--responsive) * 655);
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("../../../assets/img/2021_KBbank/section00-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/*__Default*/

/* Animation */

.title,
.visual .visual-01,
.visual .visual-02,
.visual .visual-03,
.background-logo {
  opacity: 0;
}

.title {
  animation: thumb-ani03 1200ms ease-in-out 800ms 1 forwards;
}

.visual .visual-01 {
  animation: thumb-ani01 1200ms ease-in-out 1 forwards;
}

.visual .visual-02 {
  animation: thumb-ani02 1200ms ease-in-out 1 forwards;
}

.visual .visual-03 {
  animation: thumb-ani03 1200ms ease-in-out 800ms 1 forwards;
}

.background-logo {
  animation: thumb-ani03 1200ms ease-in-out 800ms 1 forwards;
}

@keyframes thumb-ani01 {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes thumb-ani02 {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes thumb-ani03 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1200px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .thumbnail_container {
    height: 100vh;
  }

  .thumbnail-inner {
    width: 100%;
  }

  /* title */

  .title h3 {
    font-size: calc(var(--responsive) * 28);
    line-height: 1.393em;
    margin-bottom: 0.429em;
  }

  .title p {
    font-size: calc(var(--responsive) * 16);
    font-weight: 400;
  }

  /* visual */

  .visual-03,
  .background-logo {
    display: none;
  }

  .visual {
    height: 62.5%;
  }

  .visual .visual-01 {
    width: calc(var(--responsive) * 154.85);
    height: calc(var(--responsive) * 307.93);
    left: calc(var(--responsive) * 23.7);
    top: calc(var(--responsive) * 179.87);
    bottom: initial;
    z-index: 2;
  }

  .visual .visual-01::before {
    box-shadow: -0.22em 0.22em 0.35em rgba(0, 0, 0, 0.25);
  }

  .visual .visual-02 {
    width: calc(var(--responsive) * 154.85);
    height: calc(var(--responsive) * 307.93);
    right: calc(var(--responsive) * 22.09);
    top: 0;
    bottom: initial;
  }

  .visual .visual-02::before {
    box-shadow: -0.22em 0.22em 0.35em rgba(0, 0, 0, 0.2);
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
