<template>
  <div class="thumbnail_container">
    <ThemeObserver :theme="theme" />
    <div class="thumbnail-inner">
      <div class="list-wrap">
        <ul>
          <li class="ux-list">
            <div class="ux-label">Seasonal UX</div>
            <div class="ux-name-01">Kim Huiok</div>
            <div class="ux-name-02">Park Chunghyeon</div>
          </li>
          <li class="motion-list">
            <div class="motion-label">2.5D Motion</div>
            <div class="motion-name-01">Kang Hyeri</div>
            <div class="motion-name-02">Min Hyejin</div>
          </li>
        </ul>
      </div>
      <!-- pc -->
      <div class="content-wrap inactive_820w">
        <div class="content-01">
          <span>Hi, There</span>
          <div class="change-img-01"></div>
        </div>
        <div class="content-02">
          <span>This is Samsung Emoji</span>
        </div>
        <div class="content-03">
          <span>We Designed</span>
        </div>
        <div class="content-04">
          <div class="change-img-02"></div>
          <span class="txt-seasonal">Seasonal</span>
          <div class="change-img-03"></div>
          <span class="txt-emoji">Emoji</span>
        </div>
        <div class="content-05">
          <span>And 2.5D Emotional</span>
          <div class="change-img-04"></div>
        </div>
      </div>
      <!-- mobile -->
      <div class="content-wrap active_820w">
        <div class="content-01">
          <span>Hi, There</span>
          <div class="change-img-01"></div>
        </div>
        <div class="content-02">
          <span>This is</span>
        </div>
        <div class="content-03">
          <span>Samsung Emoji</span>
        </div>
        <div class="content-04">
          <span>We Designed</span>
        </div>
        <div class="content-05">
          <div class="change-img-02"></div>
          <span class="txt-seasonal">Seasonal</span>
          <div class="change-img-03"></div>
          <span class="txt-emoji">And</span>
        </div>
        <div class="content-06">
          <span>2.5D Emotional</span>
          <div class="change-img-04"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "EmojiDetail0",
  data() {
    return {
      theme: Theme.white,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Default */

.thumbnail_container {
  width: 100vw;
  height: calc(var(--responsive) * 1180);
  position: relative;
  background: #1686e1;
  font-family: "Poppins", sans-serif;
}

.thumbnail-inner {
  width: 71.875%;
  max-width: 1700px; /* 27-inch imac 대응 */
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.list-wrap {
  width: 100%;
  margin-top: calc(var(--responsive) * 146);
}

.list-wrap ul {
  display: flex;
  justify-content: flex-end;
}

.ux-list {
  margin-right: calc(var(--responsive) * 80);
}

.list-wrap ul li {
  color: #fff;
}

.ux-label,
.motion-label {
  font-size: calc(var(--responsive) * 24);
  font-weight: 600;
  margin-bottom: 0.375em;
}

[class^="ux-name-"],
[class^="motion-name-"] {
  font-size: calc(var(--responsive) * 22);
  line-height: 1.409em;
  font-weight: 400;
}

/* content-wrap */

.content-wrap {
  margin-top: calc(var(--responsive) * 75);
  color: #fff;
}

.content-wrap [class^="content-"] {
  display: flex;
  align-items: center;
}

.content-wrap [class^="content-"] span {
  font-size: calc(var(--responsive) * 90);
  font-weight: 500;
  height: 1.411em;
  line-height: 1.411em;
  white-space: nowrap;
  margin-bottom: 0.067em;
}

.content-01 span,
.content-05 span {
  margin-right: 0.156em;
}

.txt-seasonal {
  margin: 0 0.156em 0 0.178em;
}

.txt-emoji {
  margin-left: 0.133em;
}

.content-wrap .change-img-01,
.content-wrap .change-img-02,
.content-wrap .change-img-03,
.content-wrap .change-img-04 {
  width: calc(var(--responsive) * 98);
  height: calc(var(--responsive) * 98);
  min-width: 65.3333px;
  object-fit: cover;
}

.content-wrap .change-img-01 {
  animation: change-img-01 2400ms ease-in-out infinite;
  animation-delay: 1500ms;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/2021_emoji/img_1-1.webp");
}

@keyframes change-img-01 {
  0% {
    background-image: url("../../../assets/img/2021_emoji/img_1-1.webp");
  }
  32% {
    background-image: url("../../../assets/img/2021_emoji/img_1-1.webp");
  }
  33% {
    background-image: url("../../../assets/img/2021_emoji/img_1-2.webp");
  }
  65% {
    background-image: url("../../../assets/img/2021_emoji/img_1-2.webp");
  }
  66% {
    background-image: url("../../../assets/img/2021_emoji/img_1-3.webp");
  }
  99% {
    background-image: url("../../../assets/img/2021_emoji/img_1-3.webp");
  }
}

.content-wrap .change-img-02 {
  animation: change-img-02 2400ms ease-in-out infinite;
  animation-delay: 1500ms;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/2021_emoji/img_2-1.webp");
}

@keyframes change-img-02 {
  0% {
    background-image: url("../../../assets/img/2021_emoji/img_2-1.webp");
  }
  32% {
    background-image: url("../../../assets/img/2021_emoji/img_2-1.webp");
  }
  33% {
    background-image: url("../../../assets/img/2021_emoji/img_2-2.webp");
  }
  65% {
    background-image: url("../../../assets/img/2021_emoji/img_2-2.webp");
  }
  66% {
    background-image: url("../../../assets/img/2021_emoji/img_2-3.webp");
  }
  99% {
    background-image: url("../../../assets/img/2021_emoji/img_2-3.webp");
  }
}

.content-wrap .change-img-03 {
  animation: change-img-03 2400ms ease-in-out infinite;
  animation-delay: 1500ms;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/2021_emoji/img_3-1.webp");
}

@keyframes change-img-03 {
  0% {
    background-image: url("../../../assets/img/2021_emoji/img_3-1.webp");
  }
  32% {
    background-image: url("../../../assets/img/2021_emoji/img_3-1.webp");
  }
  33% {
    background-image: url("../../../assets/img/2021_emoji/img_3-2.webp");
  }
  65% {
    background-image: url("../../../assets/img/2021_emoji/img_3-2.webp");
  }
  66% {
    background-image: url("../../../assets/img/2021_emoji/img_3-3.webp");
  }
  99% {
    background-image: url("../../../assets/img/2021_emoji/img_3-3.webp");
  }
}

.content-wrap .change-img-04 {
  position: relative;
  animation: change-img-04 2400ms ease-in-out infinite;
  animation-delay: 1500ms;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/2021_emoji/img_4-1.webp");
}

@keyframes change-img-04 {
  0% {
    background-image: url("../../../assets/img/2021_emoji/img_4-1.webp");
  }
  32% {
    background-image: url("../../../assets/img/2021_emoji/img_4-1.webp");
  }
  33% {
    background-image: url("../../../assets/img/2021_emoji/img_4-2.webp");
  }
  65% {
    background-image: url("../../../assets/img/2021_emoji/img_4-2.webp");
  }
  66% {
    background-image: url("../../../assets/img/2021_emoji/img_4-3.webp");
  }
  99% {
    background-image: url("../../../assets/img/2021_emoji/img_4-3.webp");
  }
}

/*__Default*/

/* Animation */

.content-01 {
  animation: thumbnail-ani 650ms ease-in-out 1 forwards;
  animation-delay: 400ms;
  opacity: 0;
}

.content-02 {
  animation: thumbnail-ani 650ms ease-in-out 1 forwards;
  animation-delay: 600ms;
  opacity: 0;
}

.content-03 {
  animation: thumbnail-ani 650ms ease-in-out 1 forwards;
  animation-delay: 800ms;
  opacity: 0;
}

.content-04 {
  animation: thumbnail-ani 650ms ease-in-out 1 forwards;
  animation-delay: 1000ms;
  opacity: 0;
}

.content-05 {
  animation: thumbnail-ani 650ms ease-in-out 1 forwards;
  animation-delay: 1200ms;
  opacity: 0;
}

@keyframes thumbnail-ani {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1200px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .thumbnail_container {
    height: 100vh;
  }

  .thumbnail-inner {
    width: 83.33333%;
    justify-content: space-between;
  }
  .list-wrap {
    margin-top: 20vw;
  }

  .list-wrap ul {
    justify-content: flex-start;
  }

  .ux-list {
    margin-right: 12.7777vw;
  }

  .ux-label,
  .motion-label {
    font-size: 4.16666vw;
    margin-bottom: 0.333em;
  }

  [class^="ux-name-"],
  [class^="motion-name-"] {
    font-size: 3.61111vw;
  }

  .content-wrap {
    margin-top: initial;
    margin-bottom: 22.77777vw;
  }

  .content-wrap [class^="content-"] span {
    font-size: 9.166666vw;
    margin-bottom: 0.091em;
  }

  .content-05 .txt-seasonal {
    margin: 0 0.121em;
  }

  .content-05 .txt-emoji {
    margin-left: 0.121em;
    margin-right: initial;
  }

  .content-01 span {
    margin-right: 0.091em;
  }

  .content-wrap .change-img-01,
  .content-wrap .change-img-02,
  .content-wrap .change-img-03,
  .content-wrap .change-img-04 {
    width: 10vw;
    height: 10vw;
    min-width: initial;
    object-fit: cover;
  }

  /* Animation */

  .content-06 {
    animation: thumbnail-ani 650ms ease-in-out 1 forwards;
    animation-delay: 1400ms;
    opacity: 0;
  }

  /*__Animation */
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
