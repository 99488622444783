<template>
<div class="explain_containers">
            <div class="explain_content content_wrap">
                <!--01 friendly-->
                <div class="item_friendly" :class="show0 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                    <div class="text_box">
                        <ul>
                            <li>
                                <span class="deco">01</span>
                                <h3 class="item_title">Friendly</h3>
                            </li>
                            <li class="itme_img inactive_pc_all">
                                <img src="@/assets/img/2020_baro/explainimg_01.jpg" alt="card_image">
                            </li>
                            <li class="item_body">
                                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="15" viewBox="0 0 101 15">
                                    <g fill="none" fill-rule="evenodd">
                                        <g stroke="#979797" stroke-linecap="square" stroke-width=".5">
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-565 -9283) translate(1 8679) translate(563.5 296) translate(.5 308) translate(49 5)" />
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-565 -9283) translate(1 8679) translate(563.5 296) translate(.5 308) translate(49 5) rotate(90 2.5 2.5)" />
                                        </g>
                                        <g fill="rgba(230, 234, 243, 0.4)"
                                            transform="translate(-565 -9283) translate(1 8679) translate(563.5 296) translate(.5 308)">
                                            <circle cx="7.5" cy="7.5" r="7.5" />
                                            <path d="M12.143 0H27.143V15H12.143z" />
                                            <path d="M30.714 0L39.286 15 22.143 15z" />
                                        </g>
                                        <g
                                            transform="translate(-565 -9283) translate(1 8679) translate(563.5 296) translate(.5 308) translate(64)">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#FDDF16" />
                                            <circle cx="18.5" cy="7.5" r="7.5" fill="#43B0EF" />
                                            <circle cx="29.5" cy="7.5" r="7.5" fill="#EC267F" />
                                        </g>
                                    </g>
                                </svg>
                                <p>3가지 도형과 색상을 안정적으로 사용하여 fiendly 표현</p>
                            </li>
                        </ul>
                    </div>
                    <div class="itme_img inactive_mob_all">
                        <img src="@/assets/img/2020_baro/explainimg_01.jpg" alt="card_image">
                    </div>
                </div>
                <!--//friendly-->
                <!--02 stable-->
                <div class="item_stable" :class="show1 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                    <div class="text_box">
                        <ul>
                            <li>
                                <span class="deco">02</span>
                                <h3 class="item_title">Stable</h3>
                            </li>
                            <li class="itme_img inactive_pc_all">
                                <img src="@/assets/img/2020_baro/explainimg_02.jpg" alt="card_image">
                            </li>
                            <li class="item_body">
                                <svg xmlns="http://www.w3.org/2000/svg" width="67" height="15" viewBox="0 0 67 15">
                                    <g fill="none" fill-rule="evenodd">
                                        <g stroke="#979797" stroke-linecap="square" stroke-width=".5">
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-1077 -9931) translate(1 8679) translate(564 929) translate(512 323) translate(27 5)" />
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-1077 -9931) translate(1 8679) translate(564 929) translate(512 323) translate(27 5) rotate(90 2.5 2.5)" />
                                        </g>
                                        <g fill="rgba(230, 234, 243, 0.4)">
                                            <path d="M8.714 0L17.286 15 0.143 15z"
                                                transform="translate(-1077 -9931) translate(1 8679) translate(564 929) translate(512 323)" />
                                        </g>
                                        <g
                                            transform="translate(-1077 -9931) translate(1 8679) translate(564 929) translate(512 323) translate(41)">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#43B0EF" />
                                            <circle cx="18.5" cy="7.5" r="7.5" fill="#EC267F" />
                                        </g>
                                    </g>
                                </svg>
                                <p>2개의 삼각형을 안정적으로 배치하여 stable 표현</p>
                            </li>
                        </ul>
                    </div>
                    <div class="itme_img inactive_mob_all">
                        <img src="@/assets/img/2020_baro/explainimg_02.jpg" alt="card_image">
                    </div>
                </div>
                <!--//stable-->
                <!--03 carefully-->
                <div class="item_carefully" :class="show2 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <div class="text_box">
                        <ul>
                            <li>
                                <span class="deco">03</span>
                                <h3 class="item_title">Carefully</h3>
                            </li>
                            <li class="itme_img inactive_pc_all">
                                <img src="@/assets/img/2020_baro/explainimg_03.jpg" alt="card_image">
                            </li>
                            <li class="item_body">
                                <svg xmlns="http://www.w3.org/2000/svg" width="78" height="15" viewBox="0 0 78 15">
                                    <g fill="none" fill-rule="evenodd">
                                        <g stroke="#979797" stroke-linecap="square" stroke-width=".5">
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-565 -10579) translate(1 8679) translate(564 1577) translate(.5 323) translate(25 5)" />
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-565 -10579) translate(1 8679) translate(564 1577) translate(.5 323) translate(25 5) rotate(90 2.5 2.5)" />
                                        </g>
                                        <g fill="rgba(230, 234, 243, 0.4)"
                                            transform="translate(-565 -10579) translate(1 8679) translate(564 1577) translate(.5 323)">
                                            <circle cx="7.5" cy="7.5" r="7.5" />
                                        </g>
                                        <g
                                            transform="translate(-565 -10579) translate(1 8679) translate(564 1577) translate(.5 323) translate(40)">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#FBCB00" />
                                            <circle cx="18.5" cy="7.5" r="7.5" fill="#2D8458" />
                                            <circle cx="29.5" cy="7.5" r="7.5" fill="#EA5D3F" />
                                        </g>
                                    </g>
                                </svg>
                                <p>한가지 도형과 3가지 컬러를 사용하여 Carefully 표현</p>
                            </li>
                        </ul>
                    </div>
                    <div class="itme_img inactive_mob_all">
                        <img src="@/assets/img/2020_baro/explainimg_03.jpg" alt="card_image">
                    </div>
                </div>
                <!--//carefully-->
                <!--04 leading-->
                <div class="item_leading" :class="show3 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                    <div class="text_box">
                        <ul>
                            <li>
                                <span class="deco">04</span>
                                <h3 class="item_title">Leading</h3>
                            </li>
                            <li class="itme_img inactive_pc_all">
                                <img src="@/assets/img/2020_baro/explainimg_04.jpg" alt="card_image">
                            </li>
                            <li class="item_body">
                                <svg xmlns="http://www.w3.org/2000/svg" width="85" height="15" viewBox="0 0 85 15">
                                    <g fill="none" fill-rule="evenodd">
                                        <g stroke="#979797" stroke-linecap="square" stroke-width=".5">
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-1076 -11190) translate(1 8679) translate(563.5 2188) translate(512 323) translate(33 5)" />
                                            <path d="M0.357 2.5L4.643 2.5"
                                                transform="translate(-1076 -11190) translate(1 8679) translate(563.5 2188) translate(512 323) translate(33 5) rotate(90 2.5 2.5)" />
                                        </g>
                                        <g fill="rgba(230, 234, 243, 0.4)"
                                            transform="translate(-1076 -11190) translate(1 8679) translate(563.5 2188) translate(512 323)">
                                            <path  d="M15.714 0L24.286 15 7.143 15z" />
                                            <circle cx="7.5" cy="7.5" r="7.5" />
                                        </g>
                                        <g
                                            transform="translate(-1076 -11190) translate(1 8679) translate(563.5 2188) translate(512 323) translate(46.5)">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#FDDF16" />
                                            <circle cx="18.5" cy="7.5" r="7.5" fill="#43B0EF" />
                                            <circle cx="29.5" cy="7.5" r="7.5" fill="#EC267F" />
                                        </g>
                                    </g>
                                </svg>
                                <p>2가지 도형과 여러색을 자유롭게 배치하여 Leading 표현</p>
                            </li>
                        </ul>
                    </div>
                    <div class="itme_img inactive_mob_all">
                        <img src="@/assets/img/2020_baro/explainimg_04.jpg" alt="card_image">
                    </div>
                </div>
                <!--//leading-->
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.explain_containers {
    padding: 296px 0 273px 0;
    background-color: #373746;
}
.explain_content {
    max-width: 792px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.explain_content > [class^="item_"] .text_box {
    position: relative;
}

.explain_content > [class^="item_"] ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.explain_content .text_box ul li:first-child {
    font-size: 41px;
    font-weight: 600;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
}

.explain_content .text_box ul li:first-child .deco {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 7px;
}

.explain_content >  [class^="item_"] .itme_img {
    width: 33.71212%;
    
}

.explain_content >  [class^="item_"] .itme_img img {
    border-radius: 10px;
    box-shadow: 0 3px 11px rgba(0, 0, 0, 0.3);
}

.explain_content > [class^="item_"] .item_body svg{
    margin-bottom: 12px;
}

.explain_content > [class^="item_"]:not(.item_leading) {
    margin-bottom: 279px;
}


.explain_content > [class^="item_"] .item_body {
    width: 278px;
}

.explain_content > [class^="item_"] .item_body p {
   font-size: 11px;
   color: #e2e2e2; 
}

/*explain_01*/

.explain_content .item_friendly {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item_friendly .text_box ul li:first-child {
    color: #fcd228;
}

/*explain_02*/

.explain_content .item_stable::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #5a5a64;
    top: -140px;
}

.explain_content .item_stable {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}


.item_stable .text_box ul li:first-child {
    color: #00d4d4;
}

/*explain_03*/

.explain_content .item_carefully::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #5a5a64;
    top: -140px;
}

.explain_content .item_carefully {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item_carefully .text_box ul li:first-child {
    color: #f56b47;
}

/*explain_04*/

.explain_content .item_leading::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #5a5a64;
    top: -140px;
}

.explain_content .item_leading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.item_leading .text_box ul li:first-child {
    color: #ff38a7;
}

/*__Default*/


/* Animation */

.explain_content >  [class^="item_"] .itme_img img {
    cursor: pointer;
    transition: .8s ease-in-out;
}

.explain_content >  [class^="item_"] .itme_img img:hover {
    transform: rotateY(360deg);   
}

.item_friendly.active .itme_img,
.item_stable.active .itme_img,
.item_carefully.active .itme_img,
.item_leading.active .itme_img {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.item_friendly .itme_img,
.item_stable .itme_img,
.item_carefully .itme_img,
.item_leading .itme_img {
    opacity: 0;
}

.item_friendly.active .item_title,
.item_stable.active .item_title,
.item_carefully.active .item_title,
.item_leading.active .item_title  {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.item_friendly .item_title,
.item_stable .item_title,
.item_carefully .item_title,
.item_leading .item_title {
    opacity: 0;
}

.item_friendly.active .deco,
.item_stable.active .deco,
.item_carefully.active .deco,
.item_leading.active .deco {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}


.item_friendly .deco,
.item_stable .deco,
.item_carefully .deco,
.item_leading .deco {
    opacity: 0;
}


.item_friendly.active .item_body,
.item_stable.active .item_body,
.item_carefully.active .item_body,
.item_leading.active .item_body {
    opacity: 1;
    transition-delay: .2s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.item_friendly .item_body,
.item_stable .item_body,
.item_carefully .item_body,
.item_leading .item_body  {
    opacity: 0;
}

.explain_content .item_stable::before,
.explain_content .item_carefully::before,
.explain_content .item_leading::before {
    opacity: 0;
}


.explain_content .item_stable.active::before,
.explain_content .item_carefully.active::before,
.explain_content .item_leading.active::before {
    animation-name: itemline_ani;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

@keyframes itemline_ani {
    0% {
        opacity: 0;
        left: -100%;
    }
    100% {
        opacity: 1;
       left: 0;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:768px) {
 .explain_content > [class^="item_"] ul {
        width: 85.5769%;
        margin: auto;
    }

    .explain_content .text_box ul li:first-child {
        width: 100%;
        margin-bottom: 42px;
    }

    .explain_content > [class^="item_"] .itme_img {
        width: 100%;
        margin-bottom: 30px;
    }

    .explain_content > [class^="item_"] .item_body {
        width: 100%;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .explain_containers {
        padding: 80px 0 100px 0;
    }

    .explain_content > [class^="item_"]:not(.item_leading) {
        margin-bottom: 120px;
    }

    .explain_content .item_stable::before,
    .explain_content .item_carefully::before,
    .explain_content .item_leading::before {
        top: -60px;
    }

} /*__end*/


/*__반응형*/

</style>