
import {computed, defineComponent, ref, watch} from "vue";
import { useStore } from "vuex";
import {Theme} from "@/enums/Theme";

export default defineComponent({
  name: "TopNavi",
    data() {
    return {
      isActive: false,
    }
  },
  computed: {
  },
  setup() {

    const store = useStore();

    let theme = ref(Theme.dark);
    watch(() => store.getters.currentTheme, (current) => {
      theme.value = current;
    })

    return { theme }
  },
  components: {
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    INmyFilter: function() {
      this.isActive = false;
    }

  }
});
