<template>
<div class="thumbnail_containers" style="position: relative;">
  <div class="thumbnail_content content_wrap">
    <ul>
      <li>CC BOOUM</li>
      <li>Branding &#183; Product modeling &#183; UI/UX</li>
      <li><img src="@/assets/img/2021_ccb/thumbnail_01.jpg" alt="image" style="display: none">
        <div class="content_video">
          <iframe src="https://player.vimeo.com/video/513306841?muted=1&autoplay=1&autopause=0&loop=1&
           " frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>

      </li>
      <li>
        <div :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
          <p>Value Beyond <br class="active_1024w">Cosmetics</p>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail0",
  data() {
    return {
      show0: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #393531;
}
/*__ALl*/

.thumbnail_containers {
   
    padding-top: 212px;
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: normal;
}

.thumbnail_containers ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thumbnail_containers li:nth-child(1) {
    font-weight: 500;
    height: 24px;
    margin-bottom: 24px;
}

.thumbnail_containers li:nth-child(2) {
    font-weight: normal;
    height: 26px;
    margin-bottom: 83px;
}

.thumbnail_containers li:nth-child(3) {
    width: 100%;
}

.thumbnail_containers .content_video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-bottom: 122px;
}

.thumbnail_containers .content_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.thumbnail_containers li:nth-child(4) {
    height: 96px;
    font-size: 68px;
    line-height: 1.41;
    letter-spacing: -0.43px;
    font-weight: bold;
    font-family: 'Noto Serif KR', serif;
    color: #524742;
    margin-bottom: 153px;
}

/*__Default*/


/* Animation */

.thumbnail_containers ul li:nth-child(-1n+2) {
    animation-name: thumb01_ani;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
} 


@-webkit-keyframes thumb01_ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.thumbnail_containers ul li:last-child .active p {
    animation-name: thumb02_ani;
    animation-delay: .4s;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    animation-fill-mode: both;
} 

@-webkit-keyframes thumb02_ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        color: #393531;
    }
    /*--All*/

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .thumbnail_containers li:nth-child(4) {
        height: auto;
        text-align: center;
    }

    .thumbnail_containers .content_video {
        margin-bottom: 23.0769%;
    }

    .thumbnail_containers li:nth-child(4) {
        margin-bottom: 27.24358%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .thumbnail_containers {
        padding-top: 23.33333%;
    }

    .thumbnail_containers li:nth-child(4) {
        font-size: 54px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

    .thumbnail_containers li:nth-child(4) {
        font-size: 34px;
    }

} /*__end*/


/*__반응형*/

</style>
