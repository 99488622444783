<template>
  <div class="typography_containers">
      <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <h3 class="content_tit">Typography</h3>
          <div class="content_body" :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
              <div class="content_top">
                  <div class="tg_h3"><img src="@/assets/img/2019_kia_ces/kiaces_18.png" alt="image" class="inactive_768w"><img src="@/assets/img/2019_kia_ces/kiaces_18_m.png" alt="image" class="active_768w"></div>
                  <div class="tg_p"><img src="@/assets/img/2019_kia_ces/kiaces_19.png" alt="image" class="inactive_768w"><img src="@/assets/img/2019_kia_ces/kiaces_19_m.png" alt="image" class="active_768w"></div>
              </div>
              <div class="content_btm">
                  <div class="tg_h3"><img src="@/assets/img/2019_kia_ces/kiaces_20.png" alt="image" class="inactive_768w"><img src="@/assets/img/2019_kia_ces/kiaces_20_m.png" alt="image" class="active_768w"></div>
                  <div class="tg_p"><img src="@/assets/img/2019_kia_ces/kiaces_21.png" alt="image" class="inactive_768w"><img src="@/assets/img/2019_kia_ces/kiaces_21_m.png" alt="image" class="active_768w"></div>
              </div>   
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail6",
  data() {
    return {
        theme: Theme.white,
        show0: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.typography_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 354px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.typography_containers .content_tit {
    margin-bottom: 169px;
}

.typography_containers .content_body {
    position: relative;
}

.typography_containers .content_top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 113px;
}

.typography_containers .content_btm {
    display: flex;
    justify-content: space-between;
}

.typography_containers .content_top .tg_h3 {
    position: relative;
    width: 24.08333%;
}

.typography_containers .content_top .tg_h3::before {
    content: "";
    position: absolute;
    top: -18px;
    width: 100%;
    height: 1px;
    background: #fff;
}

.typography_containers .content_top .tg_p::before {
    content: "";
    position: absolute;
    top: -18px;
    width: 100%;
    height: 1px;
    background: #fff;
}

.typography_containers .content_btm .tg_h3 {
    width: 18%;
}

.typography_containers .content_top .tg_p {
    position: relative;
    width: 64.58333%;
}

.typography_containers .content_btm .tg_p {
    width: 64.58333%;
}



/*__Default*/


/* Animation */

.content_body .content_top .tg_h3,
.content_body .content_top .tg_p,
.content_body .content_btm .tg_h3,
.content_body .content_btm .tg_p {
    opacity: 0;
}

.content_body.active .content_top .tg_h3 {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.content_body.active .content_top .tg_p {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.content_body.active .content_btm .tg_h3 {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}

.content_body.active .content_btm .tg_p {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_wrap {
        max-width: 90%;
   }
} /*__end*/

@media screen and (max-width:1024px) {
    .typography_containers {
        padding-bottom: 283px;
    }

    .typography_containers .content_tit {
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .typography_containers {
        padding-bottom: 226px;
    }

    .typography_containers .content_tit {
        margin-bottom: 135px;
    }

    .typography_containers .content_top {
        flex-direction: column;
    }

    .typography_containers .content_btm {
        flex-direction: column;
    }

    .typography_containers .content_top .tg_p::before {
        display: none;
    }

    .content_body .tg_h3 {
        margin-bottom: 26px;
    }

    .typography_containers .content_top .tg_h3 {
        width: 100%;
    }

    .typography_containers .content_top .tg_p {
        width: 100%;
    }

    .typography_containers .content_btm .tg_h3 {
        width: 100%;
        position: relative;
    }

    .typography_containers .content_btm .tg_p {
        width: 100%;
    }
    
    .typography_containers .content_btm .tg_h3::before {
        content: "";
        position: absolute;
        top: -18px;
        width: 100%;
        height: 1px;
        background: #fff;
    }

    .content_body.active .content_btm .tg_h3 {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
        transition-property: opacity;
    }
    
    .content_body.active .content_btm .tg_p {
        opacity: 1;
        transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 600ms;
        transition-property: opacity;
    }



} /*__end*/

@media screen and (max-width:425px) {
    .typography_containers {
        padding-bottom: 130px;
    }


    .content_wrap {
        max-width: 86.66666vw;
   }

    .typography_containers .content_tit {
        font-size: 12px;
        margin-bottom: 90px;
        letter-spacing: -0.27px;
    }
} /*__end*/


/*__반응형*/

</style>
