<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="about"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <h3 class="title">ABOUT</h3>
        <p class="desc">
          KB금융 그룹의 모바일 서비스 디자인 업데이트 프로젝트<br />
          KB스타뱅킹, KB인터넷 뱅킹, KB인터넷 뱅킹, KB금융라이프X의
          <br class="active_820w" />디자인 업데이트 작업을 진행했습니다
        </p>
      </div>
      <div
        class="graphics"
        :class="show1 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="graphics-inner">
          <div class="item-01">
            <span class="gp-text-01">KB<br class="active_820w" />스타뱅킹</span>
          </div>
          <div class="item-02">
            <span class="gp-text-02"
              >KB<br class="active_820w" />인터넷뱅킹</span
            >
          </div>
          <div class="item-03">
            <span class="gp-text-03">국민의 평생<br />금융 파트너</span>
            <div style="display: none"></div>
          </div>
          <div class="item-04">
            <span class="gp-text-04">KB<br class="active_820w" />기업뱅킹</span>
          </div>
          <div class="item-05">
            <span class="gp-text-05"
              >KB<br class="active_820w" />골든 라이프X</span
            >
          </div>
          <div class="graphics-bg active_820w"></div>
        </div>
      </div>
      <div
        class="palette"
        :class="show2 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <div class="palette-color-01">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              YELLOW<br class="active_820w" />
              POSITIVE</b
            >
            <br />
            <span class="hex-code">FFBC00</span>
          </span>
        </div>
        <div class="palette-color-02">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              YELLOW<br class="active_820w" />
              NEGATIVE</b
            >
            <br />
            <span class="hex-code">FFCC00</span>
          </span>
        </div>
        <div class="palette-color-03">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              GOLD</b
            >
            <br />
            <span class="hex-code">8C744A</span>
          </span>
        </div>
        <div class="palette-color-04">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              GRAY</b
            >
            <br />
            <span class="hex-code">5F584C</span>
          </span>
        </div>
        <div class="palette-color-05">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              DARK GRAY</b
            >
            <br />
            <span class="hex-code">545045</span>
          </span>
        </div>
        <div class="palette-color-06">
          <span class="color-text-wrap">
            <b class="color-name"
              >KB<br class="active_820w" />
              SILVER</b
            >
            <br />
            <span class="hex-code">84888B</span>
          </span>
        </div>
      </div>
    </div>
    <!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "KookminBankDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  width: 100vw;
  position: relative;
  background: #fff;
  font-family: var(--pretendard);
}

.container-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* about */

.about {
  width: calc(var(--responsive) * 1200);
  padding-top: calc(var(--responsive) * 137);
  padding-bottom: calc(var(--responsive) * 137);
  text-align: center;
}

.about .title {
  font-size: calc(var(--responsive) * 24);
  font-weight: bold;
  line-height: 1.42em;
  margin-bottom: 1.375em;
}

.about .desc {
  font-size: calc(var(--responsive) * 16);
  line-height: 1.44em;
}

/* graphics */

.graphics {
  width: 100vw;
  height: calc(var(--responsive) * 660);
  background: #48463e;
}

.graphics-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(calc(var(--responsive) * 282) * 1.6241134);
  margin-top: calc(var(--responsive) * 142);
  margin-bottom: calc(var(--responsive) * 60);
}

.graphics-inner [class^="item-0"]:not(.item-03) {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--responsive) * 140);
  height: calc(var(--responsive) * 140);
  border-radius: 100%;
  background: #fff;
  z-index: 1;
}

.item-01,
.item-04 {
  position: relative;
  margin-right: calc(var(--responsive) * 27);
}

.item-01::after,
.item-04::after {
  content: "";
  position: absolute;
  width: calc(var(--responsive) * 27);
  height: 1px;
  right: calc(var(--responsive) * -27);
  background: rgba(255, 255, 255, 0.45);
}

.gp-text-01,
.gp-text-02,
.gp-text-04,
.gp-text-05 {
  text-align: center;
  font-size: calc(var(--responsive) * 15);
  font-weight: 500;
  letter-spacing: 0.005em;
  color: #202020;
}

.graphics-inner .item-03 {
  position: relative;
  width: calc(var(--responsive) * 282);
  height: calc(var(--responsive) * 282);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffcc00;
}

.graphics-inner .item-03::after {
  content: "";
  position: absolute;
  width: 121.2765%;
  height: 121.2765%;
  border-radius: 100%;
  background: rgba(255, 204, 0, 0.13);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.graphics-inner .item-03::before {
  content: "";
  position: absolute;
  width: 162.41134%;
  height: 162.41134%;
  border-radius: 100%;
  background: transparent;
  border: 1px solid rgba(255, 204, 0, 0.45);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.gp-text-03 {
  font-size: calc(var(--responsive) * 25);
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0.005em;
  text-align: center;
}

.graphics-inner .graphics-bg {
  position: absolute;
  width: calc(var(--responsive) * 144);
  height: calc(var(--responsive) * 144);
  bottom: calc(var(--responsive) * 148.76);
  background: rgba(255, 204, 0, 0.13);
  z-index: 0;
}

.graphics-inner .graphics-bg::after {
  content: "";
  position: absolute;
  width: 141.6666%;
  height: 141.6666%;
  background: transparent;
  border: 1px solid rgba(255, 204, 0, 0.45);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.graphics-inner .graphics-bg::before {
  content: "";
  position: absolute;
  width: 1px;
  height: calc((var(--responsive) * 89) - 1px);
  background: rgba(255, 204, 0, 0.45);
  left: calc(50% - 1px);
  top: calc(calc(var(--responsive) * -89) + (var(--responsive) * -30.24) + 1px);
}

/* palette */

.palette {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

.palette-color-01,
.palette-color-02,
.palette-color-03,
.palette-color-04 {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-left: calc(var(--responsive) * 45);
  padding-bottom: calc(var(--responsive) * 14);
  width: 25%;
  height: calc(var(--responsive) * 173);
  box-sizing: border-box;
  overflow: hidden;
}

.palette-color-05,
.palette-color-06 {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: calc(var(--responsive) * 45);
  width: 50%;
  height: calc(var(--responsive) * 77);
  box-sizing: border-box;
  overflow: hidden;
}

.palette-color-01::after,
.palette-color-02::after,
.palette-color-03::after,
.palette-color-04::after,
.palette-color-05::after,
.palette-color-06::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.palette-color-01::after {
  background: #ffbc00;
}

.palette-color-02::after {
  background: #ffcc00;
}

.palette-color-03::after {
  background: #8c744a;
}

.palette-color-04::after {
  background: #5f584c;
}

.palette-color-05::after {
  background: #545045;
}

.palette-color-06::after {
  background: #84888b;
}

.color-text-wrap {
  color: #fff;
  font-size: calc(var(--responsive) * 12);
  font-weight: 600;
  letter-spacing: 0.005em;
  line-height: normal;
  z-index: 1;
}

.color-text-wrap .hex-code {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

/*__Default*/

/* Animation */

.about,
.graphics-inner .item-01,
.graphics-inner .item-02,
.graphics-inner .item-03,
.graphics-inner .item-04,
.graphics-inner .item-05 {
  opacity: 0;
}

.about.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.graphics.active .graphics-inner .item-03 {
  animation: ani01 1200ms ease-in-out 1 forwards;
}

.graphics.active .graphics-inner .item-02,
.graphics.active .graphics-inner .item-04 {
  animation: ani01 1200ms ease-in-out 1 forwards;
}

.graphics.active .graphics-inner .item-01,
.graphics.active .graphics-inner .item-05 {
  animation: ani01 1200ms ease-in-out 1 forwards;
}

.graphics.active .graphics-inner .item-03::after {
  opacity: 1;
  animation: ani02 1800ms ease-in-out 1 forwards;
}

.graphics.active .graphics-inner .item-03::before {
  opacity: 1;
  animation: ani03 1800ms ease-in-out 1 forwards;
}

.palette {
  background: #eee;
}

.palette.active .palette-color-01::after,
.palette.active .palette-color-02::after,
.palette.active .palette-color-03::after,
.palette.active .palette-color-04::after,
.palette.active .palette-color-05::after,
.palette.active .palette-color-06::after {
  opacity: 0;
  animation: ani04 800ms ease-in-out 1 forwards;
}

.palette.active .color-text-wrap {
  opacity: 0;
  animation: ani01 1200ms ease-in-out 600ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1.2);
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani03 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.8);
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani04 {
  0% {
    opacity: 0;
    transform: translate3d(-80%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  /* about */

  .about {
    width: calc(var(--responsive) * 312);
    padding-top: calc(var(--responsive) * 46);
    padding-bottom: calc(var(--responsive) * 46);
  }

  .about .title {
    font-size: calc(var(--responsive) * 16);
    line-height: normal;
    margin-bottom: 0.625em;
  }

  .about .desc {
    font-size: calc(var(--responsive) * 12);
  }

  /* graphics */

  .graphics {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(var(--responsive) * 778);
  }

  .graphics-inner {
    width: calc(var(--responsive) * 286);
    height: calc(var(--responsive) * 616);
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: flex-end;
  }

  .graphics-inner [class^="item-0"]:not(.item-03) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--responsive) * 132);
    height: calc(var(--responsive) * 132);
    border-radius: 100%;
    background: #fff;
    z-index: 999999;
  }

  .item-01,
  .item-04 {
    margin-right: calc(var(--responsive) * 16);
  }

  .item-01,
  .item-02 {
    margin-bottom: calc(var(--responsive) * 16);
  }

  .item-01::after,
  .item-04::after {
    display: none;
  }

  .gp-text-01,
  .gp-text-02,
  .gp-text-04,
  .gp-text-05 {
    font-size: calc(var(--responsive) * 12);
    line-height: normal;
  }

  .graphics-inner .item-03 {
    position: absolute;
    width: calc(var(--responsive) * 182);
    height: calc(var(--responsive) * 182);
    top: calc(var(--responsive) * 132.5);
  }

  .graphics-inner .item-03::after {
    width: 117.582417%;
    height: 117.582417%;
  }

  .graphics-inner .item-03::before {
    width: 157.142857%;
    height: 157.142857%;
  }

  .gp-text-03 {
    font-size: calc(var(--responsive) * 18);
  }

  /* palette */

  .palette-color-01,
  .palette-color-02,
  .palette-color-03,
  .palette-color-04,
  .palette-color-05,
  .palette-color-06 {
    width: 50%;
    padding-left: calc(var(--responsive) * 16);
    padding-bottom: calc(var(--responsive) * 7);
    padding-right: calc(var(--responsive) * 12);
    align-items: flex-end;
  }

  .palette-color-01,
  .palette-color-02 {
    height: calc(var(--responsive) * 103);
  }

  .palette-color-03,
  .palette-color-04,
  .palette-color-05,
  .palette-color-06 {
    height: calc(var(--responsive) * 37);
  }

  .color-text-wrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: calc(var(--responsive) * 10);
    font-weight: 500;
  }

  /* animation */

  .graphics.active .graphics-inner .item-03::before {
    animation: none;
    opacity: 1;
  }

  /*__animation */
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
