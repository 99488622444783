<template>
  <div class="fascia_containers">
      <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <h3 class="content_tit">Center Fascia</h3>
          <div class="content_body">
                <div class="content_01" :class="show0 ? 'active' : ''" style="position: relative;">
                  <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                  <img src="@/assets/img/2019_kia_ces/kiaces_gif_01.gif" alt="image">
                </div>
                <div class="content_02" :class="show1 ? 'active' : ''" style="position: relative;">
                  <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                  <img src="@/assets/img/2019_kia_ces/kiaces_gif_02.gif" alt="image">
                </div>
                <div class="content_03" :class="show2 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <img src="@/assets/img/2019_kia_ces/kiaces_gif_03.gif" alt="image">
                </div>
                <div class="content_04" :class="show3 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                    <img src="@/assets/img/2019_kia_ces/kiaces_gif_04.gif" alt="image">
                </div>
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail7",
  data() {
    return {
        theme: Theme.white,
        show0: false,
        show1: false,
        show2: false,
        show3: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
      isShowObserver1(value: boolean) {
      this.show1 = value;
    },
      isShowObserver2(value: boolean) {
      this.show2 = value;
    },
      isShowObserver3(value: boolean) {
      this.show3 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.fascia_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 300px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.fascia_containers .content_tit {
    margin-bottom: 148px;
}

.fascia_containers .content_body .content_01 {
    width: 100%;
    margin-bottom: 260px;
}

.fascia_containers .content_body .content_02 {
    width: 100%;
    margin-bottom: 146px;
}

.fascia_containers .content_body .content_03 {
    width: 100%;
    margin-bottom: 146px;
}

.fascia_containers .content_body .content_04 {
    width: 100%;
}

/*__Default*/


/* Animation */

.content_01,
.content_02,
.content_03,
.content_04 {
    opacity: 0;
}

.content_01.active,
.content_02.active,
.content_03.active,
.content_04.active {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_wrap {
        max-width: 90%;        
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .fascia_containers .content_tit {
        font-size: 14px;
    }

    .fascia_containers .content_body .content_01 {
        margin-bottom: 208px;
    }

    .fascia_containers .content_body .content_02 {
        margin-bottom: 116px;
    }

    .fascia_containers {
        padding-bottom: 127px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .fascia_containers{
        padding-bottom: 150px;
    }
    .fascia_containers .content_tit {
        margin-bottom: 118px;
    }

    .fascia_containers .content_body .content_01 {
        margin-bottom: 166px;
    }

    .fascia_containers .content_body .content_02 {
        margin-bottom: 93px;
    }

    .fascia_containers {
        padding-bottom: 102px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_wrap {
        max-width: 86.66666vw;     
    }

    .fascia_containers .content_body .content_01 {
        margin-bottom: 66px;
    }

    .fascia_containers .content_body .content_02 {
        margin-bottom: 56px;
    }

    .fascia_containers {
        padding-bottom: 79px;
    }

   .fascia_containers .content_tit {
        font-size: 12px;
        margin-bottom: 90px;
        letter-spacing: -0.27px;
    }
} /*__end*/


/*__반응형*/

</style>
