<template>
 <div class="thumbnail_containers">
     <ThemeObserver :theme=theme />
            <div class="thumbnail_content">
                <div class="thumbnail_text">
                    <div class="thumbnail_tit">
                        <h1>삼성 키보드 이모지</h1>
                    </div>
                    <div class="thumbnail_info">
                        <p>UX Design</p>
                    </div>
                </div>

            </div>
            <div class="thumbnail_img">
                <ul>
                    <li class="msg_1"><img src="@/assets/img/2017_emoji/msg_1.png" alt="image"></li>
                    <li class="msg_2"><img src="@/assets/img/2017_emoji/msg_2.png" alt="image"></li>
                    <li class="msg_3"><img src="@/assets/img/2017_emoji/msg_3.png" alt="image"><img src="@/assets/img/2017_emoji/msg_4.png" alt="image"></li>
                    <li class="msg_4"><img src="@/assets/img/2017_emoji/msg_5.png" alt="image"></li>
                    <li class="msg_5"><img src="@/assets/img/2017_emoji/msg_6.png" alt="image"></li>
                </ul>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
  name: "EmojiDetail0",
  data() {
    return {
      theme: Theme.white
    }
  },
  components: {
    ThemeObserver
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.thumbnail_containers {
    width: 100vw;
    height: 0;
    padding-bottom: 100vh;
    position: relative;
    background-color: #555759;
    content-visibility: auto;
}

.thumbnail_containers .thumbnail_content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 175px;
    animation-name: thum_tit;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
    height: 71px;
    font-size: 48px;
    line-height: normal;
    font-weight: bold;
    color: #fff;
    margin-bottom: 35px;
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
    height: 27px;
    font-size: 18px;
    line-height: normal;
    color: rgba(255, 255, 255, 0.7);
}

.thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
    content: "";
    display: block;
    width: 23px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 6px;
}

/* thumbnail_img */

.thumbnail_img {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 0px;
    max-width: 531px;
    max-height: 689px;
    width: 44.25%;
    height: 57.41666vw;
    background-image: url(../../../assets/img/2017_emoji/iphone.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.thumbnail_img ul {
    position: relative;
    width: 100%;
    height: 100%;
}


.msg_1 {
    position: absolute;
    top: 7.25689%;
    left: 50.4708%;
    max-width: 211px;
    width: 39.7363%;
    opacity: 0;
    animation-name: msg_ani_01;
    animation-delay: .5s;
    animation-duration: 1s;
    animation-fill-mode: both;

}

.msg_2 {
    position: absolute;
    top: 21.62554%;
    left: 8.47457%;
    max-width: 336px;
    width: 63.27683%;
    opacity: 0;
    animation-name: msg_ani_02;
    animation-delay: 1.5s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.msg_3 img:first-child {
    position: absolute;
    top: 33.09143%;
    left: 58.94538%;
    max-width: 291px;
    width: 54.80225%;
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.28);
    border-radius: 25px;
    opacity: 0;
    animation-name: msg_ani_03;
    animation-delay: 2.5s;
    animation-duration: 1s;
    animation-fill-mode: both;

}

.msg_3 img:last-child {
    position: absolute;
    top: 39.91291%;
    left: -13.18267%;
    max-width: 336px;
    width: 63.27683%;
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.17);
    border-radius: 25px;
    animation-name: msg_ani_04;
    animation-delay: 3.5s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.msg_4 {
    position: absolute;
    top: 61.103047%;
    left: 24.85875%;
    max-width: 347px;
    width: 65.34839%;
    animation-name: msg_ani_05;
    animation-delay: 4.5s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.msg_5 {
    position: absolute;
    bottom: 11.32075%;
    left: 14.12429%;
    max-width: 118px;
    width: 22.22222%;
    clip: rect(0px, 0, 30px, 0);
    animation-name: msg_ani_06;
    animation-delay: 5.5s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

/*애니메이션 키프레임*/

@-webkit-keyframes thum_tit {

    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


@-webkit-keyframes msg_ani_01 {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes msg_ani_02 {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes msg_ani_03 {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes msg_ani_04 {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes msg_ani_05 {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@-webkit-keyframes msg_ani_06 {

    0% {
        clip: rect(0, 0, 30px, 0);
    }

    100% {
        clip: rect(0, 118px, 30px, 0);
    }
}

/*--애니메이션 키프레임*/

/*__Default*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        font-size: 40px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        font-size: 16px;
    }

    .thumbnail_img {
        min-width: 453px;
        min-height: 588px;
    }

} /*__end*/

@media screen and (max-width:630px) {
    .thumbnail_img {
        min-width: 380px;
        min-height: 493px;
    }

    .msg_3 img:first-child,
    .msg_3 img:last-child {
        border-radius: 13px;
    }

} /*__end*/

@media screen and (max-width:530px) {
    .thumbnail_img {
        min-width: 330px;
        min-height: 428px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .thumbnail_containers > .thumbnail_content {
        max-width: 86.66666vw;
    }

    .thumbnail_containers .thumbnail_content {
        padding-top: 84px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_tit {
        height: 36px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 47px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info {
        height: 27px;
        font-size: 18px;
    }

    .thumbnail_containers .thumbnail_content .thumbnail_text .thumbnail_info::before {
        width: 9px;
    }

    .thumbnail_img {
        min-width: 246px;
        min-height: 319px;
    }

    .msg_3 img:first-child,
    .msg_3 img:last-child {
        border-radius: 10px;
    }

     /*애니메이션 키프레임*/

    @-webkit-keyframes msg_ani_01 {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @-webkit-keyframes msg_ani_02 {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @-webkit-keyframes msg_ani_03 {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @-webkit-keyframes msg_ani_04 {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @-webkit-keyframes msg_ani_05 {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }


    @-webkit-keyframes msg_ani_06 {
        0% {
            clip: rect(0, 0, 30px, 0);
        }
        100% {
            clip: rect(0, 56px, 30px, 0);
        }
    }


    /*--애니메이션 키프레임*/

} /*__end*/

    @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
    screen and (max-height:980px) and (device-aspect-ratio:16/10), {

        .thumbnail_img {
            max-width: 477.9px;
            max-height: 620.1px;
        }
        
    }

    @media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
    screen and (max-height:800px) and (device-aspect-ratio:16/10), {

        .thumbnail_img {
            max-width: 424.8px;
            max-height: 551.2px;
        }
        
    }


/*__반응형*/

</style>
