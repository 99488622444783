<template>
<div class="uiuxmain_containers">
   
  <div class="uiuxmain_sub_text numbering">
    <div class="sub_text_left">UI/UX_MAIN</div>
    <div class="sub_text_right">06</div>
  </div>
  <div class="uiuxmain_content content_wrap">
    <!-- wrap --> 
    <div class="tit_text" :class="show0 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
      <h3>Be Simple <br class="active_1024w">and More Sustainable</h3>
    </div>
    <div class="body_text" :class="show1 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
      <p>브랜드의 가치가 직접적으로 <br class="active_425w">전해지도록 디지털 컨설팅을 진행했습니다.<br>
      사이트는 군더더기를 덜어내 미니멀하게, 제품위주의 <br class="active_425w">사진을 활용한 타이포그래피를 적용했습니다.<br class="active_425w"><br
        class="inactive_768w">
      지속가능한 가치를 추구하듯, 유행이나 멋에 <br class="active_425w">치중하지 않은 디자인 입니다.</p></div>
    <div class="uiuxmain_img" :class="show2 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxmain_01.png" alt="image">
    </div>
    <div class="uiuxmain_img" :class="show3 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver3" class="observer_exception"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxmain_02.jpg" alt="image">
    </div>
    <div class="uiuxmain_img" :class="show4 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxmain_03.jpg" alt="image">
    </div>
    <div class="uiuxmain_img" :class="show5 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver5" class="observer_exception"></ShowObserver>
      <img src="@/assets/img/2021_ccb/uiuxmain_04.png" alt="image" class="inactive_1024w">
      <img src="@/assets/img/2021_ccb/m_uiuxmain_04.png" alt="image" class="active_1024w">
    </div>
  </div><!-- //wrap -->
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail3",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    color: #393531;
}
/*__ALl*/

.uiuxmain_containers {
    background-color: #f1f1f1;
    padding-bottom: 191px;
    content-visibility: auto;
}

.uiuxmain_containers .uiuxmain_content {
    margin-top: 59px;
    text-align: center;
}

.uiuxmain_containers .uiuxmain_content > .tit_text {
    height: 75px;
    line-height: normal;
    margin-bottom: 52px;
}

.uiuxmain_containers .uiuxmain_content > .body_text {
    height: 72px;
    line-height: normal;
    letter-spacing: -0.1px;
    margin: 0 auto;
    margin-bottom: 80px;
}

.uiuxmain_containers .uiuxmain_content div:nth-child(3),
.uiuxmain_content div:nth-child(4) {
    margin-bottom: 46px;
}

.uiuxmain_containers .uiuxmain_content div:nth-child(5) {
    margin-bottom: 180px;
}

/*__Default*/


/* Animation */

.uiuxmain_containers .tit_text.active,
.uiuxmain_containers .body_text.active,
.uiuxmain_containers .uiuxmain_img.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.uiuxmain_containers .tit_text,
.uiuxmain_containers .body_text,
.uiuxmain_containers .uiuxmain_img {
    opacity: 0;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .uiuxmain_containers {
        padding-bottom: 25%;
    }

    .uiuxmain_containers .uiuxmain_content {
        margin-top: 13.7820%;
    }

    .uiuxmain_containers .uiuxmain_content > .tit_text {
        height: auto;
        margin-bottom: 7.6923%;
    }

    .uiuxmain_containers .uiuxmain_content > .body_text {
        height: auto;
        margin-bottom: 12.8205%;
    }

    .uiuxmain_containers .uiuxmain_content div:nth-child(3),
    .uiuxmain_content div:nth-child(4) {
        margin-bottom: 4.48717%;
    }

    .uiuxmain_containers .uiuxmain_content div:nth-child(5) {
        margin-bottom: 25%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .uiuxmain_containers .uiuxmain_content > .body_text {
        max-width: 570px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

} /*__end*/


/*__반응형*/

</style>
