<template>
    <div class="containers_04" style="position: relative;">
    <ThemeObserver :theme=theme />  
      <div class="content_wrap">
        <div class="text_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
           <div class="tit_box">
             <h3 class="common_tit">Design System</h3>
             <div class="roboto">Roboto</div>
           </div>
           <div class="text_box">
             <h3 class="common_tit">Typography</h3>
             <div class="body_txt">
               디바이스 기반의 GUI인 만큼 높은 가독성을 위해<br class="inactive_768w">
               X-height이 높은 Roboto 서체를 활용했습니다.
             </div>
           </div>
        </div>
        <div class="item_wrap">
          <div class="item_01" :class="show1 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>  
              <div class="common_txt_01">
                Roboto<br>
                Medium
              </div>
              <div class="common_txt_02">
                Number
              </div>
              <div class="item_body">
                <span class="t_01">Power <span class="t_j">(J)</span></span>
                <span class="t_02">0.5</span>
              </div>
          </div>
          <div class="item_02" :class="show2 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>  
              <div class="common_txt_01">
                Roboto<br>
                Regular
              </div>
              <div class="common_txt_02">
                Title/button
              </div>
              <div class="item_body">
                Are You Sure<br>
                Want to Reset?<br>
                Are You Sure<br>
                Want to Delete?
              </div>
          </div>
          <div class="item_03" :class="show3 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>  
              <div class="common_txt_01">
                Roboto<br>
                Regular Italic
              </div>
              <div class="common_txt_02">
                System Error
              </div>
              <div class="item_body">
                System error - EC
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail04.vue",
    data() {
      return {
          show0: false,
          show1: false,
          show2: false,
          show3: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
        isShowObserver1(value: boolean) {
        this.show1 = value;
      },
        isShowObserver2(value: boolean) {
        this.show2 = value;
      },
        isShowObserver3(value: boolean) {
        this.show3 = value;
      },                  
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.containers_04 {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background: #101010;
}

.containers_04 .content_wrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.containers_04 .text_wrap {
  width: 100%;
  display: flex;
  margin: 70px 0 187px 0;
}

.text_wrap .tit_box {
  width: 50%;
}

.text_wrap .text_box {
  width: 50%;
}

.text_wrap .common_tit {
  font-size: 14px;
  margin-bottom: 177px;
  color: rgba(255, 255, 255, 0.6);
}

.text_wrap .roboto {
  font-size: 84px;
  letter-spacing: -1.84px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(108deg, #f5fafc, #b0b5b9 114%);
}

.text_wrap .body_txt {
  padding: 20px 0;
  font-size: 18px;
  font-family: 'SECRobotoLight', serif;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #fff;
  
}

.item_wrap {
  width: 100%;
  display: flex;
}

.item_wrap [class^="item_0"] {
  position: relative;
  width: 32%;
  height: 0;
  padding-bottom: 50.91666%;
  background: #fff;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0;
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
}

.item_wrap .item_02 {
  margin: 0 2% 0 2%;
}

.item_wrap .common_txt_01 {
  position: absolute;
  top: 8.85416%;
  left: 7.55208%;
  /* padding: 8.85416% 0 0 7.55208%; */
}

.item_wrap .common_txt_02 {
  text-align: right;
  /* padding: 8.85416% 7.55208% 0 0; */
  position: absolute;
  top: 8.85416%;
  right: 7.55208%;

}

.item_wrap .item_01 .common_txt_01,
.item_wrap .item_01 .common_txt_02 {
  font-size: 16px;
  font-weight: 500;
  color: #2a4064;
}

.item_wrap .item_02 .common_txt_01,
.item_wrap .item_02 .common_txt_02 {
  font-size: 16px;
  color: #2a4064;
}

.item_wrap .item_03 .common_txt_01,
.item_wrap .item_03 .common_txt_02 {
  font-size: 16px;
  font-style: italic;
  color: #2a4064;
}

.item_wrap .item_01 .item_body {
  position: absolute;
  width: 100%;
  bottom: 5%;
  left: 7.55208%;
  display: flex;
  flex-direction: column;
}

.item_wrap .item_01 .item_body .t_01 {
  font-size: 40px;
  letter-spacing: -1.8px;
  background-image: linear-gradient(115deg, #77dae6 -16%, #34b4d4 107%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-bottom: 6px;
}

.item_wrap .item_01 .item_body .t_01 .t_j {
  font-size: 30px;
  margin-left: 7px;
}

.item_wrap .item_01 .item_body .t_02 {
  font-size: 120px;
  font-weight: 500;
  background-image: linear-gradient(130deg, #77dae6 -16%, #34b4d4 107%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
}

.item_wrap .item_02 .item_body {
  position: absolute;
  width: 100%;
  bottom: 6.25%;
  left: 7.291666%;
  font-size: 40px;
  line-height: normal;
  letter-spacing: -1px;
  background-image: linear-gradient(121deg, #77dae6 -16%, #34b4d4 107%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item_wrap .item_03 .item_body {
  position: absolute;
  width: 100%;
  bottom: 6.25%;
  left: 7.55208%;
  font-size: 40px;
  line-height: normal;
  letter-spacing: -1px;
  background-image: linear-gradient(121deg, #77dae6 -16%, #34b4d4 107%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}



/*__Default*/


/* Animation */

.text_wrap.active .roboto {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 100ms;
  transition-property: opacity;
}

.text_wrap.active .body_txt {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.item_01.active [class^="common_txt_"],
.item_01.active .item_body {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 200ms;
  transition-property: opacity;
}

.item_02.active [class^="common_txt_"],
.item_02.active .item_body {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.item_03.active [class^="common_txt_"],
.item_03.active .item_body {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 600ms;
  transition-property: opacity;
}

.item_01 [class^="common_txt_"],
.item_02 [class^="common_txt_"],
.item_03 [class^="common_txt_"],
.item_01 .item_body,
.item_02 .item_body,
.item_03 .item_body {
  opacity: 0;
}


.containers_04 .roboto,
.containers_04 .body_txt {
  opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
.containers_04 .content_wrap {
  max-width: 90%;
}

.text_wrap .roboto {
  font-size: 67px;
}

.text_wrap .body_txt {
  font-size: 16px;
}

/* font_system */

.item_wrap .item_01 .item_body .t_01 {
  font-size: 32px;
}

.item_wrap .item_01 .item_body .t_02 {
  font-size: 96px;
}

.item_wrap .item_02 .item_body {
  font-size: 32px;
}

.item_wrap .item_03 .item_body {
  font-size: 32px;
}



} /*__end*/

@media screen and (max-width:1024px) {

.text_wrap .roboto {
  font-size: 54px;
}

.text_wrap .body_txt {
  padding: 0;
}

/* font_system */

.item_wrap .item_01 .common_txt_01,
.item_wrap .item_01 .common_txt_02,
.item_wrap .item_02 .common_txt_01,
.item_wrap .item_02 .common_txt_02,
.item_wrap .item_03 .common_txt_01,
.item_wrap .item_03 .common_txt_02 {
  font-size: 14px;
}

.item_wrap .item_01 .item_body .t_01 {
  font-size: 26px;
}

.item_wrap .item_01 .item_body .t_02 {
  font-size: 77px;
}

.item_wrap .item_02 .item_body {
  font-size: 26px;
}

.item_wrap .item_03 .item_body {
  font-size: 26px;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_04 .text_wrap {
  flex-direction: column;
  margin: 56px 0 150px 0;
}

.text_wrap .common_tit {
  margin-bottom: 25px;
}

.text_wrap .roboto {
  font-size: 43px;
}

.text_wrap .body_txt {
  font-size: 13px;
}

.text_wrap .tit_box {
  width: 100%;
  margin-bottom: 53px;
}

.text_wrap .text_box {
  width: 100%;
}

/* font_system */

.item_wrap {
  flex-direction: column;
  align-items: center;
}

.item_wrap [class^="item_0"] {
  width: 99.03846%;
  padding-bottom: 138.461538%;
}

.item_wrap .item_02 {
  margin: 5.76923% 0;
}

.item_wrap .item_01 .item_body .t_01 {
  font-size: 40px;
}

.item_wrap .item_01 .item_body .t_02 {
  font-size: 120px;
}

.item_wrap .item_02 .item_body {
  font-size: 40px;
}

.item_wrap .item_03 .item_body {
  font-size: 40px;
}

/* ani */

.item_02.active [class^="common_txt_"],
.item_02.active .item_body {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 200ms;
  transition-property: opacity;
}

.item_03.active [class^="common_txt_"],
.item_03.active .item_body {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 200ms;
  transition-property: opacity;
}

} /*__end*/


@media screen and (max-width:425px) {

.containers_04 .content_wrap {
  max-width: 86.66666vw;
}

.containers_04 .text_wrap {
  margin: 32px 0 78px 0;
}

.text_wrap .common_tit {
  font-size: 10px;
  margin-bottom: 20px;
}

.text_wrap .roboto {
  font-size: 36px;
}


.text_wrap .tit_box {
  margin-bottom: 42px;
}

.item_wrap .item_01 .common_txt_01,
.item_wrap .item_01 .common_txt_02,
.item_wrap .item_02 .common_txt_01,
.item_wrap .item_02 .common_txt_02,
.item_wrap .item_03 .common_txt_01,
.item_wrap .item_03 .common_txt_02 {
  font-size: 14px;
}

.item_wrap .item_01 .item_body .t_01 {
  font-size: 26px;
  letter-spacing: -1.17px;
}

.item_wrap .item_01 .item_body .t_02 {
  font-size: 80px;
}

.item_wrap .item_02 .item_body {
  font-size: 32px;
  line-height: 1.22;
  letter-spacing: -1.44px;
}

.item_wrap .item_03 .item_body {
  font-size: 32px;
  letter-spacing: -1.44px;
}

} /*__end*/


/*__반응형*/

</style>
