<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="overview"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <h3>Project Overview</h3>
        <span
          >기존 Samsung Keyboard Emoji의 Face를 활용한<br class="active_820w" />
          Seasonal Emoji와 2.5D Emoji를 제작했습니다</span
        >
      </div>
      <div
        class="graphics"
        :class="show1 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="graphics-01">
          <img
            class="inactive_820w"
            src="@/assets/img/2021_emoji/graphics-01.svg"
            alt="image"
          />
          <img
            class="active_820w"
            src="@/assets/img/2021_emoji/graphics-01-m.webp"
            alt="image"
          />
          <span class="arrow-01"></span>
        </div>
        <div class="graphics-02">
          <img
            class="inactive_820w"
            src="@/assets/img/2021_emoji/graphics-02.svg"
            alt="image"
          />
          <img
            class="active_820w"
            src="@/assets/img/2021_emoji/graphics-02-m.webp"
            alt="image"
          />
          <span class="arrow-02"></span>
        </div>
        <div class="graphics-03">
          <img
            class="inactive_820w"
            src="@/assets/img/2021_emoji/graphics-03.svg"
            alt="image"
          />
          <img
            class="active_820w"
            src="@/assets/img/2021_emoji/graphics-03-m.webp"
            alt="image"
          />
        </div>
      </div>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail1",
  data() {
    return {
      show0: false,
      show1: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  width: 100vw;
  position: relative;
  background: #fff;
  font-family: "Poppins", sans-serif;
}

.container-inner {
  width: 63.33333%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* overview */

.overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--responsive) * 230);
  margin-bottom: calc(var(--responsive) * 120);
}

.overview h3 {
  font-size: calc(var(--responsive) * 40);
  font-weight: 600;
  line-height: 1.325em;
  margin-bottom: 1em;
}

.overview span {
  font-family: "NanumSquare", serif;
  font-size: calc(var(--responsive) * 16);
  font-weight: 400;
}

/* graphics */

.graphics {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphics-01 {
  position: relative;
  width: calc(var(--responsive) * 160);
  margin-right: calc(var(--responsive) * 107);
}

.arrow-01 {
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: 100%;
  width: calc(var(--responsive) * 80);
  height: 2px;
  background: #ffb21b;
}

.arrow-01::before {
  content: "";
  position: absolute;
  top: -10.5px;
  left: calc(100% - 5.5px);
  width: 2px;
  height: 14px;
  transform: rotate(-45deg);
  background: #ffb21b;
}

.graphics-02 {
  position: relative;
  width: calc(var(--responsive) * 585);
  margin-right: calc(var(--responsive) * 108);
}

.arrow-02 {
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(100% - calc(var(--responsive) * 57));
  width: calc(var(--responsive) * 137);
  height: 2px;
  background: #1686e1;
}

.arrow-02::before {
  content: "";
  position: absolute;
  top: -10.5px;
  left: calc(100% - 5.5px);
  width: 2px;
  height: 14px;
  transform: rotate(-45deg);
  background: #1686e1;
}

.graphics-03 {
  width: calc(var(--responsive) * 256);
}

/*__Default*/

/* Animation */
.overview,
.graphics .graphics-01,
.graphics .graphics-02,
.graphics .graphics-03 {
  opacity: 0;
}

.overview.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.graphics.active .graphics-01 {
  animation: default-ani 800ms ease-in-out 1 forwards;
  animation-delay: 200ms;
}

.graphics.active .graphics-02 {
  animation: default-ani 800ms ease-in-out 1 forwards;
  animation-delay: 350ms;
}

.graphics.active .graphics-03 {
  animation: default-ani 800ms ease-in-out 1 forwards;
  animation-delay: 500ms;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
  .arrow-01,
  .arrow-02 {
    height: 1px;
  }

  .arrow-01::before,
  .arrow-02::before {
    width: 1px;
    top: -11px;
    left: calc(100% - 5px);
  }
} /*__end*/

@media screen and (max-width: 1200px) {
  .arrow-02 {
    left: calc(100% - 2.96875vw);
  }
} /*__end*/

@media screen and (max-width: 820px) {
  .container-inner {
    width: 88.88888%;
  }

  .overview {
    margin-top: 22.22222vw;
    margin-bottom: 13.8888vw;
  }

  .overview h3 {
    font-size: 6.6666vw;
    margin-bottom: 0.833em;
  }

  .overview span {
    font-size: 3.8888vw;
    line-height: 1.57em;
    text-align: center;
  }

  .graphics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .graphics-01 {
    width: 26.1111vw;
    margin-right: initial;
    margin-bottom: 15.5555vw;
  }

  .graphics-02 {
    width: 88.8888vw;
    margin-right: initial;
    margin-bottom: 16.94444vw;
  }

  .graphics-03 {
    width: 43.3333vw;
  }

  .arrow-01 {
    display: block;
    position: absolute;
    top: initial;
    left: calc(50% - 3px);
    width: 3px;
    height: 10.31707vw;
  }

  .arrow-01::before {
    width: 3px;
    height: 20px;
    top: initial;
    bottom: -1.5px;
    left: 5.5px;
    transform: rotate(35deg);
  }

  .arrow-02 {
    display: block;
    position: absolute;
    top: initial;
    bottom: calc(-17.0731vw + 6vw);
    left: calc(50% - 1.5px);
    width: 3px;
    height: 17.0731vw;
  }

  .arrow-02::before {
    width: 3px;
    height: 20px;
    top: initial;
    bottom: -1.5px;
    left: 5.5px;
    transform: rotate(35deg);
  }
} /*__end*/

@media screen and (max-width: 425px) {
  .arrow-01,
  .arrow-02 {
    left: calc(50% - 1.5px);
    width: 1.5px;
  }

  .arrow-01::before,
  .arrow-02::before {
    width: 1.5px;
    height: 10px;
    left: 2.5px;
  }
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
