<template>
  <div class="container">
    <div class="container-inner">
      <div class="phone-01" :class="show0 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section02-phone01.webp"
          alt="image"
        />
        <div class="graphics-02" :class="show4 ? 'active' : ''">
          <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
          <img
            src="@/assets/img/2021_KBbank/section02-graphics02.webp"
            alt="image"
          />
        </div>
      </div>
      <div class="phone-02" :class="show1 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section02-phone02.webp"
          alt="image"
        />
      </div>
      <div class="phone-03" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section02-phone03.webp"
          alt="image"
        />
      </div>
      <div class="graphics-01" :class="show3 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section02-graphics01.webp"
          alt="image"
        />
      </div>
      <div class="graphics-03" :class="show5 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section02-graphics03.webp"
          alt="image"
        />
      </div>
      <div class="visual-label" :class="show6 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver6"></ShowObserver>
        <b>KB Star Banking</b>KB스타뱅킹 화면 리뉴얼
      </div>
    </div>
    <!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "KookminBankDetail2",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
    isShowObserver6(value: boolean) {
      this.show6 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 2240);
  position: relative;
  font-family: var(--pretendard);
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    #fff,
    rgba(255, 255, 255, 0.8) 13%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.8) 90%,
    #fff
  );
  z-index: -1;
}

.container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/2021_KBbank/section02-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -2;
}

.container-inner {
  position: relative;
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(var(--responsive) * 100);
}

/* graphics */

.graphics-01,
.graphics-02,
.graphics-03 {
  position: absolute;
  display: flex;
}

.graphics-01 {
  width: calc(var(--responsive) * 302);
  height: calc(var(--responsive) * 247.8);
  top: calc(var(--responsive) * 313);
  left: calc(var(--responsive) * 409);
}

.graphics-02 {
  width: calc(var(--responsive) * 277);
  height: calc(var(--responsive) * 266);
  top: calc(var(--responsive) * 142);
  left: calc(var(--responsive) * 46);
  border-radius: 0.1em;
}

.graphics-02::after {
  content: "";
  position: absolute;
  top: 25px;
  left: 10px;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/2021_KBbank/section02-graphics02.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  filter: blur(12px);
  opacity: 0;
  transition: 600ms ease-in-out 800ms;
  transition-property: opacity;
}

.graphics-03 {
  width: calc(var(--responsive) * 286);
  height: calc(var(--responsive) * 198);
  top: calc(var(--responsive) * 1016);
  right: 0;
}

/* phone */

.phone-01,
.phone-02,
.phone-03 {
  position: absolute;
  width: calc(var(--responsive) * 300);
  height: calc(var(--responsive) * 620);
  border-radius: 0.42em;
  box-shadow: -0.07em 0.15em 0.16em rgba(0, 0, 0, 0.15);
}

.phone-01 {
  top: calc(var(--responsive) * 473);
  left: calc(var(--responsive) * 620);
}

.phone-02 {
  top: calc(var(--responsive) * 803.75);
  left: calc(var(--responsive) * 281);
}

.phone-03 {
  top: calc(var(--responsive) * 1155.48);
  left: calc(var(--responsive) * 620);
}

/* label */

.visual-label {
  position: absolute;
  top: calc(var(--responsive) * 1481);
  left: 0;
  font-size: calc(var(--responsive) * 16);
}

.visual-label b {
  font-size: 1.5em;
  font-weight: 700;
  margin-right: 0.667em;
}

/*__Default*/

/* Animation */

.graphics-01,
.graphics-03,
.phone-01,
.phone-02,
.phone-03,
.visual-label {
  opacity: 0;
}

.graphics-01.active,
.graphics-03.active,
.phone-01.active,
.phone-02.active,
.phone-03.active,
.visual-label.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.graphics-02 {
  transform: scale(0.85) translate3d(-15%, -7%, 0);
}

.graphics-02.active {
  animation: ani02 600ms ease-in-out 800ms 1 forwards;
}

.graphics-02.active::after {
  opacity: 0.2;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    transform: scale(0.85) translate3d(-15%, -7%, 0);
  }
  100% {
    transform: scale(1) translate3d(0, 0, 0);
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container {
    height: calc(var(--responsive) * 1168);
  }

  .container-inner {
    width: calc(var(--responsive) * 312);
  }

  .container::after {
    background-position-x: 55%;
    background-size: auto 80%;
  }

  /* graphics */

  .graphics-01 {
    width: calc(var(--responsive) * 147.29);
    height: calc(var(--responsive) * 120.98);
    top: calc(var(--responsive) * 118);
    left: calc(var(--responsive) * 67);
  }

  .graphics-02 {
    width: calc(var(--responsive) * 135);
    height: calc(var(--responsive) * 129);
    top: calc(var(--responsive) * 70);
    left: initial;
    right: calc(var(--responsive) * -8);
  }

  .graphics-02::after {
    top: 9px;
    left: 6px;
    filter: blur(12px);
  }

  .graphics-03 {
    width: calc(var(--responsive) * 139);
    height: calc(var(--responsive) * 97);
    top: calc(var(--responsive) * 556);
    left: calc(var(--responsive) * 172);
  }

  /* phone */

  .phone-01,
  .phone-02,
  .phone-03 {
    width: calc(var(--responsive) * 147);
    height: calc(var(--responsive) * 303);
  }

  .phone-01 {
    left: initial;
    right: 0;
    top: calc(var(--responsive) * 211);
  }

  .phone-02 {
    left: 0;
    top: calc(var(--responsive) * 458);
  }

  .phone-03 {
    left: initial;
    right: 0;
    top: calc(var(--responsive) * 695);
  }

  /* label */

  .visual-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    top: calc(var(--responsive) * 1030);
    font-size: calc(var(--responsive) * 12);
    text-align: center;
  }

  .visual-label b {
    margin-right: initial;
    font-size: 1.167em;
    margin-bottom: 0.571em;
  }

  /* animation */

  .graphics-02 {
    transform: scale(0.85) translate3d(-12%, -7%, 0);
  }

  /*__animation */

  @keyframes ani02 {
    0% {
      transform: scale(0.85) translate3d(-12%, -7%, 0);
    }
    100% {
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
