<template>
<div class="logo_containers">
            <div class="logo_content content_wrap">
                <div class="logo_title_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                    <div class="content_title">logo_Design</div>
                    <div class="content_body">사람을 일으켜 바로 세움을 표현</div>
                </div>
                <div class="logo_item01" :class="show1 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                    <div class="logo_left"><img src="@/assets/img/2020_baro/logoimg_01.png" alt="image"></div>
                    <div class="logo_right"><img src="@/assets/img/2020_baro/logoimg_02.png" alt="image"></div>
                </div>
                <div class="logo_item02" :class="show2 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <div class="logo_graphic">
                        <h3>Graphic</h3>
                        <img src="@/assets/img/2020_baro/logoimg_03.png" alt="image">
                        <p>바로 일어섰을 때의 다리모양과<br>사람의 모습을 합성</p>
                    </div>
                    <div class="logo_color">
                        <h3>Color</h3>
                        <img src="@/assets/img/2020_baro/logoimg_04.png" alt="image">
                        <p>행복과 긍정을 상징하는 노랑,<br>신뢰와 안정을 상징하는 파랑계열을 사용</p>
                    </div>
                    <div class="logo_font">
                        <h3>Font</h3>
                        <img src="@/assets/img/2020_baro/logoimg_05.png" alt="image">
                        <p>둥근 원형느낌을 살릴 수 있는<br>폰트 사용</p>
                    </div>
                </div>
            </div>
            <div class="logo_img_bg"></div>
            <div class="logo_img_wrap">
              <div :class="show3 ? 'active' : ''" style="position: relative;"><ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                  <div class="logo_img_01"><img src="@/assets/img/2020_baro/logoimg_07.jpg" alt="image">
                  </div>
              </div>
              <div :class="show4 ? 'active' : ''" style="position: relative;"><ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                 <div class="logo_img_02"> <img src="@/assets/img/2020_baro/logoimg_08.jpg" alt="image">
                 </div>
              </div>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.logo_containers {
    width: 100%;
    margin: 194px 0 422px 0;
}

.logo_title_wrap .content_title {
    margin-bottom: 32px;
}

.logo_title_wrap {
   margin-bottom: 138px; 
}

.logo_containers .logo_content {
    display: flex;
    flex-direction: column;
}

.logo_content .logo_item01 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 225px;
    margin-left: 4.91666%;
}

.logo_item01 .logo_left {
    width: 18.91666%;
}

.logo_item01 .logo_right {
    width: 23.75%;
    margin-left: 18.08333%;
}

.logo_content .logo_item02 {
    display: flex;
    justify-content: center;
    margin-bottom: 309px;
}

.logo_content .logo_item02 [class^="logo_"] {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3f4144;
    text-align: center;
    line-height: normal;
    justify-content: center;
}

.logo_content .logo_item02 [class^="logo_"] h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    margin-bottom: 52px;    
}

.logo_content .logo_item02 [class^="logo_"] img {
    width: 240px;
    margin-bottom: 37px;
}


.logo_content .logo_item02 [class^="logo_"] p {
    font-size: 13px;
}

.logo_content .logo_color {
    margin-left: 112px;
    padding-left: 97px;
}

.logo_content .logo_color::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 107px;
    background-color: #c3c3c3;
}

.logo_content .logo_font {
    margin-left: 95px;
    padding-left: 48px;
}

.logo_content .logo_font::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 107px;
    background-color: #c3c3c3;
}

 .logo_containers .logo_img_bg {
    width: 100vw;
    height: 0;
    padding-bottom: 46.40625%;
    background-image: url(../../../assets/img/2020_baro/logoimg_06.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.logo_containers .logo_img_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.logo_img_wrap [class^="logo_img_"] {
    width: 50%;
}

.logo_img_wrap .logo_img_01 {
    margin: 16.16666% auto 4.91666% 13.6666%;
}

.logo_img_wrap .logo_img_02 {
    margin: 0 8.58333% 35.16666% auto;
}

/*__Default*/


/* Animation */

.logo_title_wrap.active .content_title,
.logo_title_wrap.active .content_body  {
    opacity: 1;
    transition-duration: .8s;
}

.logo_title_wrap .content_title,
.logo_title_wrap .content_body {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.logo_item01.active {
    opacity: 1;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.logo_item01 {
    opacity: 0;
}

.logo_item02.active {
    opacity: 1;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.logo_item02 {
    opacity: 0;
}

.logo_img_wrap .active .logo_img_01 {
    opacity: 1;
    transform: translateX(0);
}

.logo_img_wrap .logo_img_01 {
    opacity: 0;
    transform: translateX(-10vw);
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

.logo_img_wrap .active .logo_img_02 {
    opacity: 1;
    transform: translateX(0);
}

.logo_img_wrap .logo_img_02 {
    opacity: 0;
    transform: translateX(10vw);
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

    .logo_content .logo_color {
        margin-left: 9.33333%;
        padding-left: 8.08333%;
    }

    .logo_content .logo_font {
        margin-left: 7.91666%;
        padding-left: 4%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

   .logo_content .logo_color {
       margin-left: calc(9.33333% - 20px);
       padding-left: calc(8.08333% - 20px);
   }

   .logo_content .logo_font {
       margin-left: calc(7.91666% - 20px);
       padding-left: calc(4% - 20px);
   }

} /*__end*/

@media screen and (max-width:768px) {
    .logo_content .logo_item01 {
        flex-direction: column;
        margin-left: 0;
        align-items: center;
    }

    .logo_item01 .logo_left {
        width: 51.28205%;
        margin-bottom: 76px;
    }

    .logo_item01 .logo_right {
        width: 64.1025%;
    }

    .logo_item01 .logo_right {
        margin-left: 0;;
    }

    .logo_content .logo_item02 {
        flex-direction: column;
    }

    .logo_content .logo_color {
        margin-left: 0;
        padding-left: 0;
    }

    .logo_content .logo_font {
        margin-left: 0;
        padding-left: 0;
    }

    .logo_content .logo_item02 [class^="logo_"]:not(.logo_font) {
        margin-bottom: 129px;
    }

    .logo_content .logo_graphic::before {
        content: "";
        display: block;
        position: absolute;
        left: inherit;
        top: -64.5px;
        width: 34.2948%;
        height: 1px;
        background-color: #c3c3c3;
    }

    .logo_content .logo_color::before {
        left: inherit;
        top: -64.5px;
        width: 34.2948%;
        height: 1px;
    }

    .logo_content .logo_font::before {
        left: inherit;
        top: -64.5px;
        width: 34.2948%;
        height: 1px;
    }

    .logo_containers .logo_img_bg {
        padding-bottom: 154.7222%;
        background-position: center;
    }

    .logo_img_wrap [class^="logo_img_"] {
        width: 100%;
    }

    .logo_img_wrap .logo_img_01 {
        margin: 0;
        margin-top: 27px;
    }

    .logo_img_wrap .logo_img_02 {
        margin: 0;
        margin-top: 34px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .logo_containers {
        margin: 70px 0 136px 0;
    }

    .logo_content .logo_item02 {
        margin-bottom: 95px;
    }

} /*__end*/


/*__반응형*/

</style>