<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="event-page"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="label-01">
          <b>KB Event Page</b>KB인터넷 뱅킹, 기업뱅킹 등의 이벤트 페이지
          유지보수
        </div>
        <div class="looping-wrap">
          <div class="looping-img"></div>
        </div>
      </div>
      <div class="footer">
        <div
          class="footer-inner"
          :class="show1 ? 'active' : ''"
          style="position: relative"
        >
          <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
          <div class="copy">
            <p>&copy; wacky Company</p>
          </div>
          <div class="info">
            <ul>
              <li class="period">
                <span class="label">Period</span>
                <span class="text">2021</span>
              </li>
              <li class="client">
                <span class="label">Client</span>
                <span class="text">KB</span>
              </li>
              <li class="project">
                <span class="label">Project</span>
                <span class="text">App UIUX</span>
                <span class="text">Mobile Web UIUX</span>
              </li>
              <li class="resources">
                <span class="label">Resources</span>
                <span class="text">
                  <a
                    href="https://www.freepik.com/vectors/design"
                    target="_blink"
                    >macrovector</a
                  >
                </span>
                <span class="text">
                  <a
                    href="https://www.freepik.com/vectors/infographic"
                    target="_blink"
                    >fullvector</a
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "KookminBankDetail5",
  data() {
    return {
      show0: false,
      show1: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  /* position: relative; */
  width: 100vw;
  background: #fff;
  font-family: var(--pretendard);
}

.event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #545045;
}

.label-01 {
  color: #fff;
  width: calc(var(--responsive) * 1200);
  font-size: calc(var(--responsive) * 16);
  font-weight: 400;
  line-height: 1.188em;
  margin-top: calc(var(--responsive) * 97);
  margin-bottom: calc(var(--responsive) * 50);
}

.label-01 b {
  font-size: calc(var(--responsive) * 24);
  font-weight: 700;
  line-height: 1.75em;
  margin-right: 0.667em;
}

.looping-wrap {
  width: 100vw;
  height: calc(var(--responsive) * 314);
  overflow: hidden;
}

.looping-img {
  width: 100vw;
  height: calc(var(--responsive) * 348);
  margin-top: calc(var(--responsive) * -6);
  white-space: nowrap;
  background-image: url(../../../assets/img/2021_KBbank/section05-bg.webp);
  background-position: 0 center;
  background-size: auto 100%;
  background-repeat: repeat-x;
  animation: looping 24000ms linear infinite;
}

@keyframes looping {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: calc(var(--responsive) * -2804.94) center;
  }
}

/* footer */

.footer {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  width: calc(var(--responsive) * 1200);
  margin-top: calc(var(--responsive) * 336);
  margin-bottom: calc(var(--responsive) * 61);
}

.footer-inner ul {
  display: flex;
}

.footer-inner ul li {
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--responsive) * 64);
  font-size: calc(var(--responsive) * 12);
}

.copy {
  margin-top: auto;
  font-size: calc(var(--responsive) * 14);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.19);
}

.label {
  font-size: calc(var(--responsive) * 16);
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: normal;
  color: rgba(0, 0, 0, 0.8);
}

.text {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: 0.005em;
  line-height: normal;
  color: rgba(0, 0, 0, 0.6);
}

.text a {
  color: inherit;
}

.footer-inner ul li span:nth-child(2) {
  margin-top: 0.667em;
}

.footer-inner ul li span:nth-child(3) {
  margin-top: 0.167em;
}

/*__Default*/

/* Animation */

.event-page .label-01,
.event-page .looping-wrap,
.footer-inner {
  opacity: 0;
}

.event-page.active .label-01,
.footer-inner.active {
  animation: ani01 1200ms ease-in-out 1 forwards;
}

.event-page.active .looping-wrap {
  animation: ani01 1200ms ease-in-out 400ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .label-01 {
    text-align: center;
    width: calc(var(--responsive) * 312);
    font-size: calc(var(--responsive) * 12);
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--responsive) * 60);
    margin-bottom: calc(var(--responsive) * 23);
  }

  .label-01 b {
    line-height: normal;
    margin-right: initial;
    font-size: 1.167em;
    margin-bottom: 0.571em;
  }

  .looping-wrap {
    height: calc(var(--responsive) * 132);
  }

  .looping-img {
    margin-top: calc(var(--responsive) * -2);
    height: calc(var(--responsive) * 145);
    animation: looping 33750ms linear infinite;
  }

  /* footer */

  .footer-inner {
    flex-direction: column-reverse;
    width: calc(var(--responsive) * 312);
    margin-top: calc(var(--responsive) * 53);
    margin-bottom: calc(var(--responsive) * 54);
  }

  .footer-inner ul {
    display: flex;
    flex-direction: column;
  }

  .footer-inner ul li {
    margin-left: initial;
  }

  .footer-inner ul li:not(li:last-child) {
    margin-bottom: calc(var(--responsive) * 16);
  }

  .footer-inner ul li:last-child {
    margin-bottom: calc(var(--responsive) * 50);
  }

  .copy {
    font-size: calc(var(--responsive) * 12);
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
