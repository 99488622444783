<template>
  <div class="wrap">
    <TopNavi/>
    <router-view />
  </div>
</template>

<script lang="ts">
import TopNavi from "@/components/TopNavi.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "App",
  components: {
    TopNavi
  },
});
</script>

<style>
@import "assets/css/font.css";
@import "assets/css/rest.css";
@import "assets/css/component.css";
@import "assets/css/common.css";
</style>
