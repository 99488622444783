<template lang="pug">
div(:style="internalStyle")
  slot
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Vue100vh',
  data () {
    return {
      forceRecompute: 0,
    }
  },
  props: {
    style: {
      type: Object,
      default: () => ({ height: '100vvh' }),
    },
  },
  computed: {
    internalStyle () {
      const recompute = this.forceRecompute // eslint-disable-line
      return this.convertStyle()
    },
  },
  methods: {
    getWindowHeight () {
      return window.innerHeight
    },

    convertStyle () {
      const windowHeight = this.getWindowHeight()

      // throwOnBadArgs(givenStyle, windowHeight)

      const usedStyle = this.style

      const convertedStyle = {}

      Object.keys(usedStyle).forEach(key => {
        convertedStyle[key] = typeof usedStyle[key] === 'string'
          ? this.replaceVvhWithPx(usedStyle[key], windowHeight)
          : usedStyle[key]
      })

      return convertedStyle
    },

    replaceVvhWithPx (propertyStringValue, windowHeight) {
      const vvhRegex = /(\d+(\.\d*)?)vvh(?!\w)/g

      return propertyStringValue.replace(
        vvhRegex,
        (_, vvh) => {
          return `${(windowHeight * parseFloat(vvh)) / 100}px`
        }
      )
    },

    updateStyle () {
      this.forceRecompute++
    },
  },
  mounted () {
    // this.updateStyle()
    window.addEventListener('resize', this.updateStyle)
  },
  unmounted () {
    window.removeEventListener('resize', this.updateStyle)
  },
});
</script>

<style lang="postcss" scoped>
.root {}
</style>
