
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail3",
  el: "#halloween_emoji",
  data() {
    const items = [];
    const pushItems = () => {
      let urlLength = 32;
      for (let i = 1; i < urlLength; i++) {
        items.push({ url: `halloween_${i}` });
      }
    };
    pushItems();
    return {
      show0: false,
      pushItems,
      items,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
});
