<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="seasonal"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title">
          <span class="left-line"></span>
          <h3 class="inactive_820w">Halloween Seasonal</h3>
          <h3 class="active_820w">Halloween</h3>
          <span class="right-line"></span>
        </div>
        <span>
          할로윈하면 연상되는 호박, 사탕, 유령 등 여러 메타포를 Orange, Purple,
          Black의 색을 사용해 구성했으며,<br />
          특유의 공포스러운 분위기를 극대화하기 위해 기존 이모지의 표정을
          과장하여 표현했습니다
        </span>
      </div>
      <div
        class="speech_bubble"
        :class="show1 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <img
          class="bubble-emoji-01"
          src="@/assets/img/2021_emoji/speech_bubble_1.webp"
          alt="emoji image"
        />
        <span class="speech_bubble-txt">Trick or treat!</span>
        <img
          class="bubble-emoji-02"
          src="@/assets/img/2021_emoji/speech_bubble_2.webp"
          alt="emoji image"
        />
        <img
          class="last-img bubble-emoji-03"
          src="@/assets/img/2021_emoji/speech_bubble_3.webp"
          alt="emoji image"
        />
      </div>
      <div class="contnet01-wrap">
        <div
          class="contnet01-inner"
          :class="show2 ? 'active' : ''"
          style="position: relative"
        >
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
          <div class="items-01">
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-1.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-2.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-3.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-4.webp"
              alt="emoji image"
            />
          </div>
          <div class="items-02">
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-5.webp"
              alt="emoji image"
            />
          </div>
          <div class="items-03">
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-6.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-7.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-8.webp"
              alt="emoji image"
            />
            <img
              src="@/assets/img/2021_emoji/halloween-emoji-9.webp"
              alt="emoji image"
            />
          </div>
        </div>
      </div>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail2",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  width: 100vw;
  position: relative;
  background: #fff;
  font-family: "Poppins", sans-serif;
  margin-top: calc(var(--responsive) * 252);
}

.container-inner {
  width: 68.75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* seasonal title */

.seasonal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--responsive) * 140);
}

.seasonal .title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: calc(var(--responsive) * 28);
  margin-bottom: 1.75em;
}

.seasonal .title h3 {
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0.75em;
}

.seasonal .title .left-line,
.seasonal .title .right-line {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #979797;
}

.seasonal span {
  font-family: "NanumSquare", serif;
  font-size: calc(var(--responsive) * 18);
  font-weight: 400;
  line-height: 1.61em;
  text-align: center;
}

/* speech_bubble */

.speech_bubble {
  position: relative;
  width: calc(var(--responsive) * 731);
  height: calc(var(--responsive) * 120);
  margin-right: auto;
  background-image: url("../../../assets/img/2021_emoji/speech_bubble_bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(var(--responsive) * 79);
}

.speech_bubble img {
  width: 10.6703%;
  object-fit: cover;
}

.speech_bubble-txt {
  color: #fff;
  font-size: calc(var(--responsive) * 52);
  font-weight: 500;
  margin: 0 0.365em;
}

.bubble-emoji-03 {
  margin-left: 1.91518%;
}

/* content01 */

.contnet01-wrap {
  position: relative;
  display: flex;
  width: 100vw;
  height: 52.65625vw;
  font-family: "Poppins", sans-serif;
  background-image: url("../../../assets/img/2021_emoji/halloween_bg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  align-items: center;
  justify-content: center;
}

.contnet01-inner {
  width: 63.02083vw;
  height: 40.10416vw;
  display: flex;
  flex-wrap: wrap;
}

.items-01 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 44.5454%;
  height: 64.6753%;
  align-content: space-between;
  justify-content: space-between;
}
.items-01 img {
  width: 35.62152%;
  object-fit: cover;
}

.items-02 {
  width: 33.0578%;
  height: 51.948%;
  margin: 3.3884% 8.18181% auto auto;
}

.items-02 img {
  width: 100%;
  object-fit: cover;
}

.items-03 {
  display: flex;
  width: 100%;
  height: 24.6753%;
  margin-top: auto;
  justify-content: space-between;
}

.items-03 img {
  width: 15.86776%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.seasonal,
.speech_bubble,
.bubble-emoji-01,
.bubble-emoji-02,
.bubble-emoji-03,
.speech_bubble-txt,
.contnet01-inner .items-01,
.contnet01-inner .items-02,
.contnet01-inner .items-03 {
  opacity: 0;
}

.seasonal.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.speech_bubble.active {
  animation: bubble-ani-bg 500ms ease-in-out 1 forwards;
}

.speech_bubble.active .bubble-emoji-01 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 350ms;
}

.speech_bubble.active .speech_bubble-txt {
  animation: bubble-ani-02 500ms ease-in-out 1 forwards;
  animation-delay: 700ms;
}

.speech_bubble.active .bubble-emoji-02 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 1050ms;
}

.speech_bubble.active .bubble-emoji-03 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 1400ms;
}

.contnet01-inner.active .items-01 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.contnet01-inner.active .items-02 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 300ms;
}

.contnet01-inner.active .items-03 {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 600ms;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bubble-ani-bg {
  0% {
    transform: translate3d(0%, 80%, 0) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translate3d(0%, 0%, 0) scale(1);
    opacity: 1;
  }
}

@keyframes bubble-ani-01 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}

@keyframes bubble-ani-02 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
  }
  100% {
    transform: translate3d(0%, 0, 0);
    opacity: 1;
  }
}

/*__Animation*/

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container {
    margin-top: 33.3333vw;
  }

  .container-inner {
    width: 88.88888%;
  }

  .seasonal {
    margin-bottom: 20.5555vw;
  }

  .seasonal .title {
    font-size: 5.55555vw;
    margin-bottom: 1.5em;
  }

  .seasonal .title h3 {
    margin: 0 0.6em;
  }

  .seasonal span {
    font-size: 3.88888vw;
    line-height: 1.57em;
  }

  .speech_bubble {
    width: 83.3333vw;
    height: 14.72222vw;
    background-size: 100% 100%;
    margin-bottom: 8.05555vw;
  }

  .speech_bubble img {
    width: 11%;
  }

  .speech_bubble-txt {
    font-size: 6.66666vw;
    margin: 0 0.167em;
  }

  .bubble-emoji-03 {
    margin-left: 1.66666%;
  }

  .contnet01-wrap {
    position: relative;
    display: flex;
    width: 100vw;
    height: 219.7222vw;
    background-image: url("../../../assets/img/2021_emoji/halloween_bg-m.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    align-items: center;
    justify-content: center;
  }

  .contnet01-inner {
    width: 67.77777vw;
    height: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
  }

  .items-01 {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-bottom: 6.38888vw;
  }

  .items-01 img {
    width: 23.05555vw;
    object-fit: cover;
  }

  .items-01 img:nth-child(1),
  .items-01 img:nth-child(2) {
    margin-bottom: 10.5555vw;
  }

  .items-02 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: initial;
    margin-bottom: 6.38888vw;
  }

  .items-02 img {
    width: 48.0555vw;
  }

  .items-03 {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: initial;
  }

  .items-03 img {
    width: 23.05555vw;
    object-fit: cover;
  }

  .items-03 img:nth-child(1),
  .items-03 img:nth-child(2) {
    margin-bottom: 10.5555vw;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
