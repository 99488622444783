<template>
  <div class="container">
    <div class="container-inner">
      <div class="phone-01" :class="show0 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-phone01.webp" alt="image">
      </div>
      <div class="phone-02" :class="show1 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-phone02.webp" alt="image">
      </div>
      <div class="phone-03" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-phone03.webp" alt="image">
      </div>
      <div class="screen-01" :class="show0 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-screen01.webp" alt="image">
      </div>
      <div class="screen-02" :class="show0 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-screen02.webp" alt="image">
      </div>
      <div class="graphics-01" :class="show0 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-graphics01.svg" alt="image">
      </div>
      <div class="graphics-02" :class="show3 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-graphics02.svg" alt="image">
      </div>
      <div class="graphics-03" :class="show4 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-graphics03.svg" alt="image">
      </div>
      <div class="label-01" :class="show5 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
        <b>Chatbot</b>챗봇 및 상품 상세화면 리뉴얼
      </div>
      <div class="label-02" :class="show6 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver6"></ShowObserver>
        <b>Chatbot</b>실시간 챗봇 상담화면 디자인
      </div>
      <div class="message-01" :class="show7 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver7"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-message01.webp" alt="image">
      </div>
      <div class="message-02" :class="show7 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-message02.webp" alt="image">
      </div>
      <div class="message-03" :class="show8 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver8"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-message03.webp" alt="image">
      </div>
      <div class="message-04" :class="show8 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-message04.webp" alt="image">
      </div>
      <div class="message-05" :class="show3 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-message05.webp" alt="image">
      </div>
      <div class="message-06" :class="show4 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-message06.webp" alt="image">
      </div>
      <div class="wireframe-01" :class="show9 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver9"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-wireframe01.webp" alt="image">
      </div>
      <div class="wireframe-02" :class="show9 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-wireframe02.webp" alt="image">
      </div>
      <div class="wireframe-03" :class="show10 ? 'active' : ''">
        <img src="@/assets/img/2021_KBbank/section03-wireframe03.webp" alt="image">
      </div>
      <div class="wireframe-04" :class="show10 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver10"></ShowObserver>
        <img src="@/assets/img/2021_KBbank/section03-wireframe04.webp" alt="image">
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "KookminBankDetail3",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
    isShowObserver6(value: boolean) {
      this.show6 = value;
    },
    isShowObserver7(value: boolean) {
      this.show7 = value;
    },
    isShowObserver8(value: boolean) {
      this.show8 = value;
    },
    isShowObserver9(value: boolean) {
      this.show9 = value;
    },
    isShowObserver10(value: boolean) {
      this.show10 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 3805);
  position: relative;
  background: #fff;
  font-family: var(--pretendard);
}

.container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: calc(100% - calc(var(--responsive) * 340));
  top: calc(var(--responsive) * 340);
  left: 0;
  background: #F7F6F4;
}


.container-inner {
  position: relative;
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  font-size: calc(var(--responsive) * 100);
}

.container-inner::after {
  content: '';
  position: absolute;
  width: 100vw;
  height: calc(100% - calc(var(--responsive) * 340));
  top: calc(var(--responsive) * 340);
  left: calc(((100vw - calc(var(--responsive) * 1200)) / 2) * -1);
  background: linear-gradient(180deg, #FBFBFA 42.19%, rgba(252, 251, 250, 0.438731) 57.53%, rgba(250, 250, 248, 0.0001) 71.76%, rgba(248, 248, 246, 0.0001) 80.19%, rgba(247, 247, 245, 0.8) 92.05%, #F7F6F4 100%);
  z-index: 2;
}

/* phone */

.phone-01,
.phone-02,
.phone-03 {
  display: flex;
  position: absolute;
  width: calc(var(--responsive) * 300);
  height: calc(var(--responsive) * 620);
  z-index: 4;
  font-size: 1em;
  border-radius: 0.420em;
  box-shadow: 0em 0.100em 0.160em rgba(0, 0, 0, 0.2);
}

.phone-01 {
  top: calc(var(--responsive) * 29);
  left: calc(var(--responsive) * 233);
}

.phone-02 {
  top: calc(var(--responsive) * 2372);
  left: calc(var(--responsive) * 276);
}

.phone-03 {
  top: calc(var(--responsive) * 2541);
  left: calc(var(--responsive) * 624);
}

/* screen */

.screen-01,
.screen-02 {
  display: flex;
  position: absolute;
  width: calc(var(--responsive) * 231);
  height: calc(var(--responsive) * 500);
  z-index: 3;
  font-size: 1em;
  box-shadow: 0em 0.100em 0.160em rgba(0, 0, 0, 0.15);
}

.screen-01 {
  top: calc(var(--responsive) * 94);
  left: calc(var(--responsive) * 515);
}

.screen-02 {
  top: calc(var(--responsive) * 94);
  left: calc(var(--responsive) * 776);
}

/* graphics */

.graphics-01,
.graphics-02,
.graphics-03 {
  display: flex;
  position: absolute;
  z-index: 3;
}

.graphics-01 {
  width: calc(var(--responsive) * 200);
  height: calc(var(--responsive) * 248);
  top: calc(var(--responsive) * 186);
  left: calc(var(--responsive) * 1029);
}

.graphics-02 {
  width: calc(var(--responsive) * 140);
  height: calc(var(--responsive) * 286);
  top: calc(var(--responsive) * 2255.4);
  left: calc(var(--responsive) * 694);
}

.graphics-03 {
  width: calc(var(--responsive) * 254);
  height: calc(var(--responsive) * 184);
  top: calc(var(--responsive) * 2802);
  left: calc(var(--responsive) * 23);
}

/* label */

.label-01,
.label-02 {
  position: absolute;
  font-size:calc(var(--responsive) * 16);
  font-weight: 400;
  line-height: 1.188em;
  z-index: 3;
}

.label-01 b,
.label-02 b {
  font-size:calc(var(--responsive) * 24);
  font-weight: 700;
  line-height: 1.750em;
  margin-right: 0.667em;
}

.label-01 {
  top: calc(var(--responsive) * 700);
  left: 0;
}

.label-02 {
  top: calc(var(--responsive) * 3045);
  left: 0;
}

/* message */

.message-01,
.message-02,
.message-03,
.message-04,
.message-05,
.message-06 {
  display: flex;
  position: absolute;
  z-index: 3;
}

.message-01 {
  width: calc(var(--responsive) * 126);
  height: calc(var(--responsive) * 86);
  top: calc(var(--responsive) * 849);
  left: calc(var(--responsive) * 468);
}

.message-02 {
  width: calc(var(--responsive) * 226);
  height: calc(var(--responsive) * 104);
  top: calc(var(--responsive) * 1068);
  left: calc(var(--responsive) * 618);
}

.message-03 {
  width: calc(var(--responsive) * 271);
  height: calc(var(--responsive) * 171);
  top: calc(var(--responsive) * 1482);
  left: calc(var(--responsive) * 297);
}

.message-04 {
  width: calc(var(--responsive) * 371);
  height: calc(var(--responsive) * 231);
  top: calc(var(--responsive) * 1604);
  left: calc(var(--responsive) * 531);
}

.message-05 {
  width: calc(var(--responsive) * 110);
  height: calc(var(--responsive) * 73);
  top: calc(var(--responsive) * 2228);
  left: calc(var(--responsive) * 806);
}

.message-06 {
  width: calc(var(--responsive) * 80);
  height: calc(var(--responsive) * 66);
  top: calc(var(--responsive) * 2805);
  left: calc(var(--responsive) * 81);
}


/* wireframe */

.wireframe-01,
.wireframe-02,
.wireframe-03,
.wireframe-04 {
  position: absolute;
  width: calc(var(--responsive) * 250);
  height: calc(var(--responsive) * 541);
  z-index: 2;
}

.wireframe-01 {
  top: calc(var(--responsive) * 1778);
  left: calc(var(--responsive) * 303);
}

.wireframe-02 {
  top: calc(var(--responsive) * 2137);
  left: calc(var(--responsive) * -17);
}

.wireframe-03 {
  top: calc(var(--responsive) * 2991);
  left: calc(var(--responsive) * 968);
}

.wireframe-04 {
  top: calc(var(--responsive) * 3228);
  left: calc(var(--responsive) * 651);
}


/*__Default*/

/* Animation */

.phone-01,
.phone-02,
.phone-03,
.screen-01,
.screen-02,
.graphics-01,
.graphics-02,
.graphics-03,
.label-01,
.label-02,
.message-01,
.message-02,
.message-03,
.message-04,
.message-05,
.message-06,
.wireframe-01,
.wireframe-02,
.wireframe-03,
.wireframe-04 {
  opacity: 0;
}

.phone-01.active,
.phone-02.active,
.phone-03.active,
.screen-01.active,
.screen-02.active,
.graphics-01.active,
.graphics-02.active,
.graphics-03.active,
.label-01.active,
.label-02.active,
.wireframe-01,
.wireframe-02.active,
.wireframe-03.active,
.wireframe-04.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.screen-01.active {
  animation-delay: 200ms;
}

.screen-02.active {
  animation-delay: 400ms;
}

.graphics-01.active {
  animation-delay: 600ms;
}

.message-01.active,
.message-03.active {
  animation: ani02 1000ms ease-in-out 1 forwards;
}


.message-02.active,
.message-04.active {
  animation: ani02 1000ms ease-in-out 450ms 1 forwards;
}

.message-05.active,
.message-06.active {
  animation: ani02 1000ms ease-in-out 400ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    opacity: 0;
    transform: scale(0) translate3d(0, 10%, 0);
  }
  50% {
    transform: scale(1.2)  translate3d(0, -5%, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {

  .container {
    height: calc(var(--responsive) * 1683);
    overflow: hidden;
  }

  .container::before {
    height: calc(100% - calc(var(--responsive) * 249));
    top: calc(var(--responsive) * 249);
  }

  .container-inner {
    width: calc(var(--responsive) * 312);
  }

  .container-inner::after {
    height: calc(100% - calc(var(--responsive) * 249));
    top: calc(var(--responsive) * 249);
    background-image: linear-gradient(to bottom, #fbfbfa 42%, rgba(252, 251, 250, 0.69) 47%, rgba(250, 250, 248, 0) 63%, rgba(248, 248, 246, 0) 77%, rgba(247, 247, 245, 0.8) 95%, #f7f6f4 103%);
    left: calc(((100vw - calc(var(--responsive) * 312)) / 2) * -1);
  }

  /* phone */

  .phone-01,
  .phone-02,
  .phone-03 {
    width: calc(var(--responsive) * 147);
    height: calc(var(--responsive) * 303);
  }
  
  .phone-01 {
    top: calc(var(--responsive) * 99);
    left: calc(var(--responsive) * 82.93);
  }

  .phone-02 {
    top: calc(var(--responsive) * 1045);
    left: 0;
  }

  .phone-03 {
    top: calc(var(--responsive) * 1189);
    left: initial;
    right: 0;
  }

  /* screen */

  .screen-01,
  .screen-02 {
    width: calc(var(--responsive) * 102);
    height: calc(var(--responsive) * 220);
  }

  .screen-01 {
    top: calc(var(--responsive) * 140);
    left: 0;
  }

  .screen-02 {
    top: calc(var(--responsive) * 140);
    left: initial;
    right: 0;
  }

  /* graphics */

  .graphics-01 {
    width: calc(var(--responsive) * 87);
    height: calc(var(--responsive) * 108);
    top: calc(var(--responsive) * 32);
    left: calc(var(--responsive) * 188);
  }

  .graphics-02 {
    width: calc(var(--responsive) * 58);
    height: calc(var(--responsive) * 120);
    top: calc(var(--responsive) * 1070);
    left: initial;
    right: calc(var(--responsive) * 46);
  }

  .graphics-03 {
    display: none;
  }

  /* label */

  .label-01,
  .label-02 {
    width: 100%;
    text-align: center;
    font-size: calc(var(--responsive) * 12);
    display: flex;
    flex-direction: column;
  }
  
  .label-01 b,
  .label-02 b {
    line-height: normal;
    margin-right: initial;
    font-size: 1.167em;
    margin-bottom: 0.571em;
  }

  .label-01 {
    top: calc(var(--responsive) * 436);
  }

  .label-02 {
    top: calc(var(--responsive) * 1524);
  }

  /* message */

  .message-01 {
    width: calc(var(--responsive) * 38);
    height: calc(var(--responsive) * 24);
    top: calc(var(--responsive) * 507);
    left: calc(var(--responsive) * 114);
  }

  .message-02 {
    width: calc(var(--responsive) * 72);
    height: calc(var(--responsive) * 30);
    top: calc(var(--responsive) * 542);
    left: calc(var(--responsive) * 165);
  }

  .message-03 {
    width: calc(var(--responsive) * 88);
    height: calc(var(--responsive) * 53);
    top: calc(var(--responsive) * 765);
    left: calc(var(--responsive) * 68);
  }

  .message-04 {
    width: calc(var(--responsive) * 121);
    height: calc(var(--responsive) * 74);
    top: calc(var(--responsive) * 806);
    left: calc(var(--responsive) * 148);
  }

  .message-05 {
    width: calc(var(--responsive) * 51);
    height: calc(var(--responsive) * 31);
    top: calc(var(--responsive) * 1051);
    left: initial;
    right:calc(var(--responsive) * 2);
  }

  .message-06 {
    display: none;
  }

  /* wireframe */

  .wireframe-02,
  .wireframe-03 {
    display: none;
  }

  .wireframe-01,
  .wireframe-04 {
    width: calc(var(--responsive) * 106.39);
    height: calc(var(--responsive) * 230.38);
  }

  .wireframe-01 {
    top: calc(var(--responsive) * 800.82);
    left: calc(var(--responsive) * 18.59);
  }

  .wireframe-04 {
    top: calc(var(--responsive) * 1510);
    left: initial;
    right: calc(var(--responsive) * 19);
  }


} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
