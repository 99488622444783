<template>
    <div class="containers_02">
        <div class="container_wrap">
        <h3 class="content_tit">ILLUSTRATION</h3>
            <div class="content_body">
                <div class="item_wrap01">
                    <div class="item_img_01" :class="show0 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                        <img src="@/assets/img/2021_momostick/momo_03.jpg" alt="image">
                    </div>
                    <div class="item_img_02" :class="show1 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                        <img src="@/assets/img/2021_momostick/momo_04.jpg" alt="image">
                    </div>
                </div> 
                <div class="item_img_03" :class="show2 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                    <img src="@/assets/img/2021_momostick/momo_05.jpg" alt="image">
                </div>
                <div class="item_img_04" :class="show3 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                    <img src="@/assets/img/2021_momostick/momo_06.jpg" alt="image">
                </div>
                <div class="item_img_06" :class="show4 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                    <img src="@/assets/img/2021_momostick/momo_08.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ShowObserver from "@/components/ShowObserver.vue";

    export default defineComponent({
        name: "MomostickDetail2",
        data() {
          return {
              show0: false,
              show1: false,
              show2: false,
              show3: false,
              show4: false,
          }
        },
        components: {
            ShowObserver
        },
        methods: {
            isShowObserver0(value: boolean) {
            this.show0 = value;
          },
            isShowObserver1(value: boolean) {
            this.show1 = value;
          },
            isShowObserver2(value: boolean) {
            this.show2 = value;
          },
            isShowObserver3(value: boolean) {
            this.show3 = value;
          },
            isShowObserver4(value: boolean) {
            this.show4 = value;
          },
        }
      });
      </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 24px;
    color: #383f6e;
    font-weight: 700;
    font-family: 'NanumSquare', serif;
    margin-bottom: 30px;
}

.container_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.containers_02 {
    position: relative;
    width: 100%;
    padding: 162px 0;
}

.containers_02 .content_body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.containers_02 [class^="item_wrap0"] {
    display: flex;
    flex-direction: row;
    margin: 0 -9px;
}

.containers_02 [class^="item_wrap0"] [class^="item_img_0"] {
    padding: 0 9px;
}

.containers_02 .item_img_03 {
    margin: 18px 0;
}

.containers_02 .item_img_06 {
    margin: 18px 0 0 0;
}

/*__Default*/


/* Animation */


[class^="item_img_0"] {
    opacity: 0;
}

[class^="item_img_0"].active {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 200ms;
    transition-property: opacity;
}



/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .container_wrap {
       max-width: 90%;
    }
   

} /*__end*/

@media screen and (max-width:1024px) {
    .containers_02 {
        padding: 130px 0;
    }

    .content_tit {
        font-size: 19px;
    }   

} /*__end*/

@media screen and (max-width:768px) {
    .containers_02 {
        padding: 104px 0;
    }

    .content_tit {
        font-size: 15px;
        margin-bottom: 25px;
    }
    
    .containers_02 .item_wrap01{
        display: flex;
        flex-direction: column;
        margin: inherit;
    }

    .containers_02 .item_wrap01{
        margin: 0 -7px;
    }

    .containers_02 .item_wrap01 [class^="item_img_0"]{
        padding: 7px;
    }

    .containers_02 .item_wrap01 [class^="item_img_0"] {
        padding: inherit;
    }

    .containers_02 .item_img_03,
    .containers_02 .item_img_06 {
        margin: inherit;
    }

    .containers_02 [class^="item_img_0"] {
        margin-bottom: 14px;
    }

    .containers_02 .item_img_06 {
        margin-bottom: 0;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .container_wrap {
       max-width: 86.66666vw;
    }

    .containers_02 {
        padding: 74px 0;
    }

    .containers_02 .item_wrap01{
        margin: 0 -3px;
    }

    .containers_02 .item_wrap01 [class^="item_img_0"]{
        padding: 3px;
    }

    .containers_02 [class^="item_img_0"] {
        margin-bottom: 6px;
    }

} /*__end*/


/*__반응형*/

</style>
