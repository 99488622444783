<template>
<div class="content_containers">
    <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title_text">Mr.M’s Life Set Motion</div>
        <div class="body_text">삼성 Knox Messgenger 에서 사용되는 감성 이모티콘의 다양한 모션 이팩트를 적용하고,<br class="inactive_425w">
            캐릭터 일러스트의 베리에이션과<br class="active_425w"> 전체 캐릭터의 동작, 감정 등에 대한 모션을 진행 후 양산화<br class="active_425w"> 가이드 작업을 진행했습니다.</div>
    </div>
    <div class="content_img_wrap">
        <div class="image_horizontal" :class="show1 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
            <div class="image_h_01"><img src="@/assets/img/2016_emoticon/emoticon_01.jpg" alt="image"></div>
            <div class="image_h_02"><img src="@/assets/img/2016_emoticon/emoticon_02.jpg" alt="image"></div>
            <div class="image_h_03"><img src="@/assets/img/2016_emoticon/emoticon_03.jpg" alt="image"></div>
            <div class="image_h_04"><img src="@/assets/img/2016_emoticon/emoticon_04.jpg" alt="image"></div>
            <div class="image_h_05"><img src="@/assets/img/2016_emoticon/emoticon_05.jpg" alt="image"></div>
            <div class="image_h_06"><img src="@/assets/img/2016_emoticon/emoticon_06.jpg" alt="image"></div>
            <div class="image_h_07"><img src="@/assets/img/2016_emoticon/emoticon_07.jpg" alt="image"></div>
        </div>
        <div class="image_v_01" :class="show2 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
            <img src="@/assets/img/2016_emoticon/emoticon_08.gif" alt="image">
        </div>
        <div class="image_v_02" :class="show3 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
            <img src="@/assets/img/2016_emoticon/emoticon_09.gif" alt="image">
        </div>
        <div class="image_v_03" :class="show4 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
            <img src="@/assets/img/2016_emoticon/emoticon_10.gif" alt="image">
            </div>
        <div class="image_v_04" :class="show5 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
            <img src="@/assets/img/2016_emoticon/emoticon_11.gif" alt="image">
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmoticonDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 91px;
    content-visibility: auto;
}

.content_containers .main_text {
    margin: 0 auto;
    margin-bottom: 188px;

}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_img_wrap .image_horizontal {
    display: flex;
    justify-content: center;
    margin-left: -30px;
    margin-bottom: 290px;
}

.content_img_wrap .image_horizontal div {
    margin-left: 30px;
}

.content_containers .content_img_wrap .image_v_01,
.image_v_02,
.image_v_03,
.image_v_04 {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 260px;

}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_horizontal.active .image_h_01 {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_02 {
    opacity: 1;
    transition-delay: .1s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_03 {
    opacity: 1;
    transition-delay: .2s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_04 {
    opacity: 1;
    transition-delay: .3s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_05 {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_06 {
    opacity: 1;
    transition-delay: .5s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.image_horizontal.active .image_h_07 {
    opacity: 1;
    transition-delay: .6s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 


.image_horizontal .image_h_01,
.image_horizontal .image_h_02, 
.image_horizontal .image_h_03,
.image_horizontal .image_h_04,
.image_horizontal .image_h_05,
.image_horizontal .image_h_06,
.image_horizontal .image_h_07 {
    opacity: 0;
}
.image_v_01.active > img,
.image_v_02.active > img,
.image_v_03.active > img,
.image_v_04.active > img {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);

}

.image_v_01 > img,
.image_v_02 > img,
.image_v_03 > img,
.image_v_04 > img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

    .content_img_wrap .image_horizontal .image_h_01,
    .image_h_07 {
        display: none;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_img_wrap .image_horizontal {
        margin-bottom: 240px;
    }

    .content_img_wrap .image_horizontal .image_h_06 {
        display: none;
    }


    .content_containers .content_img_wrap .image_v_01,
    .image_v_02,
    .image_v_03,
    .image_v_04 {
        max-width: 72.22222vw;
        margin: 0 auto;
        margin-bottom: 160px;

    }

} /*__end*/

@media screen and (max-width:425px) {
        .content_containers > .main_text {
            max-width: 86.66666vw;
        }

        .content_containers {
            padding-top: 36px;
        }

        .content_containers .main_text {
            margin-bottom: 124px;
        }

        .content_containers .main_text .title_text {
            height: 29px;
            font-size: 20px;
            margin-bottom: 16px;
        }

        .content_containers .main_text .body_text {
            width: 312px;
            height: 72px;
            font-size: 12px;
            line-height: 1.5;
        }

        .content_img_wrap .image_horizontal {
            margin-bottom: 132px;
        }

        .content_containers .content_img_wrap .image_v_01,
        .image_v_02,
        .image_v_03,
        .image_v_04 {
            margin-bottom: 142px;

        }

} /*__end*/


/*__반응형*/

</style>
