<template>
<div class="definition_containers">
    <div class="definition_content content_wrap" id="trigger5">
    <div class="definition_title_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
         <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
         <div class="content_title">Brand Definition</div>
    </div>
    <div class="definition_infographic" :class="show1 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <div class="mission">
            <div class="title_circle">MISSION</div>
            <div class="body_text">
                <p>자신의 재능을 알고, 발휘하여 <br>무너진 자존감을 바로 세워 선함으로의 복귀</p>
            </div>
       </div>
        <div class="vision">
            <div class="title_circle">VISION</div>
            <div class="body_text">
                <p>나의 모습과 타인의 모습이 <br>다름을 인지하고 받아들여 갈등 해소 </p>
            </div>
        </div>
        <div class="value">
            <div class="title_circle">VALUE</div>
            <div class="body_text">
                <p>누구나 행복하게 살 권리가 있다. <br>본연의 모습으로 누리는 행복한 삶</p>
            </div>
        </div>
        <div class="goal">
            <div class="title_circle">GOAL</div>
            <div class="body_text">
                <p>자신의 능력을 마음껏 펼침으로써 <br>자존감을 높이고 행복한 삶을 추구</p>
            </div>
        </div>
    </div>
    </div>
    <div class="definition_bg"></div>
 </div> 
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
      show1: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.definition_containers {
    width: 100%;
    background-color: #eaf0f8;
    padding-top: 147px;
}

.definition_title_wrap {
    margin-bottom: 143px;
}

.definition_content .definition_infographic {
    display: flex;
    justify-content: center;
    margin-bottom: 199px;;

}

.definition_content .definition_infographic div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.definition_content .definition_infographic div .title_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 179px;
    border-radius: 50%;
    background-color: #4061ac;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    font-family: 'NanumSquareRound', serif;
    letter-spacing: 0.7px;
}

.definition_content .definition_infographic div .body_text {
    width: 244px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    color: #2553bc;
    margin-top: 16px;
    overflow: hidden;
}

.definition_content .definition_infographic div .body_text::before {
    content: "";
    display: block;
    width: 1.5px;
    height: 63px;
    background-color: #4061ac;
    margin-bottom: 22px;
}

.definition_bg {
    width: 100vw;
    height: 0;
    padding-bottom: 18.75%;
    background-image: url(../../../assets/img/2020_baro/definition_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

/*__Default*/


/* Animation */

.definition_title_wrap.active .content_title {
    opacity: 1;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

.definition_title_wrap .content_title {
    opacity: 0;
}

.definition_infographic.active div:not(.observer) .title_circle{
    opacity: 1;
    transform: translateY(0);
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
} 

.definition_infographic div:not(.observer) .title_circle{
    opacity: 0;
} 

.definition_infographic.active div.mission .title_circle,
.definition_infographic.active div.mission .body_text::before {
    animation-delay: .2s;
    transition-delay: .2s;
} 

.definition_infographic.active div.vision .title_circle,
.definition_infographic.active div.vision .body_text::before {
    animation-delay: .4s;
    transition-delay: .4s;
} 

.definition_infographic.active div.value .title_circle,
.definition_infographic.active div.value .body_text::before {
    animation-delay: .6s;
    transition-delay: .6s;
} 

.definition_infographic.active div.goal .title_circle,
.definition_infographic.active div.goal .body_text::before {
    animation-delay: .8s;
    transition-delay: .8s;
} 

.definition_infographic.active div:not(.observer) .body_text::before {
    animation-name: line_ani;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    animation-fill-mode: both;
}


@-webkit-keyframes line_ani {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
   }
}
.definition_infographic.active div.mission .body_text p {
    transition-delay: .6s;
}

.definition_infographic.active div.vision .body_text p {
    transition-delay: .8s;
}

.definition_infographic.active div.value .body_text p {
    transition-delay: 1s;
}

.definition_infographic.active div.goal .body_text p {
    transition-delay: 1.2s;
}

.definition_infographic.active div:not(.observer) .body_text p {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
} 

.definition_infographic div:not(.observer) .body_text p {
    opacity: 0;
} 

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

   .definition_content .definition_infographic {
       flex-wrap: wrap;
   }

   .definition_content .definition_infographic div {
       width: 50%;
   }

   .definition_content .definition_infographic > div:nth-child(-1n+2) {
       margin-bottom: 60px;
   }

} /*__end*/

@media screen and (max-width:768px) {
    .definition_content .definition_infographic {
      flex-direction: column;
      align-items: center;  
    }  

    .definition_content .definition_infographic > div:nth-child(-1n+2) {
        margin-bottom: 105px;
    }  

    .definition_content .definition_infographic > div {
        margin-bottom: 105px;
    }  

    .definition_bg {
        padding-bottom: 62.5%;
        background-position: center;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

    .definition_containers {
        padding-top: 100px;
    }

    .definition_title_wrap {
        margin-bottom: 100px;
    }

    .definition_content .definition_infographic > div.goal {
        margin-bottom: 0;
    }

} /*__end*/


/*__반응형*/

</style>