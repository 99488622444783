
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail03.vue",
    data() {
      return {
          show0: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
    }
  });
  