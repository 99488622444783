<template>
    <div class="containers_06">
      <div class="content_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
      <ThemeObserver :theme=theme />   
        <div class="tit_box">
          <h3 class="common_tit">Design System</h3>
          <div class="skeuomorphism">Skeuomorphism</div>
        </div>
        <div class="text_box">
          <h3 class="common_tit">Component</h3>
          <div class="body_txt">
            Skeuomorphism을 활용해서 스크린을 터치하는 경험에서 끝나는 것이 아닌
            실제 의료기기 자체를 만지는 듯한 느낌을 받을 수 있도록 디자인했습니다.
          </div>
        </div>
      </div>
      <div class="dark_theme" :class="show1 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>  
      <ThemeObserver :theme=theme />   
        <img src="@/assets/img/2020_viol/viol_07.png" alt="image" class="inactive_768w">
        <img src="@/assets/img/2020_viol/viol_07_m.png" alt="image" class="active_768w">
      </div>
      <div class="light_theme" :class="show2 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>  
        <img src="@/assets/img/2020_viol/viol_08.png" alt="image" class="inactive_768w">
        <img src="@/assets/img/2020_viol/viol_08_m.png" alt="image" class="active_768w">
      </div>
      <div class="example_img" :class="show3 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>  
        <img src="@/assets/img/2020_viol/viol_09.jpg" alt="image" class="ex_ani1">
        <img src="@/assets/img/2020_viol/viol_10.jpg" alt="image" class="ex_ani2">
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail06.vue",
    data() {
      return {
          show0: false,
          show1: false,
          show2: false,
          show3: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
        isShowObserver1(value: boolean) {
        this.show1 = value;
      },
        isShowObserver2(value: boolean) {
        this.show2 = value;
      },
        isShowObserver3(value: boolean) {
        this.show3 = value;
      },                  
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.containers_06 {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background: #101010;
}

.containers_06 .content_wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* justify-content: space-between; */
  padding: 70px 0 102px 0;
}

.tit_box .common_tit {
  font-size: 14px;
  margin-bottom: 188px;
  color: rgba(255, 255, 255, 0.6);
}

.text_box .common_tit {
  font-size: 14px;
  margin-bottom: 331px;
  color: rgba(255, 255, 255, 0.6);
}

.tit_box {
    width: 50%;
}

.text_box {
  width: 50%;
}

.skeuomorphism {
  font-size: 84px;
  letter-spacing: -1.84px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(98deg, #f5fafc, #b0b5b9 115%);
}

.body_txt {
  font-size: 18px;
  font-family: 'Roboto', 'SECRobotoLight', serif;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #fff;
}

.light_theme {
  width: 100%;
  background: #edeff3;
}

.example_img {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #edeff3;
}

.example_img img {
  width: 50%;
}

/*__Default*/


/* Animation */

.content_wrap.active .skeuomorphism {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 200ms;
  transition-property: opacity;
}

.content_wrap.active .body_txt {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.containers_06 .skeuomorphism,
.containers_06 .body_txt {
  opacity: 0;
}

.dark_theme.active img {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
  transition-property: opacity;
}

.dark_theme img {
  opacity: 0;
}

.light_theme.active img {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
  transition-property: opacity;
}

.light_theme img {
  opacity: 0;
}

.example_img.active img.ex_ani1 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
  transition-property: opacity;
}

.example_img.active img.ex_ani2 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 200ms;
  transition-property: opacity;
}

.example_img img {
  opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
.containers_06 .content_wrap {
  max-width: 90%;
}

.skeuomorphism {
  font-size: 67px;
}

.text_wrap .body_txt {
  font-size: 16px;
}
} /*__end*/

@media screen and (max-width:1024px) {

.skeuomorphism {
  font-size: 54px;
}

.text_wrap .body_txt {
  padding: 0;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_06 .content_wrap {
  flex-direction: column;
  padding: 56px 0 82px 0;
}

.tit_box .common_tit,
.text_box .common_tit {
  margin-bottom: 25px;
}

.skeuomorphism {
  font-size: 43px;
}

.text_box .body_txt {
  font-size: 13px;
}

.content_wrap .tit_box {
  width: 100%;
  margin-bottom: 53px;
}

.content_wrap .text_box {
  width: 100%;
}

.dark_theme img,
.light_theme img {
  padding: 24px 0;
}

.example_img {
  flex-direction: column;
}

.example_img img {
  width: 100%;
}


} /*__end*/

@media screen and (max-width:425px) {

.containers_06 .content_wrap {
  max-width: 86.66666vw;
  padding: 32px 0 62px 0;
}

.tit_box .common_tit,
.text_box .common_tit {
  font-size: 10px;
  margin-bottom: 20px;
}

.skeuomorphism {
  font-size: 36px;
}

.content_wrap .tit_box {
  margin-bottom: 42px;
}

} /*__end*/


/*__반응형*/

</style>
