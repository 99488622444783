<template>
  <div class="content_containers">
    <div class="container_bottom">
      <div class="bottom_img"><img src="@/assets/img/2019_vogo/vogo_20.png" alt="image" style="display: none"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail8",
  data() {
  },
  components: {
  },
  methods: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}
/*__All*/

.container_bottom {
    position: relative;
    width: 100%;
    height: 108px;
    background-color: #ffd800;
    z-index: 1000;
}


.container_bottom div::after {
    content: "";
    position: absolute;
    bottom: 108px;
    right: 21.7%;
    width: 223PX;
    height: 108PX;
    background-image: url(../../../assets/img/2019_vogo/vogo_20.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/*__Default*/


/* Animation */

.container_bottom div::after {
    animation-name: btm_img;
    animation-duration: 12s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


@-webkit-keyframes btm_img {
    0% {
        transform: translateX(-100vw);
    }

    70% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100vw);
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {} /*__end*/

@media screen and (max-width:1024px) {
    .container_bottom {
        height: 86.4px;
    }

    .container_bottom div::after {
        min-width: 219.2px;
        height: 106.96px;
        bottom: 86.4px;
    }

} /*__end*/

@media screen and (max-width:768px) {} /*__end*/

@media screen and (max-width:425px) {
    .container_bottom {
        height: 54px;
    }

    .container_bottom div::after {
        bottom: 54px;
        right: 1%;
        height: 74px;
        background-size: 69%;
    }
    
} /*__end*/


/*__반응형*/

</style>
