
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail8",
  data() {
  },
  components: {
  },
  methods: {
  }
});
