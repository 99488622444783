<template>
<div class="content_containers">
            <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
                <div class="title_text">Car UX &amp; HUD Design</div>
                <div class="body_text">현대 &amp; 기아 2019년 차량용 UX 개발을 진행했습니다.<br>클러스터, 인포메이션 까지 다양하게 UX를 진행하고 HUD에 
                  <br class="active_425w">들어가는 AR 관련 그래픽과 UX 개발에도 참여했습니다.<br>&#42;현재 이미지는 프로젝트의 이해를 돕기 위해 제작된 
                  <br class="active_425w">포트폴리오 이며, 본 프로젝트의 결과물은 보안상 공개 할 수 
                  <br class="active_425w">없음을 알려드립니다.&#42;
                </div>
            </div>
            <div class="content_01">
                <div class="content_01_wrap">
                    <div class="cluster" :class="show1 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
                        <div class="title_cluster">Cluster Structure</div>
                        <div class="body_cluster">
                            <p>Speedmeter</p>
                            <p>Notification</p>
                            <p>Tachometer</p>
                            <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_01.jpg" alt="image">
                        </div>
                    </div>
                    <div class="content_01_right">
                        <div class="information" :class="show2 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
                            <div class="title_information">Information</div>
                            <ul>
                                <li>
                                    <p>Music</p>
                                </li>
                                <li>
                                    <p>Smart<br>Home</p>
                                </li>
                                <li>
                                    <p>Movie</p>
                                </li>
                            </ul>
                        </div>
                        <div class="headup" :class="show3 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                            <div class="title_headup">Head - Up Display</div>
                            <ul>
                                <li>
                                    <p>HUD</p>
                                </li>
                                <li>
                                    <p>Information</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="caption_01" :class="show4 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                <p>클러스터, 인포메이션, HUD 각각 환경에 맞는 구조를 설계했습니다.</p>
            </div>
            <div class="content_02">
                <div class="content_02_wrap">
                    <div class="title_content_02" :class="show5 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
                        <div class="title_text">Display ( Cluster + Infomation)</div>
                        <div class="body_text">Cluster + Infomation + AI로 이루어진 하나의 화면으로 <br class="active_425w">컨셉 디자인</div>
                    </div>
                    <div class="image_01" :class="show6 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver6"></ShowObserver>
                        <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_02.png" alt="image">
                    </div>
                    <div class="image_02" :class="show7 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver7"></ShowObserver>
                        <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_03.png" alt="image">
                    </div>
                    <div class="image_03" :class="show8 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver8"></ShowObserver>
                        <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_04.png" alt="image">
                    </div>
                    <div class="image_04" :class="show9 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver9"></ShowObserver>
                        <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_05.png" alt="image">
                    </div>
                    <div class="image_05" :class="show10 ? 'active' : ''" style="position: relative;">
                        <ShowObserver v-on:isShow="isShowObserver10"></ShowObserver>
                        <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_06.png" alt="image">
                    </div>
                </div>
                <div class="image_06" :class="show11 ? 'active' : ''" style="position: relative;">
                    <ShowObserver v-on:isShow="isShowObserver11"></ShowObserver>
                    <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_07.png" alt="image">
                    <img src="@/assets/img/2019_hyundai_kia/hyundai_kia_08.png" alt="image">
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "Hyundai_KiaDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
    isShowObserver6(value: boolean) {
      this.show6 = value;
    },
    isShowObserver7(value: boolean) {
      this.show7 = value;
    },
    isShowObserver8(value: boolean) {
      this.show8 = value;
    },
    isShowObserver9(value: boolean) {
      this.show9 = value;
    },
    isShowObserver10(value: boolean) {
      this.show10 = value;
    },
    isShowObserver11(value: boolean) {
      this.show11 = value;
    },
    
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    width: 100%;
    padding-top: 91px;
    content-visibility: auto;
}

/*main_text*/

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 188px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 72px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

/*content_01*/

.content_01 {
    padding: 75px 0 91px 0;
    background-color: #e4e4e4;
}

.content_01 [class^="title_"] {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #003ace;
    margin-bottom: 18px;
}

.content_01 .content_01_wrap {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}

.content_01_wrap .cluster {
    width: 64%;
    position: relative;
}

.content_01_wrap .cluster .body_cluster {
    position: relative;
}

.content_01_wrap .cluster .body_cluster p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
}

.content_01_wrap .cluster .body_cluster p:nth-child(1) {
    position: absolute;
    top: 46.16935%;
    left: 25.06510%;
}

.content_01_wrap .cluster .body_cluster p:nth-child(2) {
    position: absolute;
    top: 47.17741%;
    left: 49.86979%;
}

.content_01_wrap .cluster .body_cluster p:nth-child(3) {
    position: absolute;
    top: 46.16935%;
    right: 14.58333%;
}

.content_01_wrap .content_01_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32.5%;
    margin-left: 4.5%;
}


.content_01_right .information ul {
    width: calc(100% - 44px);
    height: 146px;
    display: flex;
    flex-direction: row;
    padding: 22px;
    border: 1px solid rgba(91, 91, 91, 0.5);
}

.content_01_right .information ul li {
    width: calc(100% - 16px / 3);
    border: 1px solid #5d5d5d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_01_right .information ul li:nth-child(1n+2) {
    margin-left: 8px;
}


.content_01_right .headup ul {
    width: calc(100% - 44px);
    height: 146px;
    display: flex;
    flex-direction: row;
    padding: 22px;
    border: 1px solid rgba(91, 91, 91, 0.5);

}


.content_01_right .headup ul li {
    width: calc(100% - 8px / 2);
    border: 1px solid #5d5d5d;
    display: flex;
    justify-content: center;
    align-items: center;
}


.content_01_right .headup ul li:last-child {
    margin-left: 8px;
}

.content_01_right div ul li p {
    font-size: 15px;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.8);
}

.content_containers .caption_01 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 36px 0 188px;
}

.content_containers .caption_01 p {
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

/*content_02*/

.content_containers .content_02 {
    position: relative;
    width: 100%;
    padding-top: 88px;
    background-image: linear-gradient(to bottom, #242637, #14151f);
}

.content_02_wrap {
    max-width: 1920px;
    margin: 0 auto;
}


.content_02_wrap .title_content_02 {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 92px;
}

.content_02_wrap .title_content_02 .title_text {
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    margin-bottom: 10px;
}

.content_02_wrap .title_content_02 .body_text {
    height: 24px;
    font-size: 16px;
    line-height: normal;
    color: rgba(255, 255, 255, 0.7);
}

.content_02_wrap .image_01 {
    width: 65.36458%;
    margin: 0 auto;
    margin-bottom: 167px;
}

.content_02_wrap .image_02 {
    width: 75%;
    margin-left: 16.25%;
}

.content_02_wrap .image_03 {
    position: relative;
    width: 66.875%;
    margin: -3.33333% -5.88541% 360px auto;
}

.content_02_wrap .image_04 {
    width: 66.875%;
    left: -5.88541%;
    position: relative;
    z-index: 10;
}

.content_02_wrap .image_05 {
    width: 56.927%;
    margin: 0 auto;
    margin-top: -4.84375%;
    margin-bottom: 424px;
}

.content_02 .image_06 {
    position: relative;
    width: 100vw;
    height: 56.5625vw;
    background-image: url(../../../assets/img/2019_hyundai_kia/hyundai_kia_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content_02 .image_06 img:nth-child(2) {
    position: absolute;
    width: 30.9375%;
    left: 13.3%;
    bottom: 16.9%;
}

.content_02 .image_06 img:last-child {
    position: absolute;
    width: 11.458333%;
    top: 36.5%;
    right: 27.6%;
}

/*__Default*/


/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

/* content_01 */

.cluster.active .title_cluster,
.information.active .title_information,
.headup.active .title_headup {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.cluster .title_cluster,
.information .title_information,
.headup .title_headup {
    opacity: 0;
}

.cluster.active .body_cluster img,
.information.active ul,
.headup.active ul {
    opacity: 1;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
} 

.cluster .body_cluster img,
.information ul,
.headup ul {
    opacity: 0;
} 

.cluster.active .body_cluster p,
.information.active ul li p,
.headup.active ul li p {
    opacity: 1;
    transition-delay: .8s;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.cluster.active .body_cluster p:nth-child(2),
.information.active ul li:nth-child(2) p,
.headup.active ul li:nth-child(2) p {
    transition-delay: .9s;
}

.cluster.active .body_cluster p:nth-child(3),
.information.active ul li:nth-child(3) p {
    transition-delay: 1s;
}

.cluster .body_cluster p,
.information ul li p,
.headup ul li p {
    opacity: 0;
}

.caption_01.active p {
    opacity: 1;
    transition-delay: .8s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.caption_01 p {
    opacity: 0;
}

/* content_02 */

.title_content_02.active .title_text,
.title_content_02.active .body_text {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.title_content_02 .title_text,
.title_content_02 .body_text {
    opacity: 0;
}

.image_01.active img {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: .8s;
    transition-timing-function: ease-in-out;
}

.image_01 img {
    opacity: 0;
}

.image_02.active img {
    opacity: 1;
    transition-delay: 1s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_02 img {
    opacity: 0;
}

.image_03.active img {
    opacity: 1;
    transform: translateX(0);
    transition-duration: .8s;
    transition-timing-function: ease-in-out;
}

.image_03 img {
    opacity: 0;
    transform: translateX(20%);
}

.image_04.active img {
    opacity: 1;
    transform: translateX(0);
    transition-duration: .8s;
    transition-timing-function: ease-in-out;
}

.image_04 img {
    opacity: 0;
    transform: translateX(-20%);
}

.image_05.active img {
    opacity: 1;
    transition-delay: .8s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_05 img {
    opacity: 0;
}

.image_06.active img {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_06 img {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text,
    .content_containers > .caption_01,
    .content_01 .content_01_wrap,
    .content_02_wrap > .title_content_02 {
        max-width: 90%;
    }

    .content_01_right .information ul,
    .content_01_right .headup ul {
        height: 120px;
    }

} /*__end*/

@media screen and (max-width:1024px) {
      .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

    .content_01 [class^="title_"] {
        font-size: 20px;
        margin-bottom: 14px;
    }

    .content_01_wrap .cluster .body_cluster p {
        font-size: 12px;
    }

    .content_01_right .information ul,
    .content_01_right .headup ul {
        widows: calc(100% - 36px);
        height: 100px;
        padding: 18px;
    }

    .content_01_right div ul li p {
        font-size: 12px;
    }


    .content_containers .caption_01 p {
        font-size: 14px;
    }

    .content_02_wrap .title_content_02 .title_text {
        height: 32px;
        font-size: 20px;
        margin-bottom: 8px;
    }

    .content_02_wrap .title_content_02 .body_text {
        height: 22px;
        font-size: 14px;
    }

} /*__end*/

@media screen and (max-width:850px) {
    .content_01 .content_01_wrap {
        flex-direction: column;
        align-items: center;
    }

    .content_01_wrap .cluster,
    .content_01_wrap .content_01_right {
        width: 100%;
    }

    .content_01_wrap .content_01_right {
        margin-left: 0;
    }

    .content_01_right .information ul,
    .content_01_right .headup ul {
        height: 150px;

    }

    .content_01_wrap .cluster,
    .content_01_wrap .content_01_right .information {
        margin-bottom: 36px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_02_wrap .image_01 {
        width: 100%;
    }

    .content_02_wrap .image_03,
    .content_02_wrap .image_04 {
        width: 93.61111%;
    }

    .content_02_wrap .image_02 {
        width: 100%;
    }

    .content_02_wrap .image_05 {
        width: 100%;
    }


    .content_02 .image_06 {
        height: 74.44444vw;
        background-size: 140%;
    }
 
    .content_02 .image_06 img:nth-child(2){
        position: absolute;
        width: 36.9375%;
        left: 7.3%;
        bottom: 16.9%;
    }

    .content_02 .image_06 img:last-child {
        position: absolute;
        width: 13.458333%;
        top: 35.5%;
        right: 21%;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text,
    .content_containers > .caption_01,
    .content_02_wrap > .title_content_02 {
        max-width: 86.66666vw;
    }

   .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 98px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 108px;
        font-size: 12px;
        line-height: 1.5;
    }

    /*content_01*/

    .content_01 {
        padding: 36px 0 36px 0;
    }

    .content_01 .content_01_wrap {
        width: 100%;
        max-width: inherit;
    }

    .content_01 [class^="title_"] {
        max-width: 86.66666vw;
        font-size: 14px;
        margin: 0 auto;
        margin-bottom: 12px;
    }

    .content_01_wrap .cluster .body_cluster {
        overflow-x: hidden;
    }

    .content_01_wrap .cluster .body_cluster p {
        font-size: 8px;
    }

    .content_01_wrap .cluster .body_cluster img {
        width: 110%;
        margin-left: -5%;
    }

    .content_01_wrap .cluster .body_cluster p:nth-child(1) {
        left: 21%;
    }

    .content_01_wrap .cluster .body_cluster p:nth-child(2) {
        left: 48%;
    }

    .content_01_wrap .cluster .body_cluster p:nth-child(3) {
        right: 10%;
    }

    .content_01_wrap .content_01_right {
        max-width: 86.66666vw;
    }

    .content_01_right .information ul,
    .content_01_right .headup ul {
        height: 114px;
    }

    .content_01_right div ul li p {
        font-size: 10px;
        line-height: normal;
    }

    /*__content_01*/

    .content_containers .caption_01 {
        padding: 18px 0 80px;
    }

    .content_containers .caption_01 p {
        height: 36px;
        font-size: 12px;
    }

    /*content_02*/
    .content_containers .content_02 {
        padding-top: 36px;
    }

    .content_02_wrap .title_content_02 {
        margin-bottom: 22px;
    }

    .content_02_wrap .title_content_02 .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_02_wrap .title_content_02 .body_text {
        height: 36px;
        font-size: 12px;
    }

    .content_02_wrap .image_01 {
        margin-bottom: 55px;
    }

    .content_02_wrap .image_02 {
        margin-left: inherit;
    }

    .content_02_wrap .image_03 {
        margin-bottom: 128px;
    }

    .content_02_wrap .image_05 {
        margin-bottom: 86px;
    }

    .content_02 .image_06 {
        background-size: 134%;
        background-position: 40%;
    }

    .content_02 .image_06 img:nth-child(2) {
        width: 40%;
        left: 8.3%;
    }

    .content_02 .image_06 img:last-child {
        width: 15.458333%;
        right: 16.7%;
    }

} /*__end*/


/*__반응형*/

</style>