<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="sub-title" :class="show0 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver> 
        <h3>COLOR SYSTEM</h3>
        <p class="inactive_820w">
          그라데이션 표현을 통해 우주의 다채로움을 표현했으며<br>
          배경에 Blue Gray Color 사용을 통해 전체적인 색감이 풍부해 보이도록 했습니다
        </p>
        <p class="active_820w inline">
          둥근 모서리와 마감을 적용해 원형의 knob 형태와<br>
          이질감없이 어우러지도록 했습니다
        </p>
      </div>
      <div class="graphics" :class="show1 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver> 
        <div class="item-01">
          <div class="content">
            <span class="title">Primary</span>
            <span class="text">
              #B44EFC&nbsp; 
              <img src="@/assets/img/2021_seoyoneh/detail04-arrow.svg" alt="arrow">&nbsp; 
              #4758FF
            </span>
          </div>
          <div class="bg"></div>
        </div>
        <div class="item-02">
          <div class="content">
            <span class="title">Secondary</span>
            <span class="text">#6A6CED</span>
          </div>
          <div class="bg"></div>
        </div>
        <div class="item-03">
          <div class="content">
            <span class="title">Secondary</span>
            <span class="text">#1C7AFF</span>
            <span class="text">#393DCB</span>
            <span class="text">#CF14FF</span>
          </div>
          <div class="bg"></div>
        </div>
        <div class="item-04">
          <div class="content">
            <span class="title">BG</span>
            <span class="text">
              #39466D&nbsp; 
              <img src="@/assets/img/2021_seoyoneh/detail04-arrow.svg" alt="arrow">&nbsp; 
              #202B47
            </span>
          </div>
          <div class="bg"></div>
        </div>
      </div>
      <div class="visual-motion">
        <video src="@/assets/img/2021_seoyoneh/2021_seoyon_pc_motion_1.mp4" autoplay loop muted playsinline></video>
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail4",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  display: flex;
  width: 100vw;
  color: #fff;
  background:  #131C33;
  font-family: var(--pretendard);
}

.container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(var(--responsive) * 1200);
  margin: 0 auto;
}

/* sub title */

.sub-title {
  width: 100%;
  margin-bottom: calc(var(--responsive) * 71);
  text-align: center;
}

.sub-title h3 {
  font-size: calc(var(--responsive) * 23);
  line-height: 1.217em;
  font-weight: 400;
  margin-bottom: 0.826em;
}

.sub-title p {
  font-size: calc(var(--responsive) * 14);
  line-height: 1.214em;
  font-weight: 400;
  color: #65749D;
}

/* graphics */

.graphics {
  position: relative;
  width: calc(var(--responsive) * 1161);
  height: calc(var(--responsive) * 436);
  margin-bottom: calc(var(--responsive) * 155.47);
  /* background: rgba(255, 255, 255, 0.1); */
}

.graphics [class^='item-0'] {
  display: flex;
  position: absolute;
  justify-content: center;
}

.graphics [class^='item-0'] .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

[class^='item-0']::before {
  content: '';
  position: absolute;
  display: flex;
  z-index: 0;
  border-radius: 100%;
  font: calc(var(--responsive) * 100);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.content {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  z-index: 10;
  justify-content: center;
  align-items: flex-start;
}

.content img {
  width: calc(var(--responsive) * 31.99);
  margin-bottom: calc(var(--responsive) * 4);
}

.title {
  color: #fff;
  margin-bottom: 0.417em;
  font-weight: 400;
  line-height: 1.083em;
}

.text {
  display: flex;
  justify-content: center;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.214em;
}

/* 01 */

.item-01 {
  width: calc(var(--responsive) * 279);
  height: calc(var(--responsive) * 279);
  top: calc(var(--responsive) * 36);
  left: calc(var(--responsive) * 24);
}

.item-01 .title {
  font-size: calc(var(--responsive) * 24);
}

.item-01 .text {
  font-size: calc(var(--responsive) * 16);
}

.item-01 .bg {
  background-image: url("../../../assets/img/2021_seoyoneh/detail04-img-01.webp");
}

.item-01::before {
  width: calc(var(--responsive) * 260);
  height: calc(var(--responsive) * 260);
  top: calc(var(--responsive) * 32);
  /* left: calc(var(--responsive) * 13); */
  background: linear-gradient(48.41deg, #B44EFC 13.9%, #4758FF 105.4%);
  opacity: 0.45;
  filter: blur(0.761em);
  -webkit-filter: blur(0.761em);
}

/* 02 */

.item-02 {
  width: calc(var(--responsive) * 168);
  height: calc(var(--responsive) * 168);
  top: calc(var(--responsive) * 210);
  left: calc(var(--responsive) * 307);
}

.item-02 .title {
  font-size: calc(var(--responsive) * 22);
}

.item-02 .text {
  font-size: calc(var(--responsive) * 14);
}

.item-02 .bg {
  background-image: url("../../../assets/img/2021_seoyoneh/detail04-img-02.webp");
}

.item-02::before {
  width: calc(var(--responsive) * 148);
  height: calc(var(--responsive) * 148);
  top: calc(var(--responsive) * 29);
  /* left: calc(var(--responsive) * 12); */
  background: linear-gradient(62.97deg, #6A6CED 11.79%, rgba(106, 108, 237, 0.556687) 88.05%);
  opacity: 0.61;
  filter: blur(0.516em);
  -webkit-filter: blur(0.516em);
}

/* 03 */

.item-03 {
  width: calc(var(--responsive) * 206);
  height: calc(var(--responsive) * 206);
  top: calc(var(--responsive) * 15);
  left: calc(var(--responsive) * 559);
}

.item-03 .title {
  font-size: calc(var(--responsive) * 22);
}

.item-03 .text {
  font-size: calc(var(--responsive) * 14);
}

.item-03 .text:not(.item-03 span:last-child) {
  margin-bottom: 0.429em;
}


.item-03 .bg {
  background-image: url("../../../assets/img/2021_seoyoneh/detail04-img-03.webp");
}

.item-03::before {
  width: calc(var(--responsive) * 206);
  height: calc(var(--responsive) * 206);
  top: calc(var(--responsive) * 20);
  /* left: calc(var(--responsive) * 11); */
  background: conic-gradient(from 90deg at 50% 50%, #1C7AFF -90deg, #CF14FF 43.48deg, #CF14FF 44.21deg, #4758FF 171.47deg, #1C7AFF 270deg, #CF14FF 403.48deg);
  opacity: 0.24;
  filter: blur(1.2em);
  -webkit-filter: blur(1.2em);
}

/* 04 */

.item-04 {
  width: calc(var(--responsive) * 279);
  height: calc(var(--responsive) * 279);
  top: calc(var(--responsive) * 99);
  left: calc(var(--responsive) * 845);
}

.item-04 .title {
  font-size: calc(var(--responsive) * 24);
}

.item-04 .text {
  font-size: calc(var(--responsive) * 16);
}


.item-04 .bg {
  background-image: url("../../../assets/img/2021_seoyoneh/detail04-img-04.webp");
}

.item-04::before {
  width: calc(var(--responsive) * 265);
  height: calc(var(--responsive) * 265);
  top: calc(var(--responsive) * 40);
  /* left: calc(var(--responsive) * 22); */
  background: linear-gradient(48.41deg, #39466D 13.9%, #202B47 105.4%);
  opacity: 0.49;
  filter: blur(0.707em);
  -webkit-filter: blur(0.707em);
}

/* visual motion */

.visual-motion {
  width: 100vw;
  height: calc(var(--responsive) * 1080);
}

.visual-motion video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.sub-title,
.graphics .item-01,
.graphics .item-02,
.graphics .item-03,
.graphics .item-04 {
  opacity: 0;
}

.sub-title.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.graphics.active .item-01 {
  animation: ani01 1000ms ease-in-out 400ms 1 forwards;
}

.graphics.active .item-02 {
  animation: ani01 1000ms ease-in-out 600ms 1 forwards;
}

.graphics.active .item-03 {
  animation: ani01 1000ms ease-in-out 1000ms 1 forwards;
}

.graphics.active .item-04 {
  animation: ani01 1000ms ease-in-out 1400ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container-inner {
    width: calc(var(--responsive) * 280)
  }


  /* sub title */

  .sub-title {
    margin-bottom: calc(var(--responsive) * 63.5);
  }

  .sub-title h3 {
    font-size: calc(var(--responsive) * 17);
    line-height: 1.176em;
    margin-bottom: 1.176em;
  }

  .sub-title p {
    font-size: calc(var(--responsive) * 13);
    line-height: 1.692em;
  }

  /* graphics */

  .graphics {
    width: 100%;
    height: calc(var(--responsive) * 719);
  }

  .content img {
    width: calc(var(--responsive) * 20.18);
    margin-bottom: calc(var(--responsive) * 3);
  }

  .title {
    line-height: 1.200em;
  }

  .text {
    line-height: 1.182em;
  }

  /* 01 */

  .item-01 {
    width: calc(var(--responsive) * 176);
    height: calc(var(--responsive) * 176);
    top: 0;
    left: calc(var(--responsive) * 66.5);
  }

  .item-01 .title {
    font-size: calc(var(--responsive) * 18);
  }

  .item-01 .text {
    font-size: calc(var(--responsive) * 11);
  }

  .item-01::before {
    width: calc(var(--responsive) * 164);
    height: calc(var(--responsive) * 164);
    top: calc(var(--responsive) * 27);
    /* left: calc(var(--responsive) * 6); */
    filter: blur(0.489em);
    -webkit-filter: blur(0.489em);
  }

  /* 02 */

  .item-02 {
    width: calc(var(--responsive) * 105);
    height: calc(var(--responsive) * 105);
    top: calc(var(--responsive) * 192.5);
    left: calc(var(--responsive) * 25.5);
  }

  .item-02 .title {
    font-size: calc(var(--responsive) * 15);
  }

  .item-02 .text {
    font-size: calc(var(--responsive) * 10);
  }

  .item-02::before {
    width: calc(var(--responsive) * 93);
    height: calc(var(--responsive) * 93);
    top: calc(var(--responsive) * 20);
    filter: blur(0.272em);
    -webkit-filter: blur(0.272em);
  }

  /* 03 */

  .item-03 {
    width: calc(var(--responsive) * 130);
    height: calc(var(--responsive) * 130);
    top: calc(var(--responsive) * 341.5);
    left: calc(var(--responsive) * 125.5);
  }

  .item-03 .title {
    font-size: calc(var(--responsive) * 15);
  }

  .item-03 .text {
    font-size: calc(var(--responsive) * 10);
  }

  .item-03::before {
    width: calc(var(--responsive) * 125);
    height: calc(var(--responsive) * 125);
    top: calc(var(--responsive) * 13.89);
    /* left: calc(var(--responsive) * 10); */
    opacity: 0.3;
    filter: blur(1em);
    -webkit-filter: blur(1em);
  }

  /* 04 */

  .item-04 {
    width: calc(var(--responsive) * 176);
    height: calc(var(--responsive) * 176);
    top: calc(var(--responsive) * 512.5);
    left: calc(var(--responsive) * 31.5);
  }

  .item-04 .title {
    font-size: calc(var(--responsive) * 18);
  }

  .item-04 .text {
    font-size: calc(var(--responsive) * 11);
  }

  .item-04::before {
    width: calc(var(--responsive) * 176);
    height: calc(var(--responsive) * 176);
    top: calc(var(--responsive) * 9);
    /* left: calc(var(--responsive) * 6); */
    filter: blur(0.489em);
    -webkit-filter: blur(0.489em);
  }

  /* visual motion */

  .visual-motion {
    height: calc(var(--responsive) * 452);
  }

} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
