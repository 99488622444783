<template>
<div class="content_containers">
    <div class="main_text" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title_text">Galaxy Friends<br class="active_425w"> LED Case Theme Design</div>
        <div class="body_text">갤럭시 시리즈에 스마트 케이스를 씌우면 테마가 적용되는 갤럭시 프렌즈입니다.<br class="inactive_425w">
            갤럭시 프렌즈 케이스를 씌울 시, 적용되는<br class="active_425w"> 테마와 그 테마가 적용되는 동안의 loading UX, LED icon의 Motion을 작업했습니다.</div>
    </div>
    <div class="content_wrap">
        <div class="image_01" :class="show1 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
            <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_01.png" alt="image" style="height: 0;">
        </div>
        <div class="caption_01" :class="show2 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
            <p>갤럭시 프렌즈 LED 케이스는 White / Black 두 가지로 제공됩니다. 이에 맞도록 loading, Motion 또한 2가지 버전으로 제작했습니다.</p>
        </div>
        <div class="image_wrap">
            <div class="image_02_wrap" :class="show3 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
                <div class="img_02_tit"><h3>Black Case</h3></div>
                <div class="image_02">
                    <div class="img_02_01">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_02.jpg" alt="image">
                    </div>
                    <div class="img_02_02">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_03.jpg" alt="image">
                    </div>
                    <div class="img_02_03">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_04.jpg" alt="image">
                    </div>
                    <div class="img_02_04">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_05.jpg" alt="image">
                    </div>
                </div>
            </div>
            <div class="image_03" :class="show4 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
                <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_06.jpg" alt="image">
            </div>
            <div class="image_04_wrap" :class="show5 ? 'active' : ''" style="position: relative;">
                <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver>
                <div class="img_04_tit"><h3>White Case</h3></div>
                <div class="image_04">
                    <div class="img_04_01">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_07.jpg" alt="image">
                    </div>
                    <div class="img_04_02">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_08.jpg" alt="image">
                    </div>
                    <div class="img_04_03">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_09.jpg" alt="image">
                    </div>
                    <div class="img_04_04">
                        <img src="@/assets/img/2019_galaxy_friends/galaxy_friends_10.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "GalaxyFriendsDetail1",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.content_containers {
    width: 100%;
    padding-top: 91px;
    content-visibility: auto;
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 146px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.content_wrap .image_01 {
    width: 100%;
    height: 0;
    padding-bottom: 34.84375%;
    background-image: url(../../../assets/img/2019_galaxy_friends/galaxy_friends_01.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 36px;
}


.content_wrap .caption_01 p {
    display: block;
    max-width: 1200px;
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
    margin: 0 auto;
    margin-bottom: 232px;
}


.content_wrap .image_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_wrap .image_wrap .img_02_tit {
    position: relative;
    height: 45px;
    font-size: 30px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 48px;
}

.content_wrap .image_wrap .img_02_tit::before {
    content: "";
    position: absolute;
    top: 26.5px;
    width: 100%;
    height: 2px;
    background-color: #000;
}


.content_wrap .image_02 {
    display: flex;
    margin-bottom: 243px;
    margin-left: -24px;
}

.content_wrap .image_02 div {
    padding-left: 24px;
}

.content_wrap .image_02 div img {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
}

.content_wrap .image_wrap .image_03 {
    max-width: 58.66666%;
    padding: 22.5px 0;
    margin: 0 auto;
    margin-bottom: 242px;
}

.content_wrap .image_wrap .img_04_tit {
    position: relative;
    height: 45px;
    font-size: 30px;
    font-weight: bold;
    line-height: normal;
    color: #acacac;
    margin-bottom: 48px;
}

.content_wrap .image_wrap .img_04_tit::before {
    content: "";
    position: absolute;
    top: 26.5px;
    width: 100%;
    height: 2px;
    background-color: #acacac;
}

.content_wrap .image_04 {
    display: flex;
    margin-bottom: 381px;
    margin-left: -24px;
}

.content_wrap .image_04 div {
    padding-left: 24px;
}

.content_wrap .image_04 div img {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
}

/*__Default*/

/* Animation */

.main_text.active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.main_text {
    opacity: 0;
    transform: translateY(30px);
}

.image_01.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_01 {
    opacity: 0;
}

.caption_01.active p {
    opacity: 1;
    transition-delay: .3s;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.caption_01 p {
    opacity: 0;
}

.image_02_wrap.active .img_02_tit::before,
.image_04_wrap.active .img_04_tit::before{
    animation-name: title_line;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes title_line {

    0% {
        opacity: 0;
        width: 0;
    }

    100% {
        opacity: 1;
        width: 100%;
    }
}

.image_02_wrap.active .img_02_tit h3,
.image_04_wrap.active .img_04_tit h3 {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_02_wrap .img_02_tit h3,
.image_04_wrap .img_04_tit h3 {
    opacity: 0;
}

.image_02_wrap.active .image_02 div,
.image_04_wrap.active .image_04 div {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_02_wrap .image_02 div,
.image_04_wrap .image_04 div {
    opacity: 0;
}

.image_02_wrap.active .image_02 .img_02_01,
.image_04_wrap.active .image_04 .img_04_01 {
    transition-delay: .1s;
}

.image_02_wrap.active .image_02 .img_02_02,
.image_04_wrap.active .image_04 .img_04_02 {
    transition-delay: .2s;
}

.image_02_wrap.active .image_02 .img_02_03,
.image_04_wrap.active .image_04 .img_04_03 {
    transition-delay: .3s;
}

.image_02_wrap.active .image_02 .img_02_04,
.image_04_wrap.active .image_04 .img_04_04 {
    transition-delay: .4s;
}

.image_03.active {
    opacity: 1;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.image_03 {
    opacity: 0;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text,
    .content_wrap .caption_01 p,
    .content_wrap .image_wrap {
        max-width: 90%;
    }

    .content_wrap .image_01 {
        min-height: 348px;
        padding-bottom: 0;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }

    .content_wrap .caption_01 p {
        font-size: 14px;
    }

    .content_wrap .image_wrap .img_02_tit,
    .content_wrap .image_wrap .img_04_tit {
        height: 40px;
        font-size: 26px;
        margin-bottom: 44px;
    }

    .content_wrap .image_wrap .img_02_tit::before,
    .content_wrap .image_wrap .img_04_tit::before {
        top: 22.5px;
    }

    .content_wrap .image_wrap .image_03 {
        max-width: 75%;
        margin-bottom: 242px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .main_text .title_text {
        height: 118px;
    }


    .content_wrap .image_02,
    .content_wrap .image_04 {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
    }

    .content_wrap .image_02 div,
    .content_wrap .image_04 div {
        width: calc(50% - 12px);
        padding-left: 12px;
        margin-bottom: 12px;
    }

} /*__end*/

@media screen and (max-width:425px) {
    .content_containers > .main_text,
    .content_wrap .caption_01 p,
    .content_wrap .image_wrap {
        max-width: 86.66666vw;
    }
    
    .content_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 122px;
    }

    .content_containers .main_text .title_text {
        height: 58px;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .content_containers .main_text .body_text {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
    }

    .content_wrap .image_01 {
        margin-bottom: 18px;
    }

    .content_wrap .caption_01 p {
        height: 72px;
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 144px;
    }

    .content_wrap .image_wrap .img_02_tit,
    .content_wrap .image_wrap .img_04_tit {
        height: 25px;
        font-size: 17px;
        margin-bottom: 44px;
    }

    .content_wrap .image_wrap .img_02_tit::before,
    .content_wrap .image_wrap .img_04_tit::before {
        top: 14.5px;
        height: 1.5px;
    }

    .content_wrap .image_02 {
        margin-bottom: 168px;
    }

    .content_wrap .image_03 {
        margin-bottom: 172px;
    }

    .content_wrap .image_04 {
        margin-bottom: 153px;
    }

} /*__end*/


/*__반응형*/

</style>