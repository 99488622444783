<template>
  <div class="container">
    <div class="container-inner">
      <div class="phone-01" :class="show0 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section04-phone01.webp"
          alt="image"
        />
      </div>
      <div class="phone-02" :class="show1 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section04-phone02.webp"
          alt="image"
        />
      </div>
      <div class="graphics-01" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <img
          src="@/assets/img/2021_KBbank/section04-graphics01.svg"
          alt="image"
        />
      </div>
      <div class="label-01" :class="show3 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <b>KB Mobile Branch</b>홈화면의 예금, 적금 상품 디자인 리뉴얼
      </div>
    </div>
    <!-- //inner -->
    <div class="icons" :class="show4 ? 'active' : ''">
      <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
      <div class="icon-01">
        <img
          src="@/assets/img/2021_KBbank/section04-icon01.webp"
          alt="icon-01"
        />
      </div>
      <div class="icon-02">
        <img
          src="@/assets/img/2021_KBbank/section04-icon02.webp"
          alt="icon-02"
        />
      </div>
      <div class="icon-03">
        <img
          src="@/assets/img/2021_KBbank/section04-icon03.webp"
          alt="icon-03"
        />
      </div>
      <div class="icon-04">
        <img
          src="@/assets/img/2021_KBbank/section04-icon04.webp"
          alt="icon-04"
        />
      </div>
      <div class="icon-05">
        <img
          src="@/assets/img/2021_KBbank/section04-icon05.webp"
          alt="icon-05"
        />
      </div>
      <div class="icon-06">
        <img
          src="@/assets/img/2021_KBbank/section04-icon06.webp"
          alt="icon-06"
        />
      </div>
      <div class="icon-07">
        <img
          src="@/assets/img/2021_KBbank/section04-icon07.webp"
          alt="icon-07"
        />
      </div>
      <div class="icon-08">
        <img
          src="@/assets/img/2021_KBbank/section04-icon08.webp"
          alt="icon-08"
        />
      </div>
      <div class="icon-09">
        <img
          src="@/assets/img/2021_KBbank/section04-icon09.webp"
          alt="icon-09"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "KookminBankDetail4",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 1320);
  position: relative;
  background: #fff;
  font-family: var(--pretendard);
  z-index: -1;
}

.container-inner {
  position: relative;
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  font-size: calc(var(--responsive) * 100);
}

/* phone */

.phone-01,
.phone-02 {
  display: flex;
  position: absolute;
  width: calc(var(--responsive) * 276);
  height: calc(var(--responsive) * 573);
  z-index: 2;
  font-size: 1em;
  border-radius: 0.42em;
  box-shadow: 0em 0.1em 0.16em rgba(0, 0, 0, 0.2);
}

.phone-01 {
  top: calc(var(--responsive) * 261);
  left: calc(var(--responsive) * 290);
}

.phone-02 {
  top: calc(var(--responsive) * 485);
  left: calc(var(--responsive) * 644);
}

/* graphics */

.graphics-01 {
  display: flex;
  position: absolute;
  z-index: 3;
  width: calc(var(--responsive) * 230);
  height: calc(var(--responsive) * 365);
  top: calc(var(--responsive) * 693);
  left: calc(var(--responsive) * 825);
}

/* label */

.label-01 {
  position: absolute;
  font-size: calc(var(--responsive) * 16);
  font-weight: 400;
  line-height: 1.188em;
  z-index: 3;
  top: calc(var(--responsive) * 983);
  left: 0;
}

.label-01 b {
  font-size: calc(var(--responsive) * 24);
  font-weight: 700;
  line-height: 1.75em;
  margin-right: 0.667em;
}

/* icons */

.icons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icons [class^="icon-"] {
  position: absolute;
  display: flex;
  width: calc(var(--responsive) * 147);
  height: calc(var(--responsive) * 147);
  opacity: 0.13;
}

.icon-01 {
  top: calc(var(--responsive) * -80);
  left: 94.8%;
}

.icon-02 {
  top: calc(var(--responsive) * 82);
  left: 10.8%;
}

.icon-03 {
  top: calc(var(--responsive) * 265);
  left: 44.9%;
}

.icon-04 {
  top: calc(var(--responsive) * 360);
  left: 0;
}

.icon-05 {
  top: calc(var(--responsive) * 521);
  left: 85.2%;
}

.icon-06 {
  top: calc(var(--responsive) * 712);
  left: 26.7%;
}

.icon-07 {
  top: calc(var(--responsive) * 994);
  left: 47.1%;
}

.icon-08 {
  top: calc(var(--responsive) * 994);
  left: 94.8%;
}

.icon-09 {
  top: calc(var(--responsive) * 1197);
  left: 3.3%;
}

/*__Default*/

/* Animation */
.phone-01,
.phone-02,
.graphics-01,
.label-01,
.icons [class^="icon-"] {
  opacity: 0;
}

.phone-01.active,
.phone-02.active,
.graphics-01.active,
.label-01.active {
  animation: ani01 1200ms ease-in-out 1 forwards;
}

.graphics-01.active {
  animation-delay: 800ms;
}

.icons.active [class^="icon-"] {
  animation: ani02 1800ms ease-in-out 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.13;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container {
    height: calc(var(--responsive) * 662);
  }

  .container-inner {
    width: calc(var(--responsive) * 312);
    height: 100%;
  }

  /* phone */

  .phone-01,
  .phone-02 {
    width: calc(var(--responsive) * 147);
    height: calc(var(--responsive) * 303);
  }

  .phone-01 {
    top: calc(var(--responsive) * 164);
    left: calc(var(--responsive) * -87);
  }

  .phone-02 {
    top: calc(var(--responsive) * 164);
    left: calc(var(--responsive) * 83);
  }

  /* graphics */

  .graphics-01 {
    width: calc(var(--responsive) * 119.13);
    height: calc(var(--responsive) * 188.42);
    top: calc(var(--responsive) * 277);
    left: calc(var(--responsive) * 190);
  }

  /* label */

  .label-01 {
    width: 100%;
    text-align: center;
    font-size: calc(var(--responsive) * 12);
    display: flex;
    flex-direction: column;
    top: calc(var(--responsive) * 499);
  }

  .label-01 b {
    line-height: normal;
    margin-right: initial;
    font-size: 1.167em;
    margin-bottom: 0.571em;
  }

  /* icons */

  .icons [class^="icon-"] {
    width: calc(var(--responsive) * 49.45);
    height: calc(var(--responsive) * 49.45);
  }

  .icon-01 {
    top: calc(var(--responsive) * 38);
    left: 90.3%;
  }

  .icon-02 {
    top: calc(var(--responsive) * 168.49);
    left: 33.9861%;
  }

  .icon-03 {
    top: calc(var(--responsive) * 376.05);
    left: 42.41111%;
  }

  .icon-04 {
    top: calc(var(--responsive) * 127.01);
    left: 6.58055%;
  }

  .icon-05 {
    top: calc(var(--responsive) * 438.16);
    left: 62.32222%;
  }

  .icon-06 {
    top: calc(var(--responsive) * 348.41);
    left: 25.4472%;
  }

  .icon-07 {
    top: calc(var(--responsive) * 626.27);
    left: 18.5583%;
  }

  .icon-08 {
    top: calc(var(--responsive) * 531.27);
    left: 87.75833%;
  }

  .icon-09 {
    top: calc(var(--responsive) * -11.45);
    left: 41.9%;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
