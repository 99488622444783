<template>
        <div class="branding_containers" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
          <div class="branding_sub_text numbering">
            <div class="sub_text_left">BRANDING</div>
            <div class="sub_text_right">02</div>
          </div>
          <div class="branding_content content_wrap">
            
            <!-- wrap -->
            <div class="branding_left_item">
              <div class="b_left_img1">
                <img src="@/assets/img/2021_ccb/branding_01.jpg" alt="image" class="inactive_1024w">
                <img src="@/assets/img/2021_ccb/m_branding_02.jpg" alt="image" class="active_1024w">
              </div>
              <div class="b_left_img2"><img src="@/assets/img/2021_ccb/branding_02.jpg" alt="image"
                  class="inactive_1024w"><img src="@/assets/img/2021_ccb/m_branding_01.jpg" alt="image" class="active_1024w">
              </div>
              <div class="b_left_img3"><img src="@/assets/img/2021_ccb/branding_03.jpg" alt="image"
                  class="inactive_1024w"><img src="@/assets/img/2021_ccb/m_branding_04.jpg" alt="image" class="active_1024w">
              </div>
            </div>
            <div class="branding_center_item">
              <ul >
                <li class="tit_text">Branding</li>
                <li class="body_text">개인 맞춤형 화장품 제공으로 단순 소모품인 <br class="active_425w">화장품에서 벗어나<br
                    class="inactive_768w">
                  소비자 자신에 대해 알아가고, <br class="active_425w">지속적 가치 소비를 통한 라이프스타일의<br class="inactive_768w">
                  변화를 <br class="active_425w">제공하는 것으로 기획하였습니다.<br><br>
                  시각적 편안함을 제공하기 위해 베이지,그린, 블루, <br class="active_425w">레드, 오렌지와 같은 자연<br class="inactive_768w">
                  의 컬러와 자연물을 사용하여 'True Vegan'을 시각화했습니다.<br><br>
                  제품 패키지는 '맞춤형 화장품'이라는 강점을 <br class="active_425w">소비자에게 제공하기 위해,<br class="inactive_768w">
                  그리드 시스템을 사용하여 <br class="active_425w">맞춤형 정보를 쉽게 확인 가능하도록<br class="active_425w"><br
                    class="inactive_768w">
                  디자인하였습니다.</li>
              </ul>
            </div>
            <div class="branding_right_item">
              <div class="b_right_img1"><img src="@/assets/img/2021_ccb/branding_04.jpg" alt="image"
                  class="inactive_1024w"><img src="@/assets/img/2021_ccb/m_branding_06.jpg" alt="image" class="active_1024w">
              </div>
              <div class="b_right_img2"><img src="@/assets/img/2021_ccb/branding_05.jpg" alt="image"
                  class="inactive_1024w"><img src="@/assets/img/2021_ccb/m_branding_05.jpg" alt="image" class="active_1024w">
              </div>
              <div class="b_right_img3"><img src="@/assets/img/2021_ccb/branding_06.jpg" alt="image" class="inactive_1024w">
              </div>
            </div>
          </div><!-- //wrap -->
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail2",
  data() {
    return {
      show0: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #393531;
}
/*__ALl*/

.branding_content {
    display: flex;
    align-items: center;
    margin-top: 64px;
    position: relative;
    padding-bottom: 206px;
}

.branding_content .branding_center_item ul li:nth-child(1) {
    height: 75px;
    margin-bottom: 46px;
}

.branding_content .branding_center_item ul li {
    text-align: center;
}

.branding_left_item {
    position: relative;
}

.branding_left_item div {
    position: relative;
}

.branding_left_item div:nth-child(1) {
    top: 18px;
    left: 89px;
    max-width: 254px;
}

.branding_left_item div:nth-child(3) {
    z-index: 1;
    max-width: 255px;
}

.branding_left_item div:nth-child(3) {
    top: 42px;
    left: 235px;
    max-width: 155px;
}

.branding_center_item {
    width: 100%;
    position: absolute;
    margin: 0 auto;
    margin-top: -22px;
}

.branding_right_item {
    position: relative;
    margin-left: auto;
}

.branding_right_item div {
    position: relative;
}

.branding_right_item div:nth-child(1) {
    top: -28px;
    right: 23px;
    max-width: 183px;
}

.branding_right_item div:nth-child(2) {
    top: 132px;
    left: 99px;
    z-index: 1;
    max-width: 223px;
}

.branding_right_item div:nth-child(3) {
    top: 88px;
    right: 99px;
    max-width: 322px;
}

/*__Default*/


/* Animation */

.branding_containers.active .branding_left_item .b_left_img1 {
    transition-delay: .8s;
    transition-duration: 0.8s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_left_item .b_left_img1 {
    transform: translate3d(-40px, -40px, 0);
}

.branding_containers.active .branding_left_item .b_left_img2 {
    transition-delay: 1.2s;
    transition-duration: 0.8s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_left_item .b_left_img2 {
    transform: translate3d(-40px, 0, 0);
}

.branding_containers.active .branding_left_item .b_left_img3 {
    transition-delay: 1.4s;
    transition-duration: 0.8s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_left_item .b_left_img3 {
    transform: translate3d(-40px, 40px, 0);
}

.branding_containers.active .branding_right_item .b_right_img1 {
    transition-delay: .6s;
    transition-duration: 1s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_right_item .b_right_img1 {
    transform: translate3d( 40px, -40px, 0);
}

.branding_containers.active .branding_right_item .b_right_img2 {
    transition-delay: 1.2s;
    transition-duration: 0.8s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_right_item .b_right_img2 {
    transform: translate3d( 40px, 0, 0);
}

.branding_containers.active .branding_right_item .b_right_img3 {
    transition-delay: 1.4s;
    transition-duration: 0.8s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.branding_containers .branding_right_item .b_right_img3 {
    transform: translate3d( 40px, 40px, 0);
}

.branding_containers.active .branding_center_item {
    opacity: 1;
    transition-delay: .8s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: transform, opacity;
}

.branding_containers .branding_center_item {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        color: #393531;
    }
    /*--All*/
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .branding_content {
        position: relative;
        flex-direction: column;
        margin-top: 0;
        padding-bottom: 0;
    }

    .branding_content .branding_center_item {
        position: inherit;
    }

    .branding_content .branding_left_item {
        position: relative;
        display: flex;
        width: 100%;
        padding-bottom: 87.8205%;
    }

    .branding_content .branding_left_item div:nth-child(1) {
        position: absolute;
        max-width: 37.8205%;
        top: 25.64102%;
        z-index: 10;
        left: inherit;
        right: inherit;
        bottom: inherit;
    }

    .branding_content .branding_left_item div:nth-child(2) {
        position: absolute;
        max-width: 49.03846%;
        top: 12.5%;
        left: 27.8846%;
        right: inherit;
        bottom: inherit;
    }

    .branding_content .branding_left_item div:nth-child(3) {
        position: absolute;
        max-width: 28.8461%;
        top: 62.6923%;
        left: inherit;
        right: 0;
        bottom: inherit;

    }

    .branding_content .branding_right_item {
        position: relative;
        width: 100%;
        padding-bottom: 109.29487%;
        margin-left: 0;
    }

    .branding_right_item div:nth-child(1) {
        position: absolute;
        max-width: 73.7179%;
        top: 37.8205%;
        left: 0;
        right: inherit;
        bottom: inherit;
    }

    .branding_right_item div:nth-child(2) {
        position: absolute;
        max-width: 43.2692%;
        top: 11.53846%;
        left: inherit;
        right: 0;
        bottom: inherit;
    }

    .branding_containers .branding_left_item .b_left_img1 {
        transform: translate3d(-40px, -40px, 0);
    }

    .branding_containers .branding_left_item .b_left_img2 {
        transform: translate3d( 0, -40px, 0);
    }

    .branding_containers .branding_left_item .b_left_img3 {
        transform: translate3d(40px, 0, 0);
    }

    .branding_containers.active .branding_right_item .b_right_img1 {
        transition-delay: 2.2s;
    }

    .branding_containers.active .branding_right_item .b_right_img2 {
        transition-delay: 2.2s;
    }

    .branding_containers .branding_right_item .b_right_img1 {
        transform: translate3d( -40px, 40px, 0);
    }

    .branding_containers .branding_right_item .b_right_img2 {
        transform: translate3d( 40px, 0, 0);
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .branding_content .branding_center_item ul li:nth-child(1) {
        height: auto;
        margin-bottom: 7.6923%;
    }

    .branding_content .branding_center_item ul li {
        max-width: 360px;
        margin: 0 auto;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

} /*__end*/


/*__반응형*/

</style>
