<template>
  <div class="content_containers first_containers">
    <!--main_text-->
    <ShowObserver v-on:isShow="isShowObserver"></ShowObserver>
    <div class="main_text" :class="show ? 'active' : ''">
      <div class="title_text">실시간 방송 구매 서비스 VOGO <span class="pc_text">UX Desgin</span></div>
      <div class="body_text">삼성 임직원들에게 제공되는 실시간 방송 구매 서비스 VOGO.<br>
        방송 시청 - 구매과정까지 이루어지는 복잡한 flow를 군더더기 없는 UX를 통해 편리성을 제공 하고자 했으며, 판매자, 소비자, 호스트 모드 3가지 제작했습니다.</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail1",
  data() {
    return {
      show: false
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver(value: boolean) {
      this.show = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.first_containers {
    padding-top: 91px;
}

.content_containers .main_text {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 192px;
}

.content_containers .main_text .title_text {
    height: 59px;
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}

.content_containers .main_text .body_text {
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

/*__Default*/


/* Animation */

.content_containers .main_text.active .title_text,
.content_containers .main_text.active .body_text {
    opacity: 1;
    transform: translateY(0);
}

.content_containers .main_text .title_text,
.content_containers .main_text .body_text {
    opacity: 0;
    transform: translateY(30px);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_containers > .main_text {
        max-width: 90%;
    }

} /*__end*/

@media screen and (max-width:1024px) {

  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

      .content_containers .main_text .title_text {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .content_containers .main_text .body_text {
        font-size: 14px;
    }
} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .main_text .title_text {
        height: auto;
    }

} /*__end*/

@media screen and (max-width:425px) {
   /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/
  
   .pc_text {
        display: none;
    }

    .content_containers > .main_text {
        max-width: 86.66666vw;
    }

    .first_containers {
        padding-top: 36px;
    }

    .content_containers .main_text {
        margin-bottom: 98px;
    }

    .content_containers .main_text .title_text {
        height: 29px;
        font-size: 20px;
    }

    .content_containers .main_text .body_text {
        height: 72px;
        font-size: 12px;
    }


} /*__end*/


/*__반응형*/

</style>
