
    import {defineComponent} from "vue";
    import ThemeObserver from "@/components/ThemeObserver.vue";
    import {Theme} from "@/enums/Theme";

    export default defineComponent({
        name: "AjinDetail0",
       data() {
       return {
         theme: Theme.white
       }
      },
      components: {
        ThemeObserver
      },
      methods: {}
    });
    