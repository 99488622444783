<template>
  <div class="mockup_containers">
  <ThemeObserver :theme=theme />
      <div class="content_wrap">
          <div class="content_01" :class="show0 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
            <img src="@/assets/img/2019_kia_ces/kiaces_29.jpg" alt="image">
          </div>
          <div class="content_02" :class="show1 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
            <img src="@/assets/img/2019_kia_ces/kiaces_30.jpg" alt="image">
          </div>
          <h3 class="content_tit">2019 CES Video</h3>
          <div class="content_video" :class="show2 ? 'active' : ''" style="position: relative;">
             <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
             <iframe src="https://player.vimeo.com/video/391422210?muted=1&autoplay=1&autopause=0&loop=1&
              " frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
           </div>
           
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail9",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
    }
  },
  components: {
    ThemeObserver,
    ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
      isShowObserver1(value: boolean) {
      this.show1 = value;
    },
      isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.mockup_containers {
    position: relative;
    width: 100vw;
    padding-bottom: 159px;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.mockup_containers .content_01 {
  width: 100%;
  margin-bottom: 66px;
}

.mockup_containers .content_02 {
  width: 100%;
  margin-bottom: 375px;
}

.mockup_containers .content_tit {
    margin-bottom: 148px;
}

.mockup_containers .content_video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-bottom: 122px;
}

.mockup_containers .content_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/*__Default*/


/* Animation */

.content_01,
.content_02,
.content_video {
    opacity: 0;
}

.content_01.active,
.content_02.active,
.content_video.active {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
  .content_wrap {
    max-width: 90%;
  }
} /*__end*/

@media screen and (max-width:1024px) {
  .mockup_containers .content_tit {
      font-size: 14px;
  }

  .mockup_containers .content_01 {
    margin-bottom: 53px;
  }

  .mockup_containers .content_02 {
    margin-bottom: 300px;
  }

} /*__end*/

@media screen and (max-width:768px) {
  .mockup_containers .content_tit {
      margin-bottom: 118px;
  }

  .mockup_containers .content_01 {
    margin-bottom: 42px;
  }

  .mockup_containers .content_02 {
    margin-bottom: 240px;
  }

} /*__end*/

@media screen and (max-width:425px) {
  .content_wrap {
    max-width: 86.66666vw;
  }

  .mockup_containers .content_tit {
      font-size: 12px;
      margin-bottom: 90px;
      letter-spacing: -0.27px;
  }

  .mockup_containers .content_01 {
    margin-bottom: 35px;
  }

  .mockup_containers .content_02 {
    margin-bottom: 170px;
  }

} /*__end*/


/*__반응형*/

</style>
