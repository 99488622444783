<template>
    <div class="containers_03" :class="show0 ? 'active' : ''" style="position: relative;">
    <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
    <ThemeObserver class="whitestart" :theme=theme />  
      <div class="logo_img"><img src="@/assets/img/2020_viol/viol_04.gif" alt="logo_gif"></div>
      <div class="content_wrap">
        <!-- video 자리 -->
        <div class="video_wrap">
        <img src="@/assets/img/2020_viol/viol_05.jpg" alt="video">
        <img src="@/assets/img/2020_viol/viol_06.jpg" alt="video">
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail03.vue",
    data() {
      return {
          show0: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.observer.whitestart {
  top: 90vh;
}

.containers_03 {
  position: relative;
  width: 100%;
  background: #000;
}

.video_wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 236px;
  display: flex;
}

.video_wrap img {
  width: 50%;
}


/*__Default*/


/* Animation */

.containers_03.active .video_wrap {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 400ms;
  transition-property: opacity;
}

.containers_03 .video_wrap {
  opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {

.video_wrap {
  max-width: 90%;
}


} /*__end*/

@media screen and (max-width:1024px) {} /*__end*/

@media screen and (max-width:768px) {

.logo_img{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 63.46153%;
  background-image: url(../../../assets/img/2020_viol/viol_04.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 180%;
}

.logo_img img {
  display: none;
}

.video_wrap {
  flex-direction: column;
  padding-bottom: 189px;
}

.video_wrap img {
  width: 100%;
}

.video_wrap img:first-child {
  margin-bottom: 5.76923%;
}

} /*__end*/

@media screen and (max-width:425px) {
.video_wrap {
  max-width: 86.66666vw;
  padding-bottom: 127px;
}

} /*__end*/


/*__반응형*/

</style>
