<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="content-wrap">
        <div class="title" :class="show0 ? 'active' : ''" style="position: relative">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver> 
          <h3>
            <span class="point-color">Navigation</span>
            <br>Screen Design<span class="point-color">.</span>
          </h3>
        </div>
      <div class="position-wrap">
        <div class="sub-title" :class="show1 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver> 
          <h3>NAVIGATION</h3>
          <p>
            Search, Nearby 기능으로 보다 빠른 동작을<br>
            수행할 수 있으며, 필요한 정보만을 제공해<br>
            직관적인 화면이 되도록 구성했습니다.
          </p>
        </div>
        <div class="screen-01" :class="show2 ? 'active' : ''">
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver> 
        </div>
        <div class="screen-02" :class="show3 ? 'active' : ''">
          <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver> 
        </div>
        <div class="screen-03" :class="show4 ? 'active' : ''">
          <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver> 
        </div>
        <div class="screen-04" :class="show5 ? 'active' : ''">
          <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver> 
        </div>
      </div>
      </div>
      <div class="visual-motion">
        <video src="@/assets/img/2021_seoyoneh/2021_seoyon_pc_motion_2.mp4" autoplay loop muted playsinline></video>
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail5",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  font-family: var(--pretendard);
  background:  #131C33;
  overflow: hidden;
  color: #fff;
}

.container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.content-wrap {
  position: relative;
  width: calc(var(--responsive) * 1200);
  height: calc(var(--responsive) * 2436);
}

.content-wrap::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc((100vw - calc(var(--responsive) * 1200)) / -2);
  width: 100vw;
  height: 126.875vw;
  background-image: url("../../../assets/img/2021_seoyoneh/detail05-bg-01.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.position-wrap {
  position: relative;
  width: 100%;
  height: auto;
}

/* title */

.title {
  width: 100%;
  margin-top: calc(var(--responsive) * 181.53);
  margin-bottom: calc(var(--responsive) * 214);
}

.title h3 {
  font-size: calc(var(--responsive) * 54);
  line-height: 1.333em;
  font-weight: 400;
}

.point-color {
  color: #A85BFF;
}

/* sub title */

.sub-title {
  position: absolute;
  top: calc(var(--responsive) * 61);
  left: calc(var(--responsive) * 844);
}

.sub-title h3 {
  font-size: calc(var(--responsive) * 23);
  line-height: 1.217em;
  font-weight: 400;
  margin-bottom: 0.783em;
}

.sub-title p {
  font-size: calc(var(--responsive) * 14);
  line-height: 1.714em;
  font-weight: 400;
  color: #65749D;
}

/* screen */

[class^='screen-0'] {
  position: absolute;
  width: calc(var(--responsive) * 424);
  height: calc(var(--responsive) * 754);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.screen-01 {
  top: 0;
  left: calc(var(--responsive) * 391);
  background-image: url("../../../assets/img/2021_seoyoneh/detail05-screen-01.webp");
}

.screen-02 {
  top: calc(var(--responsive) * 384);
  left: calc(var(--responsive) * -57);
  background-image: url("../../../assets/img/2021_seoyoneh/detail05-screen-02.webp");
}

.screen-03 {
  top: calc(var(--responsive) * 778);
  left: calc(var(--responsive) * 391);
  background-image: url("../../../assets/img/2021_seoyoneh/detail05-screen-03.webp");
}

.screen-04 {
  top: calc(var(--responsive) * 597.24);
  left: calc(var(--responsive) * 839);
  background-image: url("../../../assets/img/2021_seoyoneh/detail05-screen-04.webp");
}

/* visual motion */

.visual-motion {
  width: 100vw;
  height: calc(var(--responsive) * 1080);
}

.visual-motion video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.title,
.sub-title,
.screen-01,
.screen-02,
.screen-03,
.screen-04 {
  opacity: 0;
}

.title.active,
.sub-title.active,
.screen-01.active,
.screen-02.active,
.screen-03.active,
.screen-04.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container {}

  .container-inner {}

  .content-wrap {
    width: calc(var(--responsive) * 280);
    height: calc(var(--responsive) * 2199);
  }

  .content-wrap::before {
    left: calc((100vw - calc(var(--responsive) * 280)) / -2);
    height: 100%;
    background-image: url("../../../assets/img/2021_seoyoneh/detail05-bg-02.webp");
  }

  .position-wrap {
    height: calc(var(--responsive) * 1758.33);
      /* background: #A85BFF; */
  }

  /* title */

  .title {
    margin-top: calc(var(--responsive) * 80);
    margin-bottom: calc(var(--responsive) * 100);
  }

  .title h3 {
    font-size: calc(var(--responsive) * 30);
  }

  /* sub title */

  .sub-title {
    top: calc(var(--responsive) * 828);
    left: 0;
  }

  .sub-title h3 {
    font-size: calc(var(--responsive) * 17);
    margin-bottom: 0.824em;
    line-height: 1.176em;
  }

  .sub-title p {
    font-size: calc(var(--responsive) * 13);
    line-height: 1.692em;
  }

  /* screen */

  [class^='screen-0'] {
    width: calc(var(--responsive) * 210);
    height: calc(var(--responsive) * 373.67);
  }

  .screen-01 {
    left: initial;
    right: 0;
  }

  .screen-02 {
    top: calc(var(--responsive) * 394);
    left: initial;
    right: 0;
  }

  .screen-03 {
    top: calc(var(--responsive) * 991);
    left: 0;
  }

  .screen-04 {
    top: calc(var(--responsive) * 1385);
    left: 0;
  }

  /* visual motion */

  .visual-motion {
    height: calc(var(--responsive) * 329);
  }

} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
