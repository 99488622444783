<template>
  <div class="content_containers">
    <!--main_text-->
    <!--content_01-->
    <div class="content_01" :class="show ? 'active' : ''">
    <ShowObserver v-on:isShow="isShowObserver"></ShowObserver>
      <div class="title_text">KEY POINT</div>
      <div class="content_body">
        <div class="infographic_l">
          <div>
            <p>실시간 채팅</p>
          </div>
          <div>
            <p>쉬운<br>상품구매</p>
          </div>
          <div>
            <p>스냅 동영상</p>
          </div>
        </div>
        <div class="infographic_r">
          <div>
            <p>Easy</p>
            <span></span>
            <p>Trendy</p>
          </div>
        </div>
      </div>
    </div>
    <!--//content_01-->
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail2",
  data() {
    return {
      show: false
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver(value: boolean) {
      this.show = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.content_containers .content_01 {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 130px;
}

.content_containers .content_01 .title_text {
    color: #383838;
    margin-bottom: 102px;
}

.content_containers .content_01 .content_body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_containers .content_01 .content_body .infographic_l {
    display: flex;
    padding-left: 30px;
}

.content_containers .content_01 .content_body .infographic_l div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 192px;
    height: 192px;
    border-radius: 50%;
    border: 2px solid #7469ff;
    margin-left: -30px;
}

.content_containers .content_01 .content_body .infographic_l div p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    color: #7469ff;
}

.content_containers .content_01 .content_body .infographic_r {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 340px;
    border-radius: 50%;
    background-color: #7469ff;
    text-align: center;
    margin-left: 94px;
}

.content_containers .content_01 .content_body .infographic_r div p {
    height: 41px;
    font-size: 28px;
    font-weight: bold;
    line-height: normal;
    color: #fdd933;
}

.content_containers .content_01 .content_body .infographic_r div span {
    display: block;
    margin: 21px 44.5px 26px;
}

.content_containers .content_01 .content_body .infographic_r div span::before {
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    transform: rotate(-315deg);
    background-color: #fdd933;
}

/*__Default*/


/* Animation */

.content_containers .content_01.active .title_text {
    opacity: 1;

}

.content_containers .content_01 .title_text {
    opacity: 0;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}


.content_containers .content_01.active .content_body .infographic_l div {
    opacity: 1;
    transform: translatex(0);
    transition-delay: .4s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

.content_containers .content_01 .content_body .infographic_l div {
    opacity: 0;
    transform: translatex(-800px);

}

.content_containers .content_01.active .content_body .infographic_l div:nth-child(2) {
    transition-delay: .5s;
}

.content_containers .content_01.active .content_body .infographic_l div:nth-child(3) {
    transition-delay: .6s;
}

.content_containers .content_01.active .content_body .infographic_r {
    opacity: 1;
    transform: translatex(0);
    transition-delay: .6s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

.content_containers .content_01 .content_body .infographic_r {
    opacity: 0;
    transform: translatex(800px);

}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .content_01_right .information ul,
    .content_01_right .headup ul {
        height: 120px;
    }

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

    .content_containers .content_01 .content_body {
        flex-direction: column;
    }

    .content_containers .content_01 .content_body .infographic_r {
        margin-left: inherit;
        margin-top: 93px;
    }

    .content_containers .content_01 .content_body .infographic_l div p {
        font-size: 16px;
    }

    .content_containers .content_01 .content_body .infographic_r div p {
        font-size: 24px;
    }

} /*__end*/

@media screen and (max-width:768px) {} /*__end*/

@media screen and (max-width:425px) {
     /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/

    .content_containers .content_01 {
        max-width: 86.66666vw;
    }

    .content_containers .content_01 {
        margin-bottom: 78px;
    }

    .content_containers .content_01 .title_text {
        margin-bottom: 64px;
    }

    .content_containers .content_01 .content_body .infographic_l {
        padding-left: 21px;
    }

    .content_containers .content_01 .content_body .infographic_l div {
        width: 118px;
        height: 118px;
        margin-left: -21px;
        border: 1px solid #7469ff;
    }

    .content_containers .content_01 .content_body .infographic_l div p {
        font-size: 12px;
    }

    .content_containers .content_01 .content_body .infographic_r {
        width: 214px;
        height: 214px;
        margin-top: 48px;
    }

    .content_containers .content_01 .content_body .infographic_r div p {
        height: 33px;
        font-size: 22px;
    }

    .content_containers .content_01 .content_body .infographic_r div span {
        margin: 16px 35px;
    }


} /*__end*/


/*__반응형*/

</style>
