<template>
  <div class="overview_containers" :class="show0 ? 'active' : ''" style="position: relative;">
      <ThemeObserver :theme=theme />
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver> 
      <h3 class="content_tit">Project Overview</h3>
      <div class="overview_top">
          <div class="overview_maintxt" :class="show1 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>    
              <div class="overmaintxt_1">당신의 감정을 읽는 기술</div>
              <div class="overmaintxt_2">R.E.A.D. System</div>
              <div class="overmaintxt_3">
                  <ul>
                      <li class="content_r"><span>R</span>eal-time</li>
                      <li class="content_e"><span>E</span>motion</li>
                      <li class="content_a"><span>A</span>daptive</li>
                      <li class="content_d"><span>D</span>riving</li>
                  </ul>
              </div>
          </div>
          <div class="overview_btm" :class="show2 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver> 
              <div class="overview_bodytxt">
                  <p>R.E.A.D. System은 실시간으로 운전자의 감정을 읽고 분석하여 더욱 편안하고, 즐거운 운전 경험을 선사해줍니다.<br class="inactive_768w">
                      운전 중 지속적으로 표정이나 심박 등 운전자의 생체 정보를 읽고 인공지능 분석 기술을 활용하여<br class="inactive_768w">
                      운전자의 감성 상태에 최적화되게 조명, 음악, 온도, 향기 등의 차량 환경을 구성합니다. </p>
              </div>
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";


export default defineComponent({
  name: "KiaCesDetail1",
  data() {
    return {
        theme: Theme.white,
        show0: false,
        show1: false,
        show2: false,
    }
  },
  components: {
      ThemeObserver,
      ShowObserver
  },
  methods: {
      isShowObserver0(value: boolean) {
      this.show0 = value;
    },
      isShowObserver1(value: boolean) {
      this.show1 = value;
    },
      isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ALL */
img{
    width: 100%;
    vertical-align: bottom;
}

.content_tit {
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #50b7f8;
    font-weight: 400;
}

.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

/*__ALL*/

/* Default */
.overview_containers {
    position: relative;
    width: 100%;
    height: 1080px;
    z-index: 10;
    font-family: 'NanumSquare', serif;
    font-weight: normal;
    letter-spacing: normal; 
    color: #fff;
    background: #000;
}

.overview_containers::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background: radial-gradient( circle at 50% 40%, rgba(61, 82, 121,1) 5%, rgba(0,0,0,.6) 40%);
    z-index: -1;

}

.overview_containers .content_tit {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 91px;
    margin-bottom: 239px;
}

.overview_top {
}

.overview_btm {
    position: absolute;
    width: 100vw;
    margin-top: 155px;
}

.overview_btm::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    padding-bottom: 21.5625%;
    background: #000;
    z-index: -1;
}

.overview_btm::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100vw;
    padding-bottom: 21.5625%;
    background-image: radial-gradient( at 50% -10%, rgba(61,82,121,0.5) 0%, rgba(0,0,0,0.0) 60%);
    border-image: linear-gradient(270deg, #000000 0%, rgba(0,0,0,0.00) 20%, #3D5279 50%, rgba(0,0,0,0.00) 80%, #000 100%);
    border-style: solid;
    border-image-slice: 1;
    border-image-width: 2px 0 0 0;
    border-bottom: 0;
}

.overview_top,
.overview_btm {
    text-align: center;
}

.overmaintxt_1 {
    font-size: 20px;
    letter-spacing: -0.44px;
    margin-bottom: 33px;
}

.overmaintxt_2 {
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -1.24px;
    margin-bottom: 19px;
}

.overmaintxt_3 ul {
    display: flex;
    justify-content: center;
}

.overmaintxt_3 ul li:not(.content_d) {
    margin-right: 22px;
}

.overmaintxt_3 ul li {
    color: rgba(255, 255, 255, 0.6);
    font-size: 22px;
    letter-spacing: -0.49px;
}

.overmaintxt_3 ul li span {
    color: #fff;
    font-weight: 600;
    margin-right: 2px;
}

.overview_btm .overview_bodytxt {
    width: 712px;
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
}

.overview_btm .overview_bodytxt p {
    line-height: 1.75;
    font-size: 16px;
    letter-spacing: -0.36px;
}

/*__Default*/


/* Animation */

.ob01 {
    top: 10px;
}

.overview_containers::before {
    opacity: 0;
}

.overview_containers.active::before {
    opacity: 0.35;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.overview_maintxt .overmaintxt_1,
.overview_maintxt .overmaintxt_2,
.overview_maintxt .overmaintxt_3 ul li {
    opacity: 0;
}

.overview_maintxt.active .overmaintxt_1,
.overview_maintxt.active .overmaintxt_2,
.overview_maintxt.active .overmaintxt_3 ul li {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
    transition-property: opacity;
}

.overview_maintxt.active .overmaintxt_3 ul li.content_r span {
    animation: twinkle_txt 10s cubic-bezier(0.47, 0, 0.75, 0.72) 2000ms both infinite;
}

.overview_maintxt.active .overmaintxt_3 ul li.content_e span {
    animation: twinkle_txt 10s cubic-bezier(0.47, 0, 0.75, 0.72) 12s both infinite;
}

.overview_maintxt.active .overmaintxt_3 ul li.content_a span {
    animation: twinkle_txt 10s cubic-bezier(0.47, 0, 0.75, 0.72) 5s both infinite;
}

.overview_maintxt.active .overmaintxt_3 ul li.content_d span {
    animation: twinkle_txt 10s cubic-bezier(0.47, 0, 0.75, 0.72) 3s both infinite;
}


@keyframes twinkle_txt {
    0% {
        opacity: 1;
    }
    2% {
        opacity: 0.6;
    }
    4% {
        opacity: 0.4;
    }
    5% {
        opacity: 1;
    }
    8% {
        opacity: 0.5;
    }
    10% {
        opacity: 1;
    }
    12% {
        opacity: 0.5;
    }
    14% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.overview_btm::before {
    opacity: 0;
}

.overview_btm.active::before {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72);
    transition-property: opacity;
}

.overview_btm .overview_bodytxt {
    opacity: 0;
}

.overview_btm.active .overview_bodytxt {
    opacity: 1;
    transition: 800ms cubic-bezier(0.47, 0, 0.75, 0.72) 400ms;
    transition-property: opacity;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    .overview_containers .content_tit {
        max-width: 90%;
    }
} /*__end*/

@media screen and (max-width:1024px) {
    .overview_containers {
        height: 864px;
    }

    .overview_containers::before {
        background: radial-gradient( circle at 50% 50%, rgba(61, 82, 121,1) 5%, rgba(0,0,0,.6) 40%);
    }

    .overview_btm::before {
        background-image: radial-gradient( at 50% -30%, rgba(61,82,121,0.5) 0%, rgba(0,0,0,0.0) 60%);
    }

    .overview_containers .content_tit {
        font-size: 14px;
    }
    
    .overmaintxt_2 {
        font-size: 45px;
        letter-spacing: -0.8px;
    }

    .overmaintxt_3 ul li {
        font-size: 18px;
    }    
} /*__end*/

@media screen and (max-width:768px) {
    .overview_containers .content_tit {
       margin-bottom: 191px;
    }

    .overview_btm .overview_bodytxt {
        max-width: 86.66666vw;
    }
} /*__end*/

@media screen and (max-width:425px) {

    .overview_containers .content_tit {
       max-width: 86.66666vw;
       font-size: 12px;
       margin-bottom: 147px;
       letter-spacing: -0.27px;
    }

    .overview_containers::before {
        background: radial-gradient( circle at 50% 38%, rgba(61, 82, 121,1) 5%, rgba(0,0,0,.6) 40%);
    }

    .overview_btm::before {
        background-image: radial-gradient( at 50% -50%, rgba(61,82,121,0.5) 0%, rgba(0,0,0,0.0) 100%);
        border-image: linear-gradient(270deg, #000000 0%, rgba(0,0,0,0.00) 5%, #3D5279 50%, rgba(0,0,0,0.00) 95%, #000 100%);
        border-style: solid;
        border-image-slice: 1;
        border-image-width: 1px 0 0 0;
        border-bottom: 0;
    }

    .overview_btm {
        margin-top: 106px;
    }

    .overmaintxt_1 {
        font-size: 16px;
        margin-bottom: 22px;
        letter-spacing: -0.36px;
    }

    .overmaintxt_2 {
        font-size: 36px;
        margin-bottom: 14px;
    }

    .overmaintxt_3 ul li{
        font-size: 14px;
    }
    .overmaintxt_3 ul li:not(.content_d) {
        margin-right: 10px;
    }

    .overview_btm .overview_bodytxt {
        top: 45px;
    }

    .overview_btm .overview_bodytxt p {
        font-size: 14px;
        letter-spacing: -0.31px;
        padding: 0 10px;
    }


} /*__end*/


/*__반응형*/

</style>
