<template>
    <div class="containers_05" style="position: relative;">
    <ThemeObserver :theme=theme />  
      <div class="content_wrap">
        <div class="text_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
           <div class="tit_box">
             <h3 class="common_tit">Design System</h3>
             <div class="gradient">Gradient</div>
           </div>
           <div class="text_box">
             <h3 class="common_tit">Color</h3>
             <div class="body_txt">
               각각의 Theme에는 서로 다른 포인트 컬러를 적용했으며 화면에서 깊이감을 느낄 수<br class="inactive_1280w">
               있도록 Gradient를 적극적으로 활용했습니다. Dark Theme은  SONOTITE 기기를<br class="inactive_1280w">
               모티브로 핸드피스에 사용되는 Red 컬러의 Gradient를 사용했으며, Light Theme은<br class="inactive_1280w">
               Blue계열의 Gradient를 사용하여 맑고 깨끗한 느낌을 극대화시켰습니다.
             </div>
           </div>
        </div>
        <div class="item_wrap">
          <div class="item_01" :class="show1 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>  
            <div class="palette_tit">Dark<br>Theme</div>
            <div class="palette_body">
              <div class="color_01">
                <span class="color_info_01">BG</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#2C3135</span>
                  <span class="arrow"></span>
                  <span class="color_code_02">#171B1E</span>
                </div>  
              </div>
              <div class="color_02">
                <span class="color_info_01">Button</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#393E44</span>
                  <span class="arrow"></span>
                  <span class="color_code_02">#1A1E22</span>
                </div> 
              </div>
              <div class="color_03">
                <span class="color_info_01">Text / Icon</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#B1BCC7</span>
                </div> 
              </div>
              <div class="color_04">
                <span class="color_info_01">Primary</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#FF6B6B</span>
                  <span class="arrow"></span>
                  <span class="color_code_02">#C2255C</span>
                </div> 
              </div>
            </div>
          </div>
          <div class="item_02" :class="show2 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>  
            <div class="palette_body">
              <div class="color_01">
                <span class="color_info_01">BG / Button</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#EDEFF3</span>
                </div>  
              </div>
              <div class="color_02">
                <span class="color_info_01">Icon</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#6F728E</span>
                </div> 
              </div>
              <div class="color_03">
                <span class="color_info_01">Text</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#2A4064</span>
                </div> 
              </div>
              <div class="color_04">
                <span class="color_info_01">Primary</span>
                <div class="color_code_wrap">
                  <span class="color_code_01">#3BC9DB</span>
                  <span class="arrow"></span>
                  <span class="color_code_02">#34B4D4</span>
                </div> 
              </div>
            </div>
            <div class="palette_tit">Light<br>Theme</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";

export default defineComponent({
    name: "ViOLDetail05.vue",
    data() {
      return {
          show0: false,
          show1: false,
          show2: false,
          theme: Theme.white
      }
    },
    components: {
        ShowObserver,
        ThemeObserver
    },
    methods: {
        isShowObserver0(value: boolean) {
        this.show0 = value;
      },
        isShowObserver1(value: boolean) {
        this.show1 = value;
      },
        isShowObserver2(value: boolean) {
        this.show2 = value;
      },      
    }
  });
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

.containers_05 {
  position: relative;
  width: 100%;
  background: #000;
  font-family: 'Roboto', sans-serif;
}

.containers_05 .content_wrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.containers_05 .text_wrap {
  width: 100%;
  display: flex;
  margin: 110px 0 187px 0;
}

.text_wrap .tit_box {
  width: 50%;
}

.text_wrap .text_box {
  width: 50%;
}

.text_wrap .common_tit {
  font-size: 14px;
  margin-bottom: 177px;
  color: rgba(255, 255, 255, 0.6);
}

.text_wrap .gradient {
  font-size: 84px;
  letter-spacing: -1.84px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(106deg, #f5fafc, #b0b5b9 114%);
}

.text_wrap .body_txt {
  font-size: 18px;
  font-family: 'SECRobotoLight', serif;
  line-height: 1.61;
  letter-spacing: -0.1px;
  color: #fff;
  
}

.item_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.palette_tit {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.87px;
  text-align: right;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 30px;
}

.palette_body {
  width: 74.5%;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
}

[class^="color_0"] {
  position: relative;
} 

.arrow {
  position: relative;
  width: 1px;
  height: 31px;
  left: 7px;
  background: #fff;
  margin: 10px 0;
}

.arrow::before {
  content: "";
  position: absolute;
  left: -7px;
  bottom: 4px;
  width: 10px;
  height: 1px;
  background: #fff;
  transform: rotate(-120deg);
}

.arrow::after {
  content: "";
  position: absolute;
  left: -2px;
  bottom: 4px;
  width: 10px;
  height: 1px;
  background: #fff;
  transform: rotate(120deg);
}

.color_info_01 {
  font-size: 14px;
}

[class^="color_code_0"] {
  font-size: 18px;
}

/* item_01 */

.item_01 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 11.16666%;
  color: #fff;
}

.item_01 .palette_tit {
  background-image: linear-gradient(123deg, #ff6b6b -6%, #c2255c 107%);
  margin-right: 32px;
}

.item_01 .palette_body .color_01 {
  width: 31.99105%;
  padding-bottom: 40.15659%;
  background-image: linear-gradient(to bottom, #2c3135, #171b1e);
}

.item_01 .palette_body .color_01 .color_info_01,
.item_01 .palette_body .color_02 .color_info_01 {
  position: absolute;
  top: 6.68523%;
  left: 8.74125%;
} 

.item_01 .palette_body .color_01 .color_code_wrap,
.item_01 .palette_body .color_02 .color_code_wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6.96378%;
  left: 8.741258%;
}

.item_01 .palette_body .color_03 .color_info_01,
.item_01 .palette_body .color_04 .color_info_01 {
  position: absolute;
  top: 6.68523%;
  left: 15.5279%;
} 

.item_01 .palette_body .color_03 .color_code_wrap,
.item_01 .palette_body .color_04 .color_code_wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6.96378%;
  left: 15.5279%;
}

.item_01 .palette_body .color_02 {
  width: 31.99105%;
  padding-bottom: 40.15659%;
  background-image: linear-gradient(to bottom, #393e44, #1a1e22);
}

.item_01 .palette_body .color_03 {
  width: 18.00894%;
  padding-bottom: 40.15659%;
  background: #b1bcc7;
}

.item_01 .palette_body .color_04 {
  width: 18.00894%;
  padding-bottom: 40.15659%;
  background-image: linear-gradient(to bottom, #ff6b6b, #c2255c);
}

/* item_02 */

.item_02{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 17.3333%;
}

.item_02 .palette_tit {
  background-image: linear-gradient(125deg, #53d9ea, #34b4d4 105%);
  margin-left: 32px;
  text-align: left;
}

.item_02 .palette_body .color_01 {
  width: 45.97315%;
  padding-bottom: 40.15659%;
  background: #edeff3;
}

.item_02 .palette_body .color_02 {
  width: 18.00894%;
  padding-bottom: 40.15659%;
  background: #6f728e;
}

.item_02 .palette_body .color_03 {
  width: 18.00894%;
  padding-bottom: 40.15659%;
  background: #2a4064;
}

.item_02 .palette_body .color_04 {
  width: 18.00894%;
  padding-bottom: 40.15659%;
  background-image: linear-gradient(to bottom, #78dae6, #34b4d4);
}

.item_02 .palette_body .color_01 .color_info_01 {
  position: absolute;
  top: 6.68523%;
  left: 6.32603%;
  color: #2a4064;
} 

.item_02 .palette_body .color_01 .color_code_wrap{
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6.96378%;
  left: 6.32603%;
  color: #2a4064;
}

.item_02 .palette_body .color_02 .color_info_01,
.item_02 .palette_body .color_03 .color_info_01,
.item_02 .palette_body .color_04 .color_info_01  {
  position: absolute;
  top: 6.68523%;
  left: 15.5279%;
  color: #fff;
} 

.item_02 .palette_body .color_02 .color_code_wrap,
.item_02 .palette_body .color_03 .color_code_wrap,
.item_02 .palette_body .color_04 .color_code_wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6.96378%;
  left: 15.5279%;
  color: #fff;
}

/*__Default*/


/* Animation */

.text_wrap.active .gradient {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 100ms;
  transition-property: opacity;
}

.text_wrap.active .body_txt {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 350ms;
  transition-property: opacity;
}

.containers_05 .gradient,
.containers_05 .body_txt {
  opacity: 0;
}

.item_01.active .palette_tit {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
  transition-property: opacity;
}

.item_01.active .color_01 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.item_01.active .color_02 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 300ms;
  transition-property: opacity;
}

.item_01.active .color_03 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 450ms;
  transition-property: opacity;
}

.item_01.active .color_04 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 600ms;
  transition-property: opacity;
}

.item_01 .palette_tit,
.item_01 .color_01,
.item_01 .color_02,
.item_01 .color_03,
.item_01 .color_04 {
  opacity: 0;
}

.item_02.active .palette_tit {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 000ms;
  transition-property: opacity;
}

.item_02.active .color_04 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.item_02.active .color_03 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 300ms;
  transition-property: opacity;
}

.item_02.active .color_02 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 450ms;
  transition-property: opacity;
}

.item_02.active .color_01 {
  opacity: 1;
  transition: 900ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 650ms;
  transition-property: opacity;
}

.item_02 .palette_tit,
.item_02 .color_01,
.item_02 .color_02,
.item_02 .color_03,
.item_02 .color_04 {
  opacity: 0;
}


/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {

.containers_05 .content_wrap {
  max-width: 90%;
}

.text_wrap .gradient {
  font-size: 67px;
}

.text_wrap .body_txt  {
  font-size: 16px;
}

} /*__end*/

@media screen and (max-width:1024px) {

.text_wrap .gradient {
  font-size: 54px;
}

/* palette */

.palette_tit {
  font-size: 32px;
  margin-top: 24px;
}

.item_01 .palette_tit {
  margin-right: 26px;
}

.item_02 .palette_tit {
  margin-left: 26px;
}

[class^="color_code_0"] {
  font-size: 16px;
}

} /*__end*/

@media screen and (max-width:768px) {

.containers_05 .text_wrap {
  flex-direction: column;
  margin: 100px 0 150px 0;
}

.text_wrap .common_tit {
  margin-bottom: 25px;
}

.text_wrap .gradient {
  font-size: 43px;
}

.text_wrap .body_txt  {
  font-size: 13px;
}

.text_wrap .tit_box {
  width: 100%;
  margin-bottom: 53px;
}

.text_wrap .text_box {
  width: 100%;
}

/* palette */

.palette_body {
  flex-direction: column;
  border-radius: inherit;
  align-items: center;
}

.item_01 {
  margin-bottom: 16.98717%;
}

.item_02 {
  margin-bottom: 31.41025%;
}

.item_01 .palette_body .color_01 {
  width: 62.77777vw;
  padding-bottom: 43.33333vw;
  border-radius: 12px 12px 0 0;
  background-image: linear-gradient(135deg, #2C3135 0%, #171B1E 100%);
}

.item_01 .palette_body .color_02 {
  width: 62.77777vw;
  padding-bottom: 43.33333vw;
  background-image: linear-gradient(135deg, #393e44, #1a1e22);
}

.item_01 .palette_body .color_03 {
  width: 62.77777vw;  
  padding-bottom: 24.44444vw;
}

.item_01 .palette_body .color_04 {
  width: 62.77777vw;
  padding-bottom: 24.44444vw;
  background-image: linear-gradient(135deg, #ff6b6b, #c2255c);
  border-radius: 0 0 12px 12px;
}

.item_01 .palette_body .color_01 .color_code_wrap, 
.item_01 .palette_body .color_02 .color_code_wrap {
  flex-direction: row;
  bottom: 11.54%;
  left: 10.61946%;
}

.item_01 .palette_body .color_01 .color_info_01, 
.item_01 .palette_body .color_02 .color_info_01 {
  top: 11.54%;
  left: 10.61946%;
}

.item_01 .palette_body .color_03 .color_code_wrap, 
.item_01 .palette_body .color_04 .color_code_wrap {
  flex-direction: row;
  left: 10.61946%;
  bottom: 17.16%;
}

.item_01 .palette_body .color_03 .color_info_01, 
.item_01 .palette_body .color_04 .color_info_01 {
  top: 17.16%;
  left: 10.61946%;
}

.arrow {
  width: 24px;
  height: 1px;
  top: 6px;
  left: 0;
  margin: 0 14px;
}

.arrow::before {
  left: 15px;
  bottom: 2px;
  transform: rotate(30deg);  
}

.arrow::after {
  left: 15px;
  bottom: -3px;
  transform: rotate(-30deg);  
}

.item_02 .palette_body .color_01 {
  width: 62.77777vw;
  padding-bottom: 70.83333vw;
  border-radius: 12px 12px 0 0;
}

.item_02 .palette_body .color_02 {
  width: 62.77777vw;
  padding-bottom: 24.44444vw;
}

.item_02 .palette_body .color_03 {
  width: 62.77777vw;  
  padding-bottom: 24.44444vw;
}

.item_02 .palette_body .color_04 {
  width: 62.77777vw;
  padding-bottom: 24.44444vw;
  background-image: linear-gradient(135deg, #78dae6, #34b4d4);
  border-radius: 0 0 12px 12px;
}

.item_02 .palette_body .color_01 .color_info_01 {
  top: 6.67%%;
  left: 10.61946%;    
}

.item_02 .palette_body .color_02 .color_info_01, 
.item_02 .palette_body .color_03 .color_info_01, 
.item_02 .palette_body .color_04 .color_info_01 {
  top: 17.16%;
  left: 10.61946%;  
}

.item_02 .palette_body .color_01 .color_code_wrap {
  bottom: 6.67%;
  left: 10.61946%;  
}

.item_02 .palette_body .color_02 .color_code_wrap, 
.item_02 .palette_body .color_03 .color_code_wrap, 
.item_02 .palette_body .color_04 .color_code_wrap {
  flex-direction: row;
  bottom: 17.16%;
  left: 10.61946%;  
}

/* ani */

.item_02.active .color_01 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 150ms;
  transition-property: opacity;
}

.item_02.active .color_02 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 300ms;
  transition-property: opacity;
}

.item_02.active .color_03 {
  opacity: 1;
  transition: 800ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 450ms;
  transition-property: opacity;
}

.item_02.active .color_04 {
  opacity: 1;
  transition: 900ms cubic-bezier(0.55, 0.09, 0.68, 0.53) 650ms;
  transition-property: opacity;
}

} /*__end*/

@media screen and (max-width:425px) {

.containers_05 .content_wrap {
  max-width: 86.66666vw;
}

.containers_05 .text_wrap {
  margin: 82px 0 77px 0;
}

.text_wrap .common_tit {
  font-size: 10px;
  margin-bottom: 20px;
}

.text_wrap .gradient {
  font-size: 36px;
}

.text_wrap .tit_box  {
  margin-bottom: 42px;
}

/* palette */

.palette_tit {
  font-size: 20px;
  margin-top: 18px;
}

.item_01 .palette_tit {
  margin-right: 17px;
}

.item_02 .palette_tit {
  margin-left: 17px;
}

[class^="color_code_0"] {
  font-size: 13px;
}

.color_info_01 {
  font-size: 10px;
}

} /*__end*/


/*__반응형*/

</style>
