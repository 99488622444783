<template>
  <div class="styleguide_containers">  
    <div class="styleguide_sub_text numbering">
      <div class="sub_text_left">STYLEGUIDE</div>
      <div class="sub_text_right">03</div>
    </div>
    <div class="styleguide_content content_wrap">
      <!-- wrap -->
      <div class="styleguide_item">
        <ul>
          <li class="headlines" :class="show0 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
            <div class="title">
              <span>Headlines</span><span>Noto Serif KR</span>
            </div>
            <div class="content tit_text">
              The Only Skin Care <br class="active_1024w">Brand for You.<br>
              Accurate and Safe <br class="active_1024w">Personalized<br class="inactive_1024w">
              Cosmetics.
            </div>
          </li>
          <li class="body" :class="show1 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
            <div class="title">
              <span>Body/Details</span><span>Noto Sans CJK KR</span>
            </div>
            <div class="content body_text">
              개인 마다 유전, 환경, 생활 습관에 따라 달라지는 <br class="active_425w">피부별 맞춤 솔루션이 필요한<br class="inactive_768w">
              시대 입니다. <br class="active_425w">철저히 고객 입장에서 생각하고 연구하여 최상의 <br class="active_425w">제품을 제공합니다.
            </div>
          </li>
          <li class="colors" :class="show2 ? 'active' : ''" style="position: relative;">
          <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
            <div class="title"><span>Colors</span></div>
            <div class="content">
              <ul>
                <li>
                  <div class="rect"></div>
                  <p>#DED0B7</p>
                </li>
                <li>
                  <div class="rect"></div>
                  <p>#226FA7</p>
                </li>
                <li>
                  <div class="rect"></div>
                  <p>#D74949</p>
                </li>
                <li>
                  <div class="rect"></div>
                  <p>#EF7900</p>
                </li>
                <li>
                  <div class="rect"></div>
                  <p>#56722A</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div><!-- //wrap -->
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail3",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #393531;
}
/*__ALl*/

.styleguide_containers {
    background-color: #f8f8f8;
    padding-bottom: 194px;
    content-visibility: auto;
}

.styleguide_content {
    margin-top: 103px;
}

.styleguide_containers .styleguide_content .styleguide_item ul li {
    display: flex;
    justify-content: flex-start;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .title {
    display: flex;
    flex-direction: column;
    width: 134px;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul {
    display: flex
}

.styleguide_item ul .headlines .title {
    margin-top: 21px;
}

.styleguide_item ul li .title span:nth-child(1) {
    height: 27px;
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.11px;
    color: #47423b;
}

.styleguide_item ul li .title span:nth-child(2) {
    margin-top: 2px;
    height: 24px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #b3a48a;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content.tit_text {
    height: 225px;
    margin-bottom: 79px;
}

.styleguide_containers .styleguide_content .styleguide_item ul li div:nth-child(3) {
    margin-left: 175px;
}

.styleguide_containers .styleguide_content .styleguide_item ul .body {
    margin-bottom: 186px;
}

.styleguide_containers .styleguide_content .styleguide_item ul .colors {
    position: relative;
}

.styleguide_containers .styleguide_content .styleguide_item ul .colors::before {
    content: "";
    width: 100%;
    position: absolute;
    top: -107px;
    opacity: 0;
    border-bottom: 1px solid #47423b;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 60px;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li p {
    margin-top: 12px;
    height: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.09px;
    color: #47423b;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li:nth-child(1) div {
    background-color: #ded0b7;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li:nth-child(2) div {
    background-color: #226fa7;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li:nth-child(3) div {
    background-color: #d74949;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li:nth-child(4) div {
    background-color: #ef7900;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content ul li:nth-child(5) div {
    background-color: #56722a;
}

.styleguide_containers .styleguide_content .styleguide_item ul li .content .rect {
    width: 92px;
    height: 0;
    padding-bottom: 94px;
    background-color: aliceblue;
}

/*__Default*/


/* Animation */

.headlines.active .title,
.body.active .title,
.colors.active .title {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.headlines .title,
.body .title,
.colors .title {
    opacity: 0;
}

.headlines.active .tit_text,
.body.active .body_text {
    opacity: 1;
    transition-delay: .4s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.headlines .tit_text,
.body .body_text {
    opacity: 0;
}

.colors.active .content ul li:nth-child(1){
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.colors.active .content ul li:nth-child(2){
    opacity: 1;
    transition-delay: .1s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.colors.active .content ul li:nth-child(3){
    opacity: 1;
    transition-delay: .2s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.colors.active .content ul li:nth-child(4){
    opacity: 1;
    transition-delay: .3s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.colors.active .content ul li:nth-child(5){
    opacity: 1;
    transition-delay: .4s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.colors .content ul li {
    opacity: 0;
}

.colors.active::before {
    animation-name: colorLine_ani;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    animation-fill-mode: both;
}

@-webkit-keyframes colorLine_ani {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: .2;
        width: 100%;
    }
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/

    .styleguide_item ul li .title span:nth-child(1) {
        height: 24px;
        font-size: 16px;
    }

    .styleguide_item ul li .title span:nth-child(2) {
        height: 20px;
        font-size: 14px;
    }
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .styleguide_containers {
        padding-bottom: 19.2307%;
    }

    .styleguide_content {
        margin-top: 17.6282%;
    }

    .styleguide_containers .styleguide_content .styleguide_item ul li {
        flex-direction: column;
    }

    .styleguide_containers .styleguide_content .styleguide_item ul li div.title {
        margin-bottom: 20px;
    }

    .styleguide_containers .styleguide_content .styleguide_item ul li div:nth-child(3) {
        margin-left: 0;
        margin-right: -4.16666%;
        letter-spacing: -0.15px;
    }

    .styleguide_containers .styleguide_content .styleguide_item ul li .content ul li {
        width: calc(100% / 5 - 4.16666%);
        margin-right: 4.16666%;
    }

    .styleguide_containers .styleguide_content .styleguide_item ul li .content .rect {
        width: 100%;
        padding-bottom: 100%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .styleguide_containers .styleguide_content .styleguide_item ul li .body_text {
        max-width: 464px;
    }

    .styleguide_item ul li .content ul li p {
        display: none;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

    .styleguide_item ul .headlines .title {
        margin-top: 0;
    }

} /*__end*/


/*__반응형*/

</style>
