
    import {defineComponent} from "vue";

    export default defineComponent({
        name: "MomostickDetail0",
        data() {
          return {}
        },
        components: {
        },
        methods: {}
      });
      