<template>
  <div class="container">
    <div class="container-inner">
      <div
        class="seasonal"
        :class="show0 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title">
          <span class="left-line"></span>
          <h3 class="inactive_820w">Emotional Motion Graphic</h3>
          <h3 class="active_820w">Emotional Motion</h3>
          <span class="right-line"></span>
        </div>
        <span>
          이모지가 전달하는 감정 전달 효과를 높이고자
          <br class="active_820w" />
          기존 2D 이모지를 기반으로 모션을 추가했으며,<br />
          보다 입체적인 경험을 위해 통통튀는 효과와 함께
          <br class="active_820w" />
          각 표정을 과장해 표현했습니다.
        </span>
      </div>
      <div
        class="speech_bubble"
        :class="show1 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
        <span class="speech_bubble-txt">Come and Enjoy!</span>
        <img
          class="bubble-emoji-01"
          src="@/assets/img/2021_emoji/speech_bubble_7.webp"
          alt="emoji image"
        />
        <img
          class="bubble-emoji-02"
          src="@/assets/img/2021_emoji/speech_bubble_8.webp"
          alt="emoji image"
        />
      </div>
      <div
        class="contnet03-wrap"
        :class="show2 ? 'active' : ''"
        style="position: relative"
      >
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        <div class="item-01">
          <img
            src="@/assets/img/2021_emoji/motion-emoji-1.gif"
            alt="emoji image"
          /> 
        </div>
        <div class="items-wrap">
          <div class="item-02">
            <img
              src="@/assets/img/2021_emoji/motion-emoji-2.gif"
              alt="emoji image"
            />
          </div>
          <div class="item-03">
            <img
              src="@/assets/img/2021_emoji/motion-emoji-3.gif"
              alt="emoji image"
            />
          </div>
          <div class="item-04">
            <img
              src="@/assets/img/2021_emoji/motion-emoji-4.gif"
              alt="emoji image"
            />
          </div>
          <div class="item-05">
            <img
              src="@/assets/img/2021_emoji/motion-emoji-5.gif"
              alt="emoji image"
            />
          </div>
        </div>
      </div>
      <!-- //inner -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "EmojiDetail6",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
    };
  },
  components: {
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  width: 100vw;
  position: relative;
  background: #fff;
  font-family: "Poppins", sans-serif;
  margin-top: calc(var(--responsive) * 180);
  margin-top: clamp(121.333px, 9.47916vw, 180px);
}

.container-inner {
  width: 68.75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* seasonal title */

.seasonal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--responsive) * 140);
}

.seasonal .title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: calc(var(--responsive) * 28);
  margin-bottom: 1.75em;
}

.seasonal .title h3 {
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0.75em;
}

.seasonal .title .left-line,
.seasonal .title .right-line {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #979797;
}

.seasonal span {
  font-family: "NanumSquare", serif;
  font-size: calc(var(--responsive) * 18);
  font-weight: 400;
  line-height: 1.61em;
  text-align: center;
}

/* speech_bubble */

.speech_bubble {
  position: relative;
  width: calc(var(--responsive) * 718);
  height: calc(var(--responsive) * 120);
  margin-right: auto;
  background-image: url("../../../assets/img/2021_emoji/speech_bubble_bg_3.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(var(--responsive) * 79);
}

.speech_bubble img {
  width: 10.58495%;
  object-fit: cover;
}

.speech_bubble-txt {
  color: #fff;
  font-size: calc(var(--responsive) * 52);
  font-weight: 500;
  margin-left: 1.019em;
}

.speech_bubble .bubble-emoji-02 {
  margin-left: 1.1142%;
}

/* content03 */

.contnet03-wrap {
  position: relative;
  display: flex;
  width: 100vw;
  height: 47.5vw;
  background: #ffd246;
}

.item-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50.41666%;
  height: 100%;
  background-image: url("../../../assets/img/2021_emoji/motion_bg_1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}
.item-01 img {
  width: 65.0826%;
  object-fit: cover;
}

.items-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 49.5833%;
  height: 100%;
}

.item-02 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-image: url("../../../assets/img/2021_emoji/motion_bg_2.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.item-03 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-image: url("../../../assets/img/2021_emoji/motion_bg_3.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.item-04 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-image: url("../../../assets/img/2021_emoji/motion_bg_4.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.item-05 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-image: url("../../../assets/img/2021_emoji/motion_bg_5.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.item-02 img,
.item-03 img,
.item-04 img,
.item-05 img {
  width: 78.0701%;
  object-fit: cover;
}

/*__Default*/

/* Animation */

.seasonal,
.speech_bubble,
.bubble-emoji-01,
.bubble-emoji-02,
.bubble-emoji-03,
.speech_bubble-txt,
.contnet03-wrap .item-01 img,
.contnet03-wrap .item-02 img,
.contnet03-wrap .item-03 img ,
.contnet03-wrap .item-04 img ,
.contnet03-wrap .item-05 img {
  opacity: 0;
}

.seasonal.active {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.speech_bubble.active {
  animation: bubble-ani-bg 500ms ease-in-out 1 forwards;
}

.speech_bubble.active .speech_bubble-txt {
  animation: bubble-ani-02 500ms ease-in-out 1 forwards;
  animation-delay: 350ms;
}

.speech_bubble.active .bubble-emoji-01 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 700ms;
}

.speech_bubble.active .bubble-emoji-02 {
  animation: bubble-ani-01 500ms ease-in-out 1 forwards;
  animation-delay: 1050ms;
}

.contnet03-wrap.active .item-01 img {
  animation: default-ani 1000ms ease-in-out 1 forwards;
}

.contnet03-wrap.active .item-02 img {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 300ms;
}

.contnet03-wrap.active .item-03 img {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 600ms;
}

.contnet03-wrap.active .item-04 img {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 900ms;
}

.contnet03-wrap.active .item-05 img {
  animation: default-ani 1000ms ease-in-out 1 forwards;
  animation-delay: 1200ms;
}

@keyframes default-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bubble-ani-bg {
  0% {
    transform: translate3d(0%, 80%, 0) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translate3d(0%, 0%, 0) scale(1);
    opacity: 1;
  }
}

@keyframes bubble-ani-01 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}

@keyframes bubble-ani-02 {
  0% {
    transform: translate3d(0%, 50%, 0);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
  }
  100% {
    transform: translate3d(0%, 0, 0);
    opacity: 1;
  }
}

/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 820px) {
  .container {
    margin-top: 16.3888vw;
  }

  .container-inner {
    width: 88.88888%;
  }

  .seasonal {
    margin-bottom: 20.5555vw;
  }

  .seasonal .title {
    font-size: 5.55555vw;
    margin-bottom: 1.5em;
  }

  .seasonal .title h3 {
    margin: 0 0.6em;
  }

  .seasonal span {
    font-size: 3.88888vw;
    line-height: 1.57em;
  }

  .speech_bubble {
    width: 85.5555vw;
    height: 14.7222vw;
    background-size: 100% 100%;
    margin-bottom: 8.05555vw;
  }

  .speech_bubble img {
    width: 10.7142%;
  }

  .speech_bubble-txt {
    font-size: 6.66666vw;
    margin: 0 0.167em 0 0.833em;
  }

  .speech_bubble .bubble-emoji-02 {
    margin-left: 0.6493%;
    margin-right: 3.2467%;
  }

  .contnet03-wrap {
    flex-direction: column;
    height: 190.27vw;
  }

  .item-01 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 49.48905%;
  }

  .item-01 img {
    width: 65.7644%;
  }

  .items-wrap {
    width: 100%;
    height: 50.51094%;
  }

  .item-02 img,
  .item-03 img,
  .item-04 img,
  .item-05 img {
    width: 72.73109%;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
