<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="content-wrap">
        <div class="content-head" :class="show0 ? 'active' : ''" style="position: relative">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>  
          <h3>Screen <span class="point-color">Structure.</span></h3>
        </div>
        <div class="content-body" :class="show1 ? 'active' : ''" style="position: relative">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
          <div class="content-side01">
            <span class="text">Title <span class="inactive_820w">Area</span></span>
            <div class="line-wrap">
              <span class="line"></span>
              <span class="dot"></span>
            </div>
          </div>
          <div class="content-side02">
            <span class="text">Contents <span class="inactive_820w">Area</span></span>
            <div class="line-wrap">
              <span class="line"></span>
              <span class="dot"></span>
            </div>
          </div>
          <div class="content-side03">
            <span class="text">Knob <span class="active_820w inline">Control</span> <span class="inactive_820w">Area</span></span>
            <div class="line-wrap">
              <span class="line"></span>
              <span class="dot"></span>
            </div>
          </div>
          <span class="active_820w num01">1</span>
          <span class="active_820w num02">2</span>
          <span class="active_820w num03">3</span>
          <span class="active_820w num04">4</span>
        </div>
        <div class="content-foot" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
          <div class="part-info-01">
            <span class="part-name">Navigation</span>
            <span class="part-function">Search, Nearby</span>
          </div>
          <div class="part-info-02">
            <span class="part-name">Media</span>
            <span class="part-function">Music, Radio, Video</span>
          </div>
          <div class="part-info-03">
            <span class="part-name">Lighting</span>
            <span class="part-function">Color, Brightness, On/Off</span>
          </div>
           <div class="part-info-04">
            <span class="part-name">Setting</span>
            <span class="part-function">HVAC system, Air purifier<span class="inactive_820w">, Settings</span></span>
          </div>
        </div>
      </div>
    </div><!-- //inner -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail2",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 1265);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--pretendard);
  background: #050E1F;
}

.container-inner {
  width: calc(var(--responsive) * 1200);
  height: 100%;
  padding-top: calc(var(--responsive) * 144);
  padding-bottom: calc(var(--responsive) * 144);
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, 0.1); */
}

.content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* content head */

.content-head {
  font-size: calc(var(--responsive) * 54);
  line-height: 1.204em;
  font-weight: 400;
  color: #fff;
  margin-bottom: calc(var(--responsive) * 91);
}

.point-color {
  color: rgba(168, 91, 255, 1);
}

/* content body */

.content-body {
  position: relative;
  width: calc(var(--responsive) * 490);
  height: calc(var(--responsive) * 820);
  background-image: url("../../../assets/img/2021_seoyoneh/detail02-img.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: calc(var(--responsive) * 100);
  border-radius: 0.170em;
  box-shadow: 0 0.420em 0.640em rgba(120, 63, 224, 0.0698);
}

/* content side */

[class^='content-side'] {
  position: absolute;
  display: flex;
  align-items: center;
}

[class^='content-side'] .text {
  color: #fff;
  font-size: calc(var(--responsive) * 21);
  margin-right: 0.795em;
  white-space: nowrap;
}

[class^='content-side'] .line-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--responsive) * 5);
  overflow: hidden;
}

.line-wrap .line {
  display: flex;
  width: calc(100% - 8px);
  height: 1px;
  border-top: dotted 2px #fff;
  /* height: 1px;
  background-image: linear-gradient(to right, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 0%);
  background-repeat: repeat-x;
  background-size: calc(var(--responsive) * 10); */
}

.line-wrap .dot {
  width: calc(var(--responsive) * 8);
  height: calc(var(--responsive) * 8);
  border-radius: 100%;
  background: #fff;
}

.content-side01 {
  width: calc(var(--responsive) * 293.5);
  height: auto;
  top: calc(var(--responsive) * 40);
  left: calc(var(--responsive) * -167.5);
}

.content-side02 {
  width: calc(var(--responsive) * 354.5);
  height: auto;
  top: calc(var(--responsive) * 401);
  left: calc(var(--responsive) * -228.5);
}

.content-side03 {
  width: calc(var(--responsive) * 557.5);
  height: auto;
  top: calc(var(--responsive) * 596);
  left: calc(var(--responsive) * -228.5);
}

/* content foot */

.content-foot {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(var(--responsive) * 252);
  top: calc(var(--responsive) * 292);
  right: calc(var(--responsive) * 78);
}

[class^='part-info-'] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(var(--responsive) * 84);
  padding-left: calc(var(--responsive) * 21);
  box-sizing: border-box;
}

[class^='part-info-']:not(.part-info-04) {
  margin-bottom: calc(var(--responsive) * 90);
}

[class^='part-info-']::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  background: #9CABD1;
}

.part-name {
  font-size: calc(var(--responsive) * 21);
  font-weight: 400;
  line-height: 1.182em;
  color: #fff;
  margin-bottom: 0.364em;
}

.part-function {
  font-size: calc(var(--responsive) * 14);
  font-weight: 400;
  line-height: 1.214em;
  color: #5270A7;
}


/*__Default*/

/* Animation */

.content-head,
.content-body,
.content-body .content-side01 .dot,
.content-body .content-side01 .text,
.content-body .content-side02 .dot,
.content-body .content-side02 .text,
.content-body .content-side03 .dot,
.content-body .content-side03 .text,
.content-foot .part-info-01,
.content-foot .part-info-02,
.content-foot .part-info-03,
.content-foot .part-info-04 {
  opacity: 0;
}

.content-body .content-side01 .line,
.content-body .content-side02 .line,
.content-body .content-side03 .line {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.content-head.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.content-body.active {
  animation: ani01 1000ms ease-in-out 1 forwards;
}

.content-body.active .content-side01 .dot {
  animation: ani01 600ms ease-in-out 400ms 1 forwards;
}

.content-body.active .content-side01 .line {
  animation: ani02 600ms ease-in-out 600ms 1 forwards;
}

.content-body.active .content-side01 .text {
  animation: ani01 1000ms ease-in-out 1200ms 1 forwards;
}

.content-body.active .content-side02 .dot {
  animation: ani01 600ms ease-in-out 1900ms 1 forwards;
}

.content-body.active .content-side02 .line {
  animation: ani02 600ms ease-in-out 2100ms 1 forwards;
}

.content-body.active .content-side02 .text {
  animation: ani01 1000ms ease-in-out 2700ms 1 forwards;
}

.content-body.active .content-side03 .dot {
  animation: ani01 600ms ease-in-out 3400ms 1 forwards;
}

.content-body.active .content-side03 .line {
  animation: ani02 600ms ease-in-out 3600ms 1 forwards;
}

.content-body.active .content-side03 .text {
  animation: ani01 1000ms ease-in-out 4200ms 1 forwards;
}

.content-foot.active .part-info-01 {
  animation: ani01 800ms ease-in-out 4900ms 1 forwards;
}

.content-foot.active .part-info-02 {
  animation: ani01 800ms ease-in-out 5500ms 1 forwards;
}

.content-foot.active .part-info-03 {
  animation: ani01 800ms ease-in-out 6100ms 1 forwards;
}

.content-foot.active .part-info-04 {
  animation: ani01 800ms ease-in-out 6700ms 1 forwards;
}

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani02 {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container {
    height: calc(var(--responsive) * 1007);
  }

  .container-inner {
    width: calc(var(--responsive) * 280);
    padding-top: calc(var(--responsive) * 80);
    padding-bottom: calc(var(--responsive) * 88);
  }

  /* content head */

  .content-head {
    font-size: calc(var(--responsive) * 30);
    white-space: nowrap;
    line-height: 1.200em;
    margin-bottom: calc(var(--responsive) * 64);
  }

  /* content body */

  .content-body {
    width: calc(var(--responsive) * 280);
    height: calc(var(--responsive) * 475);
    font-size: calc(var(--responsive) * 57);
    margin-bottom: calc(var(--responsive) * 90);
  }

  .num01,
  .num02,
  .num03,
  .num04 {
    position: absolute;
    color: #fff;
    font-size: calc(var(--responsive) * 13);
    line-height: 1.200em;
    left: calc(var(--responsive) * 216);
  }

  .num01 {
    top: calc(var(--responsive) * 99);
  }

  .num02 {
    top: calc(var(--responsive) * 196);
  }

  .num03 {
    top: calc(var(--responsive) * 295);
  }

  .num04 {
    top: calc(var(--responsive) * 401);
  }


  /* content side */

  [class^='content-side'] .text {
    font-size: calc(var(--responsive) * 15);
    margin-right: initial;
  }

  [class^='content-side'] .line-wrap {
    margin-bottom: initial;
    justify-content: space-between;
  }

  .line-wrap .dot {
    width: calc(var(--responsive) * 5);
    height: calc(var(--responsive) * 5);
  }

  .content-side01 {
    width: auto;
    top: calc(var(--responsive) * 17);
    left: calc(var(--responsive) * 123);
  }

  .content-side02 {
    width: auto;
    top: calc(var(--responsive) * 175);
    left: calc(var(--responsive) * 48);
  }

  .content-side01 .line-wrap,
  .content-side02 .line-wrap {
    display: none;
  }

  .content-side03 {
    width: calc(var(--responsive) * 149);
    top: calc(var(--responsive) * 348);
    left: calc(var(--responsive) * 36);
  }

  .content-side03 .text {
    margin-right: 0.3em;
  }

  /* content foot */
  
  .content-foot {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    top: initial;
    right: initial;
  }

  [class^='part-info-'] {
    padding-left: initial;
    height: calc(var(--responsive) * 71);
    justify-content: flex-end;
  }

  [class^='part-info-']:not(.part-info-04) {
    margin-bottom: initial;
    box-sizing: initial;
  }

  [class^='part-info-']::before {
    display: none;
  }

  .part-name::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.5px;
    background: #9CABD1;
    top: calc(var(--responsive) * -12);
    opacity: 0.51;
  }

  .part-name::after {
    position: absolute;
    width: 100%;
    top: calc(var(--responsive) * -17);
    left: 0;
    transform: translate3d(0, -100%, 0);
    font-size: calc(var(--responsive) * 13);
    color: #9DA9BF;
  }

  .part-info-01 .part-name::after {
    content: '1';
  }

  .part-info-02 .part-name::after {
    content: '2';
  }

  .part-info-03 .part-name::after {
    content: '3';
  }

  .part-info-04 .part-name::after {
    content: '4';
  }

  .part-info-01,
  .part-info-03 {
    width: 43.9285%;
    margin-right: 9.82142%;
  }

  .content-foot .part-info-01,
  .content-foot .part-info-02 {
    margin-bottom: calc(var(--responsive) * 32);
  }

  .part-info-02,
  .part-info-04 {
    width: 46.07142%;
  }

  .part-name {
    position: relative;
    font-size: calc(var(--responsive) * 15);
    margin-bottom: 0.467em;
    line-height: 1.200em;
  }

  .part-function {
    font-size: calc(var(--responsive) * 11);
  }

  /* Animation */

  .content-body.active .content-side01 .text {
    animation: ani01 600ms ease-in-out 400ms 1 forwards;
  }

  .content-body.active .content-side02 .text {
    animation: ani01 600ms ease-in-out 1000ms 1 forwards;
  }

  .content-body.active .content-side03 .dot {
    animation: ani01 600ms ease-in-out 1300ms 1 forwards;
  }

  .content-body.active .content-side03 .line {
    animation: ani02 600ms ease-in-out 1500ms 1 forwards;
  }

  .content-body.active .content-side03 .text {
    animation: ani01 1000ms ease-in-out 2100ms 1 forwards;
  }

  .content-foot.active .part-info-01 {
    animation: ani01 800ms ease-in-out 1 forwards;
  }
  
  .content-foot.active .part-info-02 {
    animation: ani01 800ms ease-in-out 400ms 1 forwards;
  }
  
  .content-foot.active .part-info-03 {
    animation: ani01 800ms ease-in-out 800ms 1 forwards;
  }
  
  .content-foot.active .part-info-04 {
    animation: ani01 800ms ease-in-out 1200ms 1 forwards;
  }

  /*__Animation */

} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
