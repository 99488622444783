<template>
  <div class="content_containers">
    <div class="content_04">
      <div style="position: relative;" :class="show0 ? 'active' : ''">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        <div class="title_text" >VOGOPAY MODE</div>
        <div class="body_text">전용 결제 시스템인 VOGO Pay를 이용하여 손쉬운 결제를 <br class="active_425w">유도합니다.</div>
      </div>
      <div class="content_wrap">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
      <div class="content_body" :class="show1 ? 'active' : ''">
        <div><img src="@/assets/img/2019_vogo/vogo_06.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_07.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_08.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_09.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_10.jpg" alt="app_screen_img">
        </div>
        <div><img src="@/assets/img/2019_vogo/vogo_11.jpg" alt="app_screen_img">
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "VogoDetail4",
  data() {
    return {
      show0: true,
      show1: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_containers {
    position: relative;
    width: 100%;
}

.content_containers [class^="content_"] .title_text {
    text-align: center;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
}

.content_containers [class^="content_"] .body_text {
    text-align: center;
    height: 24px;
    font-size: 16px;
    line-height: normal;
}

.content_wrap,
.content_body {
    position: relative;
}
/*__All*/

.content_containers .content_04 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 256px 0 266px 0;
    background-color: #ebebeb;
    margin-bottom: 257px;
}

.content_containers .content_04 .title_text {
    margin-bottom: 12px;
}

.content_containers .content_04 .body_text {
    margin-bottom: 101px;
}

.content_containers .content_04 .content_body {
    max-width: 844px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -50px;
    margin-bottom: -50px;
    margin-right: inherit;

}

.content_containers .content_04 .content_body div {
    width: calc(100% / 3 - 50px);
    padding: 0 0 50px 50px;
}

.content_containers .content_04 .content_body div img {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13);
}

/*__Default*/


/* Animation */

.content_containers .content_04 .active .title_text,
.content_containers .content_04 .active .body_text {
    opacity: 1;
    transition-duration: .8s;
}

.content_containers .content_04 .title_text,
.content_containers .content_04 .body_text {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}


.content_containers .content_04 .content_body.active div {
    opacity: 1;
    transform: translateY(0);
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.content_containers .content_04 .content_body div {
    opacity: 0;
    transform: translateY(200px);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {} /*__end*/

@media screen and (max-width:1024px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 32px;
        font-size: 20px;
    }

    .content_containers [class^="content_"] .body_text {
        height: 20px;
        font-size: 14px;
    }
  /*__All*/

    .content_containers .content_04 {
        padding: 204.8px 0 212.8px 0;
        margin-bottom: 205.6px;
    }

} /*__end*/

@media screen and (max-width:768px) {
    .content_containers .content_04 .content_body {
        max-width: 90%;
    }

    .content_containers .content_04 .content_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; 
    }

    .content_containers .content_04 .content_body {
        margin-left: -30px;
        margin-bottom: -30px;
    }

    .content_containers .content_04 .content_body div {
        width: calc(100% / 2 - 30px);
        padding: 0 0 30px 30px;
    }

  /* active animation */

    .content_containers .content_04 .content_body div:nth-child(2) {
        transition-delay: 0s;
    }

    .content_containers .content_04 .content_body div:nth-child(1) {
        transition-delay: .2s;
    }

    .content_containers .content_04 .content_body div:nth-child(4) {
        transition-delay: .4s;
    }

    .content_containers .content_04 .content_body div:nth-child(3) {
        transition-delay: .6s;
    }

    .content_containers .content_04 .content_body div:nth-child(6) {
        transition-delay: .8s;
    }
    .content_containers .content_04 .content_body div:nth-child(5) {
        transition-delay: 1s;
    }

} /*__end*/

@media screen and (max-width:425px) {
  /* All */
    .content_containers [class^="content_"] .title_text {
        height: 20px;
        font-size: 14px;
    }

    .content_containers [class^="content_"] .body_text {
        height: auto;
        font-size: 12px;
    }
  /*--All*/

    .content_containers .content_04 .content_body {
        margin-left: -16px;
        margin-bottom: -16px;
    }

    .content_containers .content_04 .content_body div {
        width: calc(100% / 2 - 16px);
        padding-left: 16px;
        padding-bottom: 16px;
    }

    .content_containers .content_04 {
        padding: 94px 0 88px 0;
        margin-bottom: 102px;
    }

    .content_containers .content_04 .body_text {
        margin-bottom: 48px;
    }

} /*__end*/


/*__반응형*/

</style>
