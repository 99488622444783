<template>
         <div class="leaflet_containers">
            <div class="leaflet_content" :class="show0 ? 'active' : ''" style="position: relative;">
            <ShowObserver v-on:isShow="isShowObserver0" class="logo_observer"></ShowObserver>
                <div class="leaflet_title_wrap">
                    <div class="content_title">Leaflet & Card design</div>
                </div>
                <div class="leaflet_img_wrap">
                    <div class="content_body">바로세움 연구소 안내 리플렛과 유형카드 디자인</div>
                    <div class="leaflet_img_bg"></div>
                </div>
            </div>
         </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "BaroDetail0",
  data() {
    return {
      show0: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* All */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.content_title {
    font-family: 'NanumSquareRound', serif;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.content_body {
    font-size: 16px;
    text-align: center;
    line-height: normal;
}
/*__All*/

.leaflet_containers {
    width: 100%;
}

.leaflet_title_wrap {
    position: relative;
    z-index: 10;
}

.leaflet_img_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.leaflet_img_wrap .content_body {
    position: absolute;
    margin-top: 32px;
    top: 0;
}

.leaflet_img_bg {
    width: 100vw;
    height: 0;
    padding-bottom: 40.46875%;
    background-image: url(../../../assets/img/2020_baro/leaflet_img_01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

/*__Default*/


/* Animation */

.leaflet_content.active .content_title,
.leaflet_content.active .content_body {
    opacity: 1;
    transition-duration: .8s;
}

.leaflet_content .content_title,
.leaflet_content .content_body {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
      .content_wrap {
        max-width: 90%;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .content_body {
        font-size: 14px;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:768px) {
    .leaflet_img_bg {
        padding-bottom: 166.6666%;
        background-position: center;
    }
    
} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap, 
    .thumbnail_containers .thumbnail_content {
        max-width: 86.6666vw;
    }
    /*__All*/

} /*__end*/


/*__반응형*/

</style>