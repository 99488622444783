<template>
<div class="productmodeling1_containers">
  <div class="productmodeling_sub_text numbering">
    <div class="sub_text_left">PRODUCT MODELING</div>
    <div class="sub_text_right">04</div>
  </div>
  <div class="productmodeling_content content_wrap">
    <!-- wrap -->
    <div class="productmodeling1_item1">
      <span class="tit_text" :class="show0 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
        True Beauty<br>
        Begins with Getting<br>
        to Know Me
      </span>
      <div class="item1_img" :class="show1 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver>
      <img src="@/assets//img/2021_ccb/productmodeling1_01.jpg" alt="image">
      </div>
      <span class="body_text" :class="show2 ? 'active' : ''" style="position: relative;">
      <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver>
        별도 스튜디오 촬영 대신 실 제품 모델링과 컨셉 <br class="active_425w">스타일링을 자체적으로 완료했습니다.<br
          class="active_425w"><br class="inactive_1280w">
        제품, 그 외 조형물 및 라이팅 이펙트를 자체 세팅해 <br class="active_425w">브랜드의 강점인 '자연'과 '맞춤형'<br class="inactive_1280w">
        정보가 어우러지도록 진행했습니다.<br class="inactive_1280w">
      </span>
    </div>
    <div class="productmodeling1_img">
      <div class="productmodeling1_item2" :class="show3 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver>
        <img src="@/assets//img/2021_ccb/productmodeling1_02.jpg" alt="image">
      </div>
      <div class="productmodeling1_item3" :class="show4 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver>
        <img src="@/assets//img/2021_ccb/productmodeling1_03.jpg" alt="image">
      </div>
    </div>
  </div><!-- //wrap -->
  <div class="productmodeling1_bg"></div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail4",
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
     isShowObserver1(value: boolean) {
      this.show1 = value;
    },
     isShowObserver2(value: boolean) {
      this.show2 = value;
    },
     isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    color: #393531;
}
/*__ALl*/

.productmodeling1_containers {
    width: 100%;
    position: relative;
    padding-bottom: 287px;
    content-visibility: auto;
}

.productmodeling1_containers .productmodeling_content {
    display: flex;
    justify-content: space-between;
    margin-top: 194px;
}

.productmodeling_content .productmodeling1_item1 .tit_text {
    display: block;
    height: 210px;
    line-height: 1.35;
    margin-bottom: 56px;
}

.productmodeling_content .productmodeling1_item1 .body_text {
    display: block;
}

.productmodeling_content .productmodeling1_item1 img {
    margin-bottom: 40px;
}

.productmodeling1_containers .productmodeling_content .productmodeling1_img .productmodeling1_item2 {
    margin-bottom: 52px;
}

.productmodeling1_containers::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    z-index: -10;
    width: 100%;
    height: 1080px;
    background-color: #f1ede4;
}

/*__Default*/


/* Animation */

.productmodeling1_item1 .tit_text.active,
.productmodeling1_item1 .item1_img.active,
.productmodeling1_item1 .body_text.active,
.productmodeling1_img .productmodeling1_item2.active,
.productmodeling1_img .productmodeling1_item3.active {
    opacity: 1;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
}

.productmodeling1_item1 .tit_text,
.productmodeling1_item1 .item1_img,
.productmodeling1_item1 .body_text,
.productmodeling1_img .productmodeling1_item2,
.productmodeling1_img .productmodeling1_item3 {
    opacity: 0;
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Noto Sans KR', sans-serif;
        color: #393531;
    }
    /*--All*/

    .productmodeling_content > [class^="productmodeling1_"] {
        width: 47.833333%;
    }
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .productmodeling1_containers {
        padding-bottom: 7.69230%;
    }

    .productmodeling1_containers .productmodeling_content {
        margin-top: 13.7820%;
    }

    .productmodeling1_containers .productmodeling_content {
        flex-direction: column;
    }

    .productmodeling_content > [class^="productmodeling1_"] {
        width: 100%;
    }

    .productmodeling_content .productmodeling1_item1 .tit_text {
        height: auto;
        line-height: normal;
        letter-spacing: -0.15px;
    }

    .productmodeling_content .productmodeling1_item1 {
        margin-bottom: 14.74358%;
    }

    .productmodeling_content .productmodeling1_item1 img {
        margin-bottom: 6.41025%;
    }

    .productmodeling1_containers::before {
        height: 0;
        padding-bottom: 295.51282%;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/

} /*__end*/


/*__반응형*/

</style>
