
  import {
    defineComponent
  } from "vue";
  import ShowObserver from "@/components/ShowObserver.vue";

  export default defineComponent({
    name: "CcbDetail8",
    data() {
      return {
        show0: false,
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
      }
    },
    components: {
      ShowObserver
    },
    methods: {
      isShowObserver0(value: boolean) {
        this.show0 = value;
      },
      isShowObserver1(value: boolean) {
        this.show1 = value;
      },
      isShowObserver2(value: boolean) {
        this.show2 = value;
      },
      isShowObserver3(value: boolean) {
        this.show3 = value;
      },
      isShowObserver4(value: boolean) {
        this.show4 = value;
      },
      isShowObserver5(value: boolean) {
        this.show5 = value;
      },
    }
  });
