<template>
  <div class="fullpage">
    <ThemeObserver :theme=theme v-on:isShow="isShowObserver0"/>
    <vue100vh :css="{height: '100rvh'}">
   <div class="main_wrap" :class="show0 ? 'active' : ''" style="position: relative;">
     <ul>
       <li class="txt_wrap_01">
         <h3><span class="txt_ani_1">This is</span> <br class="active_820w"><a href="javascript:void(0);"><router-link to="/#about"><span class="txt_ani_2">Wacky Company</span></router-link></a></h3>
       </li>
       <li class="txt_wrap_02">
         <h3><span class="txt_ani_3">We Design</span> <br class="active_820w"><a href="javascript:void(0);"><router-link to="/#works"><span class="txt_ani_4">User eXperience</span></router-link></a></h3>
       </li>
       <li class="txt_wrap_03">
         <h3><span class="txt_ani_5">for our Better Life</span></h3>
       </li>
       <li class="txt_wrap_04">
         <h3><span class="txt_ani_6">If You Want</span> <br class="active_820w"><a href="javascript:void(0);"><router-link to="/#contact"><span class="txt_ani_7">Contact Us</span></router-link></a></h3>
       </li>
     </ul>
   </div>
  </vue100vh>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import {Theme} from "@/enums/Theme";
import vue100vh from '@/components/Vue100vh.vue';

export default defineComponent({
  name: "Home",
  data() {
    return {
      show0: false,
      theme: Theme.hide,
    }
  },
  components: {
    ThemeObserver,
    vue100vh,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<style scoped lang="css">

/* Default */

#home::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0e0a1d;
  background-blend-mode: multiply;
  opacity: .2;
}

div.fullpage {
    height: 100%;
}

.main_wrap {
    position: relative;
    display: flex;
    top: calc(var(--responsive) * 208);
    width: calc(var(--responsive) * 1200);
    margin: 0 auto;
    height: 100%;
    z-index: 100;
    overflow: hidden;
} 

#home::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 30vh;
    background: linear-gradient(180deg,rgba(000,000,000,0),rgba(000,000,000,.8) 75%,#000);
    z-index: 100;

}

.main_wrap ul {
    position: relative;
    overflow: hidden;
    z-index: 9999;
}

.main_wrap ul li {
    font-size: calc(var(--responsive) * 80);
    font-weight: 500;
    color: #fff;
    height: 1.212em;
    line-height: 1.212em;
    letter-spacing: -0.025em;
    font-family: 'Roboto', sans-serif;
}

.main_wrap li[class^="txt_wrap_"] h3  {
  position: relative;
  display: inline-block;
}

.main_wrap ul li a {
    color: #fff;
}

.main_wrap ul li a:hover {
    color: #fff400;
    transition: .4s ease-in-out;
}

.main_wrap ul li.txt_wrap_04 {
    margin-top: 0.350em;
}

.main_wrap ul li a {
    position: relative;
    width: 100%;
}

.main_wrap ul li a::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 10.5%;
    opacity: 0;
}

/*__Default*/


/* Animation */

  .main_wrap.active li[class^="txt_wrap_"] h3 {
    animation: maintxt_ani 1000ms cubic-bezier(0.22, 0.61, 0.36, 1) both;
  }

  .main_wrap.active li.txt_wrap_01 h3 {
    animation-delay: 500ms;
  }

  .main_wrap.active li.txt_wrap_02 h3 {
    animation-delay: 800ms;
  }

  .main_wrap.active li.txt_wrap_03 h3 {
    animation-delay: 1100ms;
  }

  .main_wrap.active li.txt_wrap_04 h3 {
    animation-delay: 1400ms;
  }

  @keyframes maintxt_ani {
    0% {
      transform: translateX(-102%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .main_wrap ul li a:hover::before {
      background-color: #fff400;
      transition: .4s ease-in-out;
  } 
  
  .main_wrap.active ul li a::before {
      content: "";
      animation: home_line 1500ms ease-in-out both;
      animation-delay: 700ms;
  }
  
  @keyframes home_line {
     0% {
       opacity: 0;
     }
     100% {
       opacity: 1;
     }
  }

/*__Animation*/

/* 반응형 */

@media screen and (max-width:820px) {

  .main_wrap {
    width: calc(var(--responsive) * 312);
    top: calc(var(--responsive) * 64);
  }

  .main_wrap ul li {
    font-size: min(calc(var(--responsive) * 40), 58px) ;
    line-height: 1.200em;
    height: auto;
  }

  .main_wrap ul li.txt_wrap_04 {
    margin-top: 1.350em;
  }

  /* Animation */

  .main_wrap.active li[class^="txt_wrap_"] h3 {
    animation: initial;
  }

  .main_wrap.active li[class^="txt_wrap_"] h3 span {
    display: inline-block;
    animation: maintxt_ani 1000ms cubic-bezier(0.22, 0.61, 0.36, 1) both;
  }

  .main_wrap.active li h3 span.txt_ani_1 {
    animation-delay: 300ms;
  }

  .main_wrap.active li h3 span.txt_ani_2 {
    animation-delay: 500ms;
  }

  .main_wrap.active li h3 span.txt_ani_3 {
    animation-delay: 700ms;
  }

  .main_wrap.active li h3 span.txt_ani_4 {
    animation-delay: 900ms;
  }

  .main_wrap.active li h3 span.txt_ani_5 {
    animation-delay: 1100ms;
  }

  .main_wrap.active li h3 span.txt_ani_6 {
    animation-delay: 1300ms;
  }

  .main_wrap.active li h3 span.txt_ani_7 {
    animation-delay: 1500ms;
  }

  .main_wrap.active ul li a::before {
    animation-delay: 1700ms;
  }

  /*__Animation */

} /*__end*/

/*__반응형*/

</style>
