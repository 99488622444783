<template>
  <div class="container">
    <ThemeObserver :theme=theme />
    <div class="container-inner">
      <div class="title" :class="show0 ? 'active' : ''" style="position: relative">
      <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver> 
        <h3>
          <span class="point-color">Content</span>
          <br>Screen Design<span class="point-color">.</span>
        </h3>
        <p><span class="inactive_820w">현 스크린은 전시장의 </span>실제 스크린과 상이할 수 있습니다</p>
      </div>
      <div class="screen-01" :class="show1 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver1"></ShowObserver> 
      </div>
      <div class="screen-02" :class="show2 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver2"></ShowObserver> 
      </div>
      <div class="screen-03" :class="show3 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver3"></ShowObserver> 
      </div>
      <div class="screen-04" :class="show4 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver4"></ShowObserver> 
      </div>
      <div class="copy" :class="show5 ? 'active' : ''">
        <ShowObserver v-on:isShow="isShowObserver5"></ShowObserver> 
        <span>&copy; </span><p>2021 Wacky</p>
      </div>
    </div><!-- //inner -->
    <div class="container-bg"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShowObserver from "@/components/ShowObserver.vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "SeoyonehDetail6",
  data() {
    return {
      theme: Theme.white,
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    };
  },
  components: {
    ThemeObserver,
    ShowObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
    isShowObserver1(value: boolean) {
      this.show1 = value;
    },
    isShowObserver2(value: boolean) {
      this.show2 = value;
    },
    isShowObserver3(value: boolean) {
      this.show3 = value;
    },
    isShowObserver4(value: boolean) {
      this.show4 = value;
    },
    isShowObserver5(value: boolean) {
      this.show5 = value;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* pc 1280 ~ 1920 */
/* Default */

.container {
  position: relative;
  width: 100vw;
  height: calc(var(--responsive) * 2797);
  color: #fff;
  background:  #131C33;
  font-family: var(--pretendard);
  overflow: hidden;
}

.container-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/img/2021_seoyoneh/detail06-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.container-bg::before {
  content: '';
  position: absolute;
  top: -50.67708vw;
  right: -44.11458vw;
  width: calc(var(--responsive) * 1694);
  height: calc(var(--responsive) * 1694);
  border-radius: 100%;
  background: rgba(151, 83, 255, 0.2);
  filter: blur(5vw);
  -webkit-filter: blur(5vw);
}

.container-bg::after {
  content: '';
  position: absolute;
  bottom: -50.67708vw;
  left: -44.11458vw;
  width: calc(var(--responsive) * 1694);
  height: calc(var(--responsive) * 1694);
  border-radius: 100%;
  background: rgba(122, 75, 255, 0.2);
  filter: blur(5vw);
  -webkit-filter: blur(5vw);
}

.container-inner {
  position: relative;
  display: flex;
  width: calc(var(--responsive) * 1200);
  height: 100%;
  margin: 0 auto;
  z-index: 5;
}

/* title */

.title {
  width: 100%;
  margin-top: calc(var(--responsive) * 181.53);
  margin-bottom: calc(var(--responsive) * 214);
  text-align: center;
}

.title h3 {
  font-size: calc(var(--responsive) * 54);
  line-height: 1.333em;
  font-weight: 400;
  margin-bottom: 0.796em;
}

.point-color {
  color: #A85BFF;
}

.title p {
  font-size: calc(var(--responsive) * 14);
  line-height: 1.714em;
  font-weight: 400;
  color: #65749D;
}

/* screen */

[class^='screen-0'] {
  position: absolute;
  width: calc(var(--responsive) * 424);
  height: calc(var(--responsive) * 754);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

[class^='screen-0']::before {
  position: absolute;
  color: #fff;
  font-size: calc(var(--responsive) * 18);
}

.screen-01 {
  top: calc(var(--responsive) * 604);
  left: calc(var(--responsive) * 166);
  background-image: url("../../../assets/img/2021_seoyoneh/detail06-screen-01.webp");
}

.screen-01::before {
  content: 'MUSIC PLAYER';
  writing-mode: vertical-rl;
  top: calc(var(--responsive) * 30);
  left: calc(var(--responsive) * -21);
  transform: translate3d(-100%, 0, 0) rotate(180deg);
}

.screen-02 {
  top: calc(var(--responsive) * 827);
  left: calc(var(--responsive) * 614);
  background-image: url("../../../assets/img/2021_seoyoneh/detail06-screen-02.webp");
}

.screen-02::before {
  content: 'VEDIO PLAYER';
  writing-mode: vertical-rl;
  top: calc(var(--responsive) * 30);
  right: calc(var(--responsive) * -21);
  transform: translate3d(100%, 0, 0);
}


.screen-03 {
  top: calc(var(--responsive) * 1382);
  left: calc(var(--responsive) * 166);
  background-image: url("../../../assets/img/2021_seoyoneh/detail06-screen-03.webp");
}

.screen-03::before {
  content: 'RADIO';
  writing-mode: vertical-rl;
  top: calc(var(--responsive) * 30);
  left: calc(var(--responsive) * -21);
  transform: translate3d(-100%, 0, 0) rotate(180deg);
}

.screen-04 {
  top: calc(var(--responsive) * 1605);
  left: calc(var(--responsive) * 614);
  background-image: url("../../../assets/img/2021_seoyoneh/detail06-screen-04.webp");
}

.screen-04::before {
  content: 'SETTING';
  writing-mode: vertical-rl;
  bottom: calc(var(--responsive) * 30);
  right: calc(var(--responsive) * -21);
  transform: translate3d(100%, 0, 0);
}

/* copy */

.copy {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: calc(var(--responsive) * 97);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-size: calc(var(--responsive) * 14);
  font-weight: 400;
}

.copy span {
  font-size: 1.571em;
  font-weight: 200;
  margin-right: 0.17em;
}

/*__Default*/

/* Animation */

 .title,
 .screen-01,
 .screen-02,
 .screen-03,
 .screen-04,
 .screen-01::before,
 .screen-02::before,
 .screen-03::before,
 .screen-04::before,
 .copy {
   opacity: 0;
 }

 .title.active,
 .screen-01.active,
 .screen-02.active,
 .screen-03.active,
 .screen-04.active,
 .copy.active {
   animation: ani01 1000ms ease-in-out 1 forwards;
 }

 .screen-01.active::before,
 .screen-02.active::before,
 .screen-03.active::before,
 .screen-04.active::before {
   animation: ani01 1000ms ease-in-out 400ms 1 forwards;
 }

@keyframes ani01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*__Animation */

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/


@media screen and (max-width: 820px) {

  .container {
    height: calc(var(--responsive) * 2088);
  }

  /* .container::before {
    background-image: url("../../../assets/img/2021_seoyoneh/detail06-bg-02.webp");
  } */

  .container-bg {
    background-size: auto 100%;
    background-position-x: 50%;
  }

  .container-bg::before {
    top: -55vw;
    right: -55vw;
    width: calc(var(--responsive) * 400);
    height: calc(var(--responsive) * 400);
    filter: blur(15vw);
    -webkit-filter: blur(15vw);
  }

  .container-bg::after {
    bottom: -55vw;
    left: -55vw;
    width: calc(var(--responsive) * 400);
    height: calc(var(--responsive) * 400);
    filter: blur(15vw);
    -webkit-filter: blur(15vw);
  }

  .container-inner {
    width: calc(var(--responsive) * 280);
  }

  /* title */

  .title {
  margin-top: calc(var(--responsive) * 82);
  margin-bottom: calc(var(--responsive) * 92);
  }

  .title h3 {
    font-size: calc(var(--responsive) * 30);
    line-height: 1.200em;
    margin-bottom: 0.800em;
  }

  .title p {
    font-size: calc(var(--responsive) * 13);
  }

  /* screen */

  [class^='screen-0'] {
    width: calc(var(--responsive) * 210);
    height: calc(var(--responsive) * 373.33);
  }

  [class^='screen-0']::before {
    font-size: calc(var(--responsive) * 12);
  }

  .screen-01 {
    top: calc(var(--responsive) * 292);
    left: 0;
  }

  .screen-01::before {
    top: calc(var(--responsive) * 13);
    left: initial;
    right: calc(var(--responsive) * -12);
    transform: translate3d(100%, 0, 0) rotate(0deg);
  }

  .screen-02 {
    top: calc(var(--responsive) * 1080);
    left: 0;
  }

  .screen-02::before {
    top: calc(var(--responsive) * 13);
    right: calc(var(--responsive) * -12);
  }

  .screen-03 {
    top: calc(var(--responsive) * 686);
    left: initial;
    right: 0;
  }

  .screen-03::before {
    top: calc(var(--responsive) * 13);
    left: calc(var(--responsive) * -12);
  }

  .screen-04 {
    top: calc(var(--responsive) * 1474);
    left: initial;
    right: 0;
  }

  .screen-04::before {
    top: calc(var(--responsive) * 13);
    bottom: initial;
    left: calc(var(--responsive) * -12);
    right: initial;
    transform: translate3d(-100%, 0, 0) rotate(180deg);
  }

  /* copy */
  
  .copy {
    bottom: calc(var(--responsive) * 50);
    font-size: calc(var(--responsive) * 11);
  }
  

} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/* 노트북 해상도? */
/* @media screen and (max-height:980px) and (device-aspect-ratio:16/9), 
screen and (max-height:980px) and (device-aspect-ratio:16/10), {
    
}
@media screen and (max-height:800px) and (device-aspect-ratio:16/9), 
screen and (max-height:800px) and (device-aspect-ratio:16/10), {
    
} */

/*__반응형*/
</style>
