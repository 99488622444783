<template>
        <div class="about_containers" :class="show0 ? 'active' : ''" style="position: relative;">
        <ShowObserver v-on:isShow="isShowObserver0"></ShowObserver>
          <div class="about_sub_text numbering">
            <div class="sub_text_left">ABOUT</div>
            <div class="sub_text_right">01</div>
          </div>
          <div class="about_content content_wrap">  
            <!-- wrap -->
            <div class="about_text">
              <ul>
                <li class="tit_text">About the Project</li>
                <li class="body_text"><span>CC Booum은 소비자의 피부타입 및 고민을 고려하여 <br class="active_425w">좋은 원료와<br
                      class="inactive_1280w">
                    성분을 담은 화장품을 생산하고 있으며, <br class="active_425w">친화경 소재를 활용한 포장과<br class="inactive_1280w">
                    비동물실험을 실천하는 <br class="active_425w">자연친화적인 브랜드입니다.</span>
                  <span>
                    와키컴퍼니에서는 CC Booum 의 Brand Value를 <br class="active_425w">높이고, 사용자에게는<br class="inactive_1280w">
                    단순 소모품인 화장품을 넘어 <br class="active_425w">나를 알아가고 라이프스타일의 건강한<br class="inactive_1280w">
                    변화를 추구하는 <br class="active_425w">I Love &amp; We Love 기업 철학이 담긴 브랜딩 &amp; 디지털<br
                      class="inactive_1280w">
                    <br class="active_425w">컨설팅을 진행했습니다.</span>
                </li>
              </ul>
            </div>
            <div class="about_img_wrap">
              <div class="about_img01"><img src="@/assets/img/2021_ccb/about_01.jpg" alt="image" class="inactive_425w"><img
                  src="@/assets/img/2021_ccb/m_about_01.jpg" alt="image" class="active_425w"></div>
              <div class="about_img02"><img src="@/assets/img/2021_ccb/about_02.jpg" alt="image"></div>
            </div>
          </div><!-- //wrap -->
        </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ShowObserver from "@/components/ShowObserver.vue";

export default defineComponent({
  name: "CcbDetail1",
  data() {
    return {
      show0: false,
    }
  },
  components: {
    ShowObserver
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Default */

/* ALl */
.content_wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.numbering {
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.numbering div:nth-child(1) {
    padding-left: 6.6%;
}

.numbering div:nth-child(2) {
    padding-right: 6.6%;
}

.tit_text {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.33px;
    font-family: 'Noto Serif KR', serif;
    color: #47423b;
    line-height: normal;
}

.body_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #393531;
}
/*__ALl*/

.about_containers {
    width: 100%;
    height: auto;
    background-color: #E9E0CE;
    padding-bottom: 140px;
}

.about_containers .about_content {
    display: flex;
    justify-content: space-between;
    margin-top: 62px;
}

.about_containers .about_content .about_text {
    width: 39.16666%;
}

.about_containers .about_content .about_text ul li:nth-child(1) {
    height: 75px;
    padding: 141px 0 46px 0;
}

.about_containers .about_content .about_text ul li:nth-child(2) {
    height: 192px;
    padding-bottom: 121px;
}

.about_containers .about_content .about_text ul li span {
    display: block;
    width: 100%;
}

.about_containers .about_content .about_text ul li span:first-child {
    margin-bottom: 24px;
}

.about_containers .about_content .about_img_wrap {
    width: 51%;
    margin-left: 9.83333%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.about_containers .about_content .about_img_wrap div:nth-child(1) {
    width: 93.79084%;
    margin-right: 71px;
}

.about_containers .about_content .about_img_wrap div:nth-child(2) {
    width: 41.50326%;
    margin-left: auto;
    margin-top: -195px;
    z-index: 1;
}

.about_containers .about_content .about_img_wrap div:nth-child(2) img {
    box-shadow: -4px -4px 16px rgba(137, 104, 66, 0.15);
}


/*__Default*/


/* Animation */

.about_containers.active .about_text {
    opacity: 1;
    transition-delay: .7s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    transition-property: opacity;
} 

.about_containers .about_text {
    opacity: 0;
} 

.about_containers.active .about_img01 {
    opacity: 1;
    transition-delay: 1.2s;
    transform: translatex(0);
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transition-property: transform, opacity;
}

.about_containers .about_img01 {
    opacity: 0;
    transform: translatex(40px);
}

.about_containers.active .about_img02 {
    opacity: 1;
    transition-delay: 1.6s;
    transform: translateY(0);
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-property: transform, opacity;
}

.about_containers .about_img02 {
    opacity: 0;
    transform: translateY(40px);
}

/*__Animation*/


/* 반응형 */

@media screen and (max-width:1280px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 90%;
    }

    .numbering div {
        font-size: 14px;
    }

    .numbering div:nth-child(1) {
        padding-left: inherit;
    }

    .numbering div:nth-child(2) {
        padding-right: inherit;
    }

    .tit_text {
        font-size: 41.6px;
        font-weight: 600;
        letter-spacing: -0.33px;
        font-family: 'Noto Serif KR', serif;
        color: #47423b;
        line-height: normal;
    }

    .body_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        color: #393531;
    }
    /*--All*/
    
} /*__end*/

@media screen and (max-width:1024px) {
    /* All */
    .numbering {
        padding-top: 7.6923%;
    }
    /*--All*/

    .about_containers .about_content {
        flex-direction: column;
        margin-top: 14.42307%;
    }

    .about_containers .about_content .about_text {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .about_containers .about_content .about_text ul li:nth-child(1) {
        text-align: center;
        padding: 0 0 7.6923% 0;
    }

    .about_containers .about_content .about_text ul li:nth-child(2) {
        height: auto;
        padding-bottom: 14.42307%;
    }

    .about_containers .about_content .about_img_wrap {
        width: 100%;
        margin-left: inherit;
    }

} /*__end*/

@media screen and (max-width:768px) {
    /* All */
      .tit_text {
        font-size: 33px;
    }
    /*__All*/

    .about_containers .about_content .about_text ul li:nth-child(1) {
        height: auto;
    }

} /*__end*/

@media screen and (max-width:425px) {
    /* All */
    .content_wrap,
    .numbering {
        max-width: 86.66666vw;
    }

    .tit_text {
        font-size: 24px;
    }

    .numbering div {
        font-size: 10px;
    }
    /*--All*/
    
    .about_containers .about_content .about_img_wrap div:nth-child(1) {
        width: 84.61538%;
    }
    
    .about_containers .about_content .about_img_wrap div:nth-child(2) {
        margin-top: -8.97435%;
    }

} /*__end*/


/*__반응형*/

</style>
